import React from "react";
import PermissionSettings from "comps/PermissionSettings";
import usePageTitle from "hooks/usePageTitle";
import { OnboardingContainer } from "themes/theme";

const PermissionsVerify = () => {

  usePageTitle('Verify Permissions');

  return (
    <OnboardingContainer>
      <div className="x-spacer-sm" />
      <PermissionSettings continue="/home"></PermissionSettings>
    </OnboardingContainer>
  );
};

export default PermissionsVerify;
