import RedbullTheme from "assets/RedBull_Theme.jpg";
import { PaletteMode, createTheme, Palette } from "@mui/material";
import { generateThemeContrastColorDarker } from "colors/Utils";

const redbullPalette = (mode: PaletteMode, screenCast: boolean): Palette => {
  const themeBase = createTheme();

  return createTheme({
    palette: {
      name: "xrai-redbull-palette",
      screenCast,
      mode,
      ...{
        image: {
          main: `url(${RedbullTheme})`,
          panel: undefined,
          neutral: undefined,
          paper: undefined,
        },
        panel: {
          main: screenCast ? "#000000" : generateThemeContrastColorDarker("#0020A2", mode),
        },
        background: {
          default: screenCast ? "#000000" : "#0020A2",
          paper: screenCast ? "#000000" : "#0020A2",
        },
        primary: {
          main: "#ED1C24",
          contrastText: screenCast ? "#FFFFFF" : "#FFF",
        },
        secondary: {
          main: "#ED1C24",
          contrastText: screenCast ? "#FFFFFF" : "#FFF",
        },
        action: {
          ...themeBase.palette.action,
          active: "#FFF",
          hover: "rgba(255, 255, 255, 0.08)",
          selected: "rgba(255, 255, 255, 0.15)",
          selectedOpacity: 0.15,
          focus: "rgba(255, 255, 255, 0.25)",
        },
        text: {
          primary: "#FFF",
        },
        success: {
          main: "#2e7d32",
        },
      },
    },
  }).palette;
};

export default redbullPalette;
