import React from "react";
import CustomTabPanel from "./CustomTabPanel";
import AdjustableControl from "comps/common/AdjustableControl";

import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { FormatAlignJustify, FormatAlignLeft } from "@mui/icons-material";
import { Box, Button, List } from "@mui/material";

import { useStoreLocal } from "state/store";
import { TFunction } from "i18next";

type Props = {
  activeTab: number;
  t: TFunction<"translation", undefined, "translation">; // Translation
  index: number;
};

const FontsScreen: React.FC<Props> = ({ activeTab, t, index }) => {
  // global persistent state
  const [fontSize, setFontSize] = useStoreLocal<number>("fontSize");
  const [textAlign, setTextAlign] = useStoreLocal<string>("textAlign");
  const [fontWeight, setFontWeight] = useStoreLocal<number>("fontWeight");
  const [, setPanelHeight] = useStoreLocal<number>("panelHeight");
  const [textTransform, setTextTransform] =
    useStoreLocal<string>("textTransform");
  //const [fontFamily   , setFontFamily   ] = useStoreLocal<string> ("fontFamily"    );

  const textTransformOptions = ["none", "uppercase", "capitalize", "lowercase"];

  const fontWeightOptions = [300, 600, 1000];

  const toggleTextTransform = () => {
    const currentIndex = textTransformOptions.indexOf(textTransform);
    const nextIndex = (currentIndex + 1) % textTransformOptions.length;
    setTextTransform(textTransformOptions[nextIndex]);
  };

  const resetFontDefaults = () => {
    setFontSize(1);
    //setFontFamily("Roboto");
    setTextAlign("left");
    setTextTransform("none");
    setFontWeight(400);
    setPanelHeight(40);
  };

  const handleTextAlign = () => {
    setTextAlign((textAlign) =>
      textAlign === "left"
        ? "center"
        : textAlign === "center"
        ? "right"
        : textAlign === "right"
        ? "justify"
        : "left"
    );
  };

  const handleTextTransform = () => {
    const currentIndex = fontWeightOptions.indexOf(fontWeight);
    const nextIndex = (currentIndex + 1) % fontWeightOptions.length;
    setFontWeight(fontWeightOptions[nextIndex]);
  };

  /*
  const fontFamilyOptions = [
    { name: "Roboto"      , style: "sans-serif" }, // Android font that has a balanced combination of mechanical & geometric forms, highly legible
  //{ name: "Arial"       , style: "sans-serif" }, // Known for its simplicity and clarity
  //{ name: "Atkinson H." , style: "sans-serif" }, // Specifically designed font by the Braille Institute to improve legibility for users with low vision
  //{ name: "Futura"      , style: "sans-serif" }, // Futura is a geometric sans-serif typeface designed by Paul Renner and released in 1927
  //{ name: "Georgia"     , style: "serif"      }, // Highly legible font for low-resolution screens, comfortable to read on screen despite having serifs
    { name: "Literata"    , style: "serif"      }, // Designed by Google for their Play Books app, easy on the eyes and highly legible
  //{ name: "Tahoma"      , style: "sans-serif" }, // Another Microsoft font, known for its character spacing that helps in readability
  //{ name: "Trebuchet MS", style: "sans-serif" }, // Has wide letters and tall lowercase letters, making it a good choice for on-screen reading
  //{ name: "Verdana"     , style: "sans-serif" }, // Designed for Microsoft, and known for its large lowercase characters which improves readability
  ];
  */

  /*
  const toggleFontFamily = () => {
    const currentIndex = fontFamilyOptions
      .map((font) => font.name)
      .indexOf(fontFamily);
    const nextIndex = (currentIndex + 1) % fontFamilyOptions.length;
    setFontFamily(fontFamilyOptions[nextIndex].name);
  };
  */

  /*
  useEffect(() => {
    updateTheme({
      typography: {
        fontFamily: fontFamily,
      },
    });
  // warning: updateTheme infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontFamily]);
  */

  return (
    <CustomTabPanel value={activeTab} index={index}>
      <div>
        <List dense={true}>
          {/* Removed until this works correctly across platforms
                    <ListItem
                      sx={{}}
                      secondaryAction={
                        <Button
                          variant="text"
                          sx={{
                            textTransform: "none",
                            fontFamily: fontFamily,
                            fontSize: theme.typography.body2.fontSize,
                          }}
                          onClick={toggleFontFamily}
                        >
                          {fontFamily}
                        </Button>
                      }
                    >
                      <ListItemIcon>
                        <FormatSizeIcon
                          sx={{
                            color: "var(--text-contrast) !important",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemTextStyle primary={t("Font")} />
                    </ListItem>
                    */}

          <AdjustableControl
            label={t("Size")}
            value={100 * fontSize}
            min={60}
            max={550}
            mult={1.075}
            decimalPlaces={0}
            icon={<TextIncreaseIcon />}
            onValueChange={(newValue) => {
              setFontSize(newValue / 100);
            }}
            units="%"
          />

          <AdjustableControl
            mode="button"
            label={t("Transform")}
            value={textTransform}
            icon={<FontDownloadIcon />}
            onValueChange={toggleTextTransform}
            hideValueLabel
            renderSliderCondition={false}
          />

          <AdjustableControl
            mode="button"
            label={t("FontWeight")}
            value={fontWeight}
            icon={<FormatBoldIcon />}
            onValueChange={handleTextTransform}
            hideValueLabel
            renderSliderCondition={false}
          />

          <AdjustableControl
            mode="button"
            label={t("Text")}
            value={textAlign}
            icon={<FormatAlignLeft />}
            buttonChildren={<FormatAlignJustify />}
            onValueChange={handleTextAlign}
            renderSliderCondition={false}
          />
        </List>

        <Box>
          <Button
            variant="contained"
            color="error"
            startIcon={<RestartAltIcon />}
            onClick={resetFontDefaults}
          >
            {t("Reset")}
          </Button>
        </Box>
      </div>
    </CustomTabPanel>
  );
};

export default FontsScreen;
