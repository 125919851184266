import React from "react";

type Props = {
  size?: number;
  style?: React.CSSProperties;
}

export default function VoskLogo(props: Props) {
  const size = `${props.size || 24}px`;
  return (
    <svg
      style={props?.style}
      width={size}
      height={size}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M65 67L81.875 89.5L65 112L48.125 89.5L65 67Z" fill="#61A69C" />
      <path d="M65 22L81.875 44.5L65 67L48.125 44.5L65 22Z" fill="#91C0B9" />
      <path
        d="M81.875 44.5L98.75 67L81.875 89.5L65 67L81.875 44.5Z"
        fill="#7DB9B3"
      />
      <path
        d="M98.75 22L115.625 44.5L98.75 67L81.875 44.5L98.75 22Z"
        fill="#B4DBD9"
      />
      <path
        d="M48.125 44.5L31.25 67L48.125 89.5L65 67L48.125 44.5Z"
        fill="#438D82"
      />
      <path
        d="M31.25 22L14.375 44.5L31.25 67L48.125 44.5L31.25 22Z"
        fill="#25796C"
      />
    </svg>
  );
}
