import React from "react";

import EmptyContent from "comps/common/EmptyContent";
import { SubtitlesNotStartedSvg } from "comps/SvgAssets";

import { Container, Skeleton, Typography, styled } from "@mui/material";
import { CaptionStatus } from "types/types";
import { TFunction } from "i18next";

type Props = {
  captionStatus: CaptionStatus;
  subtitleSeqN: number | null;
  pendingStatus: () => boolean;
  t: TFunction<"translation", undefined, "translation">; // Translation
  getTheme: () => any;
};

const FlashingContainer = styled(Skeleton)({
  padding: "12px 24px",
  borderRadius: "1000px",
});

const ButtonSkeletons: React.FC<Props> = ({
  captionStatus,
  subtitleSeqN,
  pendingStatus,
  t,
  getTheme,
}) => (
  <Container>
    {/* Show Listening Status until we get some subtitles */}
    {captionStatus.status === "confirmStart" && !subtitleSeqN && (
      <EmptyContent
        title={
          <Typography variant="body1" component="span">
            <FlashingContainer variant="rounded">
              {globalThis.isXraiGo ? t("caption.wait") : t("caption.listening")}
              ...
            </FlashingContainer>
          </Typography>
        }
      />
    )}

    {/* Show Paused until we get some subtitles */}
    {captionStatus.status === "confirmPause" && !subtitleSeqN && (
      <EmptyContent
        title={
          <Typography variant="body1" component="span">
            <FlashingContainer variant="rounded">
              {t("Subtitling.paused")}...
            </FlashingContainer>
          </Typography>
        }
      />
    )}

    {/* Show Stopped until we get some subtitles */}
    {captionStatus.status === "confirmStop" && !subtitleSeqN && (
      <EmptyContent
        title={t("caption.not_started")}
        message={t("caption.startPrompt")}
        footer={
          <SubtitlesNotStartedSvg color={getTheme().palette.primary.light} />
        }
      />
    )}

    {/* Show Loading until we get some subtitles */}
    {pendingStatus() && !subtitleSeqN && (
      <EmptyContent
        title={
          <Typography variant="body1" component="span">
            <FlashingContainer variant="rounded">
              {t("Loading")}...
            </FlashingContainer>
          </Typography>
        }
      />
    )}
  </Container>
);

export default ButtonSkeletons;
