import { useState, useCallback } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useStore } from "state/store";

export const useMinimize = () => {
  const [idle, setIdle] = useState(false);
  const [screenCast] = useStore<boolean>("screenCast");
  const [activePopup] = useStore<boolean>("activePopup", false);

  const onIdle = useCallback(() => {
    setIdle(true);
  }, [setIdle]);

  const onActive = useCallback(() => {
    setIdle(false);
  }, [setIdle]);

  const minimize = !(
    activePopup ||
    !idle ||
    !screenCast ||
    window.location.pathname !== "/subtitles"
  );

  const isIdle = idle && screenCast;

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 3000,
  });

  return {
    isIdle,
    minimize,
  };
};

export default useMinimize;
