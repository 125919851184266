import { Slider } from "@mui/material";
import { SxProps } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Setting } from "types/types";
import { getSetting, setSetting } from "utils/settings";


interface Props {
  sx?: SxProps
  color?: any
}

export default function ArFontSizeSelector(props:Props) {
  const { t } = useTranslation();
  let [currentValue, setCurrentValue] = useState<number>(1);
  let [marks, setMarks] = useState<Array<{value: number, label:string | undefined}>>([]);
  let [min, setMin] = useState(-1)
  let [max, setMax] = useState(1);
  
  const handleChange = (_:any, newValue : number) => {
    setCurrentValue(newValue);
    setSetting("GlassFontScaleIndex", `${newValue}`);
  };

  function valuetext(v: number) {
    return t(`settings.GlassFontScaleIndex.${v}`);
  }

  function valueLabelFormat(v: number) {
    return t(`settings.GlassFontScaleIndex.${v}`);
  }

  useEffect(()=>{
    getSetting("GlassFontScaleIndex").then((v:Setting)=>{
      setCurrentValue(parseInt(v.Value))
      const options = v.Options ? v.Options : [[v.Value, v.Value]];
      const _marks:any = options.map((m:[string,string])=>{
        return {
          value: parseInt(m[0])
        }
      });
      setMin(Math.min.apply(Math, _marks.map((m:any)=>m.value)))
      setMax(Math.max.apply(Math, _marks.map((m:any)=>m.value)))
      setMarks(_marks)
    })
  }, [])

  return <Slider
    key="ar-slider-fontsize"
    sx={props.sx}
    color={props.color}
    aria-label={t(`settings.GlassFontScaleIndex.title`)}
    value={currentValue}
    valueLabelFormat={valueLabelFormat}
    getAriaValueText={valuetext}
    min={min}
    max={max}
    step={null}
    onChange={handleChange}
    valueLabelDisplay="off"
    marks={marks}
  />
}
