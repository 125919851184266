import React from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface XLargeButtonProps extends ButtonProps {
  label: any;
  description?: any;
}

const StyledButton = styled(Button)({
  paddingTop: "20px",
  paddingBottom: "20px",
  maxWidth: "400px",
  width: "100%",
});

export const XLargeButton = (buttonProps: XLargeButtonProps) => {
  return (
    <StyledButton
      sx={{
        borderRadius: "10px !important",
      }}
      {...buttonProps}
    >
      <div>
        <Typography
          variant="h3"
          sx={{
            mt: "2px",
            lineHeight: "1.2rem",
            marginTop: "6px",
            fontWeight: 800,
          }}
        >
          {buttonProps.label}
        </Typography>
        <Typography variant="body2" sx={{ mt: "2px", opacity: 0.6 }}>
          {buttonProps.description}
        </Typography>
      </div>
    </StyledButton>
  );
};
