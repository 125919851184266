import React, { useState } from "react";

import {
  Alert,
  AlertProps,
  Snackbar,
  Typography,
} from "@mui/material";

interface Props {
  message?: string;
  color?: AlertProps["severity"];
  timeout?: number;
  variant?: AlertProps["variant"];
  position?: "top" | "bottom"
}

export const useXRaiSnackbar = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState(props.message);
  const [color, setColor] = useState<AlertProps["severity"]>(
    props.color || "info",
  );

  function present(
    message: string,
    color: AlertProps["severity"],
    timeout?: number,
  ) {
    if (message) setMessage(message);
    setIsOpen(true);
    setColor(color);
    setTimeout(() => {
      setIsOpen(false);
    }, timeout || props.timeout || 3000);
  }

  return {
    Snackbar: (
      <Snackbar  
        open={isOpen} 
        sx={{ pb: "24px", px:'6px', width: "auto" }}
        anchorOrigin={{ vertical: props.position || 'bottom', horizontal: "left" }}
        >
        <Alert
          onClick={() => {
            setIsOpen(false); 
          }}
          
          sx={{
            width: "100%",
            maxWidth: "400px",
            userSelect: "none",
            cursor: "pointer",
            alignItems: "center",
            px: "22px",
            py: "2px",
            borderRadius: "7px",
          }}
          variant={props.variant}
          severity={color}
        >
          <Typography variant="body1">{message}</Typography>
        </Alert>
      </Snackbar>
    ),
    present,
    success(message: string, timeout?: number) {
      present(message, "success", timeout);
    },
    warning(message: string, timeout?: number) {
      present(message, "warning", timeout);
    },
    info(message: string, timeout?: number) {
      present(message, "info", timeout);
    },
    error(message: string, timeout?: number) {
      present(message, "error", timeout);
    },
  };
};
