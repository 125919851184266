import React, { useContext } from "react";
import {
  Avatar,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Theme,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Check } from "@mui/icons-material";
import { ThemeContext } from "@emotion/react";
import { SingleConversation } from "types/types";
import formatDate from "utils/formatDate";
import isDateToday from "utils/isDateToday";
import { TFunction } from "i18next";
import { useStore, useStoreLocal } from "state/store";

const presentDate = (dateStr: string) => {
  const dateConversion = dateStr + "Z";
  const date = new Date(dateConversion);

  if (isDateToday(date)) {
    return formatDate(date, {
      hour: "numeric",
      minute: "numeric",
    });
  } else {
    return formatDate(date, { dateStyle: "short" });
  }
};

interface ConverstaionProps extends SingleConversation {
  onClick: () => void;
  t: TFunction<"translation", undefined, "translation">; // Translation
  style?: React.CSSProperties;
  selected?: boolean;
  conversationTitles: { [key: string]: string };
}

const Conversation = (props: ConverstaionProps) => {
  const theme = useContext(ThemeContext) as Theme;
  const [fontSize] = useStoreLocal<number>("fontSize");
  const [iconSize] = useStore<number>("rootIconSize");

  return (
    <>
      <ListItemButton
        id="ConversationListItem--container"
        selected={props.selected}
        onClick={props.onClick}
        sx={props.style}
      >
        <ListItemAvatar>
          {!props.selected && (
            <Avatar
              style={{
                textShadow: "0px 1px 2px rgba(0,0,0,0.4)",
                height: `calc(${iconSize}px + ${fontSize + 16}px)`,
                width: `calc(${iconSize}px + ${fontSize + 16}px)`,
                marginRight: "16px",
                color: theme.palette.primary.contrastText,
                backgroundColor: props.LastMessageColor,
              }}
              src={props.LastUserAvatar}
            >
              {`S${props.LastMessageSpeakerId}`}
            </Avatar>
          )}

          {props.selected && (
            <Avatar
              style={{
                width: "46px",
                height: "46px",
                marginRight: "16px",
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Check style={{ width: "32px", height: "32px" }} />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          sx={{ alignContent: "center" }}
          primary={
            <Stack
              direction="row"
              alignItems="center"
              sx={{ width: "100%" }}
              spacing={2}
            >
              <Typography
                component="span"
                variant="h5"
                noWrap
                style={{ width: "100%", fontWeight: "600" }}
              >
                {/* TODO:// List out All Speaker Names */}
                {/* {ConversationNames} */}
                {/* Speaker {props.LastMessageSpeakerId} */}
                {props.conversationTitles[props.SessionId] ??
                  `${props.t("untitled")} #${props.SessionId.slice(0, 6)}`}
              </Typography>
              <Typography
                noWrap
                variant="body2"
                sx={{ flexShrink: 0, opacity: 0.8, fontSize: "0.9rem" }}
                component="span"
              >
                {presentDate(props.SessionEnds)}
              </Typography>
            </Stack>
          }
          secondary={
            <React.Fragment>
              <Typography
                variant="body2"
                color="textPrimary"
                component="span"
                sx={{
                  overflow: "hidden",
                  lineHeight: "130%",
                  opacity: "70%",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {props.LastMessage}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItemButton>
      <Divider variant="fullWidth" sx={{ opacity: 0.4 }} component="li" />
    </>
  );
};

export default Conversation;
