import { useRef } from 'react';

export const useStringToIndex = (): ((str: string) => number) => {
  const indexMap = useRef<Map<string, number>>(new Map());
  const nextIndex = useRef<number>(0);

  const getStringIndex = (str: string): number => {
    if (!indexMap.current.has(str)) {
      indexMap.current.set(str, nextIndex.current);
      nextIndex.current += 1;
    }
    return indexMap.current.get(str)!;
  };

  return getStringIndex;
};
