import { createContext } from "react";
import { AppUser } from "types/types";
import useUnityAuth from "comps/auth/useUnityAuth";

const AppUserContext = createContext<{
  appUser: AppUser | null;
  setAppUser: (appUser: AppUser | null) => void;
  userAuth: ReturnType<typeof useUnityAuth> | null;
}>({
  appUser: null,
  setAppUser: (appUser: AppUser | null) => { },
  userAuth: null,
});

export default AppUserContext;
