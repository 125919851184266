import { List, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { Thtml } from "comps/common/Thtml";
import { XraiFieldWrapper } from "comps/XraiFieldWrapper";
import { Setting } from "types/types";
import ArFontSizeSelector from "./ArFontSizeSelector";
import SettingBoolean from "./SettingBoolean";
import SettingDropdown from "./SettingDropdown";
import SettingTextfield from "./SettingTextfield";
import LanguageDropdown from "comps/language/LanguageDropdown";
import { LangSettingType } from "comps/language/langaugeUtils";

interface Props {
  setting: Setting;
  updateSetting: (setting: Setting, value: any) => void;
}

export default function SettingsGroup(props: Props) {
  const { t } = useTranslation();
  const setting = props.setting;

  if (setting) {
    return (
      <List sx={{ mb: 1 }} id={`settings-${setting.Name}`}>
        {/* List out settings for this group */}
        <Stack direction="column" spacing={1}>
          <div key={setting.Id} style={{ marginBottom: "2px" }}>
            {/* Boolean Settings */}
            {setting.Type === "Boolean" && (
              <SettingBoolean
                setting={setting}
                updateSetting={(name, value) => {
                  props.updateSetting(setting, value);
                }}
              />
            )}

            {/* String / Language Dropdown */}
            {["String", "Language"].indexOf(setting.Type) > -1 &&
              setting.Options && (
                <SettingDropdown
                  label={t(`settings.${setting.Name}.title`)}
                  setting={setting}
                  updateSetting={(name, value) => {
                    props.updateSetting(setting, value);
                  }}
                />
              )}

            {["LanguageEngine"].indexOf(setting.Type as LangSettingType) > -1 &&
              setting.Options && (
                <XraiFieldWrapper
                  style={{ marginBottom: "4px" }}
                  label={t(`settings.${setting.Name}.title`)}
                  description={
                    <Thtml tag={`settings.${setting.Name}.summary`} />
                  }
                >
                  <LanguageDropdown
                    displayType="dropdown"
                    type={setting.Name as LangSettingType}
                    config="settings"
                  />
                </XraiFieldWrapper>
              )}

            {setting.Type === "Int32" && (
              <div>
                {/* GlassFontScaleIndex  */}
                {setting.Name === "GlassFontScaleIndex" && (
                  <XraiFieldWrapper
                    label={t(`settings.${setting.Name}.title`)}
                    description={
                      <Thtml tag={`settings.${setting.Name}.summary`} />
                    }
                  >
                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{ mt: "-10px", mr: "16px" }}
                      alignItems="center"
                    >
                      <Typography sx={{ fontSize: "70%" }}>
                        {t("aA")}
                      </Typography>
                      <ArFontSizeSelector />
                      <Typography sx={{ fontSize: "130%" }}>
                        {t("aA")}
                      </Typography>
                    </Stack>
                  </XraiFieldWrapper>
                )}

                {/* Other Dropdowns  */}
                {setting.Name !== "GlassFontScaleIndex" && (
                  <SettingDropdown
                    setting={setting}
                    updateSetting={(name, value) => {
                      props.updateSetting(setting, value);
                    }}
                  />
                )}
              </div>
            )}

            {/* Text Field Setting */}
            {setting.Type === "String" && !setting.Options && (
              <SettingTextfield
                setting={setting}
                updateSetting={(name, value) => {
                  props.updateSetting(setting, value);
                }}
              />
            )}
          </div>
        </Stack>
      </List>
    );
  } else {
    return <></>;
  }
}
