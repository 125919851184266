import { useEffect, useState } from 'react';

/**
 * This hook makes it easy to change the 
 * page title of any page - mainly used for
 * ensuring Google Analytics tracks the proper page 
 * names. 
 * 
 * Usage:
 * usePageTitle("Page Title to Show")
 * 
 */
function usePageTitle(_: string) {

  const [defaultTitle] = useState<string>("XRAI");

  useEffect(() => {
    document.title = defaultTitle;
    return () => {
      document.title = defaultTitle;
    };
  }, [defaultTitle]);
}

export default usePageTitle;
