import React, { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { getState } from "utils/settings";
import { useStoreLocal, useStoreUnity } from "state/store";
import { xfetchLocal } from "utils/xfetch";
import { useTranslation } from "react-i18next";
import { useOpenURL } from "hooks/useOpenUrl";
import xconsole from "utils/xconsole";

export interface SoftwareUpdateDetails {
  version: string;
  updateUrl: string;
  whatsNew: string[];
}
interface SofwareUpdateResponse {
  Prompt: ReactElement<any, any>;
  updateDetails: SoftwareUpdateDetails;
  hasUpdate: boolean;
  open:()=>void;
}

// Compare two dates to see if its the same day
const isSameDay = (date1: Date, date2: Date): boolean => {
  return date1.toDateString() === date2.toDateString();
};

// Extract the Build Number from the version string
const getBuildVersion = (version:string):string => {
  const verSplit = `${version || ""}`.split(".");
  return verSplit[verSplit.length - 1];
}

export const useSoftwareUpdate = (): SofwareUpdateResponse => {
  const { t } = useTranslation();

  const [globalVersions, ] = useStoreUnity<any>("Versions");
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [updateInfo, setUpdateInfo] = useState<SoftwareUpdateDetails | null>(
    null,
  );
  const [updateSkipDate, setUpdateSkipDate] = useStoreLocal<Date | null>(
    "update-skip",
    null,
  );
  const [openPrompt, setOpenPrompt] = useState(false);
  const [showWhatsNew, setShowWhatsNew] = useState(false);
  const URL = useOpenURL();

  useEffect(() => {
     xfetchLocal("/versions").catch(e=>{ throw e });
  }, []);

  useEffect(() => {
    const parseVersions = async () => {
      try {
        const platformKey = await getState("PlatformKey").catch(e=>{ throw e });
        const subtitleVersion = globalVersions["glass.xrai.subtitles"];
        const platformVersion = subtitleVersion[platformKey];
        const versionInfo = platformVersion ? platformVersion : subtitleVersion;
        const whatsNew = versionInfo.whatsnew;
        const latestVersion = versionInfo.version;
        const latestBuildNum = getBuildVersion(latestVersion)

        // Get the Current Version of the App
        const currentVersion = await getState("XraiBuild").catch(e=>{ throw e });
        const installedBuildNum = getBuildVersion(currentVersion);

        // Check if the user skipped today
        const hasSkipped = isSameDay(
          new Date(),
          updateSkipDate ? new Date(updateSkipDate) : new Date("2000-01-01"),
        );

        // Make sure we're not in DEV mode
        if(false) {
      //if(installedBuildNum !== "0000") {
          if (parseInt(latestBuildNum) > parseInt(installedBuildNum) ) {
            setUpdateAvailable(true);
            setUpdateInfo({
              version: latestVersion,
              updateUrl: process.env.REACT_APP_ANDROID_APP_STORE,
              whatsNew: whatsNew || [],
            });
            if (!hasSkipped) {
              setOpenPrompt(true);
            }
          }
        }
      } catch (e) {
         xconsole.error("fail Versions",globalVersions,e);
      }
    };

    if (globalVersions) parseVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalVersions]);

  const handleUpdate = () => {
    if(updateInfo && updateInfo.updateUrl) {
      URL.open(updateInfo.updateUrl);
      setOpenPrompt(false);
    }
  };

  const handleSkip = () => {
    setUpdateSkipDate(new Date());
    setOpenPrompt(false);
  };

  const handleClose = () => {
    setOpenPrompt(false);
  }

  const toggleWhatsNew = () => {
    setShowWhatsNew(!showWhatsNew);
  };

  return {
    updateDetails: updateInfo,
    hasUpdate: updateAvailable,
    open: ()=>{
      setOpenPrompt(true);
    },
    Prompt: (
      <Dialog open={openPrompt} onClose={handleClose}>
        <DialogTitle>{t("updater.new_update_title")}</DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <List sx={{ px: "5px" }} component="div">
            <ListItem>
              {t("updater.new_update_message")}
            </ListItem>
            {showWhatsNew &&
              (
                <>
                  <Divider style={{ marginTop: "12px" }} />
                  <Typography
                    sx={{
                      px: "12px",
                      pt: "20px",
                      fontWeight: "700",
                    }}
                  >
                    {t("updater.whats_new", "What's New")}
                  </Typography>
                  {updateInfo?.whatsNew?.map((item, index) => {
                    return (
                      <ListItem key={index}>
                        {item}
                      </ListItem>
                    );
                  })}
                </>
              )}
          </List>
        </DialogContent>
        <DialogActions style={{ paddingRight: "16px" }}>
          <Button color="secondary" onClick={toggleWhatsNew}>
            {showWhatsNew && <span>{t("updater.hide", "Hide")}&nbsp;</span>}
            {!showWhatsNew && <span>{t("updater.whats_new", "What's New")}</span>}
          </Button>
          <Button color="secondary" onClick={handleSkip}>
            {t("updater.skip", "Skip")}
          </Button>
          <Button color="secondary" onClick={handleUpdate}>
            {t("updater.update", "Update")}
          </Button>
        </DialogActions>
      </Dialog>
    ),
  };
};
