import { createGlobalState } from 'react-hooks-global-state';
import {
    Trinary,
    CaptionStatus,
    PermissionsStatus,
    Multicast
} from "./types/types";

const initGlobalState : {
  haveArMode: Trinary,
  captionStatus: CaptionStatus,
  permissionsStatus: PermissionsStatus,
  heyxrai: string | undefined,
  purchaseStatus: "idle" | "complete" | "processing",
  rootFontSize: number,
  subtitleSeqN: number,
  subtitleText: string,
  multicast: Multicast,
} = {
  haveArMode: {value:"False"},
  captionStatus: {status:"pendingConnect"},
  permissionsStatus: {audioPermission:"Default", bluetoothPermission:"Default", contactsPermission:"Default"},
  rootFontSize: 16,
  purchaseStatus: "idle",
  heyxrai: "",
  subtitleSeqN: 0,
  subtitleText: "",
  multicast: null,
};

export const { useGlobalState, getGlobalState, setGlobalState } = createGlobalState(initGlobalState);
