import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Clear } from "@mui/icons-material";

interface Props {
  onKeyword: (searchTerm: string) => void;
  onClear?: ()=> void;
}

export default function SearchBar(props:Props) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();
  const { onKeyword } = props;

  useEffect(() => { 
    // Debounce the Requests to every 400 ms
    const delayDebounceFn = setTimeout(() => {
      onKeyword(searchTerm);
    }, 400);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchTerm, onKeyword]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const handleClear = ()=>{
    setSearchTerm("");
    if(props.onClear) props.onClear();
  }

  return <TextField
    id="search"
    value={searchTerm}
    onChange={handleInputChange}
    label={t("Search")}
    autoFocus={true}
    size="small"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {searchTerm && (
            <IconButton style={{top:'-4px'}} onClick={handleClear} edge="end">
              <Clear />
            </IconButton>
          )}
        </InputAdornment>
      ),
    }}
    variant="standard"
    sx={{ width: "100%" }}
  />
}