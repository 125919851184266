import { useCallback, useEffect, useState } from "react";

import {
  LangSettingType,
  LanguageGroup,
  getLanguages,
  setActiveLanguage,
} from "comps/language/langaugeUtils";
import { groupArray } from "utils/groupArray/groupArray";
import { useTranslation } from "react-i18next";
import useLanguageDownloader from "comps/language/useLanguageDownloader";
import { toJSONPostRequest, xfetch } from "utils/xfetch";
import { FavoriteLanguagePayload, LanguageNode } from "./types";
import { useStore } from "state/store";

const getType = (langView: "from" | "to"): LangSettingType =>
  langView === "from" ? "SourceLanguage" : "TargetLanguage";

const getGroup = (langType: "SourceLanguage" | "TargetLanguage") =>
  langType === "SourceLanguage" ? "spoken" : "subtitle";

const useLanguages = (languageView: "from" | "to") => {
  const type: LangSettingType = getType(languageView);
  const group = getGroup(type);

  const [loading, setLoading] = useState(true);
  const [filterFavorites, setFilterFavorites] = useState(false);
  const [groupedLanguages, setGroupedLanguages] = useState<LanguageGroup>({});
  const [favoriteLanguages, setFavoriteLanguages] = useState<
    FavoriteLanguagePayload[]
  >([]);

  const [selectedSourceLanguages, setSelectedSourceLanguages] = useStore<
    string[]
  >("selectedSourceLanguages", []);

  const [selectedLanguages, setSelectedLanguages] = useStore<string[]>(
    "selectedLanguages",
    []
  );

  const [triggerSubtitleLanguageChange, setTriggerSubtitleLanguageChange] =
    useStore<FavoriteLanguagePayload>("triggerSubtitleLanguageChange", null);

  const { t } = useTranslation();
  const { downloadStatus } = useLanguageDownloader();

  const isLangDownloaded = (langNode: LanguageNode) => {
    return langNode.cloud ? true : langNode.local_down === langNode.local_size;
  };

  const getLanguageList = async ({
    group,
    sublist,
  }: {
    group: string;
    sublist: string;
  }): Promise<FavoriteLanguagePayload[]> => {
    if (globalThis.isXraiGo) return Promise.resolve([]);

    const res = await xfetch(`/language/get?group=${group}&subList=${sublist}`);
    const data = (await res.json()) as FavoriteLanguagePayload[];
    return data;
  };

  const getFavoriteLanguages = useCallback(() => {
    getLanguageList({ group: group, sublist: "favorite" }).then((res) => {
      setFavoriteLanguages(res);
    });
  }, [setFavoriteLanguages, group]);

  const toggleFavorite = async (id: string) => {
    const payload = await toJSONPostRequest(null);
    await xfetch(`/language/favorite?code=${id}&group=${group}`, payload);
    const newFavorites = await getLanguageList({
      group: group,
      sublist: "favorite",
    });
    setFavoriteLanguages(newFavorites);
    return newFavorites;
  };

  const setFavoriteSubtitleLang = useCallback(
    async (lang: FavoriteLanguagePayload) => {
      const payload = await toJSONPostRequest({
        selected: [lang.key],
      });

      await xfetch("/language/select?group=subtitle", payload);

      const newFavorites = await getLanguageList({
        group: group,
        sublist: "favorite",
      });

      setFavoriteLanguages(newFavorites);
    },
    [group]
  );

  const toggleFavoriteSpokenLangs = async (engine: FavoriteLanguagePayload) => {
    const payload = await toJSONPostRequest(null);

    await xfetch(`/language/toggle?code=${engine.key}&group=spoken`, payload);

    const newFavorites = await getLanguageList({
      group: group,
      sublist: "favorite",
    });

    setFavoriteLanguages(newFavorites);
  };

  const toggleFavoritesView = () => {
    setFilterFavorites(!filterFavorites);
  };

  // TODO: This needs to be deprecated.
  const selectLanguage = (key: string) => {
    setActiveLanguage(key, type);
  };

  const getLangListFromSearch = useCallback(
    (term?: string) => {
      return (
        Object.keys(groupedLanguages)
          .sort((a, b) => {
            try {
              // Sort by localized Label
              const aLabel = groupedLanguages[a][0].label?.toLowerCase();
              const bLabel = groupedLanguages[b][0].label?.toLowerCase();

              return aLabel > bLabel ? 1 : -1;
            } catch (e) {
              return 1;
            }
          })
          ?.filter((a) => {
            const langs = groupedLanguages[a];

            if (term) {
              const label = langs[0].label;

              return label.toLowerCase().search(term.toLowerCase()) > -1;
            } else if (filterFavorites) {
              let hasFav = false;

              langs.forEach((lang) => {
                if (
                  !hasFav &&
                  favoriteLanguages.find((f) => f.key === lang.key)
                ) {
                  hasFav = true;
                }
              });

              return hasFav;
            } else {
              return true;
            }
          }) ?? []
      );
    },
    [groupedLanguages, favoriteLanguages, filterFavorites]
  );

  useEffect(() => {
    getFavoriteLanguages();
  }, [getFavoriteLanguages]);

  useEffect(() => {
    const newSet = new Set(
      favoriteLanguages.filter((lang) => lang.selected).map((lang) => lang.key)
    );

    if (languageView === "from") {
      setSelectedSourceLanguages(Array.from(newSet));
    }

    setSelectedLanguages(Array.from(newSet));
  }, [
    languageView,
    favoriteLanguages,
    setSelectedLanguages,
    setSelectedSourceLanguages,
  ]);

  useEffect(() => {
    const convertToLanguageGroup = (langs: any[], source: string) => {
      const langGroup = groupArray(langs, "langcode");

      // Loop over langs to get their localized label in our payload
      Object.keys(langGroup).forEach((lgKey: string) => {
        const langsArr: Array<any> = langGroup[lgKey];
        // Loop over lang nodes
        langsArr.forEach((lang, index) => {
          // Set the label
          langGroup[lgKey][index].label = t(
            `settings.${source}.${lang.langcode}`
          );
        });
      });

      return langGroup;
    };

    getLanguages(type).then((langs: any[]) => {
      const langGroup = convertToLanguageGroup(langs, type);

      setGroupedLanguages(langGroup);
      setLoading(false);
    });
  }, [languageView, t, type]);

  useEffect(() => {
    if (triggerSubtitleLanguageChange) {
      setFavoriteSubtitleLang(triggerSubtitleLanguageChange);
      setTriggerSubtitleLanguageChange(null);
    }
  }, [
    triggerSubtitleLanguageChange,
    setFavoriteSubtitleLang,
    setTriggerSubtitleLanguageChange,
  ]);

  return {
    type,
    loading,
    groupedLanguages,
    favoriteLanguages,
    downloadStatus,
    selectedLanguages,
    selectedSourceLanguages,
    isLangDownloaded,
    getLangListFromSearch,
    toggleFavorite,
    setFavoriteSubtitleLang,
    toggleFavoriteSpokenLangs,
    toggleFavoritesView,
    selectLanguage,
  };
};

export default useLanguages;
