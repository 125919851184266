export type DownloadStatus = {
  isDownloading: boolean;
  currentDownloadFileName: string | null;
  downloadQueue: Array<DownloadAsset>;
  progress: number;
}

export type DownloadAsset = {
  id: string;
  status: "NotDownloaded" | "Downloaded" | "Verified" | "Corrupted";
  label: string;
  path: string;
  type?: "model" | "font" | "unknown";
  size: number;
}

let status: DownloadStatus | undefined = undefined;
let listeners: Array<any> = [];

export const downloadStore = {
  set(_status: DownloadStatus) {
    status = _status;
    emitChange();
  },
  get() {
    return status;
  },
  subscribe(listener: any) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
};

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}
