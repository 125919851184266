import { Switch } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Thtml } from "comps/common/Thtml";
import { XraiFieldWrapper } from "comps/XraiFieldWrapper";
import { Setting } from "types/types";

interface Props {
  setting: Setting;
  updateSetting: (name: string, value: any) => void;
}

export default function SettingBoolean(props: Props) {
  const { t } = useTranslation();
  const setting = props.setting;
  return (
    <XraiFieldWrapper
      label={t(`settings.${setting.Name}.title`)}
      description={<Thtml tag={`settings.${setting.Name}.summary`} />}
      labelRight={
        <Switch
          checked={setting.Value === "True" ? true : false}
          onChange={(e) => {
            props.updateSetting(
              setting.Name,
              e.target.checked ? "True" : "False",
            );
          }}
        />
      }
    />
  );
}
