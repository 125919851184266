import React, { useCallback, useEffect, useState } from "react";
import ScrollMonitor from "comps/common/ScrollMonitor";
import { IconButton } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useStore } from "state/store";

interface Props {
  position: "top" | "bottom" | "default";
  variant?: "full";
  style?: any;
  arrowDownBottom?: string;
  backgroundColor: string;
  children?: any;
}

/**
 * Subtitle Scroller
 * This component is used to present subtitles and allow for scrolling with in a
 * specific height as well as toggling if subtitles are on top, bottom, or full
 */
export default function SubtitleScroller(props: Props) {
  const theme = useTheme();
  const [iconSize] = useStore<number>("rootIconSize");
  const [manualScroll, setManualScroll] = useState<boolean>(false);
  const [scrollToBottom, setScrollToBottom] = useState<boolean>(false);
  const [, setScrollable] = useState(false);

  // Define the gradient masks for fading out subtitles at top or bottom
  const maskStyles =
    props.position === "top"
      ? {
          WebkitMaskImage:
            "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 7%)",
          maskImage:
            "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 7%)",
        }
      : props.position === "bottom"
      ? {
          WebkitMaskImage:
            "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 22%)",
          maskImage:
            "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 22%)",
        }
      : {};

  const handleScrollToBottom = useCallback(
    (forceScroll = false) => {
      if (scrollToBottom || forceScroll) {
        // Bottom element contained in `SubtitleList` to scroll to.
        const ele = document.getElementById("subtitleBasic-bottom");

        ele?.scrollIntoView({ behavior: "smooth" });

        setScrollToBottom(false);
      }
    },
    [scrollToBottom]
  );

  useEffect(() => {
    handleScrollToBottom();
  }, [props.children, handleScrollToBottom]);

  useEffect(() => {
    if (!manualScroll) {
      setScrollToBottom((prev) => {
        return prev ? prev : true;
      });
    } else {
      setScrollToBottom(false);
    }
  }, [props.children, manualScroll]);

  return (
    <>
      <ScrollMonitor
        onScroll={(val) => {
          if (!scrollToBottom) {
            setManualScroll(val > 100);
          }
        }}
        blockScroll={scrollToBottom}
        onScrollable={(val) => setScrollable(val > 0)}
        style={{
          zIndex: 2,
          flexGrow: 0,
          flexShrink: 1,
          display: "flex",
          overflowY: "auto",
          flexDirection: "column",
          ...maskStyles,
          ...props.style,
        }}
      >
        <div
          className="subtitle-items"
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          {props.children}
          <div id="subtitle-scroll-bottom"></div>
        </div>
      </ScrollMonitor>

      <IconButton
        size="small"
        onClick={() => handleScrollToBottom(true)}
        className={`animate-slide-up ${manualScroll ? "active" : "inactive"}`}
        style={{
          position: "fixed",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.primary,
          bottom: props.arrowDownBottom,
          right: theme.spacing(1.5),
          zIndex: 600,
        }}
      >
        <ArrowDownward
          sx={{
            width: iconSize,
            height: iconSize,
          }}
        />
      </IconButton>
    </>
  );
}
