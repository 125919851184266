import { Key } from "@mui/icons-material";
import { IconButton, Stack, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Thtml } from "comps/common/Thtml";
import { XraiFieldWrapper } from "comps/XraiFieldWrapper";
import { useOpenURL } from "hooks/useOpenUrl";
import { Setting } from "types/types";

interface Props {
  setting: Setting;
  updateSetting: (name: string, value: any) => void;
}

export default function SettingTextfield(props: Props) {
  const { t } = useTranslation();
  const openURL = useOpenURL();
  const setting = props.setting;
  return (
    <XraiFieldWrapper
      label={t(`settings.${setting.Name}.title`)}
      description={<Thtml tag={`settings.${setting.Name}.summary`} />}
    >
      <Stack direction="row">
        <TextField
          size="small"
          sx={{
            margin: "0",
            width: "100%",
            borderRadius: "6px",
            border: "solid 1px #B4B4B4",
          }}
          id={setting.Name}
          placeholder={t(`settings.${setting.Name}.title`)}
          defaultValue={setting.Value}
          onChange={(e) => {
            props.updateSetting(setting.Name, e.target.value);
          }}
        />
        {setting.Name === "OpenAiGptKey" &&
          (
            <IconButton
              onClick={() => {
                openURL.open("https://platform.openai.com/account/api-keys");
              }}
            >
              <Key />
            </IconButton>
          )}
      </Stack>
    </XraiFieldWrapper>
  );
}
