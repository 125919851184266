import i18n from "i18n-config";
import { Setting } from "types/types";
import { xfetch } from "./xfetch";

const getInitialLocale = async () => {
  const reqSettings: RequestInit = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Length": "0",
    }
  };

  const res = await xfetch("/configuration", reqSettings);
  const settings = (await res.json()) as Setting[];

  i18n.changeLanguage(settings?.find((setting: Setting) => {return setting.Name === "LocaleLanguage"}).Value);
};

export default getInitialLocale;
