import React, {
  useState,
  useEffect,
  useCallback,
  useSyncExternalStore,
  useContext,
} from "react";

// React & State
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGlobalState } from "state";
import { ThemeUpdaterContext } from "themes/ThemeLoader";
import { sequenceNumberStore } from "comps/subtitles/subtitleStore";

// Hooks
import useMinimize from "hooks/useMinimize";
import useOrientation from "hooks/useOrientation";
import useGoogleAnalytics from "hooks/useGoogleAnalytics";
import useSessionManager from "hooks/useSessionManager";
import { useBottomSheet } from "hooks/useBottomSheet";
import { useDialog } from "hooks/useDialog";
import { useStore, useStoreLocal, useStoreUnity } from "state/store";
import { useDeleteConversation } from "comps/conversations/useDeleteConversation";

// Utils
import { wait } from "utils/wait";
import { xfetch, xhostLocal } from "utils/xfetch";

// Icons
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import FolderZipIcon from "@mui/icons-material/FolderZip";

// Types
import { SessionHistory, SessionMeta } from "types/types";

// Components
import SubtitleSidebar from "comps/subtitles/SubtitleSidebar";
import SafeArea from "comps/common/SafeArea";
import ButtonSkeletons from "comps/subtitles/ButtonSkeletons";
import SubtitleBody from "comps/subtitles/SubtitleBody";
import ActionButtons from "comps/subtitles/ActionButtons";
import SubtitleContainer from "comps/subtitles/SubtitleContainer";
import { useSubtitleScreenHook } from "comps/subtitles/useSubtitleScreenHook";
import ConversationScreen from "./ConversationScreenV2";
import XraiModal from "comps/XraiModal";
import { useXRaiSnackbar } from "hooks/useXraiSnackbar";
import { Box, Container, Input, Typography, useTheme } from "@mui/material";
import Flag from "react-world-flags";
import { LangCodeToCountry } from "comps/languageDrawer/LangCodeToCountry";

export enum PanelPosition {
  bottom = "bottom",
  top = "top",
  default = "default",
}

const getReqSettings = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
};

const SubtitleScreen = () => {
  const { getTheme } = useContext(ThemeUpdaterContext);

  const subtitleSeqN = useSyncExternalStore(
    sequenceNumberStore.subscribe,
    sequenceNumberStore.get
  );

  const [isDrawerOpen] = useStore("isDrawerOpen", false);
  const [cameraPass] = useStore("cameraPass", false);
  const [iconSize] = useStore<number>("rootIconSize");
  const [isArMode] = useStore<boolean>("IsArMode", false);
  const [selectedSourceLanguages] = useStore<string[]>("selectedSourceLanguages", []);
  const [conversationsCount, setConversationsCount] = useStore(
    "ConversationCount",
    0
  );

  const [globalCapStatus] = useGlobalState("captionStatus");

  const [displayFlag] = useStoreUnity<boolean>("DisplayLanguageFlags");
  const [haveArMode] = useStoreUnity<boolean>("HaveArMode", false);
  const [targetLanguage] = useStoreUnity<string>("TargetLanguageInfo");

  const [renameValue, setRenameValue] = useState("");
  const [renameModalIsOpen, setRenameModalIsOpen] = useState(false);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);
  const [hasRecording] = useState(false);
  const [playRecording, setPlayRecording] = useState(false);
  const [arTimeout, setArTimeout] = useState(false);
  const [selectedSessions, setSelectedSessions] = useState<SessionMeta[]>([]);
  const [searchResults, setSearchResults] = useState<
    SessionHistory[] | undefined
  >(undefined);

  const [, setSelectedConversation] = useStoreLocal<SessionMeta>(
    "selectedConversation",
    null
  );
  const [panelHeight] = useStoreLocal<number>("panelHeight");
  const [screenCast] = useStoreLocal<boolean>("screenCast");
  const [, setConversationDeleted] = useStoreLocal("convoDeleted", false);
  const [panelPosition, setPanelPosition] = useStoreLocal<PanelPosition>(
    "panelPosition",
    PanelPosition.default
  );
  const [conversationTitles, setConversationTitles] = useStoreLocal<{
    [key: string]: string;
  }>("conversationTitles", {});

  const { isIdle, minimize } = useMinimize();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const orientation = useOrientation();
  const ga = useGoogleAnalytics();
  const DeletePrompt = useDeleteConversation();
  const Dialog = useDialog();
  const arDialog = useDialog();
  const [searchParams] = useSearchParams();
  const { activeSessionId, activeSegmentId, isActiveSession, resetSession } =
    useSessionManager();

  const AlertSnack = useXRaiSnackbar({
    position: "top",
  });

  const sessionId: string = searchParams.get("sessionId");
  const isChatHistory = sessionId !== null;
  const sessionActive = isActiveSession();
  const bgColor: string =
    theme.palette.neutral?.main ?? theme.palette.background.default;
  const showSubtitles = isChatHistory || sessionActive || globalThis.isXraiGo;

  const displayLangsToCountryCodes = useCallback(() => {
    if (!LangCodeToCountry[targetLanguage]) {
      return selectedSourceLanguages?.map(
        (lang) => LangCodeToCountry[lang.split(".")[1]]
      );
    } else {
      return [LangCodeToCountry[targetLanguage]];
    }
  }, [selectedSourceLanguages, targetLanguage]);

  const {
    captionStatus,
    pendingStatus,
    getStatus,
    startSession,
    startCaptionHandler,
    handleStop,
  } = useSubtitleScreenHook({
    dialog: Dialog,
    isChatHistory,
    activeSegmentId,
    activeSessionId,
    t,
    navigate,
    resetSession,
  });

  const ShareDrawer = useBottomSheet({
    title: t("conversations.share"),
    menuItems: [
      {
        icon: <TextSnippetIcon />,
        label: t("conversations.share_transcript"),
        onClick: async () => {
          xfetch("/sharetranscript?sessionid=" + sessionId, getReqSettings);
          ga.event("share-transcript");
        },
      },
      {
        icon: <AudiotrackIcon />,
        label: t("conversations.share_audio"),
        disabled: !hasRecording,
        onClick: async () => {
          xfetch("/sharerecording?sessionid=" + sessionId, getReqSettings);
          ga.event("share-audio");
        },
      },
      {
        icon: <FolderZipIcon />,
        label: t("conversations.share_transcript_audio"),
        disabled: !hasRecording,
        onClick: async () => {
          xfetch("/sharepackage?sessionid=" + sessionId, getReqSettings);
          ga.event("share-audio-transcript");
        },
      },
    ],
  });

  const handleNavigateBack = () => {
    if (sessionActive) {
      startCaptionHandler(undefined, "pause");
      setDisplayModal(true);
    } else if (isChatHistory) {
      navigate("/subtitles");
    } else {
      setSelectedConversation(null);
      handleStop("/home");
    }
  };

  const togglePosition = () => {
    if (panelPosition === PanelPosition.default) {
      setPanelPosition(PanelPosition.top);
    } else if (panelPosition === PanelPosition.top) {
      setPanelPosition(PanelPosition.bottom);
    } else {
      setPanelPosition(PanelPosition.default);
    }
  };

  const showARFailDialog = useCallback(() => {
    arDialog.showDialog({
      closable: false,
      title: t("caption.ArModeFail.title"),
      message: t("caption.ArModeFail.summary"),
    });
  }, [arDialog, t]);

  useEffect(() => {
    getStatus();
  }, [getStatus, globalCapStatus]);

  // Check if we are in a fresh session
  useEffect(() => {
    if (!isChatHistory && !sessionActive) {
      resetSession();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatHistory, sessionActive]);

  // Check if arTimeout, isArMode and if globalHaveArMode is false
  useEffect(() => {
    if (!isArMode) return;
    if (haveArMode) {
      arDialog.closeDialog();
    } else if (!arTimeout) {
      arDialog.showDialog({
        closable: false,
        title: t("caption.checking_ar_space"),
        message: t("Please Wait") + "...",
      });
    } else {
      showARFailDialog();
    }
  }, [arDialog, t, haveArMode, showARFailDialog, arTimeout, isArMode]);

  useEffect(() => {
    if (cameraPass) {
      // Show Web Cam here
      document.documentElement.classList.add("camera-passthrough");
    }
    return () => {
      // Stop Showing Web Cam Here
      document.documentElement.classList.remove("camera-passthrough");
    };
  }, [cameraPass]);

  useEffect(() => {
    if (!isArMode) {
      // Not AR Mode
      //xfetch("/enableautowebviewhide", { method: "POST" });
      xfetch("/loadunitycast", { method: "POST" });
      //return () => { xfetch("/disableautowebviewhide", { method: "POST" }); }
    } else {
      // Try to load up AR Mode
      xfetch("/loadEnhanced", { method: "POST" }).then(() => {
        // toggleTp(1);
        wait(3100).then(() => {
          setArTimeout(true);
        });
      });
    }
  }, [isArMode]);

  useEffect(() => {
    if (isChatHistory) {
      setRenameValue(conversationTitles[sessionId] || "");
    } else {
      setRenameValue("");
    }
  }, [sessionId, isChatHistory, conversationTitles]);

  return (
    <SubtitleContainer
      isArMode={isArMode}
      sessionId={sessionId}
      shareDrawer={ShareDrawer}
      ga={ga}
      deletePrompt={DeletePrompt}
      setPlayRecording={setPlayRecording}
      navigate={navigate}
      handleNavigateBack={handleNavigateBack}
      xhostLocal={xhostLocal}
      t={t}
      setConversationDeleted={setConversationDeleted}
      captionStatus={captionStatus}
      iconSize={iconSize}
      bgColor={bgColor}
      isChatHistory={isChatHistory}
      playRecording={playRecording}
      conversationsCount={conversationsCount}
      setEditMode={setEditMode}
      selectedSessions={selectedSessions}
      editMode={editMode}
      setSearchResults={setSearchResults}
      displayConversationInfo={!sessionActive}
      setRenameModalIsOpen={setRenameModalIsOpen}
      startSession={startSession}
      isActiveSession={isActiveSession}
    >
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "hidden",
          }}
        >
          {renameModalIsOpen && (
            <XraiModal
              title={t("conversations.rename_conversation")}
              content={
                <Input
                  value={renameValue}
                  onChange={(e) => setRenameValue(e.target.value)}
                  type="text"
                  placeholder={`${t("untitled")} #${sessionId.slice(0, 6)}`}
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    borderRadius: "0.25rem",
                  }}
                />
              }
              closeHandler={() => setRenameModalIsOpen(false)}
              confirmHandler={async () => {
                setConversationTitles((prev) => ({
                  ...prev,
                  [sessionId]: renameValue,
                }));

                setRenameModalIsOpen(false);

                AlertSnack.success(t("conversations.rename_success"));
              }}
              open={renameModalIsOpen}
            />
          )}

          {displayModal && (
            <XraiModal
              title={t("Warning")}
              content={t("conversations.warning_modal_in_active_session")}
              closeHandler={() => {
                startCaptionHandler(undefined, "play");
                setDisplayModal(false);
              }}
              confirmHandler={async () => {
                await xfetch("/endsession");
                setDisplayModal(false);
                navigate("/subtitles");
              }}
              open={displayModal}
            />
          )}

          {globalThis.isXraiGo && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                padding: "8px",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {t("conversations.powered_by_go")}
              </Typography>
            </Box>
          )}

          {showSubtitles && (
            <>
              {displayFlag && (
                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    p: 2,
                    m: 1,
                  }}
                >
                  {displayLangsToCountryCodes()?.map((code) => (
                    <Flag
                      key={code}
                      code={code}
                      height={displayLangsToCountryCodes()?.length > 1 ? iconSize : iconSize * 3}
                      style={{
                        marginRight: theme.spacing(2),
                        marginBottom: theme.spacing(1),
                        position: theme.palette.name?.includes("redbull") ? "absolute" : "inherit",
                        top: "10%",
                        left: "15%",
                      }}
                    />
                  ))}
                </Container>
              )}

              {!minimize && (
                <ButtonSkeletons
                  captionStatus={captionStatus}
                  pendingStatus={pendingStatus}
                  t={t}
                  getTheme={getTheme}
                  subtitleSeqN={subtitleSeqN}
                />
              )}

              <SubtitleBody
                panelPosition={panelPosition}
                panelHeight={panelHeight}
                bgColor={bgColor}
                isArMode={isArMode}
              />
            </>
          )}

          {!showSubtitles && (
            <div
              style={{
                flex: 1,
              }}
            >
              <ConversationScreen
                conversationTitles={conversationTitles}
                editMode={editMode}
                searchResults={searchResults}
                setEditMode={setEditMode}
                setConversationsCount={setConversationsCount}
                setSelectedSessions={setSelectedSessions}
                t={t}
                startCaptionHandler={startCaptionHandler}
              />
            </div>
          )}
        </div>

        <ActionButtons
          isChatHistory={isChatHistory}
          isActiveSession={sessionActive}
          captionStatus={captionStatus}
          pendingStatus={pendingStatus}
          startCaptionHandler={startCaptionHandler}
          handleStop={handleStop}
          orientation={orientation}
          iconSize={iconSize}
          isArMode={isArMode}
          screenCast={screenCast}
          isIdle={isIdle}
          isDrawerOpen={isDrawerOpen}
          panelPosition={panelPosition}
          togglePosition={togglePosition}
        />

        <SafeArea position={"bottom"} />

        <SubtitleSidebar inAr={isArMode} />

        {/* Hook Rendered Elements */}
        {Dialog.Dialog}
        {arDialog.Dialog}
        {DeletePrompt.Dialog}
        {ShareDrawer.drawer}
        {AlertSnack.Snackbar}
      </>
    </SubtitleContainer>
  );
};

export default SubtitleScreen;
