import React from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import Entitlements from "./entitlements";
import { TimerOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface Props {
  entitlements: Entitlements,
  id: string;
  label: string;
}
export default function EntitlementUsageMeter(props:Props) {
  const exists = props.entitlements.hasEntitlement(props.id);
  const { t } = useTranslation();
  if(exists) {
    const usage = props.entitlements.usage(props.id);
    return <MenuItem onClick={()=>{}}>
      <ListItemIcon>
        <TimerOutlined />
      </ListItemIcon>
      <ListItemText>
        <div style={{fontWeight:"bold", lineHeight:"110%"}}>{props.label}</div>
        {usage.remaining === -1 && <span>{t('Unlimited')}</span>}
        {usage.remaining !== -1 && <span>{usage.remaining} {usage.meter} remaining</span>}
      </ListItemText>
    </MenuItem>
  }
}