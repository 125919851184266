import React from "react";

import {
  Pause,
  Stop,
  VerticalAlignTop,
  VerticalAlignBottom,
  FormatAlignJustify,
  Mic,
  Translate,
  Add,
} from "@mui/icons-material";

import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Slide,
  Stack,
  useTheme,
} from "@mui/material";

import HorizontalLock from "comps/subtitles/HorizontalLockButton";
import SubtitleSettingsDrawer from "comps/SettingsDrawer/SubtitleSettingsDrawer";
import LanguageDrawer from "comps/languageDrawer/LanguageDrawer";
import { PanelPosition } from "pages/SubtitleScreen";

import SubtitleBarHighlight from "assets/subtitle-bar-highlight.png";
import { CaptionStatus } from "types/types";
import useMinimize from "hooks/useMinimize";

type Props = {
  isChatHistory: boolean;
  isActiveSession: boolean;
  captionStatus: CaptionStatus;
  orientation: string;
  isArMode: boolean;
  panelPosition: PanelPosition;
  screenCast: boolean;
  isIdle: boolean;
  isDrawerOpen: boolean;
  iconSize: number;
  pendingStatus: () => boolean;
  togglePosition: () => void;
  startCaptionHandler: (e: any, action?: string) => void;
  handleStop: (url: string) => void;
};

const ActionButtons: React.FC<Props> = ({
  isChatHistory,
  isActiveSession,
  captionStatus,
  orientation,
  isArMode,
  panelPosition,
  screenCast,
  isIdle,
  isDrawerOpen,
  iconSize,
  pendingStatus,
  togglePosition,
  startCaptionHandler,
  handleStop,
}) => {
  const minimize = useMinimize().minimize;

  const displaySlider = () => {
    if (!minimize) return true;
    if (minimize) return false;
  };

  const theme = useTheme();
  const isXraiGo = globalThis.isXraiGo;

  return (
    <Slide direction="up" in={displaySlider()} mountOnEnter unmountOnExit>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.image?.main
            ? "transparent"
            : theme.palette.primary.main,
          /* Rectangle 22435 */
          backgroundImage: `url(${SubtitleBarHighlight})`,
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          py: 1,
          px: 1,
        }}
      >
        {!isXraiGo && <LanguageDrawer />}

        <Box
          sx={{
            width: isXraiGo ? "100%" : `calc(100% - ${iconSize}px - 70px)`,
            visibility:
              isActiveSession || isChatHistory || isXraiGo
                ? "visible"
                : "hidden",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <IconButton
            id="ActionButtons--alignButton"
            onClick={togglePosition}
            aria-label="Toggle Position"
          >
            {panelPosition === PanelPosition.bottom && (
              <VerticalAlignBottom sx={{ width: iconSize, height: iconSize }} />
            )}
            {panelPosition === PanelPosition.top && (
              <VerticalAlignTop sx={{ width: iconSize, height: iconSize }} />
            )}
            {panelPosition === PanelPosition.default && (
              <FormatAlignJustify sx={{ width: iconSize, height: iconSize }} />
            )}
          </IconButton>

          <HorizontalLock />
        </Box>

        <Box
          sx={{
            display: "flex",
            position: "relative",
            justifyContent: "center",
            width: "70px",
          }}
        >
          {captionStatus.status === "pendingConnect" && (
            <Skeleton
              variant="circular"
              style={{ flexShrink: 0, width: "46px", height: "46px" }}
            />
          )}

          {isXraiGo && (
            <Button
              className={orientation === "landscape" ? "compact" : ""}
              onClick={() => {
                window.open("https://xrai.glass/stream-instructions", "_blank");
              }}
              variant={"contained"}
            >
              <Translate
                sx={{
                  height: iconSize,
                  width: iconSize,
                }}
              />
            </Button>
          )}

          {captionStatus.status !== "pendingConnect" && (
            <Stack direction="row" spacing={1}>
              {captionStatus.status !== "confirmStart" && !isXraiGo && (
                <Button
                  id="ActionButtons--startButton"
                  className={orientation === "landscape" ? "compact" : ""}
                  onClick={startCaptionHandler}
                  variant={"contained"}
                  disabled={pendingStatus()}
                >
                  {!isChatHistory && !isActiveSession ? (
                    <Add
                      sx={{
                        height: iconSize,
                        width: iconSize,
                      }}
                    />
                  ) : (
                    <Mic
                      sx={{
                        height: iconSize,
                        width: iconSize,
                      }}
                    />
                  )}
                </Button>
              )}

              {captionStatus.status === "confirmStart" && !isXraiGo && (
                <Button
                  className={orientation === "landscape" ? "compact" : ""}
                  onClick={startCaptionHandler}
                  color="primary"
                  variant={"contained"}
                  disabled={pendingStatus()}
                >
                  <Pause
                    sx={{
                      height: iconSize,
                      width: iconSize,
                    }}
                  />
                </Button>
              )}

              {captionStatus.status !== "confirmStop" && !isXraiGo && (
                <Button
                  id="ActionButtons--stopButton"
                  className={orientation === "landscape" ? "compact" : ""}
                  color="error"
                  variant={"contained"}
                  sx={{
                    opacity: 1,
                    backgroundColor: "#FF0000",
                    color: theme.palette.text.primary,
                  }}
                  onClick={() => {
                    startCaptionHandler(undefined, "pause");
                    handleStop("/subtitles");
                  }}
                  disabled={pendingStatus()}
                >
                  <Stop
                    sx={{
                      height: iconSize,
                      width: iconSize,
                    }}
                  />
                </Button>
              )}
            </Stack>
          )}
        </Box>

        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="end"
          width="100%"
        >
          <SubtitleSettingsDrawer inAr={isArMode} />
        </Stack>
      </Stack>
    </Slide>
  );
};

export default ActionButtons;
