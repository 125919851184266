import * as muiColors from "@mui/material/colors";
import { Palette, PaletteMode, createTheme } from "@mui/material";

import { ColorHueKey, Colors } from "colors/Types";
import {
  generateThemeContrastColor,
  generateThemeContrastColorDarker,
  generateButtonTheme,
  getContrastColor,
  getShadeByDarkMode,
  hexToRgba,
  hsvToRgb,
  rgbToHex,
} from "colors/Utils";

import companyPalette from "./palettes/companyPalette";
import neutralPalette from "./palettes/neutralPalette";
import highContrastPalette from "./palettes/highContrastPalette";
import classicPalette from "./palettes/classicPalette";
import redbullPalette from "./palettes/redbullPalette";

// Glowing Effect
export const glowingLight =
  "0 0 10px rgba(0, 0, 0, 0.7), 0 0 20px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.3)";

export const glowingDark =
  "0 0 10px rgba(255, 255, 255, 0.7), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.3)";

// remove black and white as they have no hues
const { common, ...tmpColors } = muiColors;

const defaults = {
  primary: "#2196f3",
  secondary: "#f50057",
};

export const colors: Colors = tmpColors as Colors;

export const speakerColors = [
  hsvToRgb(0 / 360, 0.0, 0.8),
  hsvToRgb(240 / 360, 1.0, 1.0),
  hsvToRgb(0 / 360, 1.0, 1.0),
  hsvToRgb(266 / 360, 1.0, 1.0),
  hsvToRgb(120 / 360, 1.0, 1.0),
  hsvToRgb(180 / 360, 1.0, 1.0),
  hsvToRgb(300 / 360, 1.0, 1.0),
  hsvToRgb(330 / 360, 1.0, 1.0),
  hsvToRgb(30 / 360, 1.0, 1.0),
  hsvToRgb(90 / 360, 1.0, 1.0),
  hsvToRgb(150 / 360, 1.0, 1.0),
  hsvToRgb(210 / 360, 1.0, 1.0),
  hsvToRgb(270 / 360, 1.0, 1.0),
].map((rgb) => rgbToHex(rgb[0], rgb[1], rgb[2]));

export const White = rgbToHex(255, 255, 255);
export const Clear = rgbToHex(0, 0, 0);

export const ColorEmpty = speakerColors[0];
export const ColorXRAI = speakerColors[1];

export const hues: ColorHueKey[] = [
  "grey",
  "red",
  "pink",
  "purple",
  "deepPurple",
  "indigo",
  "blue",
  "lightBlue",
  "cyan",
  "teal",
  "green",
  "lightGreen",
  "lime",
  "yellow",
  "amber",
  "orange",
  "deepOrange",
];

export const TRADITIONAL_PALETTES = ["company", "classic"];
export const CUSTOM_PALETTES = ["highContrast", "neutral", "redbull"];

export const paletteMode = (
  darkMode: boolean,
  screenCast: boolean
): PaletteMode => (screenCast ? "dark" : darkMode ? "dark" : "light");

export function getCompanyPalette(
  darkMode: boolean,
  screenCast: boolean
): Palette {
  return companyPalette(paletteMode(darkMode, screenCast), screenCast);
}

export function getContrastPalette(
  darkMode: boolean,
  screenCast: boolean
): Palette {
  return highContrastPalette(paletteMode(darkMode, screenCast), screenCast);
}

export function getNeutralPalette(
  darkMode: boolean,
  screenCast: boolean
): Palette {
  return neutralPalette(paletteMode(darkMode, screenCast), screenCast);
}

export function getClassicPalette(
  darkMode: boolean,
  screenCast: boolean
): Palette {
  return classicPalette(paletteMode(darkMode, screenCast), screenCast);
}

export function getRedbullPalette(
  darkMode: boolean,
  screenCast: boolean
): Palette {
  return redbullPalette(paletteMode(darkMode, screenCast), screenCast);
}

export function getColorPalette(
  hue: ColorHueKey,
  darkMode: boolean,
  screenCast: boolean
): Palette {
  let color = colors[hue][getShadeByDarkMode(darkMode)];

  if (!color) color = defaults.primary;

  const themeBase = createTheme();
  const contrastThemeColor = generateThemeContrastColor(
    color,
    paletteMode(darkMode, screenCast)
  );

  const paperColor = screenCast
    ? "#000000"
    : generateThemeContrastColorDarker(
        color,
        paletteMode(darkMode, screenCast)
      );

  const colorAugment = (mode: string, primaryColor: string) =>
    themeBase.palette.augmentColor({
      color: {
        main: primaryColor,
        light:
          mode === "light" ? generateButtonTheme(primaryColor, "light") : "",
        contrastText: screenCast ? "#FFFFFF" : getContrastColor(primaryColor),
      },
    });

  const mode = paletteMode(darkMode, screenCast);

  return createTheme({
    palette: {
      name: hue,
      screenCast,
      mode,
      ...{
        neutral: {},
        panel: {
          main: screenCast ? "#000000" : color,
          contrastText: screenCast
            ? getContrastColor("#000000")
            : getContrastColor(color),
        },
        background: {
          default: screenCast ? "#000000" : contrastThemeColor,
          paper: screenCast ? "#000000" : paperColor,
        },
        primary: colorAugment(mode, color),
        secondary: colorAugment(mode, contrastThemeColor),
        action: {
          ...themeBase.palette.action,
          active: getContrastColor(contrastThemeColor),
          selected: hexToRgba(contrastThemeColor, 1),
          hover: hexToRgba(contrastThemeColor, 0.35),
          selectedOpacity: 0.15,
          focus: getContrastColor(paperColor),
          focusOpacity: 0.25,
          disabledOpacity: 0.38,
          disabledBackground: hexToRgba(color, 0.21),
          disabled: hexToRgba(color, 0.3),
        },
        error: {
          main: "#FF0000",
        },
        text: {
          primary: getContrastColor(paperColor),
        },
      },
    },
  }).palette;
}
