import React, { useEffect, useState } from "react";
import LanguageDialog from "./LanguageDialog";
import { LangSettingType } from "./langaugeUtils";
import useLanguageDownloader from "./useLanguageDownloader";
import xconsole from "utils/xconsole";

interface Props {
  type: LangSettingType;
  title: string;
  onSelect: (lang: string) => void;
  onClose?: () => void;
  hideSelection?: boolean;
  hideFlags?: boolean;
}
export default function useLanguageDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const [props, setProps] = useState<undefined | Props>(undefined);

  const { downloadStatus } = useLanguageDownloader();

  const open = (props: Props) => {
    setProps(props);
    setIsOpen(true);
  };

  useEffect(() => {
    if (downloadStatus) xconsole.log({ downloadStatus });
  }, [downloadStatus]);

  return {
    open,
    isOpen: open,
    Dialog: (
      <LanguageDialog
        title={props?.title}
        onSelect={(lang) => {
          props?.onSelect(lang);
          setIsOpen(false);
        }}
        onClose={() => {
          setIsOpen(false);
          if(props.onClose) props.onClose();
        }}
        downloadStatus={downloadStatus}
        onDownloadStart={(key:string)=>{
          //TODO implement download start
          xconsole.log(`Start Download of ${key}`)
        }}
        onDownloadCancel={(key:string)=>{
          //TODO implement download start
          xconsole.log(`Cancel Download of ${key}`)
        }}
        open={isOpen}
        type={props?.type}
        id={`lang-${props?.type}`}
        hideSelection={props?.hideSelection ?? false}
        hideFlags={props?.hideFlags ?? false}
      />
    ),
  };
}
