import { useEffect, useRef } from "react";
import NoSleep from "nosleep.js";

// Documentation found at https://github.com/richtr/NoSleep.js?tab=readme-ov-file
const useMobileSleepLock = () => {
  const noSleep = useRef(null);

  useEffect(() => {
    if (!globalThis.isXraiGo) return;

    if (!noSleep.current) {
      noSleep.current = new NoSleep();
    }

    const enableNoSleep = () => {
      if (!noSleep.current.enabled) {
        noSleep.current.enable();
      }
    };

    const disableNoSleep = () => {
      if (noSleep.current.enabled) {
        noSleep.current.disable();
      }
    };

    // Lock the screen on page load.
    enableNoSleep();

    // Remove sleep lock when the component unmounts.
    return () => {
      disableNoSleep();
    };
  }, []);
};

export default useMobileSleepLock;
