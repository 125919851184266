import React from "react";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";

interface Props {
  checked: boolean;
  style?: any;
  className?: any;
  disabled?: boolean;
  onClick: (evt: any) => void;
}
export default function FavoriteToggle(props: Props) {
  const theme = useTheme();

  return (
    <IconButton
      style={{ padding:0, opacity: props.disabled ? 0.4 : 1 }}
      disabled={props.disabled}
      onClick={(evt) => {
        if (props.onClick) props.onClick(evt);
      }}
    >
      {!props.checked && <FavoriteBorder style={{ ...props.style }} />}
      {props.checked && <Favorite style={{ fill: theme.palette.error.main, ...props.style }} />}
    </IconButton>
  );
}
