import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { parseSku } from "comps/entitlements/parseSku";
import { Subscription } from "./subscriptionUtils";

interface Props {
  onExpanded: (id: string) => void;
  features: Array<string>;
  onSubscribe: (id: string) => void;
  onUnsubscribe: (id: string) => void;
  subscription: Subscription;
  expanded: boolean;
  active: boolean;
}

export default function SubscriptionAccordion(props: Props) {
  const { t } = useTranslation();
  return (
    <Accordion
      onClick={() => {
        props.onExpanded(props.subscription.Id);
      }}
      expanded={props.expanded}
    >
      <AccordionSummary expandIcon={<ExpandMore />} id={props.subscription.Id}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ width: "100%" }}
        >
          <Typography style={{ width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>
              {props.subscription.Name}{" "}
            </span>
          </Typography>
          {props.active && (
            <Chip size="medium" label={t("Active")} sx={{
              height: 50,
              fontSize: "1rem",
              marginRight: "12px",
            }} />
          )}
          {!props.active && <Typography variant="body2">{
              props.subscription.Price === "0" ? t("Free") : props.subscription.Price
            }</Typography>}
        </Stack>
      </AccordionSummary>
      <AccordionDetails 
      sx={{
       
      }}>
        <ul
          style={{
            paddingTop: 0,
            marginTop: 0,
            margin: 0,
            paddingBottom: "12px",
            lineHeight: "160%",
          }}
        >
          {props.features.map((feature: string, index: number) => {
            return <li key={index}>{t(`subscription.${feature}`)}</li>;
          })}
        </ul>
        {props.active && !parseSku(props.subscription.Id).isTrial && props.subscription.Id !== "glass_essentials" && (
          <Stack>
            <Button
              variant="text"
              onClick={() => {
                props.onUnsubscribe(props.subscription.Id);
              }}
            >
              {t("cancel-subscription")}
            </Button>
          </Stack>
        )}

        {/* 
        trs, a) this should be a chip not a button which doesn't do anything
        b) trial status is shown from the chip at the top of the accordian
        c) now we need to show manage at the bottom for large font display support, better to have one button down here
        
        {props.active && parseSku(props.subscription.Id).isTrial && (
          <Stack>
           

            <Chip size="medium" label={t("Trial Mode")} sx={{
              height: 50,
              fontSize: "1rem",
              marginRight: "12px",
            }} />
          </Stack>
        )} */}
        {!props.active && props.subscription.Id !== "glass_essentials" &&
          <Button
            fullWidth
            style={{ marginTop: "12px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              props.onSubscribe(props.subscription.Id);
            }}
          >
            {props.subscription.Price === "0" && <span>{t("Free")}</span>}
            {props.subscription.Price !== "0" && (
              <span>
                {props.subscription.Name} {props.subscription.Price}
              </span>
            )}
          </Button>
        }
      </AccordionDetails>
    </Accordion>
  );
}
