import React, { useState, useRef } from "react";
import {
  ListItem,
  ListItemIcon,
  IconButton,
  Button,
  Slider,
  ButtonGroup,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { jsonEqual } from "types/types";
import { getContrastColor } from "colors/Utils";

interface AdjustableControlProps {
  label: string;
  value: number | string;
  onValueChange: (newValue?: number) => void;
  icon: React.ReactElement;
  mode?: "slider" | "buttons" | "button";
  min?: number;
  max?: number;
  step?: number;
  mult?: number;
  units?: string;
  decimalPlaces?: number;
  iconColor?: string;
  renderSliderCondition?: boolean;
  onIconClick?: () => void;
  showIcon?: boolean;
  buttonChildren?: React.ReactNode;
  hideValueLabel?: boolean;
}

const AdjustableControl: React.FC<AdjustableControlProps> = ({
  label,
  value,
  mode = "buttons",
  min = 0,
  max = 100,
  step = 1,
  mult = null,
  units = "",
  decimalPlaces = 2,
  icon,
  onValueChange,
  renderSliderCondition = true,
  onIconClick,
  iconColor,
  showIcon = false,
  buttonChildren,
  hideValueLabel = false,
}) => {
  const [internalControlMode, setInternalControlMode] = useState(mode);

  const [slideSize, setSlideSize] = useState<[number, number]>([16, 16]);

  const theme = useTheme();

  const valueIsNumber = typeof value === "number";

  const multiplyLess = (m: number) => {
    if (!valueIsNumber) return;

    const newValue = Math.round(value / m / 5) * 5;
    if (newValue <= max && newValue >= min) {
      onValueChange(newValue);
    }
  };

  const multiplyMore = (m: number) => {
    if (!valueIsNumber) return;

    const newValue = Math.round((value * m) / 5) * 5;
    if (newValue < max && newValue >= min) {
      onValueChange(newValue);
    }
  };

  const handleChangePlusMinus = (delta: number) => {
    if (!valueIsNumber) return;

    const newValue = parseFloat((value + delta).toFixed(decimalPlaces));
    onValueChange(Math.max(min, Math.min(max, newValue)));
  };

  const handleControlModeToggle = () => {
    setInternalControlMode((prevMode) =>
      prevMode === "buttons" ? "slider" : "buttons"
    );
  };

  const handleChangeSlider = (event: Event, newValue: number | number[]) => {
    const floatValue =
      typeof newValue === "number"
        ? newValue.toFixed(decimalPlaces)
        : newValue[0].toFixed(decimalPlaces);
    onValueChange(parseFloat(floatValue));
  };

  const buttonGroupRef = useRef(null);

  if (buttonGroupRef.current) {
    const w = Math.max(16, buttonGroupRef.current.offsetWidth);
    const h = Math.max(16, buttonGroupRef.current.offsetHeight);
    const wh = [w, h] as [number, number];
    // this jsonEqual prevents an infinite loop
    if (!jsonEqual(wh, slideSize)) {
      setSlideSize(wh);
    }
  }

  return (
    <ListItem
      sx={{
        minWidth: "100%",
        display: "flex",
        justifyContent: "space-between",
        px: 0,
      }}
    >
      <Box
        sx={{
          flex: "0 0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <ListItemIcon>
          {!onIconClick ? (
            icon
          ) : (
            <IconButton
              onClick={onIconClick}
              aria-label="Toggle Icon"
              sx={{
                backgroundColor: iconColor ? iconColor : "transparent",
              }}
            >
              {icon}
            </IconButton>
          )}
        </ListItemIcon>

        <Typography variant="body2">{label}</Typography>
      </Box>

      <Box
        sx={{
          flex: "0 0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="small"
          variant="text"
          color="inherit"
          disabled={!renderSliderCondition}
          sx={{
            marginRight: `${slideSize[1] / 3}px`,
            justifyContent: "flex-end",
            "&.Mui-disabled": {
              color: theme.palette.text.primary,
            },
          }}
          endIcon={
            showIcon &&
            (internalControlMode === "slider" ? (
              <ToggleOnIcon />
            ) : (
              <ToggleOffIcon />
            ))
          }
          onClick={handleControlModeToggle}
        >
          {!hideValueLabel && (
            <Typography variant="body2">
              {/* If value is a number, round it to the decimalPlaces, otherwise capitalize the first letter. */}
              {valueIsNumber
                ? value.toFixed(decimalPlaces)
                : [value.slice(0, 1).toUpperCase(), value.slice(1)].join("")}
              {units}
            </Typography>
          )}
        </Button>

        {internalControlMode === "button" && (
          <ButtonGroup>
            <Button variant="contained" onClick={() => onValueChange()}>
              {buttonChildren ?? (
                <Typography variant="body2">{value}</Typography>
              )}
            </Button>
          </ButtonGroup>
        )}

        {internalControlMode === "buttons" && (
          <ButtonGroup ref={buttonGroupRef} sx={{ typography: "body2" }}>
            <Button
              variant="contained"
              size="small"
              disabled={!renderSliderCondition}
              onClick={() => {
                if (mult) {
                  multiplyLess(mult);
                } else {
                  handleChangePlusMinus(-step);
                }
              }}
            >
              <RemoveIcon />
            </Button>

            <Button
              variant="contained"
              size="small"
              disabled={!renderSliderCondition}
              onClick={() => {
                if (mult) {
                  multiplyMore(mult);
                } else {
                  handleChangePlusMinus(step);
                }
              }}
            >
              <AddIcon />
            </Button>
          </ButtonGroup>
        )}

        {internalControlMode === "slider" && typeof value === "number" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...(slideSize && { minHeight: `${slideSize[1]}px` }),
            }}
          >
            <Slider
              disabled={!renderSliderCondition}
              value={value}
              onChange={handleChangeSlider}
              valueLabelDisplay="off"
              min={min}
              max={max}
              step={step}
              sx={{
                typography: "body2",
                color: getContrastColor(theme.palette.primary.main),
                ...(slideSize && { width: `${slideSize[0]}px` }),
              }}
            />
          </Box>
        )}
      </Box>
    </ListItem>
  );
};

export default AdjustableControl;
