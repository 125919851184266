import { Setting } from "types/types";
import { xfetch, xfetchLocal, toJSONPostRequest, statusGood } from "utils/xfetch";
import xconsole from "utils/xconsole";

const makeSettingsMap = (settings: Setting[]): Map<string, Setting> => {
  const map = new Map<string, Setting>();
  settings.forEach((setting) => {
    map.set(setting.Name, setting);
  });

  return map;
};

const getSetting = async (key: string): Promise<Setting> => {
  if (globalThis.isXraiGo) {
    return new Promise<Setting>((resolve, reject) => {
      resolve(undefined);
    });
  }

  const setting = { Name: key };
  const payload = await toJSONPostRequest(setting);

  const res = await xfetch("/getsetting", payload);
  return (await res.json()) as Setting;
};

const setSetting = async (key: string, val: string) => {

  // TODO: take care this must be an array (even for one setting)
  // or the server side will crumble.
  var setting = [{
     Name: key
   , Value: val
  }]

  const payload = await toJSONPostRequest(setting);
  await xfetch("/updatesettings", payload)
    .then((res) => {
      if (!statusGood(res.status)) {
        xconsole.error(`setSetting fail fetch k(${key})`);
      }
    })
    .catch((e) => {
      xconsole.error(`setSeetting fail parse k(${key})`,e);
    });
}

const getStateLocal = <T = string> (key:string): Promise<T> => {
    return getState(key,true);
}

const setStateLocal = async<T = string> (key:string, value:T ) => {
    setState(key,value,true);
}

const getState = <T = string> (key:string, local:boolean=false): Promise<T> => {
  if (globalThis.isXraiGo) {
    return new Promise<T>((resolve, reject) => {
      resolve(undefined);
    });
  }

  //xconsole.log("getState",key,local);

  var query = { key: key };

  var qry = local
    ? toJSONPostRequest(query).then((p) => {
        return xfetchLocal("/getstate", p);
      })
    : toJSONPostRequest(query).then((p) => {
        return xfetch("/getstate", p);
      });

  qry.catch((e) => {
    xconsole.error(`getState fail fetch k(${key}) l(${local})`, e);
  });

  var val = qry
    .then((res) => {
      if (!statusGood(res.status)) {
        xconsole.error(`getState fail status k(${key}) l(${local})`);
        return undefined;
      }
      return res.json();
    })
    .then((jsn) => {
      return jsn.val;
    })
    .catch((e) => {
      xconsole.error(`getState fail parse k(${key}) l(${local})`, e);
      return undefined;
    });

  return val;
}

const setState = async<T = string> (key:string, value:T, local:boolean=false) => {

  var query = {
    Key: key,
    Value: value
  };
  
  var qry = local ?
      toJSONPostRequest(query).then((p) => { return xfetchLocal("/setstate", p) }) :
      toJSONPostRequest(query).then((p) => { return xfetch("/setstate", p) });

  qry.then((res) => {
    if (!statusGood(res.status)) {
      xconsole.error("setState fail fetch",res,query,local);
    }
  })
  .catch((e) => {
    xconsole.error("setState fail parse",e,query,local);
  });
}

export {makeSettingsMap};
export {getSetting};
export {setSetting};
export {getState};
export {setState};
export {getStateLocal};
export {setStateLocal};
