import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import usePairingDialog from "./usePairingDialog";
import { ReactComponent as DevicesIcon } from "assets/MenuDevices.svg";
import { ReactComponent as DisconnectIcon } from "assets/MenuDisconnect.svg";
//import ConnectIcon from "@mui/icons-material/TapAndPlay";
//import DisconnectIcon from "@mui/icons-material/PhonelinkErase";
import {getStateLocal} from "utils/settings";
import { xfetchLocal, paired } from "utils/xfetch";
import {useStore} from "state/store"

interface PairingButtonProps {
  handler?: () => void;
  color?: string
}

export function PairingButton(
  { handler, color }: PairingButtonProps,
) {
  const pairingDialog = usePairingDialog();
  const [enablePairing, setEnablePairing] = useState<boolean>(false);
  const [height,] = useStore<number>("rootIconSize");
  useEffect(() => {
    let isMounted = true;
    const fetchState = async () => {
      const result = await getStateLocal<string>("EnablePairing");
      if ( isMounted ) {
        setEnablePairing(result==="True");
      }
    };
    fetchState();
    return () => {
      isMounted = false;
    };
  }, []);

  if (paired()) {
    return (
      <>
        <IconButton
          onClick={() => {
            xfetchLocal(`/unpairdevice`);
          }}
        >
          <DisconnectIcon style={{ width: height, height: height}} />
        </IconButton>
      </>
    );
  }

  if (!enablePairing) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={() => {
          pairingDialog.open();
        }}
        aria-haspopup="true"
      >
        <DevicesIcon style={{ width: height, height: height}} />
      </IconButton>
      {pairingDialog.Dialog}
    </>
  );
}
