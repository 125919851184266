import { Fade, Typography } from "@mui/material";
import { Stack, SxProps } from "@mui/system";
import React, { ReactElement } from "react";

interface EmptyProps {
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
  sx?: SxProps;
  footer?: ReactElement<any, any>;
  header?: ReactElement<any, any>;
}
const EmptyContent = (props: EmptyProps) => {
  return (
    <Fade in={true} timeout={200}>
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          ...{
            p: "16px",
            maxWidth: "320px",
            mx: "auto",
            minHeight: "15vh",
            textAlign: "center",
          },
          ...props.sx,
        }}
      >
        {props.header}
        <Typography variant="h3" component="h2">
          {props.title}
        </Typography>
        {!!props.message && (
          <Typography
            variant="body2"
            sx={{
              opacity: 0.6,
              lineHeight: "120%",
            }}
            component="div"
          >
            {props.message}
          </Typography>
        )}
        {props.footer}
      </Stack>
    </Fade>
  );
};
export default EmptyContent;
