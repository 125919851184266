import { useEffect, useState } from "react";

type Dxy = {
  x: number;
  y: number;
};

const getWindowDxy = (): Dxy => {
  return { x: window.innerWidth, y: window.innerHeight };
};

const useWindowDxy = (): [Dxy] => {
  const [windowDxy, setWindowDxy] = useState<Dxy>(getWindowDxy());

  useEffect(() => {
    const onResize = () => {
      const wdxy = getWindowDxy();
      setWindowDxy(wdxy);
    };

    window.addEventListener("resize", onResize);
    
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return [windowDxy];
};

export default useWindowDxy;
