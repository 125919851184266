import React from "react";
import { Button, Stack } from "@mui/material";
import { LangSettingType } from "./langaugeUtils";
import { useTranslation } from "react-i18next";
import "./LanguageDropdown.css";
import { ArrowDropDown } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useFavoriteLanguageDialog from "comps/favoriteLanguages/useFavoriteLanguageDialog";
import { useStoreUnity } from "state/store";

interface Props {
  type: LangSettingType;
  displayType?: "button" | "dropdown";
  label?: string;
  sublabel?: string;
  onChange?: (key: string) => void;
  size?: "small" | "large";
  config?: "wizard" | "settings" | "drawer";
  sx?: {};
}

export default function LanguageDropdown(props: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const color = theme.palette.text.primary;

  const Dialog = useFavoriteLanguageDialog();

  const displayType = props.displayType || "button";

  const [languageInfo] = useStoreUnity<string>(`${props.type}Info`, null);

  const label = () => {
    if (props.label) {
      return props.label;
    } else {
      return t(`settings.${props.type}.title`);
    }
  };

  const sublabel = () => {
    if (props.sublabel) {
      return props.sublabel;
    } else if (languageInfo === "Multiple") {
      return t(languageInfo);
    } else {
      return t(`settings.${props.type}.${languageInfo}`);
    }
  };

  return (
    <>
      <Button
        className={`display-type-${displayType}`}
        sx={{ ...props.sx }}
        variant="contained"
        fullWidth
        size={props.size}
        onClick={() => {
          Dialog.open({
            title: t(`settings.${props.type}.title`),
            type: props.type,
            onSelect: () => {},
          });
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems={displayType === "button" ? "center" : "start"}
          style={{ width: "100%" }}
        >
          <span
            style={{
              paddingTop: "5px",
              opacity: 0.7,
              textTransform: "uppercase",
              margin: "0px 0",
              color: color,
              lineHeight: props.config === "wizard" ? "160%" : "100%",
            }}
          >
            {label()}
          </span>
          <span
            style={{
              paddingTop: "5px",
              opacity: 0.7,
              margin: "0px 0",
              color: color,
              display: "inline-block",
              maxWidth: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {sublabel()}
          </span>
          {/*
          {!!selected && (
            <div className="label-wrap" style={{width:"95%"}}>
              <LineClamp
                lines={1}
                text={t(`settings.${props.type}.${selected}`)}
                style={{
                  color: "var(--text-main)",
                  textTransform: "capitalize",
                  fontSize: theme.typography.body2.fontSize,
                  lineHeight: props.config !== "drawer" ? "3em" : "2em",
                }}
              />
            </div>
          )}*/}
          {displayType === "dropdown" && (
            <div
              style={{
                position: "absolute",
                right: "12px",
                color: theme.palette.text.disabled,
              }}
            >
              <ArrowDropDown />
            </div>
          )}
        </Stack>
      </Button>
      {Dialog.Dialog}
    </>
  );
}
