import { ThemeContext } from "@emotion/react";
import { ThemeVars } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import Logo from "./Logo";

interface Props {
  size?: number
}

export default function HeyXraiIndicator(props: Props) {
  const size = props.size || 60;
  const theme = useContext(ThemeContext) as ThemeVars;
  return (
    <Box sx={{
      position:"relative",
      overflow:"visible", 
      width:`${size}px`, 
      display:"flex",   
      alignItems:"center",
      justifyContent: "center",
      height:`${size}px`}}>
      
      <Box sx={{
        backgroundColor:theme.palette.primary.main, 
        width:`${size * 0.9}px`,
        zIndex:1, 
        height:`${size * 0.9}px`, 
        borderRadius:"50%",
        display:"flex",
        alignItems:"center",
        justifyContent: "center",
        border:`solid 4px rgba(0,0,0,0.5)`
      }}>
        <Logo type="logomark" style={{marginTop:`${size * 0.15}px`, width:`${size * 0.51}px`}} />
      </Box>
      <div className="animate-spin" style={{
        width: "100%",
        height: "80%",
        position:"absolute",
        zIndex:0,
        backgroundColor:"red",
        borderRadius:"50px",
        filter: "blur(6px)"
      }}></div>
      <div className="animate-spin" style={{
        height: "100%",
        width: "80%",
        position:"absolute",
        zIndex:0,
        right: "4px",
        backgroundColor:"blue",
        borderRadius:"50px",
        filter: "blur(8px)"
      }}></div>
      <div className="animate-spin-2" style={{
        height: "100%",
        width: "80%",
        left: "4px",
        zIndex:0,
        position:"absolute",
        backgroundColor:"green",
        borderRadius:"50px",
        filter: "blur(6px)"
      }}></div>
    </Box>
  );
}
