import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { ThemeLoader } from "themes/ThemeLoader";
import KeyListener from "KeyListener";

import { SoftwareUpdateContextProvider } from "comps/softwareUpdate/SoftwareUpdateContext";

import AppVitalsScreen from "pages/AppVitalsScreen";
import AudioChannelPage from "pages/Dante";
import HomeScreen from "pages/HomeScreen";
import InstallGlassesApps from "pages/InstallGlassesApps";
import LoginScreen from "pages/LoginScreen";
import LanguageScreen from "pages/Onboarding/LanguageScreen";
import OverviewScreen from "pages/Onboarding/OverviewScreen";
import PermissionsScreen from "pages/Onboarding/PermissionsScreen";
import PermissionsVerify from "pages/Onboarding/PermissionsVerify";
import Privacy from "pages/Onboarding/Privacy";
import RegionScreen from "pages/Onboarding/RegionScreen";
import Terms from "pages/Onboarding/Terms";
import WelcomeScreen from "pages/Onboarding/WelcomeScreen";
import ProfileScreen from "pages/ProfileScreen";
import SettingsPage from "pages/SettingsPage";
import SubscriptionsScreen from "pages/SubscriptionsScreen";
import SubtitleScreen from "pages/SubtitleScreen";
import GuidedTooltip from "comps/guides/GuidedTooltip";

type Props = {
  Dialog: any;
};

const AppRoutes: React.FC<Props> = ({ Dialog }) => {
  const enableKeyListener = false;
  const isPort3000 = window.location.port === "3000";

  return (
    <Router>
      {enableKeyListener && isPort3000 && <KeyListener />}

      <ThemeLoader>
        <SoftwareUpdateContextProvider>
          <CssBaseline />
          <GuidedTooltip />

          <Routes>
            <Route
              path="/"
              element={
                !globalThis.isXraiGo ? <LoginScreen /> : <SubtitleScreen />
              }
            />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/welcome" element={<WelcomeScreen />} />
            <Route path="/dante" element={<AudioChannelPage />} />
            <Route path="/language" element={<LanguageScreen />} />
            <Route path="/region" element={<RegionScreen />} />
            <Route path="/permissions" element={<PermissionsScreen />} />
            <Route path="/verifyperms" element={<PermissionsVerify />} />
            <Route path="/overview" element={<OverviewScreen />} />
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/vitals" element={<AppVitalsScreen />} />
            <Route path="/settings/*" element={<SettingsPage />} />
            <Route path="/subtitles" element={<SubtitleScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/installers" element={<InstallGlassesApps />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/subscriptions" element={<SubscriptionsScreen />} />
            <Route path="/:param" element={<SubtitleScreen />} />
          </Routes>
        </SoftwareUpdateContextProvider>

        {Dialog.Dialog}
      </ThemeLoader>
    </Router>
  );
};

export default AppRoutes;
