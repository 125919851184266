import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const ButtonGroup = styled(DialogActions)({
  margin: "1.5rem 1rem",
  display: "flex",
  alignContent: "center",
  justifyContent: "space-around",
});

interface XraiModalProps {
  title?: string;
  message: string;
  open: boolean;
  closeHandler: () => void;
}

const XraiErrorModal = ({
  title,
  message,
  open,
  closeHandler,
}: XraiModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={closeHandler}>
      <DialogTitle sx={{ fontSize: "1.5rem" }}>{title ?? t("Error")}</DialogTitle>
      <DialogContent sx={{ paddingBottom: "0" }}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <ButtonGroup>
        <Button variant="contained" size="large" onClick={closeHandler}>
          {t("Okay")}
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default XraiErrorModal;
