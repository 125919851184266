import JwtToken from "./JwtToken";
import { AppUser } from "types/types";
import { appReload } from "utils/xfetch";

export interface AuthState {
  isLoading?: boolean;
  isError?: boolean;
  appUser: AppUser;
  isMounted: false;
  errorMessage?: string;
}

export const authStateZero: AuthState = {
  isLoading: true,
  isError: false,
  appUser: undefined,
  isMounted: false,
  errorMessage: undefined,
};

export interface AuthTokens {
  idToken: JwtToken;
  accessToken: JwtToken;
  refreshToken: string;
}

export const authStateReducer = (state: any, action: any) => {
  switch (action.type) {
    case "USER_DATA_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case "USER_NOT_LOGGED_IN":
      if (window.location.pathname !== "/") {
        appReload();
      }
      return {
        ...state,
        isLoading: false,
        isError: false,
        appUser: undefined,
      };

    case "USER_DATA_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        appUser: action.payload.appUser,
        isMounted: action.payload.isMounted,
      };

    case "USER_DATA_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
      };

    case "RESET_USER_DATA":
      return { ...state };

    default:
      return { ...state };
  }
};
