//import xconsole from "utils/xconsole";
import { useCallback, useSyncExternalStore } from "react";
import { isEqual } from "lodash";
import {
  getState as getStateUnity,
  setState as setStateUnity
} from "utils/settings";

function decodeLegacy<T>(text: string): T {
  try {
    return JSON.parse(text) as T;
  } catch {
    // handle legacy non-json
    if (text === "True"  || text === "true" ) {
      return true  as T;
    } else
    if (text === "False" || text === "false" ) {
      return false as T;
    } else {
      return text  as T;
    }
  }
}

export class Store<T>
{
  private state: T|undefined;
  private listeners: Array<(state: T|undefined) => void> = [];

  constructor() {
    this.state = undefined;
  }

  set(state: T):T {

    if ( state === undefined ) {
      if ( this.state !== undefined ) {
        this.state = undefined;
        this.emit();
      }
      return this.state;
    }

    if (!isEqual(this.state,state)) {
      this.state = state;
      this.emit();
    }

    return this.state;
  }

  get():T {
    return this.state;
  }

  subscribe(listener: (state: T|undefined) => void) {
    this.listeners = [...this.listeners, listener];
    return () => {
      this.listeners = this.listeners.filter(l => l !== listener);
    };
  }

  clear():T {
    return this.set(undefined);
  }

  private emit() {
    for (let listener of this.listeners) {
      listener(this.state);
    }
  }

  // TODO HACK: junk to deal with our boolean debacle
  decode(text: string)
  {
    this.set(decodeLegacy<T>(text));
  }
}

const StoreMap: Record<string, Store<any>> = {};

export function getOrMakeStore<T>(key: string): Store<T> {
  if(!StoreMap[key]) {
    StoreMap[key] = new Store<T>();
  }

  return StoreMap[key] as Store<T>;
}

export function useStore<T>(
  key : string,
  val : T | (() => T) | undefined = undefined
) :
  [T, (nv: T | ((ov: T) => T)) => T]
{
  const store = getOrMakeStore<T>(key);

  const state = useSyncExternalStore<T>(
    store.subscribe.bind(store),
    store.get.bind(store)
    )

  const storeBoundSet = store.set.bind(store);

  const setState = useCallback((val : T | ((v:T) => T)) => {
    if ( typeof val === 'function' ) {
      return storeBoundSet((val as (v:T) => T)(store.get()));
    } else {
      return storeBoundSet(val);
    }
  // disable for storeBoundSet as it only changes with the store
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  if ( state !== undefined ) {
    return [state, setState];
  }

  // initialize state

  // no initial value provided
  if ( val === undefined ) {
    return [state, setState];
  }

  if ( typeof val === 'function' ) {
    storeBoundSet((val as () => T)());
  } else {
    storeBoundSet(val);
  }

  return [state, setState]
}

export function useStoreLocal<T>(
  key : string,
  val : T | (() => T) = undefined
) :
  [T, (nv: T | ((ov: T) => T)) => T]
{
  const [state, setState] = useStore<T>(key);

  const setStateLocal = useCallback((val : T | ((v:T) => T)): T => {
    const newVal = setState(val);
    if ( newVal !== undefined )
    {
      window.localStorage.setItem(key,JSON.stringify(newVal));
    } else {
      window.localStorage.removeItem(key);
    }
    return newVal;
  // disable for key as if key changes so will the store/setState
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setState]);

  if ( state !== undefined || val === undefined ) {
    return [state, setStateLocal];
  }

  // initialize state

  const jsonVal = window.localStorage.getItem(key);

  try {
    if ( jsonVal !== null ) {
      setStateLocal(JSON.parse(jsonVal));
      return [state, setStateLocal];
    }
  } catch {
    // failed to parse continue to argument val
  }

  // no initial value argument provided
  if ( val === undefined ) {
    return [state, setStateLocal];
  }

  if ( typeof val === 'function' ) {
    setStateLocal((val as () => T)());
  } else {
    setStateLocal(val);
  }

  return [state, setStateLocal];
}

export function useStoreUnity<T>(
  key : string,
  val : T | (() => T) = undefined
) :
  [T, (nv: T | ((ov: T) => T)) => T]
{
  const [state, setState] = useStore<T>(key);

  const setStateWrap = useCallback((val : T | ((v:T) => T)): T => {
    const oldVal = state;
    const newVal = setState(val);
    if ( newVal !== undefined && !isEqual(oldVal,newVal))
    {
      setStateUnity(key,newVal);
    }
    return newVal;
  // disable for key as if key changes so will the store/setState
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState]);

  if ( state !== undefined ) {
    return [state, setStateWrap];
  }

  //TODO HACK: abuse null for "pending" to stop multiple renders from calling
  setState(null);

  //initialize state
  getStateUnity(key).then((text)=>{
    if ( text !== undefined ) {
      setState(decodeLegacy<T>(text));
    } else if ( val !== undefined ) {
      setState(val);
    }
  });

  return [state, setStateWrap];
}
