import React, { useState, useRef } from "react";
import { ListItemButton } from "@mui/material";

type Props = {
  onLongPress: () => void;
  onClick: (event?: any) => void;
  children: React.ReactNode;
  selected?: boolean;
  delay?: number;
  sx?: React.CSSProperties;
  id?: string;
};

const LongPressListItemButton: React.FC<Props> = ({
  onLongPress,
  onClick,
  children,
  selected = false,
  delay = 200,
  sx = {},
  id = "",
}) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timerRef = useRef(null);

  const startPressTimer = () => {
    setLongPressTriggered(false);
    timerRef.current = setTimeout(() => {
      setLongPressTriggered(true);
      onLongPress();
    }, delay);
  };

  const clearPressTimer = () => {
    clearTimeout(timerRef.current);
  };

  const handleMouseDown = () => {
    startPressTimer();
  };

  const handleMouseUp = () => {
    clearPressTimer();
    if (!longPressTriggered) {
      onClick();
    }
  };

  const handleMouseLeave = () => {
    clearPressTimer();
  };

  const handleTouchStart = () => {
    startPressTimer();
  };

  const handleTouchEnd = () => {
    clearPressTimer();
    if (!longPressTriggered) {
      onClick();
    }
  };

  const handleTouchMove = () => {
    clearPressTimer();
  };

  return (
    <ListItemButton
      id={id}
      selected={selected}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      sx={sx}
    >
      {children}
    </ListItemButton>
  );
};

export default LongPressListItemButton;
