import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Thtml } from "comps/common/Thtml";
import { XraiFieldWrapper } from "comps/XraiFieldWrapper";
import { Setting } from "types/types";
import { Stack } from "@mui/system";

interface Props {
  setting: Setting;
  label?: string;
  updateSetting: (name: string, value: any) => void;
}

export default function SettingDropdown(props: Props) {
  const { t, i18n } = useTranslation();
  const setting = props.setting;
  return (
    <XraiFieldWrapper
      label={t(`settings.${setting.Name}.title`)}
      description={<Thtml tag={`settings.${setting.Name}.summary`} />}
    >
      <FormControl
        fullWidth
        variant="outlined"
        sx={{ mb: "6px" }}
        className="x-form-control"
      >
        <Select
          value={setting.Value}
          onChange={(e) => {
            props.updateSetting(setting.Name, e.target.value);
          }}
          sx={{ boxShadow: 2 }}
        >
          {props.label && <MenuItem disabled={true}>{props.label}</MenuItem>}
          {setting.Options && setting.Options.map((v, index) => {
            if (
              true &&
              setting.Name !== "SelectedMicrophone" &&
              setting.Name !== "SelectedAudioDevice" &&
              setting.Name !== "SelectedAudioSource" &&
              setting.Name !== "SelectedAudioOutput" &&
              setting.Name !== "SelectedVideoSource"
            ) {
              const localKey = `settings.${setting.Name}.${v[0]}`;
              const globalKey = `global.${localKey}`;
              const labels: {
                main: string,
                secondary: string | null
              } = {
                main: i18n.exists(globalKey) ?
                  `${t(globalKey)}` : `${t(localKey)}`,
                secondary: i18n.exists(globalKey) ?
                  `${t(localKey)}` : null
              }

              return (
                <MenuItem key={index} value={v[0]}>
                  <Stack style={{width:"100%"}} direction="row" justifyContent="space-between">
                    <div style={{width:"100%"}}>{labels.main}</div>
                    {!!labels.secondary && <div style={{fontSize:"0.85rem", opacity:0.7}}>
                      {labels.secondary}
                    </div>}
                  </Stack>
                </MenuItem>
              );
            } else {
              return <MenuItem key={index} value={v[0]}>{t(v[1])}</MenuItem>;
            }
          })}
        </Select>
      </FormControl>
    </XraiFieldWrapper>
  );
}
