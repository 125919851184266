import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { ReactComponentElement } from "react";

type Props = {
  label: string;
  className?: string;
  labelRight?: ReactComponentElement<any>;
  icon?: ReactComponentElement<any>;
  input?: ReactComponentElement<any>;
}

const InputListItem: React.FC<Props> = (props) => (
  <Stack
    direction="row"
    className={`input-list-item ${props.className || ""}`}
    style={{ position: "relative" }}
    alignItems="center"
    spacing={1}
  >
    {!!props.icon && (
      <Stack justifyContent="center" style={{ width: "36px" }}>
        {props.icon}
      </Stack>
    )}
    <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body2" component="span">
          {props.label}
        </Typography>

        {props.labelRight}
      </Stack>
      {props.input}
    </Stack>
  </Stack>
);

export default InputListItem;
