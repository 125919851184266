import React, { useEffect, useState } from "react";
import { FavoriteLanguagePayload, getFavoriteSubtitleLanguages, setFavoriteSubtitleLang } from "./favoriteLanguage-utils";
import FavoriteLanguageItem from "./FavoriteLanguageItem";

export const FavoriteSubtitleLanguages = () => {
  const [langs, setLangs] = useState<FavoriteLanguagePayload[]>([]);
  const [selectedCount, setSelectedCount] = useState(0);


  const handleLanguageToggle = async (lang: FavoriteLanguagePayload) => {

    const updated = langs.map((l) => {
      l.selected = false;
      if(l === lang) {
        l.selected = !l.selected
      }
      return l;
    });

    // Set the current number of selected items
    const selected = langs.filter((l) => l.selected);
    setSelectedCount(selected.length);
    await setFavoriteSubtitleLang(selected[0]);
    // Update the langs state
    setLangs(updated);
  };

  useEffect(() => {
    getFavoriteSubtitleLanguages().then((res) => {
      setSelectedCount(() => {
        return res?.filter((l) => l.selected)?.length ?? 0;
      });
      setLangs(() => {
        return res ?? [];
      });
    });
  }, []);

  return (
    <div>
      {langs.map((lang, index) => {
        return (
          <FavoriteLanguageItem
            forceToAzure={selectedCount > 1}
            onToggle={() => {
              handleLanguageToggle(lang);
            }}
            key={index}
            lang={lang}
            type="TargetLanguage"
          />
        );
      })}
    </div>
  );
};
