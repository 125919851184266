import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import { styled, useTheme } from "@mui/material/styles";
import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  Stack,
  Box,
  Typography,
  Container,
  Dialog,
  IconButton,
  Input,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useStore, useStoreUnity } from "state/store";
import { Multicast } from "types/types";
import { ReactComponent as StreamIcon } from "assets/StreamIcon.svg";
import xconsole from "utils/xconsole";
import useWindowDxy from "hooks/useWindowDxy";

// BUTTONS
const ButtonColumnContainer = styled(Stack)({
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  margin: "8px",
});

const ActionButton = styled(Button)({
  borderRadius: "9px",
  backgroundColor: "#6300FF",
  border: "1px solid #6300FF",
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#3700B3",
  },
});

const ArrowButton = styled(IconButton)({
  backgroundColor: "#6300FF",
  color: "white",
  borderRadius: "9px",
  "&:hover": {
    backgroundColor: "#3700B3",
  },
});

const CancelButton = styled(Button)({
  borderRadius: "9px",
  backgroundColor: "#EF0C58",
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#C51162",
  },
});

// CAROUSEL
const CarouselContainer = styled(Box)<{
  size: string;
  backgroundimage: string;
}>(({ size, backgroundimage }) => ({
  width: size,
  height: size,
  aspectRatio: "1 / 1",
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url(${backgroundimage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderRadius: "19px",
  dropshadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
}));

type Props = {};

const maxWidth = "75vw";

const indexMap: Map<string, number> = new Map();
let nextIndex: number = 0;

const getStringIndex = (key: string): number => {
  if (!indexMap.has(key)) {
    indexMap.set(key, nextIndex++);
    return nextIndex;
  }

  return indexMap.get(key);
};

const StreamsScreen: React.FC<Props> = () => {
  const theme = useTheme();
  const sliderRef = useRef<Slider>(null);
  const { t } = useTranslation();
  const [windowDxy] = useWindowDxy();
  const size = windowDxy.x < windowDxy.y ? "79vw" : "49vh";

  const [manualCode, setManualCode] = useState("");
  const [slideIndex, setSlideIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const [iconSize] = useStore<number>("rootIconSize");
  //const [streamMode] = useStoreUnity<string>("StreamMode", null);
  const [multicastListRaw] = useStoreUnity<Multicast[]>("MulticastList", []);
  const [multicastList, setMulticastList] = useStore<Multicast[]>(
    "multicastListClean",
    []
  );
  const [multicastSkip, setMulticastSkip] = useStore<Set<string>>(
    "multicastSkip",
    new Set<string>()
  );
  const [multicastShow, setMulticastShow] = useState<number[]>([]);
  const [targetSourceStream, setTargetSourceStream] = useStoreUnity<string>(
    "TargetSourceStream",
    ""
  );

  const [, setActivePopup] = useStore<boolean>("activePopup");

  let initialSlide = 0;
  if (multicastShow?.length > 0) {
    initialSlide = multicastShow[0];
  } else if (targetSourceStream && multicastList.length > 0) {
    const index = multicastList.findIndex((m) => m.Name === targetSourceStream);
    if (index > 0) initialSlide = index;
  }

  const sliderSettings = {
    dots: true,
    infinite: multicastList?.length > 1,
    speed: 500,
    initialSlide: initialSlide,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    onInit: () => setSlideIndex(initialSlide),
    afterChange: (current: number) => setSlideIndex(current),
  };

  const joined = () => {
    if (
      !multicastList ||
      slideIndex < 0 ||
      slideIndex >= multicastList.length
    ) {
      return false;
    }

    const res = targetSourceStream === multicastList[slideIndex].Name;

    return res;
  };

  const doOpen = useCallback(() => {
    setOpen(true);
    setActivePopup(true);
  }, [setOpen, setActivePopup]);

  const doClose = useCallback(() => {
    setMulticastShow([]);
    setOpen(false);
    setActivePopup(false);
  }, [setMulticastShow, setOpen, setActivePopup]);

  const handleJoinLeave = () => {
    const currentStream = multicastList[slideIndex];

    if (joined()) {
      // Leave stream
      xconsole.log("Leaving stream", currentStream.Name);
      setTargetSourceStream("");
    } else {
      // Join stream
      xconsole.log("Joining stream", currentStream.Name);
      setTargetSourceStream(currentStream.Name);
    }

    doClose();
  };

  const handlePrev = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  useEffect(() => {
    if (!multicastListRaw || multicastListRaw.length <= 0) {
      setMulticastList([]);
      return;
    }
    const uniqueMulticasts = new Map<string, Multicast>();

    multicastListRaw.forEach((item) => {
      if (!uniqueMulticasts.has(item.Name)) {
        uniqueMulticasts.set(item.Name, item);
        getStringIndex(item.Name);
      }
    });

    const sortedList = Array.from(uniqueMulticasts.values()).sort(
      (a, b) => getStringIndex(a.Name) - getStringIndex(b.Name)
    );

    setMulticastList(sortedList);
  }, [multicastListRaw, setMulticastList]);

  useEffect(() => {}, [multicastListRaw]);

  useEffect(() => {
    multicastList?.forEach((item, index) => {
      if (!multicastSkip.has(item.Name)) {
        setMulticastSkip((prev) => new Set(prev).add(item.Name));
        setMulticastShow((prev) => [...prev, index]);
      }
    });
  }, [multicastList, multicastSkip, setMulticastSkip, setMulticastShow]);

  useEffect(() => {
    if (multicastShow.length <= 0) return;
    doOpen();
  }, [slideIndex, setMulticastShow, doOpen, multicastShow.length]);

  // Uncomment for testing purposes.
  // const mockStreams: Multicast[] = React.useMemo(
  //   () => [
  //     {
  //       Type: "xRad",
  //       IpEp: null as null,
  //       Name: "XRAI Multicast - generic 1",
  //       Meta: "https://cdn.xrai.glass/share/multicast/splash/generic.png",
  //     },
  //     {
  //       Type: "xRad",
  //       IpEp: null as null,
  //       Name: "XRAI Multicast - generic 2",
  //       Meta: require("assets/pairing-splash.svg").default,
  //     },
  //     {
  //       Type: "xRad",
  //       IpEp: null as null,
  //       Name: "XRAI Multicast - generic 3",
  //       Meta: require("assets/pairing-splash.svg").default,
  //     },
  //   ],
  //   []
  // );

  // useEffect(() => {
  //   setMulticastList(mockStreams);
  // }, [mockStreams, setMulticastList]);

  return (
    <>
      {multicastList?.length > 0 && (
        <IconButton aria-describedby="StreamsScreen--dialog" onClick={doOpen}>
          <StreamIcon
            style={{
              width: iconSize,
              height: iconSize,
            }}
          />
        </IconButton>
      )}

      <Dialog id="StreamsScreen--dialog" open={open} onClose={doClose}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "8px 0",
          }}
        >
          {/* CAROUSEL */}
          <Box
            sx={{
              width: "100%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Slider ref={sliderRef} {...sliderSettings}>
                {multicastList?.map((stream, index) => (
                  <React.Fragment key={index}>
                    <Typography sx={{ textAlign: "center" }} variant="h6">
                      {stream.Name}
                    </Typography>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CarouselContainer
                        key={index}
                        size={size}
                        backgroundimage={stream.Meta}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </Slider>
            </Box>
          </Box>

          {/* JOIN / LEAVE */}
          {multicastList?.length > 0 && (
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: "center", maxWidth, padding: "8px" }}
            >
              <ArrowButton onClick={handlePrev} sx={{ boxShadow: 2 }}>
                <ArrowBack
                  sx={{ color: `${theme.palette.common.white} !important` }}
                />
              </ArrowButton>

              <ActionButton
                variant="contained"
                sx={{ flex: "1 1 auto", width: "70%" }}
                onClick={handleJoinLeave}
              >
                {joined() ? t("streams.leaveStream") : t("streams.joinStream")}
              </ActionButton>

              <ArrowButton onClick={handleNext} sx={{ boxShadow: 2 }}>
                <ArrowBack
                  sx={{
                    transform: "rotate(180deg)",
                    color: `${theme.palette.common.white} !important`,
                  }}
                />
              </ArrowButton>
            </Stack>
          )}

          {/* MAIN ACTION STACK */}
          {false && (
            <ButtonColumnContainer direction="column" sx={{ maxWidth }}>
              <Input
                sx={{ width: "100%", margin: "8px" }}
                placeholder={t("streams.enterCode")}
                onClick={(e) => {
                  e.stopPropagation();
                  sliderRef.current?.slickGoTo(-1);
                }}
                onChange={(e) => setManualCode(e.target.value)}
                value={manualCode}
                inputProps={{
                  style: { textAlign: "center" },
                }}
              />
            </ButtonColumnContainer>
          )}

          {/* CANCEL BUTTON */}
          <CancelButton
            variant="contained"
            sx={{ margin: "8px" }}
            onClick={doClose}
          >
            {t("Cancel")}
          </CancelButton>
        </Container>
      </Dialog>
    </>
  );
};

export default StreamsScreen;
