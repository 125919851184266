import cloneDeep from 'lodash/cloneDeep';
import xconsole from "utils/xconsole";
import { decrypt, encrypt } from "utils/encryption";

export const statusGood = ( status:number ):boolean => {
    return status === 200 || status === 204 ;
}

export const xhostLocal = ():string => {
  let xhost = globalThis.xhostLocal;
  return xhost ? xhost : process.env.REACT_APP_UNITY_API;
}

export const xhostOther = ():string => {
  let xhost = globalThis.xhostOther;
  return xhost ? xhost : xhostLocal();
}

export const xhostRoot = ():string => {
  return xhostOther();
}

export const paired = ():boolean => {
  return xhostLocal() !== xhostOther() ;
}

export function appReload()
{
  if (globalThis.isXraiGo) return;
  // @ts-ignore
  window.location.href = globalThis.xhrefAppLoad;
}

/**
 * Unity Fetch Proxies
 *
 * Used to easily call services running on the local or other remote
 * backend Unity webserver from react's development server
**/

export async function xfetchLocal(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  if (globalThis.isXraiGo) {
    return Promise.resolve(null);
  }

  const xhost = xhostLocal();

  xconsole.log(`xfetchLocal ${xhost} ${JSON.stringify(input)}`);

  if(!xhost){
    return Promise.reject(new Error("fail xhostLocal not defined"))
  }

  return doXfetch(xhost, input, init)
}

export async function xfetchOther(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {

  if (globalThis.isXraiGo) {
    return Promise.resolve(null);
  }

  const xhost = xhostOther();

  xconsole.log(`xfetchOther ${xhost} ${JSON.stringify(input)}`);

  if(!xhost){
    return Promise.reject(new Error("fail xhostOther not defined"))
  }

  return doXfetch(xhost, input, init)
}

export async function xfetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response>
{
  return xfetchOther(input,init);
}

export async function xsign(input: RequestInfo | URL, init?: RequestInit): Promise<RequestInit>
{
  const key = globalThis.aesKey;
  if ( !key ) {
    return init;
  }

  const [iv, cipher] = await encrypt(input as string);

  const headers = {
    "X-Challenge-Iv": iv,
    "X-Challenge": cipher
  };

  let temp = init ? cloneDeep(init) : {};
  temp.headers = {
    ...temp.headers,
    ...headers
  };

  return temp;
}

export async function decodeResponse(response: Response): Promise<Response>
{
  const encrypted = response.headers.get("X-Encryption-Flag") === "true";
  if ( !encrypted ) {
    return response;
  }

  const iv = response.headers.get("X-Encryption-Iv");
  const cipher = await response.arrayBuffer();
  const decrypted = await decrypt(cipher, iv);

  return new Response(decrypted, {
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
  });
}

export async function doXfetch(xhost: string, input: RequestInfo | URL, init?: RequestInit): Promise<Response>
{
  let promise: Promise<Response>;
  let vinit = await xsign(input, init);

  if (typeof input == 'string') {
    promise = fetch(`${xhost}${input}`, vinit)
  } else if (input instanceof Request) {
    let requestInfo = new Request(`${xhost}${input.url}`, input);
    promise = fetch(requestInfo);
  } else {
    return Promise.reject(new Error("fail invalid input parameter type"))
  }

  return promise.then(decodeResponse).catch(e => {
    xconsole.error('fail doXfetch', e, xhost, input, vinit);
    return null;
  });
}

export async function toJSONPostRequest(payload: any): Promise<RequestInit>
{
  const method = "POST";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const plaintext = JSON.stringify(payload);
  const key = globalThis.aesKey;

  if ( key && plaintext.length > 0 ) {
    const [iv, cipher] = await encrypt(plaintext);

    return {
      method: method,
      headers: {
        ...headers,
        "X-Encryption-Flag": "true",
        "X-Encryption-Iv": iv,
        "Content-Length": cipher.length.toString(),
      },
      body: cipher
    };
  }

  return {
    method: method,
    headers: {
      ...headers,
      "X-Encryption-Flag": "false",
      "Content-Length": plaintext.length.toString()
    },
    body: plaintext
  };
}
