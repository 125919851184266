import React from "react";
import CustomTabPanel from "./CustomTabPanel";
import { PaperSettings } from "./SubtitleSettingsDrawer";
import { useStore } from "state/store";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  activeTab: number;
  index: number;
};

const XraiGoSettings: React.FC<Props> = ({ activeTab, index }) => {
  const [languages] = useStore<string[]>("targetLangList");
  const [targetLang, setXraiGoTargetLang] = useStore<string>(
    "xraiGoTargetLang",
    null
  );

  const { t } = useTranslation();

  const dropdownValue = targetLang ?? "default";

  return (
    <CustomTabPanel value={activeTab} index={index}>
      <PaperSettings sx={{ p: 2 }}>
        <Typography variant="caption" sx={{ mb: 1 }}>
          {t("settings.TargetLanguage.title")}
        </Typography>

        <FormControl fullWidth>
          <Select
            id="XraiGoSettings--targetLang-select"
            defaultValue={dropdownValue}
            value={dropdownValue}
            onChange={(e) => setXraiGoTargetLang(e.target.value)}
          >
            <MenuItem value="default">
              {t("Default")}
            </MenuItem>

            {languages?.map((lang) => (
              <MenuItem key={lang} value={lang}>
                {t(`settings.TargetLanguage.${lang}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </PaperSettings>
    </CustomTabPanel>
  );
};

export default XraiGoSettings;
