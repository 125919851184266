import { Setting } from "types/types";
import { xfetch } from "utils/xfetch";

export const getConfigurationSetting = async (
  key: string
): Promise<Setting | undefined> => {
  const config = await getConfiguration();
  return config.get(key);
};

export async function getConfiguration() {
  if (globalThis.isXraiGo) return;

  const call = await xfetch("/configuration");
  const res = ((await call.json()) as Setting[]) || [];

  return {
    get(key: string) {
      return res.find((s) => s.Name === key);
    },
  };
}
