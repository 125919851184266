import { useEffect } from "react";
import { useStoreLocal } from "state/store";
import { setSetting } from "utils/settings";
import { xfetch } from "utils/xfetch";

const STOP = "STOP_BROADCAST";
const START = "START_BROADCAST";
const THEME = "UPDATE_THEME";
const ORIGIN = "https://teams.xrai.glass";

const useBroadcastConsumer = () => {
  const [, setAppThemeMode] = useStoreLocal<string>("appThemeMode");

  useEffect(() => {
    const handleBroadcastMessage = async (event: MessageEvent) => {
      const { data, origin } = event;

      if (origin !== ORIGIN) {
        return;
      }

      if (typeof data === "string") {
        const parsedData = JSON.parse(data);

        if (parsedData?.type === STOP) {
          await setSetting("StreamMode", "receive");
          await setSetting("StreamId", parsedData?.streamUniqueID);
        }

        if (parsedData?.type === START) {
          await setSetting("StreamMode", "multicast");
          await setSetting("StreamId", parsedData?.streamUniqueID);

          // If an active session is not running, start one.
          await xfetch("/startsession");
        }

        if (parsedData?.type === THEME) {
          switch (parsedData?.theme) {
            case "dark":
              setAppThemeMode("dark");
              break;
            case "default":
              setAppThemeMode("light");
              break;
            default:
              setAppThemeMode("system");
              break;
          }
        }
      }
    };

    window.addEventListener("message", handleBroadcastMessage);

    return () => {
      window.removeEventListener("message", handleBroadcastMessage);
    };
  }, [setAppThemeMode]);
};

export default useBroadcastConsumer;
