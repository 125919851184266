/* eslint-disable no-unreachable */
import React, { useEffect, useState, useSyncExternalStore } from "react";
import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import HeyXraiIndicator from "comps/HeyXraiIndicator";
import { useTranslation } from "react-i18next";
import useOrientation from "hooks/useOrientation";
import SubtitleItem from "comps/subtitles/SubtitleItem";
import { heyXraiStore } from "comps/gpt/HeyXraiStore";
//import { finalSubtitlesStore, fluidSubtitleStore, showFluid } from "./subtitleStore";
//import { finalSubtitlesStore, fluidSubtitleStore } from "./subtitleStore";
import { finalSubtitlesStore } from "comps/subtitles/subtitleStore";
import { useStore } from "state/store";
import {
  SubtitleLiveFinal,
  SubtitleLiveFluid,
  Transcription,
} from "types/types";
import { sequenceNumberStore } from "comps/subtitles/subtitleStore";
import ConversationDetailScreen from "pages/ConversationDetailScreen";
import ConversationSkeleton from "comps/conversations/ConversationSkeleton";
import { useSearchParams } from "react-router-dom";

export interface MessageExample {
  primary: string;
  secondary: string;
  person: string;
  timeStamp: string;
}

interface Props {
  sx?: any;
  children?: any;
  footerSize?: number;
}

export default function SubtitleList(props: Props) {
  const [searchParams] = useSearchParams();
  const sessionId: string = searchParams.get("sessionId");

  const heyxrai = useSyncExternalStore(
    heyXraiStore.subscribe,
    heyXraiStore.get
  );
  // const fluidSubtitle = useSyncExternalStore(fluidSubtitleStore.subscribe, fluidSubtitleStore.get);
  const subtitles = useSyncExternalStore(
    finalSubtitlesStore.subscribe,
    finalSubtitlesStore.get
  );
  const sequence = useSyncExternalStore(
    sequenceNumberStore.subscribe,
    sequenceNumberStore.get
  );
  const orientation = useOrientation();

  const [multiFluid] = useStore<Map<string | null, SubtitleLiveFluid>>(
    "multiFluid",
    new Map<string | null, SubtitleLiveFluid>()
  );
  const [multiFluidSeqN] = useStore<Map<string | null, number>>(
    "multiFluidSeqN",
    new Map<string | null, number>()
  );
  const [multiFinalSeqN] = useStore<Map<string | null, number>>(
    "multiFinalSeqN",
    new Map<string | null, number>()
  );

  const { transcriptions, loading } = ConversationDetailScreen();
  const [transcriptionsLoaded, setTranscriptionsLoaded] = useState(false);
  const [subtitlesLoaded, setSubtitlesLoaded] = useStore<SubtitleLiveFinal[]>(
    "subtitlesLoaded",
    []
  );

  const { t } = useTranslation();

  const seenConversation = new Map();

  useEffect(() => {
    if (!transcriptionsLoaded && transcriptions.length > 0) {
      const convertedTranscriptions: SubtitleLiveFinal[] = transcriptions.map(
        (transcript: Transcription, index: number) => ({
          c: transcript.Color,
          i: transcript.SpeakerId,
          l: t("Speaker") + " " + transcript.SpeakerId,
          n: index,
          t: transcript.Transcript,
          g: transcript.Id,
        })
      );

      setSubtitlesLoaded(convertedTranscriptions);

      setTranscriptionsLoaded(true);
    }
  }, [transcriptions, setSubtitlesLoaded, transcriptionsLoaded, t]);

  const showMultiFluid = (key: string) => {
    const fluidN = multiFluidSeqN?.get(key);
    const finalN = multiFinalSeqN?.get(key);

    // console.log(`FRACK (${key}) (${fluidN}) (${finalN})`);

    return fluidN && (!finalN || fluidN > finalN);
  };

  const scrollToBottom = (behavior?: ScrollBehavior) => {
    document.getElementById("subtitleBasic-bottom")?.scrollIntoView({
      block: "end",
      behavior: behavior ? behavior : "auto",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [orientation]);

  useEffect(() => {
    if (heyxrai === "error") {
      setTimeout(() => {
        heyXraiStore.set(undefined);
      }, 3000);
    }
  }, [heyxrai]);

  return (
    <Container
      sx={{
        maxHeight: "100%",
        width: "min(var(--line-width), 90vw)",
        ...props.sx,
      }}
    >
      {loading && sessionId !== null && (
        <Stack
          spacing={3}
          style={{
            padding: "24px 16px",
          }}
        >
          {new Array(5).fill(0).map((c, index) => {
            return <ConversationSkeleton key={index} />;
          })}
        </Stack>
      )}

      <Stack direction="column" spacing={2}>
        {!loading &&
          sessionId !== null &&
          subtitlesLoaded?.map((s, index) => {
            seenConversation.set(s.g, true);

            return (
              <SubtitleItem
                key={"subtitlesLoaded_" + s.n}
                text={s.t}
                speakerId={s.i}
                speakerLabel={s.l}
                color={s.c}
                nextLabel={subtitles[index + 1]?.l}
                number={s.n}
                t={t}
              />
            );
          })}

        {subtitles?.map((s, index) => {
          if (seenConversation.has(s.g)) {
            return null;
          }

          return (
            <SubtitleItem
              key={s.n}
              text={s.t}
              speakerId={s.i}
              speakerLabel={s.l}
              color={s.c}
              nextLabel={subtitles[index + 1]?.l}
              number={s.n}
              t={t}
            />
          );
        })}

        {!!sequence &&
          multiFluid &&
          [...multiFluid].map(
            ([key, value], index) =>
              showMultiFluid(key) && (
                <SubtitleItem
                  key={`${index}-${key}`}
                  fluid={!value.i}
                  text={value.t}
                  speakerId={value.i}
                  speakerLabel={value.i}
                  number={value.n}
                  t={t}
                />
              )
          )}

        {props.children}

        <Stack
          alignItems="center"
          className={`animate-slide-up ${
            heyxrai === "asked" ? "active" : "inactive"
          }`}
          sx={{
            width: "100%",
            left: 0,
            right: 0,
            pointerEvents: "none",
            position: "fixed",
            bottom: "110px",
          }}
        >
          <HeyXraiIndicator size={60} />
        </Stack>

        {heyxrai === "error" && (
          <Stack
            alignItems="center"
            sx={{ color: "#666", textAlign: "center", width: "100%" }}
          >
            <Typography variant="body1">
              {t("caption.heyxrai_error")}
            </Typography>
          </Stack>
        )}

        <div id="subtitleBasic-bottom"></div>
      </Stack>
    </Container>
  );
}
