import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  current?: string;
  options?: string[];
  selectProfile: (profileName: string) => void;
};

const ChannelProfileSelector: React.FC<Props> = ({
  current,
  options,
  selectProfile,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const labelColor = isDarkMode
    ? theme.palette.common.white
    : theme.palette.common.black;

  return (
    <>
      <Typography variant="caption" sx={{ mb: 0 }}>
        {t("settings.DanteInterface.activeProfile")}:{" "}
        <em>{current || t("settings.DanteInterface.noProfileSelected")}</em>
      </Typography>

      <FormControl sx={{ width: "100%", mt: 1, mb: 2 }} size="small">
        <InputLabel
          id="ChannelProfileSelector--select-label"
          sx={{ fontSize: "0.75rem" }}
        >
          {t("settings.DanteInterface.audioProfile")}
        </InputLabel>

        <Select
          labelId="ChannelProfileSelector--select-label"
          id="ChannelProfileSelector--select"
          value={current || ""}
          defaultValue={current || ""}
          label={t("settings.DanteInterface.audioProfile")}
          onChange={(e) => {
            selectProfile(e.target.value);
          }}
          sx={{
            width: "100%",
            color: labelColor,
          }}
          inputProps={{
            sx: {
              fontSize: "0.75rem",
            },
          }}
        >
          {options?.map((option) => (
            <MenuItem
              key={option}
              value={option}
              style={{ fontSize: "0.75rem", padding: "4px 8px" }}
            >
              {option}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText sx={{ marginLeft: "0px", fontSize: "0.75rem" }}>
          {t("settings.DanteInterface.selectProfile")}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default ChannelProfileSelector;
