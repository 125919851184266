import React from "react";
import { createContext, useContext } from "react";
import { useSoftwareUpdate } from "./useSoftwareUpdate";
import { SoftwareUpdateDetails } from "./useSoftwareUpdate";

interface UpdateContextType {
  isUpdateAvailable: boolean;
  details: undefined | SoftwareUpdateDetails;
  open: ()=>void;
}

const defaultUpdateContext: UpdateContextType = {
  isUpdateAvailable: false,
  details: undefined,
  open() {}
};

const UpdateContext = createContext<UpdateContextType>(defaultUpdateContext);

export const useSoftwareUpdateContext = () => useContext(UpdateContext);

interface SoftwareUpdateContextProps {
  children?: any;
}

export const SoftwareUpdateContextProvider = (
  { children }: SoftwareUpdateContextProps,
) => {
  const SoftwareUpdate = useSoftwareUpdate();
  return (
    <UpdateContext.Provider
      value={{
        isUpdateAvailable: SoftwareUpdate.hasUpdate,
        details: SoftwareUpdate.updateDetails,
        open: SoftwareUpdate.open
      }}
    >
      {children}
      {SoftwareUpdate.Prompt}
    </UpdateContext.Provider>
  );
};
