import React, { ReactElement } from "react";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

interface Props {
  label:string;
  size?: number;
  onClick?: (evt:any)=>void;
  icon: ReactElement<any>;
  className?: string
  style?: any;
}

const StackedButton = styled(Button)({
  minWidth: "var(--size)",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center",
  "& svg": {
    height: "var(--size)",
    width: "var(--size)"
  }
})

export default function IconLabelButton(props:Props) {
  const size = props.size ? `${props.size}px` : "36px";
  return (
    <StackedButton
      onClick={(evt)=>{
        if(props.onClick) props.onClick(evt);
      }}
      className={`${props.className}`}
      style={{
        ...props.style,
        ...{
          "--size": size
        }
      }}
      title={props.label}
    >
      {props.icon}
      {props.label&&(<Typography variant="body2">{props.label}</Typography>)}
    </StackedButton>
  )
}
