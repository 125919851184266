import React, { useState } from "react";

import InputListItem from "comps/common/InputListItem";
import { CameraSwitch } from "comps/SvgAssets";

import EmergencyRecordingIcon from "@mui/icons-material/EmergencyRecording";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import {
  Box,
  Button,
  Fade,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { useStore } from "state/store";
import { useThemeUpdater } from "themes/ThemeLoader";

import { useTranslation } from "react-i18next";
import { toJSONPostRequest, xfetchLocal } from "utils/xfetch";

type Props = {};

interface VideoToggle {
  camera: string;
  enabled: boolean;
}

const VideoPassthroughToggle: React.FC<Props> = () => {
  const [cameraPass, setCameraPass] = useStore<boolean>("cameraPass", false);

  const { t } = useTranslation();

  const [cameraCount, setCameraCount] = useState(1);
  const [cameraNames, setCameraNames] = useState<string[]>([]);
  const [selectedCameraName, setSelectedCameraName] = useState("");
  const [showCameraName, setShowCameraName] = useState(false);

  const { getTheme } = useThemeUpdater();

  const cycleWebcam = async () => {
    if (cameraPass) {
      const payload = await toJSONPostRequest(null);
      const call = await xfetchLocal("/cyclevideo", payload);
      const res: VideoToggle = await call.json();
      if (!cameraNames.includes(res.camera)) {
        setCameraNames([...cameraNames, res.camera]);
        setCameraCount(cameraNames.length + 1);
      } else {
        setCameraCount(cameraNames.indexOf(res.camera) + 1);
      }
      setSelectedCameraName(res.camera);
    }
  };

  const toggleWebcam = async () => {
    const payload = await toJSONPostRequest(null);
    const call = await xfetchLocal("/togglevideo", payload);
    const res: VideoToggle = await call.json();
    if (res.enabled !== cameraPass) {
      const enabled = res.enabled ? true : false;
      setCameraPass(enabled);
      setSelectedCameraName(res.camera);
      setShowCameraName(!showCameraName); // Toggle the camera name visibility
    }
  };

  return (
    <>
      <InputListItem
        label={t("VideoPassthrough")}
        icon={
          <>
            {cameraPass ? (
              <IconButton
                sx={{ marginLeft: "-8px" }}
                onClick={() => setShowCameraName(!showCameraName)}
              >
                {showCameraName ? <EmergencyRecordingIcon /> : <VideocamIcon />}
              </IconButton>
            ) : (
              <VideocamOffIcon />
            )}
          </>
        }
        labelRight={
          <Stack direction="row" style={{ marginRight: "-12px" }}>
            <Switch
              checked={cameraPass}
              onChange={(e) => {
                toggleWebcam();
              }}
            />
          </Stack>
        }
      />

      <Box
        sx={{
          marginTop: "0px !important",
          marginBottom: "8px !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={cameraPass} unmountOnExit>
          <Button
            variant="contained"
            onClick={() => {
              cycleWebcam();
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <CameraSwitch color={getTheme().palette.primary.contrastText} />
              <Typography
                variant="body2"
                sx={{ alignItems: "center", marginLeft: "12px" }}
              >
                {showCameraName
                  ? selectedCameraName
                  : `${t("Camera")} ${cameraCount}`}
              </Typography>
            </Stack>
          </Button>
        </Fade>
      </Box>
    </>
  );
};

export default VideoPassthroughToggle;
