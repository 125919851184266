import React from "react";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import { Tab, Tabs, Theme } from "@mui/material";
import { TFunction } from "i18next";
import { ReactComponent as StreamIcon } from "assets/StreamIcon.svg";
import { useStore, useStoreUnity } from "state/store";

type Props = {
  activeTab: number;
  handleChangeTab: (event: React.ChangeEvent<{}>, newValue: number) => void;
  theme: Theme;
  t: TFunction<"translation", undefined, "translation">; // Translation
};

const SettingsNavigation: React.FC<Props> = ({
  activeTab,
  handleChangeTab,
  theme,
  t,
}) => {
  const [iconSize] = useStore<number>("rootIconSize");
  const [enableServer] = useStoreUnity<boolean>("EnableServer", false);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const tabSx = {
    fontSize: theme.typography.body2.fontSize,
    textTransform: "var(--text-transform)",
    width: enableServer ? "20%" : "25%", // 100% divided by 5 tabs
    minWidth: enableServer ? "20%" : "25%",
  };

  return (
    <Tabs
      value={activeTab}
      onChange={handleChangeTab}
      variant="scrollable"
      scrollButtons="auto"
      textColor="inherit"
      indicatorColor="secondary"
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: "12px 12px 0 0",
      }}
    >
      <Tab
        icon={
          <DisplaySettingsIcon sx={{ height: iconSize, width: iconSize }} />
        }
        label={t("Settings")}
        sx={{
          ...tabSx,
          borderTopLeftRadius: "12px",
        }}
        {...a11yProps(0)}
      />

      <Tab
        icon={<ColorLensIcon sx={{ height: iconSize, width: iconSize }} />}
        label={t("Colors")}
        sx={{
          ...tabSx,
        }}
        {...a11yProps(1)}
      />

      <Tab
        icon={<FormatSizeIcon sx={{ height: iconSize, width: iconSize }} />}
        label={t("Fonts")}
        sx={{
          ...tabSx,
        }}
        {...a11yProps(2)}
      />

      <Tab
        icon={<FormatShapesIcon sx={{ height: iconSize, width: iconSize }} />}
        label={t("Spacing")}
        sx={{
          ...tabSx,
          borderTopRightRadius:
            !globalThis.isXraiGo && enableServer ? "" : "12px",
        }}
        {...a11yProps(3)}
      />

      {!globalThis.isXraiGo && enableServer && (
        <Tab
          icon={
            <StreamIcon
              style={{
                height: iconSize,
                width: iconSize,
              }}
            />
          }
          label={t("Stream")}
          sx={{
            ...tabSx,
            borderTopRightRadius: "12px",
          }}
          {...a11yProps(4)}
        />
      )}
    </Tabs>
  );
};

export default SettingsNavigation;
