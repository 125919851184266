import { PaletteMode, createTheme, Palette } from "@mui/material";

const highContrastPalette = (mode: PaletteMode, screenCast: boolean): Palette =>
  createTheme({
    palette: {
      name: "xrai-contrast-palette",
      screenCast,
      mode: screenCast || mode === "dark" ? "dark" : "light",
      ...(screenCast || mode === "dark"
        ? {
            panel: {
              main: screenCast ? "#000000" : "#1a1a1a",
            },
            action: {
              activatedOpacity: 0.24,
              active: "#FFFFFF",
              disabled: "rgba(255, 255, 255, 0.3)",
              disabledBackground: "rgba(255, 255, 255, 0.12)",
              disabledOpacity: 0.38,
              focus: "rgba(255, 255, 255, 1)",
              focusOpacity: 1,
              hover: "rgba(255, 255, 255, 0.08)",
              hoverOpacity: 0.08,
              selected: "rgba(255, 255, 255, 0.15)",
              selectedOpacity: 0,
            },
            background: {
              default: "#000000",
              paper: "#000000",
            },
            common: {
              black: "#000000",
              white: "#FFFFFF",
            },
            contrastThreshold: 3,
            divider: "rgba(255, 255, 255, 0.12)",
            error: {
              contrastText: "#fff",
              dark: "#d32f2f",
              light: "#e57373",
              main: "#f44336",
            },
            grey: {
              "50": "#fafafa",
              "100": "#f5f5f5",
              "200": "#eeeeee",
              "300": "#e0e0e0",
              "400": "#bdbdbd",
              "500": "#9e9e9e",
              "600": "#757575",
              "700": "#616161",
              "800": "#424242",
              "900": "#212121",
              A100: "#f5f5f5",
              A200: "#eeeeee",
              A400: "#bdbdbd",
              A700: "#616161",
            },
            info: {
              contrastText: "rgba(0, 0, 0, 0.87)",
              dark: "#0288d1",
              light: "#4fc3f7",
              main: "#29b6f6",
            },
            primary: {
              contrastText: "#FFFFFF",
              dark: "#000000",
              light: "rgb(95, 95, 95)",
              main: "#000000",
            },
            secondary: {
              contrastText: "#000000",
              dark: "#777777",
              light: "#303030",
              main: "#C6C6C6",
            },
            success: {
              contrastText: "rgba(0, 0, 0, 0.87)",
              dark: "#388e3c",
              light: "#81c784",
              main: "#66bb6a",
            },
            text: {
              disabled: "rgba(255, 255, 255, 0.5)",
              primary: "#fff",
              secondary: "rgba(255, 255, 255, 0.7)",
            },
            tonalOffset: 0.2,
            warning: {
              contrastText: "rgba(0, 0, 0, 0.87)",
              dark: "#f57c00",
              light: "#ffb74d",
              main: "#ffa726",
            },
          }
        : /* LIGHT MODE */
          {
            panel: {
              main: "#fafafa",
            },
            action: {
              activatedOpacity: 0.12,
              active: "rgba(0, 0, 0, 0.54)",
              disabled: "rgba(0, 0, 0, 0.26)",
              disabledBackground: "rgba(0, 0, 0, 0.12)",
              disabledOpacity: 0.38,
              focus: "rgba(0, 0, 0, 1)",
              focusOpacity: 1,
              hover: "rgba(0, 0, 0, 0.04)",
              hoverOpacity: 0.04,
              selected: "rgba(0, 0, 0, 0.15)",
              selectedOpacity: 0,
            },
            background: {
              default: "#FFFFFF",
              paper: "#FFFFFF",
            },
            common: {
              black: "#000000",
              white: "#FFFFFF",
            },
            contrastThreshold: 3,
            divider: "rgba(0, 0, 0, 0.12)",
            error: {
              contrastText: "#FFFFFF",
              dark: "#c62828",
              light: "#ef5350",
              main: "#d32f2f",
            },
            grey: {
              "50": "#fafafa",
              "100": "#f5f5f5",
              "200": "#eeeeee",
              "300": "#e0e0e0",
              "400": "#bdbdbd",
              "500": "#9e9e9e",
              "600": "#757575",
              "700": "#616161",
              "800": "#424242",
              "900": "#212121",
              A100: "#f5f5f5",
              A200: "#eeeeee",
              A400: "#bdbdbd",
              A700: "#616161",
            },
            info: {
              contrastText: "#FFFFFF",
              dark: "#01579b",
              light: "#03a9f4",
              main: "#0288d1",
            },
            primary: {
              contrastText: "#000000",
              dark: "#FFFFFF",
              light: "#C6C6C6",
              main: "#FFFFFF",
            },
            secondary: {
              contrastText: "#FFFFFF",
              dark: "#777777",
              light: "#C6C6C6",
              main: "#303030",
            },
            success: {
              contrastText: "#fff",
              dark: "#1b5e20",
              light: "#4caf50",
              main: "#2e7d32",
            },
            text: {
              disabled: "rgba(0, 0, 0, 0.38)",
              primary: "rgba(0, 0, 0, 0.87)",
              secondary: "rgba(0, 0, 0, 0.6)",
            },
            tonalOffset: 0.2,
            warning: {
              contrastText: "#FFFFFF",
              dark: "#e65100",
              light: "#ff9800",
              main: "#ed6c02",
            },
          }),
    },
  }).palette;

export default highContrastPalette;
