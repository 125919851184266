import React, { ReactElement, useEffect, useState } from "react";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGlobalState } from "state";
import { PermissionStatusType } from "types/types";
import { Thtml } from "comps/common/Thtml";
import { xfetch, toJSONPostRequest } from "utils/xfetch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/material/styles";
import { RadioButtonUnchecked } from "@mui/icons-material";

interface PermissionSettingsProps {
  continue: string;
}

const PermissionSettings = ({ ...props }: PermissionSettingsProps) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [globalPermissions] = useGlobalState("permissionsStatus");
  const [permissions, setPermissions] = useState(globalPermissions);

  const requestPermission = async (permission: string) => {
    var p = {
      Permission: permission,
    };

    const payload = await toJSONPostRequest(p);
    await xfetch("/requestpermission", payload);
  };

  const getPermStatus = (perm: string): PermissionStatusType => {
    return perm === "audio"
      ? permissions.audioPermission
      : perm === "bluetooth"
      ? permissions.bluetoothPermission
      : perm === "contacts"
      ? permissions.contactsPermission
      : "Default";
  };

  const permLabel = (perm: string): string => {
    let status: PermissionStatusType = getPermStatus(perm);

    if (status === "Default") {
      return t("permissions.enable") + " " + t("permissions." + perm);
    } else if (status === "Granted") {
      return t("permissions." + perm) + " " + t("permissions.granted");
    } else if (status === "Denied") {
      return t("permissions." + perm) + " " + t("permissions.denied");
    } else if (status === "DeniedAndDontAskAgain") {
      return t("permissions." + perm) + " " + t("permissions.deniedagain");
    } else {
      return t("permissions." + perm) + " " + t("permissions.error");
    }
  };

  const getStatusIcon = (perm: string): ReactElement<any, any> => {
    let status: PermissionStatusType = getPermStatus(perm);

    if (status === "Granted") {
      return <CheckCircleIcon color="success" />;
    } else if (status === "Default") {
      return <RadioButtonUnchecked />;
    } else if (status === "Denied") {
      return <ErrorIcon color="error" />;
    }
  };

  useEffect(() => {
    setPermissions(globalPermissions);
  }, [globalPermissions, setPermissions]);

  useEffect(() => {
    xfetch("/getpermissions");
  }, []);

  return (
    <>
      <Typography variant="h1" component="h1" gutterBottom>
        {t("permissions.title")}
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden scroll",
          paddingRight: theme.spacing(2),
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{ my: theme.spacing(2) }}
          >
            <Thtml tag="permissions.summary" />
          </Typography>

          <Box
            sx={{ my: 2, display: "flex", flexDirection: "column", flex: 1 }}
          >
            <Stack spacing={2}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor:
                    permissions.audioPermission === "Granted"
                      ? "transparent"
                      : theme.palette.error.main,
                  color:
                    permissions.audioPermission === "Granted"
                      ? ""
                      : theme.palette.error.contrastText,
                }}
                onClick={async () => {
                  if (permissions.audioPermission !== "Granted") {
                    await requestPermission("RECORD_AUDIO");
                  }
                }}
                startIcon={getStatusIcon("audio")}
              >
                {permLabel("audio")}
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor:
                    permissions.bluetoothPermission === "Granted"
                      ? "transparent"
                      : theme.palette.error.main,
                  color:
                    permissions.audioPermission === "Granted"
                      ? ""
                      : theme.palette.error.contrastText,
                }}
                onClick={async () => {
                  if (permissions.bluetoothPermission !== "Granted") {
                    await requestPermission("BLUETOOTH_CONNECT");
                  }
                }}
                startIcon={getStatusIcon("bluetooth")}
              >
                {permLabel("bluetooth")}
              </Button>

              {/* not currently used
              <XRAIButton
                variant="outlined"
                color="purple"
                sx={{color:"#6E00FF"}}
                disabled={permissions.contactsPermission!=="Default"}
                onClick={async () => {await requestPermission("READ_CONTACTS")}}
              >
                {permLabel("contacts")}
              </XRAIButton>
            */}

              {(false ||
                permissions.audioPermission === "Denied" ||
                permissions.bluetoothPermission === "Denied" ||
                //|| permissions.contactsPermission==="Denied"
                permissions.audioPermission === "DeniedAndDontAskAgain" ||
                permissions.bluetoothPermission ===
                  "DeniedAndDontAskAgain") && (
                //|| permissions.contactsPermission==="DeniedAndDontAskAgain"
                <Alert color="error" variant="filled">
                  <Thtml tag="permissions.instructions" />
                </Alert>
              )}
            </Stack>
          </Box>
        </Box>

        <Stack sx={{ my: 2 }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={
              !(
                (
                  true &&
                  permissions.audioPermission === "Granted" &&
                  permissions.bluetoothPermission === "Granted"
                )
                //&& permissions.contactsPermission==="Granted"
              )
            }
            onClick={() => navigate(props.continue)}
          >
            {t("continue")}
          </Button>
        </Stack>
      </div>
    </>
  );
};

export default PermissionSettings;
