import { AuthTokens } from "./AuthTypes";

export interface AuthStatus {
  tokens: AuthTokens | null;
  status: string ;
  reason: string ;
}

let status:AuthStatus | undefined = undefined;
let listeners:Array<any> = [];

export const authStore = {
  set(status_:AuthStatus) {
    let sa = JSON.stringify(status);
    let sb = JSON.stringify(status_);
    if ( sa === sb ) return;
    status = status_;
    emitChange();
  },
  get() {
    return status;
  },
  subscribe(listener:any) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  },
  clear() {
    doClear();
  }
}

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}

function doClear() {
    authStore.set({tokens:null,status:"",reason:""});
}
