import { Buffer } from 'buffer';

export default class JwtToken
{
  jwtToken : string ;
  payload  : any    ;

  constructor(token:string) {
    this.jwtToken = token || '';
    this.payload = this.decode();
  }

  decode() {
    const payload = this.jwtToken.split('.')[1];
    try {
        return JSON.parse(Buffer.from(payload,'base64').toString('utf8'));
    } catch {
        return {};
    }
  }
}
