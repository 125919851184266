import React from "react";
import { useTranslation } from "react-i18next";
import { Stack, Button, CircularProgress } from "@mui/material";

import { MeshDeviceInfo } from "./PairTypes";

import { useStoreLocal } from "state/store";
import { getGlobalState } from "state";

import FormattedTextField from "./FormattedTextField";

type Props = {
  wifi?: boolean;
  pair?: boolean;
  wifiName?: string;
  wifiPass?: string;
  pairCode?: string;
  setWifiName?: (value: string) => void;
  setWifiPass?: (value: string) => void;
  setPairCode?: (value: string) => void;
  connect: (info: MeshDeviceInfo) => void;
  forget?: (info: MeshDeviceInfo) => void;
  loading: boolean;
  setLoading?: (value: boolean) => void;
  state?: "active" | "offline" | "online";
  info: MeshDeviceInfo;
  onCancel?: () => void;
};

const ConnectInfo: React.FC<Props> = ({
  wifi = false,
  pair = false,
  wifiName,
  wifiPass,
  pairCode,
  setWifiName,
  setWifiPass,
  setPairCode,
  connect,
  forget,
  loading,
  setLoading,
  state,
  info,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [fontSize] = useStoreLocal<number>("fontSize");
  const rootFontSize = getGlobalState("rootFontSize");

  const isOffline = state === "offline";
  const connectDisabled = isOffline && !wifiName && !wifiPass;

  const formatPair = (str: string): string => {
    const cleanedString = str.replace(/[^a-z0-9]/gi, "").toUpperCase();
    return cleanedString.substring(0, 6);
  };

  const canConnect = () => {
    const pairGood = !pair || pairCode.length === 6;
    const wifiGood =
      !wifi || wifiName || state === "online" || state === "active";

    return !loading && pairGood && wifiGood;
  };

  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      {wifi && (
        <>
          <FormattedTextField
            required={isOffline}
            placeholder="pairing.wifinameplace"
            label="pairing.wifinamelabel"
            value={wifiName}
            setValue={setWifiName}
          />

          <FormattedTextField
            required={false}
            placeholder="pairing.wifipassplace"
            label="pairing.wifipasslabel"
            value={wifiPass}
            setValue={setWifiPass}
          />
        </>
      )}

      {pair && (
        <FormattedTextField
          required
          placeholder="pairing.paircodeplace"
          label="pairing.paircodelabel"
          value={pairCode}
          setValue={setPairCode}
          format={formatPair}
        />
      )}

      <Stack direction="row" sx={{ marginTop: "1ex", width: "100%" }}>
        <div style={{ flex: "1 0 0" }} />

        <Button
          variant="contained"
          sx={{ margin: "0 0.5ex", "&:disabled": { opacity: 0.6 } }}
          onClick={() => {
            if (!loading && onCancel) {
              onCancel();
            }

            if (loading) {
              // cancel: If loading, just cancel the loading
              setLoading(false);
            }
          }}
        >
          {loading ? t("Cancel") : t("Close")}
        </Button>

        {forget && (
          <Button
            variant="contained"
            disabled={false}
            sx={{ margin: "0 0.5ex", "&:disabled": { opacity: 0.6 } }}
            onClick={() => {
              if (loading) {
                // cancel: If loading, just cancel the loading
                setLoading(false);
              }
              forget(info);
            }}
          >
            {t("Forget")}
          </Button>
        )}

        <Button
          variant="contained"
          onClick={() => connect(info)}
          disabled={!canConnect() || connectDisabled}
          sx={{ margin: "0 0.5ex", "&:disabled": { opacity: 0.6 } }}
        >
          {loading ? (
            <CircularProgress size={`${rootFontSize * fontSize}px`} />
          ) : (
            t("Connect")
          )}
        </Button>

        <div style={{ flex: "1 0 0" }} />
      </Stack>
    </Stack>
  );
};

export default ConnectInfo;
