import { useEffect } from "react";
import { getGlobalState, setGlobalState } from "state";
import { useStoreLocal, useStore } from "state/store";
import { getState } from "utils/settings";

const useThemeSetter = () => {
  const [appThemeMode] = useStoreLocal<string>("appThemeMode");
  const [sysThemeMode, setSysThemeMode] = useStore<string>("SystemTheme");
  const [, setDarkMode] = useStore<boolean>("darkMode");

  useEffect(() => {
    if (appThemeMode === "system") {
      if (sysThemeMode === "dark") {
        setDarkMode(true);
      } else {
        setDarkMode(false);
      }
    } else if (appThemeMode === "dark") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, [appThemeMode, sysThemeMode, setDarkMode]);

  useEffect(() => {
    getState("SystemTheme").then((val) => {
      setSysThemeMode(val);
    });
  }, [setSysThemeMode]);

  const getRootFontSize = () => getGlobalState("rootFontSize");
  const setRootFontSize = (size: number) =>
    setGlobalState("rootFontSize", size);

  return { getRootFontSize, setRootFontSize };
};

export default useThemeSetter;
