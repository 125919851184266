import React from "react";
import { ColorShadeKey } from "./Types";
import { Theme, useTheme } from "@mui/material";
import { glowingDark, glowingLight } from "./Palettes";

export const hsvToRgb = (
  h: number,
  s: number,
  v: number
): [number, number, number] => {
  let r, g, b;
  let i = Math.floor(h * 6);
  let f = h * 6 - i;
  let p = v * (1 - s);
  let q = v * (1 - f * s);
  let t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;

      break;
    case 1:
      r = q;
      g = v;
      b = p;

      break;
    case 2:
      r = p;
      g = v;
      b = t;

      break;
    case 3:
      r = p;
      g = q;
      b = v;

      break;
    case 4:
      r = t;
      g = p;
      b = v;

      break;
    case 5:
      r = v;
      g = p;
      b = q;

      break;
    default:
      break;
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

export const rgbToHex = (r: number, g: number, b: number): string => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const hexToRgb = (hex: string): [number, number, number] | null => {
  var hex6 = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (hex6) {
    return [
      parseInt(hex6[1], 16),
      parseInt(hex6[2], 16),
      parseInt(hex6[3], 16),
    ];
  }

  var hex3 = /^#?([a-f\d])([a-f\d])([a-f\d])$/i.exec(hex);
  if (hex3) {
    const r = hex3[1] + hex3[1];
    const g = hex3[2] + hex3[2];
    const b = hex3[3] + hex3[3];
    return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
  }

  return null;
};

export const rgbToHsl = (
  r: number,
  g: number,
  b: number
): [number, number, number] | null => {
  r = r / 255;
  g = g / 255;
  b = b / 255;
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }
  return [h, s, l];
};

export const hslToRgb = (
  h: number,
  s: number,
  l: number
): [number, number, number] | null => {
  var r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    var hue2rgb = function hue2rgb(p: number, q: number, t: number) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

export const xraiIndexToColor = (i: number): string => {
  if (typeof i !== "number" || i < 0) {
    return "unset";
  } else if (i === 0) {
    return `rgb(${hsvToRgb(0, 0, 0.8)})`;
  } else {
    return `rgb(${hsvToRgb(i / 360, 0.8, 0.8)})`;
  }
};

export const generateTriadicColor = (primaryColor: string): string => {
  const primaryColorRGB = hexToRgb(primaryColor) || [128, 128, 128];
  const primaryColorHSL = rgbToHsl(...primaryColorRGB);
  primaryColorHSL[0] = (primaryColorHSL[0] + 1 / 3) % 1;
  const secondaryColorRGB = hslToRgb(...primaryColorHSL);

  return rgbToHex(...secondaryColorRGB);
};

export const getShadeByDarkMode = (darkMode: boolean): ColorShadeKey => {
  return darkMode ? 800 : 300;
};

export const useGlowingEffect = (showGlow: boolean): string => {
  const [glowAnimation, setGlowAnimation] = React.useState<string>("");

  const theme: Theme = useTheme();
  const isDarkMode = theme?.palette?.mode === "dark";

  React.useEffect(() => {
    const glowCss: string = showGlow
      ? `${isDarkMode ? glowingDark : glowingLight}`
      : "";
    setGlowAnimation(glowCss);
  }, [showGlow, isDarkMode]);

  return glowAnimation;
};

// Function to get the contrast color based on a given color (black or white only)
export const getContrastColor = (color: string): string => {
  const rgb = hexToRgb(color);
  if (!rgb) {
    return "#000000";
  }

  const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return brightness >= 128 ? "#000000" : "#FFFFFF";
};

// Function to generate a high-contrast color in the same theme
export const generateThemeContrastColor = (
  primaryColor: string,
  mode: 'light' | 'dark'
): string => {
  const primaryColorRGB = hexToRgb(primaryColor) || [128, 128, 128];
  const primaryColorHSL = rgbToHsl(...primaryColorRGB);

  // Adjust the lightness for high contrast
  if (mode === "light") {
    // Light mode: move towards almost white
    primaryColorHSL[2] =
      primaryColorHSL[2] < 0.7
        ? primaryColorHSL[2] + (1 - primaryColorHSL[2]) * 0.7
        : 0.95; // Ensure it doesn't go too white
  } else if (mode === "dark") {
    // Dark mode: move towards almost black
    primaryColorHSL[2] =
      primaryColorHSL[2] > 0.3 ? primaryColorHSL[2] * 0.3 : 0.05; // Ensure it doesn't go too black
  }

  const contrastColorRGB = hslToRgb(...primaryColorHSL);

  return rgbToHex(...contrastColorRGB);
};

// Function to generate a high-contrast color in the same theme but darker by 20%
export const generateThemeContrastColorDarker = (
  primaryColor: string,
  mode: 'light' | 'dark'
): string => {
  const primaryColorRGB = hexToRgb(primaryColor) || [128, 128, 128];
  const primaryColorHSL = rgbToHsl(...primaryColorRGB);

  // Adjust the lightness for high contrast and darken by 20%
  if (mode === "light") {
    // Light mode: move towards almost white and darken by 20%
    primaryColorHSL[2] =
      primaryColorHSL[2] < 0.7
        ? (primaryColorHSL[2] + (1 - primaryColorHSL[2]) * 0.7) * 0.8 // Darken by 20%
        : 0.95 * 0.8; // Ensure it doesn't go too white and darken by 20%
  } else if (mode === "dark") {
    // Dark mode: move towards almost black and darken by 20%
    primaryColorHSL[2] =
      primaryColorHSL[2] > 0.3 
        ? (primaryColorHSL[2] * 0.3) * 0.8 // Darken by 20%
        : 0.05 * 0.8; // Ensure it doesn't go too black and darken by 20%
  }

  const contrastColorRGB = hslToRgb(...primaryColorHSL);

  return rgbToHex(...contrastColorRGB);
};

export const generateButtonTheme = (
  primaryColor: string,
  mode: 'light' | 'dark'
): string => {
  const primaryColorRGB = hexToRgb(primaryColor) || [128, 128, 128];
  const primaryColorHSL = rgbToHsl(...primaryColorRGB);

  // Adjust the lightness for high contrast and darken by 10%
  if (mode === "light") {
    // Light mode: move towards almost white and darken by 10%
    primaryColorHSL[2] =
      primaryColorHSL[2] < 0.7
        ? (primaryColorHSL[2] + (1 - primaryColorHSL[2]) * 0.7) * 0.9 // darken by 10%
        : 0.95 * 0.9; // Ensure it doesn't go too white and darken by 10%
  } else if (mode === "dark") {
    // Dark mode: move towards almost black and darken by 10%
    primaryColorHSL[2] =
      primaryColorHSL[2] > 0.3 
        ? (primaryColorHSL[2] * 0.3) * 0.9 // darken by 10%
        : 0.05 * 0.9; // Ensure it doesn't go too black and darken by 10%
  }

  const contrastColorRGB = hslToRgb(...primaryColorHSL);

  return rgbToHex(...contrastColorRGB);
}

export function luminance(r: number, g: number, b: number) {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function contrastRatio(rgb1: [number, number, number], rgb2: [number, number, number]) {
  const lum1 = luminance(...rgb1) + 0.05;
  const lum2 = luminance(...rgb2) + 0.05;
  return lum1 > lum2 ? lum1 / lum2 : lum2 / lum1;
}

// Function to get the contrast color based on the background color
export const getContrastColorFromBase = (baseHex: string, targetHex: string): string => {
  const baseRgb = hexToRgb(baseHex);
  const targetRgb = hexToRgb(targetHex);

  if (!baseRgb || !targetRgb) {
    return targetHex;
  }

  const ratio = contrastRatio(baseRgb, targetRgb);

  // If contrast ratio is sufficient, return target color as is
  if (ratio >= 4.5) {
    return targetHex;
  }

  // Otherwise, adjust lightness for contrast
  const baseHsl = rgbToHsl(...baseRgb);
  const targetHsl = rgbToHsl(...targetRgb);

  let contrastLightness = targetHsl[2];

  if (Math.abs(baseHsl[2] - targetHsl[2]) < 0.5) {
    contrastLightness = baseHsl[2] > 0.5 ? 0.2 : 0.8;
  }

  const contrastRgb = hslToRgb(targetHsl[0], targetHsl[1], contrastLightness);

  return rgbToHex(...contrastRgb);
}

export const hexToRgba = (hex: string, opacity: number): string => {
  const rgb = hexToRgb(hex);
  return rgb ? `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})` : null;
}

export const darkenColor = (color: string, amount: number): string => {
  const rgb = hexToRgb(color);
  if (!rgb) {
    return color;
  }

  const hsl = rgbToHsl(...rgb);
  hsl[2] = Math.max(0, hsl[2] - amount);

  const newRgb = hslToRgb(...hsl);
  return rgbToHex(...newRgb);
}
