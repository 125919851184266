import { useEffect, useState } from "react";

type Orientation = "landscape" | "portrait";

const getOrientation = (): Orientation => {
  return window.screen.width < window.screen.height
    ? "portrait"
    : "landscape";
};

const useOrientation = (): Orientation => {
  const [orientation, setOrientation] = useState<Orientation>(
    getOrientation(),
  );

  useEffect(() => {
    const onOrientationChange = () => {
      setOrientation(getOrientation());
    };
    window.addEventListener("orientationchange", onOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", onOrientationChange);
    };
  }, []);

  return orientation;
};

export default useOrientation;
