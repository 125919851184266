import React, { useContext } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
} from "@mui/material";
import { FavoriteLanguagePayload } from "./favoriteLanguage-utils";
import MicrosoftLogo from "comps/icons/MicrosoftLogo";
import VoskLogo from "comps/icons/VoskLogo";
import AmazonLogo from "comps/icons/AmazonLogo";
import DeepgramLogo from "comps/icons/DeepgramLogo";
import NoTransLogo from '@mui/icons-material/ContentCopy';
import AutoRevLogo from '@mui/icons-material/SwapHoriz';
import { ThemeContext } from "@emotion/react";
import RoundCheckbox from "comps/RoundCheckbox";
import { LangSettingType } from "comps/language/langaugeUtils";
import { useTranslation} from "react-i18next";

interface FavoriteLangProps {
  lang: FavoriteLanguagePayload;
  type: LangSettingType;
  onToggle: ()=>void
  forceToAzure?: boolean;
}
export default function FavoriteLanguageItem(props: FavoriteLangProps) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext) as Theme;
  const darkColor = theme.palette.mode === "dark" ? "white" : "black";

  return (
    <ListItem disablePadding divider disabled={props.lang.disabled}>
      <ListItemButton onClick={()=>{
        if(!props.lang.disabled) props.onToggle();
      }}>
        {props.lang.vendor && <ListItemIcon style={{ minWidth: "36px" }}>
          {props.forceToAzure ? (
            <MicrosoftLogo size={18} />
          ) : (
            <div style={{display:"flex"}}>
              {props.lang.key    === "xrai._xa-SP" &&  <NoTransLogo />}
              {props.lang.key    === "xrai._xb-RV" &&  <AutoRevLogo />}
              {props.lang.vendor === "azure"       &&  <MicrosoftLogo size={18} />}
              {props.lang.vendor === "vosk"        &&  <VoskLogo      size={20} />}
              {props.lang.vendor === "deepgram"    &&  <DeepgramLogo  size={20} />}
              {props.lang.vendor === "aws"         && (<AmazonLogo size={20} color={darkColor} />)}
            </div>
          )}
        </ListItemIcon>}
        <ListItemText>
          <div style={{ lineHeight: "120%" }}>{t(`settings.${props.type}.${props.lang.langcode }`)}</div>
          {props.lang.multiSupport === false && (
            <div style={{ fontSize: "0.75rem", opacity: 0.6 }}>
              No Multi-language Support
            </div>
          )}
        </ListItemText>
        <RoundCheckbox checked={props.lang.selected} />
        {/* <Checkbox
          checked={props.lang.selected}
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            props.lang.selected = !props.lang.selected;
          }}
        /> */}
      </ListItemButton>
    </ListItem>
  );
}
