import React from "react";
import ReactDOM from "react-dom/client";

import "./i18n-config";
import "./index.css";
import App from "./App";
import { getEncryptionKey } from "utils/encryption";

const ROUTES = [
  "/",
  "/login",
  "/welcome",
  "/language",
  "/region",
  "/permissions",
  "/verifyperms",
  "/overview",
  "/home",
  "/vitals",
  "/settings",
  "/subtitles",
  "/conversations",
  "/profile",
  "/terms",
  "/installers",
  "/privacy",
  "/subscriptions",
];

(() => {
  globalThis.xhrefAppLoad = window.location.href;

  var params = new URLSearchParams(window.location.search);

  if (params.has("xhostLocal")) {
    globalThis.xhostLocal = params.get("xhostLocal");
  } else {
    globalThis.xhostLocal = null;
  }

  if (params.has("xhostOther")) {
    globalThis.xhostOther = params.get("xhostOther");
  } else {
    globalThis.xhostOther = null;
  }

  globalThis.isXraiGo = true;
  globalThis.xraiGoKey = "xraigo-dca9a179bd74";
      
  if (!ROUTES.find((r) => r === window.location.pathname)) {
    globalThis.isXraiGo = true;
    globalThis.xraiGoKey = window.location.pathname.split("/")[1];
    globalThis.isRedBull = xraiGoKey.startsWith("redbull");
  }

  if (params.has("xraigo")) {
    const xraigo = params.get("xraigo");
    if ( xraigo && xraigo !== "false" ) {
        globalThis.isXraiGo = true;
        if ( xraigo !== "true" ) {
            globalThis.xraiGoKey = xraigo;
        }
    }
  }

  globalThis.appRunNonce = Math.floor(Math.random() * 100000000);
  globalThis.lastSeqNum = -1;
})();

// TODO: this feels wrong
getEncryptionKey().then(() => {
  const rootElement = document.getElementById("root");
  if (!rootElement) throw new Error("Failed to find the root element");
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});
