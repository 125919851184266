import React, { ReactElement } from "react";
import { Typography } from "@mui/material"
import { Stack } from "@mui/system";

interface XraiFieldProps {
  children?: any,
  label: string,
  style?: any,
  labelRight?: ReactElement<any,any>,
  description?: any,
  helper?: string,
  crapHack?: string,
}
export const XraiFieldWrapper = ({...props}:XraiFieldProps) => {
  return (
    <div 
    style={{ 
      ...(props.style || {}),

    }}>
      <Stack 
      alignItems="center" 
      justifyContent="space-between" 
      direction="row">
        <Typography
        variant="body2"
          sx={{  

            fontWeight: 800
           }}
          component="div"
        >
          {props.label}
        </Typography>
        {props.labelRight}
      </Stack>
      {!!props.description && (
        <Typography
        variant="body2"
          sx={{
            margin: "0.4rem 0",
            lineHeight: "130%",
            opacity: 0.6,
          }}
          component="div"
        >
          {props.description}
        </Typography>
      )}

      <div className="field-wrap" style={{ 
        marginTop: props.crapHack?props.crapHack:"1rem",
      }}>
        {props.children}
      </div>
      {!!props.helper && (
        <Typography component="div">{props.helper}</Typography>
      )}
    </div>
  );
}
