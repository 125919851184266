import React, { useState } from "react";
import SubscriptionDialog from "./SubscriptionDialog";

export default function useSubscriptionDialog() {
  const [isOpen, setIsOpen] = useState(false);
  return {
    Drawer: <SubscriptionDialog open={isOpen} onClose={()=>{
      setIsOpen(false);
    }} />,
    open() {
      setIsOpen(true)
    },
    close() {
      setIsOpen(false);
    }
  }
}