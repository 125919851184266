import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultLayout from "comps/layout/DefaultLayout";
import {
  Chip,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Stack,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import {
  Assignment,
  Badge,
  Brush,
  ChevronRight,
  DirectionsRun,
  Email,
  GppGood,
  GraphicEq,
  Hub,
  InterpreterMode,
  Map,
  Mic,
  MusicVideo,
  NetworkCheck,
  PhoneAndroid,
  PriorityHigh,
  SmartToy,
  Speaker,
  ThumbUpSharp,
} from "@mui/icons-material";
import { ThemeContext } from "@emotion/react";
import { getConfiguration } from "comps/settings/getConfigurationSetting";
import { getState } from "utils/settings";
import queryChatGPT from "comps/gpt/queryGPT";
import AppUserContext from "comps/context/AppUserContext";
import { useDialog } from "hooks/useDialog";
import { xfetch } from "utils/xfetch";
import sendEmail from "utils/sendEmail";
import Entitlements from "comps/entitlements/entitlements";
import { appReload } from "utils/xfetch";

async function checkInternetConnection(): Promise<boolean> {
  try {
    await fetch("https://www.google.com", { mode: "no-cors" });
    return true;
  } catch (error) {
    return false;
  }
}

interface StatusChipProps {
  hasPassed?: boolean;
  label?: string;
}
export function StatusChip(props: StatusChipProps) {
  const WideChip = styled(Chip)({
    paddingTop: "5px",
  });
  if (props.hasPassed) {
    return (
      <WideChip
        variant="filled"
        color="success"
        label={<ThumbUpSharp style={{ fontSize: "18px" }} />}
      />
    );
  } else if (props.hasPassed === false) {
    return (
      <WideChip
        variant="filled"
        color="error"
        label={<PriorityHigh style={{ fontSize: "18px" }} />}
      />
    );
  } else if (props.label) {
    return <WideChip variant="filled" color="primary" label={props.label} />;
  }
}

const MonoFont = styled(Typography)({
  fontFamily:"Roboto Mono, monospace"
})

export default function AppVitalsScreen() {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext) as Theme;
  const [unityConnected, setUnityConnected] = useState(false);
  const [networkConnected, setNetworkConnected] = useState(false);
  const [openApiKey, setOpenApiKey] = useState<undefined | string>(undefined);
  const [deviceId, setDeviceId] = useState<undefined | string>(undefined);
  const [sourceLanguage, setSourceLanguage] = useState<undefined | string>(undefined);
  const [logLevel, setLogLevel] = useState<undefined | string>(undefined);
  const [noiseCancel, setNoiseCancel] = useState<boolean>(false);
  const [appRunId, setAppRunId] = useState<undefined | string>(undefined);
  const [sessionId, setSessionId] = useState<undefined | string>(undefined);
  const [gptError, setGPTError] = useState<undefined | string>(undefined);
  const [region, setRegion] = useState<undefined | string>(undefined);
  const [audioDevice, setAudioDevice] = useState<undefined | string>(undefined);
  const [audioRecordingEnabled, setAudioRecordingEnabled] = useState<boolean>(
    false
  );
  const AppUser = useContext(AppUserContext);
  const Dialog = useDialog();

  const entitlements = new Entitlements(AppUser.appUser?.Entitlements || []);

  const emailReport = ()=>{
    let body = {
      created: new Date().toJSON(),
      deviceId,
      sessionId,
      appRunId,
      unityConnected,
      networkConnected,
      region,
      audioDevice,
      audioRecordingEnabled,
      logLevel,
      sourceLanguage,
      gptError,
      onBoarded: AppUser.appUser?.Setup?.onboarded,
      hasPermissions: AppUser.appUser?.Setup?.hasPermissions
    }
    sendEmail("support@xrai.glass", `Vital Report: ${deviceId}`, JSON.stringify(body, null, 2))
  }

  const doReset = async (mode: "hard" | "soft") => {
    await xfetch(`/reset${mode}`);
    appReload();
  };

  const confirmReset = (mode: "hard" | "soft") => {
    Dialog.showDialog({
      title: `${t("vitals.resetAppSettings")} - ${t(mode)}`,
      message: t("vitals.resetAppSettingsMessage"),
      buttons: [
        {
          label: t("Cancel"),
        },
        {
          label: t("Continue"),
          action() {
            doReset(mode);
          },
        },
      ],
    });
  };
  
  const formatEntitlementKey = (str: string):string => {
    return str.replace(/(_|-)/g, " ");
  }

  useEffect(() => {
    document.body.scrollTo(0,0);
    getConfiguration()
      .then((res) => {
        const gptKey = res.get("OpenAiGptKey")?.Value;
        const audioDevice = res.get("SelectedAudioSource")?.Value || res.get("SelectedAudioDevice")?.Value;
        setOpenApiKey(gptKey);
        setUnityConnected(true);
        setAudioDevice(audioDevice);
        setAudioRecordingEnabled(res.get("EnableRecordAudio")?.Value === "True");
        setNoiseCancel(res.get("EnableNoiseCancel")?.Value === "True");
        setRegion(res.get("AwsTranscribeRegion")?.Value);
        setLogLevel(res.get("LogLevel")?.Value);
        setSourceLanguage(res.get("SourceLanguage")?.Value);
        if (gptKey) {
          queryChatGPT({
            prompt: "What time is it?",
            command: "ask"
          })
            .then((results) => {
              setGPTError(undefined);
            })
            .catch((e) => {
              setGPTError(e.message);
            });
        }
      })
      .catch((e) => {
        setUnityConnected(false);
      });

    checkInternetConnection().then((connected) => {
      setNetworkConnected(connected);
    });
    getState("DeviceId").then((val) => {
      setDeviceId(`${val || "N/A"}`.substring(0, 8));
    });
    getState("AppRunId").then((val) => {
      setAppRunId(`${val || "N/A"}`.substring(0, 8));
    });
    getState("SessionId").then((val) => {
      setSessionId(`${val || "N/A"}`.substring(0, 8));
    });
  }, []);

  return (
    <DefaultLayout
      backLabel={t("back")}
      pageTitle="Vitals"
      backNavigate={-1}
      menuItems={<></>}
      headerComponent={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ px: "20px", pt: "8px", pb: "16px" }}
        >
          <Typography variant="h1" component="h1">
            {t("Vitals")}
          </Typography>
        </Stack>
      }
    >
      <Stack direction="column">
        <List>
          {/* Backend Connected */}
          <ListItem>
            <ListItemIcon>
              <Hub />
            </ListItemIcon>
            <ListItemText>{t("vitals.backendConnected")}</ListItemText>
            <StatusChip hasPassed={unityConnected} />
          </ListItem>
          {/* Network Connected */}
          <ListItem>
            <ListItemIcon>
              <NetworkCheck />
            </ListItemIcon>
            <ListItemText>{t("vitals.internetAvailable")}</ListItemText>
            <StatusChip hasPassed={networkConnected} />
          </ListItem>
          {/* Chat GPT */}
          <ListItem>
            <ListItemIcon>
              <SmartToy />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("Chat GPT")}</Typography>
              {gptError && <Typography variant="body2">{gptError}</Typography>}
            </ListItemText>
            {!!openApiKey && (
              <div>
                {!gptError && <StatusChip label="Connected" hasPassed={true} />}
                {gptError && <StatusChip label="Error" hasPassed={false} />}
              </div>
            )}
            {!openApiKey && <Typography>{t("Not Configured")}</Typography>}
          </ListItem>
          <Divider />
          
          <ListSubheader>{t("vitals.deviceDetails")}</ListSubheader>
          {/* Device ID */}
          <ListItem>
            <ListItemIcon>
              <PhoneAndroid />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("vitals.deviceId")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <MonoFont>{deviceId || "N/A"}</MonoFont>
            </ListItemSecondaryAction>
          </ListItem>
          {/* Session Id */}
          <ListItem>
            <ListItemIcon>
              <Speaker />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("vitals.sessionId")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <MonoFont>{sessionId || "N/A"}</MonoFont>
            </ListItemSecondaryAction>
          </ListItem>
         
           {/* App Run Id */}
           <ListItem>
            <ListItemIcon>
              <DirectionsRun />
            </ListItemIcon>
            <ListItemText>
                <Typography>{t("vitals.appRunId","App Run Id")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <MonoFont>{appRunId}</MonoFont>
            </ListItemSecondaryAction>
          </ListItem>
          
           {/* Audio Device */}
           <ListItem>
            <ListItemIcon>
              <Mic />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("vitals.audioDevice")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              {audioDevice || "N/A"}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InterpreterMode />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("settings.SourceLanguage.title")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              {sourceLanguage || "N/A"}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("settings.LogLevel.title")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              {logLevel || "N/A"}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
  
          <ListSubheader>{t("Setup")}</ListSubheader>
          {/* Audio Recording Enabled */}
          <ListItem>
            <ListItemIcon>
              <GraphicEq />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("vitals.audioRecordingEnabled")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              {audioRecordingEnabled ? t("Yes") : t("No")}
            </ListItemSecondaryAction>
          </ListItem>
          {/* Transcription Region */}
          <ListItem>
            <ListItemIcon>
              <Map />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("vitals.transcriptionRegion")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>{region}</ListItemSecondaryAction>
          </ListItem>
          {/* Has Onboarded */}
          <ListItem>
            <ListItemIcon>
              <Badge />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("vitals.onboarded")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              {AppUser.appUser?.Setup?.onboarded ? t("Yes") : t("No")}
            </ListItemSecondaryAction>
          </ListItem>
          {/* Has Permissions */}
          <ListItem>
            <ListItemIcon>
              <GppGood />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("vitals.hasPermissions")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              {AppUser.appUser?.Setup?.hasPermissions ? t("Yes") : t("No")}
            </ListItemSecondaryAction>
          </ListItem>
          {/* Noise Cancellation */}
          <ListItem>
            <ListItemIcon>
              <MusicVideo />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("settings.EnableNoiseCancel.title")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              {noiseCancel ? t('Yes') : t("No")}
            </ListItemSecondaryAction>
          </ListItem>
          {/* System Theme */}
          <ListItem>
            <ListItemIcon>
              <Brush />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t("Theme")}</Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              {theme.palette.mode}
            </ListItemSecondaryAction>
          </ListItem>


          <Divider />
          <ListSubheader>{t("vitals.entitlements", "Entitlements")}</ListSubheader>
          {entitlements.entitlements.filter((ent)=>{
            return entitlements.usage(ent.name).meter ? true : false
          }).map((ent)=>{
            return <ListItem key={ent.name}>
              <ListItemText>
                <Typography style={{ textTransform: "capitalize" }}>{t(`Entitlements.${ent.name}`, formatEntitlementKey(ent.name))}</Typography>
              </ListItemText>
              <ListItemSecondaryAction>
                {entitlements.usage(ent.name).remaining === -1 && <>{t("Unlimited")}</>}
                <>
                  {entitlements.usage(ent.name).remaining > -1 && <>
                    {entitlements.usage(ent.name).remaining}/
                    {entitlements.usage(ent.name).allowed}&nbsp;
                    {entitlements.usage(ent.name).meter}
                  </>}
                </>
              </ListItemSecondaryAction>
            </ListItem>
          })}

          <Divider />
          <ListSubheader>
            {t("vitals.resetSettings")}
            <Typography variant="body2" style={{opacity:0.7, marginTop:"-6px", paddingBottom:"12px"}}>
              {t("vitals.resetDescription")}
            </Typography>
          </ListSubheader>
          {/* Soft Reset Option */}
          <ListItemButton onClick={() => confirmReset("soft")}>
            <ListItemIcon>
              <PhoneAndroid />
            </ListItemIcon>
            <ListItemText>{t("vitals.softReset")}</ListItemText>
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>
          {/* Hard Reset Option */}
          <ListItemButton onClick={() => confirmReset("hard")}>
            <ListItemIcon>
              <PhoneAndroid />
            </ListItemIcon>
            <ListItemText>{t("vitals.hardReset")}</ListItemText>
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>
          <Divider />
          <ListSubheader>{t("vitals.sendReport")}</ListSubheader>
          <ListItemButton onClick={() => emailReport()}>
            <ListItemIcon>
              <Email />
            </ListItemIcon>
            <ListItemText>{t("vitals.sendToSupport")}</ListItemText>
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>
        </List>
      </Stack>
      {Dialog.Dialog}
    </DefaultLayout>
  );
}
