import { HeyXraiResponses, heyXraiStore } from "comps/gpt/HeyXraiStore";
import { decryptEvent } from "utils/encryption";
import { AuthStatus, authStore } from "comps/auth/AuthStore";
import { downloadStore } from "comps/languageDrawer/DownloaderStore";
import { getOrMakeStore, useStore } from "state/store";
import JwtToken from "comps/auth/JwtToken";
import { useState, useEffect } from "react";
import xconsole from "utils/xconsole";
import { useTranslation } from "react-i18next";
import {
  Multicast,
  PermissionsStatus,
  SubtitleLiveFinal,
  SubtitleLiveFluid,
} from "types/types";
import { setGlobalState, useGlobalState } from "state";
import { wait } from "utils/wait";
import useUnityAuth from "comps/auth/useUnityAuth";
import {
  finalSubtitlesStore,
  fluidSubtitleStore,
  sequenceNumberStore,
} from "comps/subtitles/subtitleStore";

const targetLangIndexMap: Map<string, number> = new Map();
let nextIndex: number = 0;
let targetLangRef:string = null;

const getTargetLangIndex = (key: string): number => {
  if (!targetLangIndexMap.has(key)) {
    targetLangIndexMap.set(key, nextIndex++);
    return nextIndex;
  }

  return targetLangIndexMap.get(key);
};

type Props = {
  setRootFontSize: (size: number) => void;
  getRootFontSize: () => number;
  Dialog: any;
};

class TranscriptResult {
  language: string;
  textStable: string;
  textPartial: string;
  speakerId: string;
  color: string;
  startTime: number;
  endTime: number;
  partial: boolean;
  finalSeqNum: number;
  fluidSeqNum: number;
  speakerEmbedding: number[];

  constructor() {
    this.textStable = "";
    this.textPartial = "";
    this.speakerId = "";
  }

  get final(): boolean {
    return !this.partial;
  }

  get text(): string {
    return (
        !this.textStable  ? this.textPartial :
        !this.textPartial ? this.textStable  :
        `${this.textStable} ${this.textPartial}`
    );
  }
}

const useEventParsing = ({
  getRootFontSize,
  setRootFontSize,
  Dialog,
}: Props) => {
  const [isNetworkConnected, setisNetworkConnected] = useState<
    boolean | undefined
  >(undefined);

  const [, setPermissionStatus] = useGlobalState("permissionsStatus");
  const [, setCapStatus] = useGlobalState("captionStatus");

  const setPurchaseStatus = (a: any) => setGlobalState("purchaseStatus", a);
  const setMulticast = (a: Multicast) => setGlobalState("multicast", a);

  const [multiFluid, setMultiFluid] = useStore<
    Map<string | null, SubtitleLiveFluid>
  >("multiFluid", new Map<string | null, SubtitleLiveFluid>());

  const [multiFluidSeqN, setMultiFluidSeqN] = useStore<
    Map<string | null, number>
  >("multiFluidSeqN", new Map<string | null, number>());

  const [multiFinalSeqN, setMultiFinalSeqN] = useStore<
    Map<string | null, number>
  >("multiFinalSeqN", new Map<string | null, number>());

  const userAuth = useUnityAuth();

  const { t } = useTranslation();

  const logoutRefresh = async () => {
    localStorage.setItem("autologin", "1");
    await userAuth.signOut();
  };

  //@ts-ignore;
  window["logoutRefresh"] = logoutRefresh;

  function decode(abuff: ArrayBuffer): TranscriptResult {
    const data = new Uint8Array(abuff);
    const result = new TranscriptResult();
    let offset = 0;
    const partial = data[offset++];
    const lcSize = data[offset++];
    const sidSize = data[offset++];
    //const colSize = data[offset++];

    const finalBytes = data.slice(offset, offset + 4);
    offset += finalBytes.length;
    const finalSeqNum = new DataView(finalBytes.buffer).getInt32(0, true);

    const fluidBytes = data.slice(offset, offset + 4);
    offset += fluidBytes.length;
    const fluidSeqNum = new DataView(fluidBytes.buffer).getInt32(0, true);

    const lc_ = data.slice(offset, offset + lcSize);
    offset += lc_.length;
    const lc = new TextDecoder().decode(lc_);

    const sid_ = data.slice(offset, offset + sidSize);
    offset += sid_.length;
    const sid = new TextDecoder().decode(sid_);

    //const col_ = data.slice(offset, offset + colSize);
    //offset += col_.length;
    //const col = new TextDecoder().decode(col_);

    const txt_ = data.slice(offset);
    const txt = new TextDecoder().decode(txt_);

    result.language = lc;
    result.speakerId = sid;
    //result.color       = col ;
    result.textPartial = partial === 1 ? txt : "";
    result.textStable = partial === 1 ? "" : txt;
    result.partial = partial === 1;
    result.finalSeqNum = finalSeqNum;
    result.fluidSeqNum = fluidSeqNum;

    return result;
  }

  let setSeenTargetLangRef:React.Dispatch<React.SetStateAction<string>> =
    ((prev) => {
      xconsole.debug("seenTargetLang fail noop");
    });

  // Listen for Network Offline / Online changes
  const eventParseNetwork = (evt: MessageEvent) => {
    decryptEvent(evt.data).then((d) => {
      const networkConnected = JSON.parse(d);
      // Compare to current State
      if (networkConnected !== isNetworkConnected) {
        setisNetworkConnected(networkConnected);
      }
    });
  };

  const eventParseHeyXrai = (evt: MessageEvent) => {
    decryptEvent(evt.data).then((d) => {
      heyXraiStore.set(d as HeyXraiResponses);
    });
  };

  const eventParseAuth = (evt: MessageEvent) => {
    decryptEvent(evt.data).then((d) => {
      const a = JSON.parse(d);
      const s: AuthStatus = {
        tokens: {
          idToken: new JwtToken(a.tokens.Id),
          accessToken: new JwtToken(a.tokens.Access),
          refreshToken: a.tokens.Refresh,
        },
        status: a.status,
        reason: a.reason,
      };
      authStore.set(s);
    });
  };

  const eventParseState = (evt: MessageEvent) => {
    decryptEvent(evt.data).then((d) => {
      const a = JSON.parse(d);
      const s = getOrMakeStore<any>(a.Key);
      s.set(a.Val);
    });
  };

  const eventParseDownloader = (evt: MessageEvent) => {
    decryptEvent(evt.data).then((d) => {
      downloadStore.set(JSON.parse(d));
    });
  };

  const eventParseSetting = (evt: Event) => {
    const msg = evt as MessageEvent;
    decryptEvent(msg.data).then((d) => {
      let obj = null;
      try {
        obj = JSON.parse(d);
      } catch (e) {
        xconsole.error("EventSource fail", e, d);
        return;
      }

      if (obj.hasOwnProperty("RootFontSize")) {
        let size = parseInt(obj.RootFontSize);
        if (size === getRootFontSize()) return;
        setRootFontSize(size);
        xconsole.log(`EventSource RootFontSize (${d}) success`);
      }

      // support new useStore mechanism
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const s = getOrMakeStore<any>(key);
          const v = obj[key];
          s.decode(v);
        }
      }
    });
  };

  const eventParsePurchase = (evt: any) => {
    // Auth.signOut();
    let status = "complete";
    decryptEvent(evt.data).then((d) => {
      try {
        let data = JSON.parse(d);
        status = data.status;
      } catch (e) {}
      xconsole.log(`EventSource purchase call: ${status}`);
      setPurchaseStatus(status);
      if (status === "processing") {
        Dialog.closeDialog();
        wait(200).then(() => {
          Dialog.showDialog({
            title: t("Processing Subscription"),
            message: t("please-hold"),
            buttons: [],
          });
        });
      } else if (status === "complete") {
        Dialog.closeDialog();
        wait(200).then(async () => {
          Dialog.showDialog({
            closable: false,
            title: t("Success"),
            message: t(
              "subscription.complete",
              "Subscription complete, you will now need to restart the app in order to see your changes."
            ),
            buttons: [
              {
                label: t("Restart"),
                async action() {
                  logoutRefresh();
                },
              },
            ],
          });
        });
        setTimeout(() => {
          setPurchaseStatus("idle");
        }, 700);
      }
    });
  };

  const eventParseLogin = (evt: Event) => {
    //TODO: Brandon, I believe this is supposed to refresh the purchase status
    //so that it updates after the customer buys something. We made need to fix
    //this to work with the new token refresh schemes. -- Keith
    // Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
    xconsole.log("EventSource purchase login");
  };

  const eventParseCapState = (evt: Event) => {
    const msg = evt as MessageEvent;
    decryptEvent(msg.data).then((d) => {
      xconsole.log(`EventSource CapState (${d}) receive`);
      if (d === "created") {
        setCapStatus({ status: "confirmStop" });
      } else if (d === "started") {
        setCapStatus({ status: "confirmStart" });
      } else if (d === "paused") {
        setCapStatus({ status: "confirmPause" });
      } else if (d === "stopped") {
        setCapStatus({ status: "confirmStop" });
      } else {
        xconsole.debug(`EventSource CapState (${d}) failure`);
      }
    });
  };

  const eventParsePermissionsStatus = (evt: Event) => {
    const msg = evt as MessageEvent;
    decryptEvent(msg.data).then((d) => {
      xconsole.log(`EventSource PermissionsStatus (${d}) receive`);
      let permissions = JSON.parse(d) as PermissionsStatus;
      setPermissionStatus({
        audioPermission: permissions.audioPermission,
        bluetoothPermission: permissions.bluetoothPermission,
        contactsPermission: permissions.contactsPermission,
      });
    });
  };

  const eventParseMulticast = (evt: any) => {
    decryptEvent(evt.data).then((d) => {
      try {
        let data: Multicast = JSON.parse(d);
        setMulticast(data);
      } catch (e) {
        xconsole.warn(`fail EventSource multicast (${d})`);
      }
    });
  };

  const eventParseRelocate = (evt: any) => {
    decryptEvent(evt.data).then((d) => {
      try {
        const a = JSON.parse(d);
        if (a.Url) {
          window.location.href = a.Url;
        }
      } catch (e) {
        xconsole.warn(`fail EventSource relocate (${d})`);
      }
    });
  };

  const processSubtitleFluid = (s: SubtitleLiveFluid) => {
    if (s.n === 0) {
      fluidSubtitleStore.set(undefined);
      // new session has started
      finalSubtitlesStore.clear();
      sequenceNumberStore.set(0);
      setMultiFluid(new Map<string | null, SubtitleLiveFluid>());
      setMultiFluidSeqN(new Map<string | null, number>());
    } else {
      fluidSubtitleStore.set(s);
      sequenceNumberStore.set(s.n);
      // setSubtitleSeqN(s.n);
      // setSubtitleText(s.t);
      multiFluid.set(s.i, s);
      setMultiFluid(multiFluid);
      multiFluidSeqN.set(s.i, s.n);
      setMultiFluidSeqN(multiFluidSeqN);
    //console.log(
    //  `FRACK set fluid (${s.i}) (${s.n}) (${multiFinalSeqN?.get(
    //    null
    //  )}) (${multiFinalSeqN?.get("")})`
    //);
    }
  };

  const eventParseSubtitleFluid = (evt: Event) => {
    const msg = evt as MessageEvent;
    decryptEvent(msg.data).then((d) => {
      //TODO: implement the same efficient log(0)?.log(...) interface we have for C#
      //xconsole.log(`EventSource SubtitleFluid (${msg.data}) -> (${d}) receive`)
      let s: SubtitleLiveFluid = JSON.parse(d);
      processSubtitleFluid(s);
    });
  };

  const processSubtitleFinal = (s: SubtitleLiveFinal) => {
    if (s.n === 0) {
      // new session has started
      // resetSubtitles();
      finalSubtitlesStore.clear();
      sequenceNumberStore.set(0);
      setMultiFinalSeqN(new Map<string | null, number>());
    } else {
      finalSubtitlesStore.push(s);
      // globalThis.subtitles.push(s);
    }
    // setSubtitleSeqN(s.n);
    sequenceNumberStore.set(s.n);
    multiFinalSeqN.set(s.i, s.n);
    // TODO: Find a better way to handle to handle partials with no speaker.
    // For now any final overrides earlier partials.
    multiFinalSeqN.set(null, s.n);
    multiFinalSeqN.set("", s.n);
  //console.log(
  //  `FRACK set final (${s.i}) (${s.n}) (${multiFinalSeqN?.get(
  //    null
  //  )}) (${multiFinalSeqN?.get("")})`
  //);
    setMultiFinalSeqN(multiFinalSeqN);
  };

  const eventParseSubtitleFinal = (evt: Event) => {
    const msg = evt as MessageEvent;
    decryptEvent(msg.data).then((d) => {
      let s: SubtitleLiveFinal = JSON.parse(d);
      processSubtitleFinal(s);
    });
  };

  const [seenTargetLang, setSeenTargetLang] = useState<string|null>(null);
  const [targetLangList, setTargetLangList] = useStore<string[]>("targetLangList",[]);
  const [xraiGoTargetLang, ] = useStore<string|null>("xraiGoTargetLang",null);

  setSeenTargetLangRef = setSeenTargetLang;

  function handleMessage(event: any) {
    const result = decode(event.data);

    const [baseLang] = result?.language?.split('-');

    setSeenTargetLangRef((prev) => {
        if(!baseLang) return prev;
        if(prev===baseLang) return prev;
        return baseLang;
    });

    if (globalThis.isXraiGo) {
      if(targetLangRef && targetLangRef !== baseLang ) {
        return;
      }
    }

    if (result.partial === true) {
      const s: SubtitleLiveFluid = {
        n: result.fluidSeqNum,
        t: result.text,
        i: result.speakerId,
      };

      if (s.n <= globalThis.lastSeqNum) {
        return;
      }

      globalThis.lastSeqNum = s.n;
      processSubtitleFluid(s);
      return;
    } else {
      const s: SubtitleLiveFinal = {
        n: result.finalSeqNum,
        t: result.text,
        i: result.speakerId,
        l: result.speakerId,
        c: result.color,
        g: null,
      };

      if (s.n <= globalThis.lastSeqNum) {
        return;
      }

      globalThis.lastSeqNum = s.n;
      processSubtitleFinal(s);
    }
  }

  useEffect(() => {
    setTargetLangList((prev) => {
      if(!seenTargetLang || prev.includes(seenTargetLang)) return prev;
      getTargetLangIndex(seenTargetLang);
      const unique = new Set<string>([...prev,seenTargetLang]);
      const sorted = Array.from(unique.values()).sort(
        (a, b) => getTargetLangIndex(a) - getTargetLangIndex(b)
      );
      xconsole.debug("setTargetLangList",prev,sorted);
      return sorted;
    });
  }, [seenTargetLang, setTargetLangList]);

  useEffect(() => {
    const targetLang =
      xraiGoTargetLang === "default" ? "" :
      xraiGoTargetLang ? xraiGoTargetLang : "";
    targetLangRef = targetLang;
  }, [xraiGoTargetLang, targetLangList]);

  return {
    isNetworkConnected,
    eventParseNetwork,
    eventParseHeyXrai,
    eventParseAuth,
    eventParseState,
    eventParseDownloader,
    eventParseSetting,
    eventParsePurchase,
    eventParseLogin,
    eventParseCapState,
    eventParsePermissionsStatus,
    eventParseMulticast,
    eventParseRelocate,
    eventParseSubtitleFluid,
    eventParseSubtitleFinal,
    handleMessage,
  };
};

export default useEventParsing;
