import React, { useEffect, useState, useCallback } from "react";
import {
  FavoriteLanguagePayload,
  getFavoriteSpokenLanguages,
  togSpokenLangs,
} from "./favoriteLanguage-utils";
import FavoriteLanguageItem from "./FavoriteLanguageItem";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

export const FavoriteSpokenLanguages = () => {
  const [langs, setLangs] = useState<FavoriteLanguagePayload[]>([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const { t } = useTranslation();
  
  const getLangsUnity = useCallback(() => {
    getFavoriteSpokenLanguages().then((res) => {
      setSelectedCount(
        res?.filter((l) => l.selected)?.length ?? 0
      );
      setLangs(res);
    });
  }, [setLangs]);

  const handleLanguageToggle = async (engine: FavoriteLanguagePayload) => {
    await togSpokenLangs(engine);
    getLangsUnity();
  };

  useEffect(() => {
    getLangsUnity();
  }, [getLangsUnity]);

  const lineHeight = 1.5;
  const threeLinesHeight = `${4 * lineHeight}em`;

  return (
    <div>
      {selectedCount > 1 ? (
          <Alert style={{
            lineHeight: `${lineHeight}em`,
            minHeight: threeLinesHeight,
            maxHeight: threeLinesHeight,
            overflow: 'hidden',
            }} severity="info">
            {t("language.multiple_langs_use_azure")}
          </Alert>
        ) : (
          <Alert style={{
            lineHeight: `${lineHeight}em`,
            minHeight: threeLinesHeight,
            maxHeight: threeLinesHeight,
            overflow: 'hidden',
            }} severity="info">
            {t("language.select_multiple_langs")}
          </Alert>
        )
      }
      {langs.map((lang, index) => {
        return (
          <FavoriteLanguageItem
            forceToAzure={selectedCount > 1}
            onToggle={() => {
              handleLanguageToggle(lang);
            }}
            key={index}
            lang={lang}
            type="SourceLanguage"
          />
        );
      })}
    </div>
  );
};
