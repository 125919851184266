import React from "react";
import { Box, useTheme } from "@mui/material";

type Props = {
  index: number;
  value: number;
  children?: React.ReactNode;
};

const CustomTabPanel: React.FC<Props> = (props) => {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ overflow: "hidden" }}
      {...other}
    >
      <Box
        style={{
          display: value === index ? "block" : "none",
          height: "100%",
          padding: theme.spacing(2),
          paddingTop: 0,
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default CustomTabPanel;
