import React, { ReactElement, SyntheticEvent, useState } from "react";
import { Box } from "@mui/system";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  useTheme,
} from "@mui/material";

import { wait } from "utils/wait";
import useOrientation from "hooks/useOrientation";
import { useStore } from "state/store";

interface DialogButton {
  label: string;
  action?: (evt: SyntheticEvent) => void;
  color?: any;
  default?: boolean | undefined;
  disabled?: boolean;
  variant?: undefined | "contained" | "text" | "outlined";
}

interface UseDialogProps {
  title: string;
  message?: string;
  buttons?: DialogButton[];
  body?: ReactElement<any, any>;
  closable?: boolean;
  bgimage?: string;
  bgplace?: string;
  onClose?: () => void;
}

interface UseDialogResponse {
  dialogProps: UseDialogProps;
  showDialog: (props: UseDialogProps) => void;
  closeDialog: () => void;
  Dialog: ReactElement<any, any>;
}

export const useDialog = (): UseDialogResponse => {
  const [dialogProps, setDialogProps] = useState<UseDialogProps>({
    title: "",
    message: undefined,
    body: undefined,
    buttons: [],
    closable: true,
    bgimage: undefined,
    bgplace: null,
    onClose: null,
  });

  const [open, setOpen] = useState(false);
  const [, setActivePopup] = useStore<boolean>("activePopup");

  const orientation = useOrientation();
  const theme = useTheme();

  const handleClose = (evt: SyntheticEvent, reason: string) => {
    let escaped = ["escapeKeyDown", "backdropClick"].indexOf(reason) > -1;
    if (escaped && dialogProps.closable !== false) {
      closeDialog();

      if (dialogProps.onClose) dialogProps.onClose();
    } else if (reason === "buttonClick") {
      closeDialog();
    }
  };

  const showDialog = (props: UseDialogProps) => {
    setDialogProps(props);
    setOpen(true);
    setActivePopup(true);
  };

  const closeDialog = async () => {
    await wait(200);
    setOpen(false);
    setActivePopup(false);
  };

  return {
    dialogProps,
    showDialog,
    closeDialog,
    Dialog: (
      <Dialog
        className="useDialogBox"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor:
              theme.palette.neutral?.main ?? theme.palette.background.default,
          },
        }}
      >
        {!!dialogProps.bgimage && dialogProps.bgplace !== "bot" && (
          <Box
            sx={{
              width: orientation === "landscape" ? "66vh" : "66vw",
              height: orientation === "landscape" ? "66vh" : "66vw",
              backgroundImage: `url(${dialogProps.bgimage})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          />
        )}
        <Box>
          <DialogTitle
            sx={{
              fontWeight: "bold",
              pb: "8px",
              lineHeight: "140%",
            }}
          >
            {dialogProps.title}
          </DialogTitle>

          {!!dialogProps.message && (
            <DialogContent>
              <DialogContentText>
                {dialogProps.message}
              </DialogContentText>
            </DialogContent>
          )}

          {!!dialogProps.body && dialogProps.body}

          {!!dialogProps.buttons?.length && (
            <DialogActions sx={{ p: 2, justifyContent: "flex-end" }}>
              {dialogProps.buttons.map((button, index) => (
                <Button
                  key={index}
                  size="small"
                  variant={button.variant || "contained"}
                  color={button.color}
                  autoFocus={button.default}
                  aria-label={button.label}
                  disabled={button.disabled}
                  onClick={async (evt) => {
                    handleClose(evt, "buttonClick");
                    await wait(100);
                    if (button.action) button.action(evt);
                  }}
                >
                  {button.label}
                </Button>
              ))}
            </DialogActions>
          )}
        </Box>
        {!!dialogProps.bgimage && dialogProps.bgplace === "bot" && (
          <Box
            sx={{
              width: orientation === "landscape" ? "66vh" : "66vw",
              height: orientation === "landscape" ? "66vh" : "66vw",
              backgroundImage: `url(${dialogProps.bgimage})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          />
        )}
      </Dialog>
    ),
  };
};
