import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Container,
  List,
  ListItemButton,
  Divider,
  Chip,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import DefaultLayout from "comps/layout/DefaultLayout";
import { ThemeContext } from "@emotion/react";
import { Theme } from "@mui/system";
import { useOpenURL } from "hooks/useOpenUrl";
import { wait } from "utils/wait";
import useGoogleAnalytics from "hooks/useGoogleAnalytics";
import { useStoreUnity } from "state/store";

interface APKLink {
  id: string;
  label: string;
  href: string;
}

const InstallGlassesScreen = () => {
  const { t } = useTranslation();
  const browser = useOpenURL();
  const theme = useContext(ThemeContext) as Theme;
  const ga = useGoogleAnalytics();
  const [loading, setLoading] = useState(true);

  const [links] = useStoreUnity<APKLink[]>("GlassesApks");

  const openLink = async (url: string) => {
    await wait(300);
    ga.event(`installer open-apk ${url}`);
    browser.open(url);
  };

  const FadedPaper = styled(Box)({
    backgroundColor: `${theme.palette.secondary.dark} !important`,
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
  });

  useEffect(() => {
    if ( links ) {
      setLoading(false);
    }
  }, [links]);

  return (
    <DefaultLayout
      pageTitle="Xrai Glasses Apps"
      backLabel={t("Back")}
      backNavigate={-1}
      menuItems={<></>}
      headerComponent={
        <Box sx={{ px: "22px", py: "8px" }}>
          <Typography variant="h1" component="h1" gutterBottom>
            {t("xraiInstallers.title")}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "12px" }}>
            {t("xraiInstallers.description")}
          </Typography>
        </Box>
      }
    >
      <Container maxWidth="sm" sx={{ px: "24px" }}>
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
        <FadedPaper sx={{ marginBottom: "24px", padding: 0 }}>
          <List sx={{ padding: 0 }}>
            {links && links.map((link, index) => {
              return (
                <div key={index}>
                  <ListItemButton
                    sx={{ py: "12px" }}
                    onClick={() => {
                      openLink(link.href);
                    }}
                  >
                    <Stack
                      alignItems="center"
                      spacing={2}
                      sx={{ width: "100%" }}
                      direction="row"
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          width: "100%",
                          lineHeight: "125%",
                        }}
                      >
                        {link.label}
                      </Typography>
                      <Chip label={t("Install")} />
                    </Stack>
                  </ListItemButton>
                  {index < links.length - 1 && <Divider />}
                </div>
              );
            })}
          </List>
        </FadedPaper>
      </Container>
    </DefaultLayout>
  );
};

export default InstallGlassesScreen;
