import React from "react";
import { useStore } from "state/store";

export const Disk = (props:any) => {

  const [height,] = useStore<number>("rootIconSize");

  return (
    <span {...props}
      style={{
         borderRadius: "50%",
         display: "inline-block",
         width: height/2,
         height: height/2,
         verticalAlign: "text-top",
         backgroundColor:"#FF0000",
      }}
    />
  );
};
