import { ReactElement } from "react";
import { wait } from "utils/wait";
import { useDialog } from "hooks/useDialog";
import { useTranslation } from "react-i18next";
import { xfetch } from "utils/xfetch";

interface UseDialogResponse {
  deleteConversations: (sessions: Array<string>) => Promise<Array<DeletedProps>>;
  Dialog: ReactElement<any, any>;
}

interface DeletedProps {
  id: string;
  deleted: boolean; 
  error?: any
}

// Delete a Single Conversation 
export const deleteConversation = async (sessionId:string) => {
  let call = await xfetch('/deletesession', {
    method: 'POST',
    body: JSON.stringify({ SessionId: sessionId })
  })
  if(call.status > 299) {
    throw new Error(call.statusText)
  }
  await wait(80);
  return true;
};

// Delete multiple Conversations 
// loop over each await for single deletion
const deleteManyConversations = async (sessions:Array<string>):Promise<Array<DeletedProps>> => {
  let done = [];
  for(let i=0;i<sessions.length;i++) {
    const sessionId = sessions[i];
    try {
      let deleted = await deleteConversation(sessionId);
      done.push({id: sessionId, deleted})
    } catch(e) {
      done.push({id: sessionId, deleted: false, error: e})
    }
  }
  return done;
}

export const useDeleteConversation = (): UseDialogResponse => {
  const { t } = useTranslation();
  const { Dialog, showDialog } = useDialog();

  const deleteConversations = async (sessions: Array<string>): Promise<any> => {
    return new Promise((resolve, reject) => {
      const handleDelete = async () => {
        const finished = await deleteManyConversations(sessions);
        return finished;
      };

      const cancelButton = {
        label: t("Cancel"),
      };

      const deleteButton = {
        label: t("Delete"),
        color: "error",
        async action() {
          handleDelete().then((payload) => {
            resolve(payload);
          }).catch(async (e) => {
            reject(e);
            await wait(200);
            showDialog({
              title: t("Error"),
              message: `${e}`,
              buttons: [
                {
                  label: t("Close"),
                },
              ],
            });
          });
        },
      };

      // Confirm with User they want to
      // delete this conversation

      showDialog({
        title: sessions.length === 1
          ? t("conversations.deletePrompt")
          : `${t("Delete")} ${sessions.length} ${t("conversations.title")}`,
        message: t("undoable"),
        buttons: [
          cancelButton,
          deleteButton,
        ],
      });
    });
  };

  return {
    deleteConversations,
    Dialog: Dialog,
  };
};
