import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Alert, Snackbar } from "@mui/material";

import getInitialLocale from "utils/getInitialLocale";
import { getState } from "utils/settings";
import { useTranslation } from "react-i18next";

import xconsole from "utils/xconsole";
import { getEncryptionKey } from "utils/encryption";
import i18n from "i18n-config";

import {
  fluidSubtitleStore,
  sequenceNumberStore,
} from "comps/subtitles/subtitleStore";
import { useStore } from "state/store";
import useUnityAuth from "comps/auth/useUnityAuth";

import { AppUser, SubtitleLiveFinal } from "types/types";

import useThemeSetter from "hooks/useThemeSetter";
import useAppEvents from "hooks/useAppEvents";
import useBroadcastConsumer from "hooks/useBroadcastConsumer";
import useMobileSleepLock from "hooks/useMobileSleepLock";
import { useDialog } from "hooks/useDialog";

import AppRoutes from "Routes";
import AppUserContext from "comps/context/AppUserContext";
import ReactErrorDisplay from "comps/common/ReactErrorDisplay";

function App() {
  const [appUser, setAppUser] = useState<AppUser | null>(null);

  const [locale] = useStore<string>("LocaleLanguage");

  const Dialog = useDialog();
  const userAuth = useUnityAuth();
  const { t } = useTranslation();
  const { getRootFontSize, setRootFontSize } = useThemeSetter();

  // This is a custom hook that listens for broadcast messages from the Teams app.
  useBroadcastConsumer();

  // This is a custom hook that prevents the mobile device from sleeping while in XRAI Go mode.
  useMobileSleepLock();

  const {
    isNetworkConnected,
    handleMessage,
    createAppEventSource,
    deleteAppEventSource,
  } = useAppEvents({
    setRootFontSize,
    getRootFontSize,
    Dialog,
  });

  window.alert = (msg: string) => {
    Dialog.showDialog({
      title: undefined,
      message: msg,
      buttons: [
        {
          label: t("Close"),
        },
      ],
    });
  };

  //KHD: I'd like to know why this method of changing the theme's fontSize did not
  //dynamically update all font sizes as I had expected. Whereas changing the root
  //HTML element does work as I expected. For now I'm keeping this so I can figure
  //out how to dynamically chang styles beyond just font size such as colors for
  //color blind support.
  //const theme = React.useMemo(()=>makeTheme(14),[rootFontSize]);

  const resetSubtitles = () => {
    globalThis.subtitles = [] as SubtitleLiveFinal[];
    sequenceNumberStore.set(0);
    fluidSubtitleStore.set(undefined);
  };

  useEffect(() => {
    setAppUser(userAuth.state.appUser);
  }, [userAuth.state.appUser]);

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    const createXrai = async () => {
      await getEncryptionKey();

      resetSubtitles();
      getInitialLocale();
      createAppEventSource();
      getState("RootFontSize").then((size) => {
        let rootFontSize: number = parseInt(size);
        setRootFontSize(rootFontSize);
      });

      return deleteAppEventSource;
    };

    /* XRAI Go ********************************************************************/

    const createPubsub = async (): Promise<WebSocket> => {
      const group = globalThis.xraiGoKey;
      const url = await fetch(
        `https://xraigo-connstr-service-uksouth.azurewebsites.net/api/getconnstr?group=${group}`
      ).then((r) => r.text());
      const ws = new WebSocket(url);
      ws.binaryType = "arraybuffer";

      return ws;
    };

    const createXraiGo = async () => {
      const ws = await createPubsub();
      ws.addEventListener("message", handleMessage);
      resetSubtitles();

      const deleteXraiGo = () => {
        ws.removeEventListener("message", handleMessage);
        ws.close();

        xconsole.log("deleteXraiGo done");
      };

      xconsole.log("createXraiGo done");

      return deleteXraiGo;
    };

    /******************************************************************************/

    const init = async () => {
      let cleanup: (() => void) | undefined;

      if (globalThis.isXraiGo) {
        cleanup = await createXraiGo();
      } else {
        cleanup = await createXrai();
      }

      // Broadcast a message to Teams App to notify that the app has started.
      const message = { type: "notification", message: "App started" };
      window.parent.postMessage(message, "*");

      return cleanup;
    };

    let cleanup: (() => void) | undefined;
    let isMounted = true;

    init().then((c) => {
      if (isMounted && c) {
        cleanup = c;
      }
    });

    return () => {
      isMounted = false;
      cleanup?.();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //useEffect(() => {
  //  const _iid = setInterval(() => {
  //    xfetch('/ping');
  //  }, 1000);
  //  return () => clearInterval(_iid);
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, []);

  return (
    <AppUserContext.Provider value={{ appUser, setAppUser, userAuth }}>
      <ErrorBoundary
        fallbackRender={ReactErrorDisplay}
        onError={(e) => {
          xconsole.always("React Error", e);
        }}
      >
        <AppRoutes Dialog={Dialog} />
      </ErrorBoundary>

      <Snackbar open={isNetworkConnected === false}>
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", maxWidth: "500px" }}
        >
          {t("Network.offline")}
        </Alert>
      </Snackbar>
    </AppUserContext.Provider>
  );
}

export default App;
