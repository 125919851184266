import {
  ArrowDownwardOutlined,
  CenterFocusStrong,
  HideImage,
  PushPin,
  PushPinOutlined,
} from "@mui/icons-material";
import { Button, Slide, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import IconLabelButton from "comps/common/IconLabelButton";
import { toJSONPostRequest, xfetch } from "utils/xfetch";
import VerticalAlignTop from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignBot from "@mui/icons-material/VerticalAlignBottom";
//import VerticalAlignJus from '@mui/icons-material/FormatAlignJustify';
import { useStore, useStoreUnity } from "state/store";

const ArStackButton = (props: any) => (
  <IconLabelButton
    style={{
      background: "transparent",
      border: 0,
      boxShadow: "none",
    }}
    {...props}
  />
);

interface Props {
  children?: any;
  inAr?: boolean;
  isPinned?: boolean;
  showBottom?: boolean;
  handleBottomClick?: () => void;
}
export default function SubtitleSidebar(props: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isHidden, setIsHidden] = useState(false);
  const [pinned, setPinned] = useState(false);

  const [arHave3Dof] = useStoreUnity<boolean>("ArHave3Dof", false);
  const [arPanelTop, setArPanelTop] = useStoreUnity<boolean>(
    "EnableHighSubtitles",
    false
  );

  const [iconSize] = useStore<number>("rootIconSize");

  const togglePin = () => {
    let _pinned = !pinned;
    toJSONPostRequest({ pinned: _pinned }).then(async (payload) => {
      await xfetch("/togglePinned", payload);
      setPinned(_pinned);
    });
  };

  // Center the Captions
  const centerCaptions = () => {
    xfetch("/centerCaptions", {
      method: "POST",
    });
  };

  const iconCSS = {
    color: theme.palette.text.primary,
  };

  return (
    <>
      {isHidden && (
        <Slide direction="up" in={isHidden}>
          <Stack
            sx={{
              position: "absolute",
              zIndex: 6000,
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: "#000",
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="outlined"
              color="secondary"
              style={{ color: "#FFF" }}
              onClick={() => {
                setIsHidden(false);
              }}
            >
              {t("Unhide")}
            </Button>
          </Stack>
        </Slide>
      )}

      <Stack
        sx={{
          position: "absolute",
          zIndex: 1000,
          bottom: "140px",
          right: theme.spacing(1.25),
        }}
        direction="column"
        spacing={1}
      >
        {props.inAr && (
          <>
            <ArStackButton
              size={iconSize}
              onClick={() => setIsHidden(true)}
              //label={t("Hide")}
              icon={<HideImage />}
              style={iconCSS}
            />

            <ArStackButton
              size={iconSize}
              onClick={() => setArPanelTop(!arPanelTop)}
              //label={t("Position")}
              icon={arPanelTop ? <VerticalAlignTop /> : <VerticalAlignBot />}
              style={iconCSS}
            />

            {arHave3Dof && (
              <ArStackButton
                onClick={() => togglePin()}
                size={iconSize}
                //label={pinned ? t("Unpin") : t("Pin")}
                icon={pinned ? <PushPinOutlined /> : <PushPin />}
                style={iconCSS}
              />
            )}

            {arHave3Dof && (
              <ArStackButton
                onClick={() => centerCaptions()}
                size={iconSize}
                //label={t("Center")}
                icon={<CenterFocusStrong />}
                style={iconCSS}
              />
            )}
          </>
        )}

        {props.showBottom && (
          <ArStackButton
            size={iconSize}
            onClick={() => {
              if (props.handleBottomClick) props.handleBottomClick();
            }}
            label={t("Bottom")}
            icon={<ArrowDownwardOutlined />}
          />
        )}

        {props.children}
      </Stack>
    </>
  );
}
