import React, { useState } from "react";
import { LangSettingType } from "comps/language/langaugeUtils";
import FavoriteLanguagesDialog from "./FavoriteLanguagesDialog";

interface Props {
  type: LangSettingType;
  title: string;
  onSelect: (lang: string) => void;
}
export default function useFavoriteLanguageDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const [props, setProps] = useState<undefined | Props>(undefined);

  const open = (props: Props) => {
    setProps(props);
    setIsOpen(true);
  };

  return {
    open,
    isOpen: open,
    Dialog: (
      <FavoriteLanguagesDialog
        onSelect={(lang) => {
          props?.onSelect(lang);
          setIsOpen(false);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        open={isOpen}
        type={props?.type}
        id={`favlang-${props?.type}`}
      />
    ),
  };
}
