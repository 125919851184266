import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  Avatar,
  Button,
  Container,
  List,
  ListItemButton,
  Divider,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ChevronRight,
  Instagram,
  LinkedIn,
  PsychologyRounded,
  QuizRounded,
  ShoppingCartRounded,
  SupportRounded,
  Twitter,
} from "@mui/icons-material";
import AppUserContext from "comps/context/AppUserContext";
import { useTranslation } from "react-i18next";

import DefaultLayout from "comps/layout/DefaultLayout";
import { ThemeContext } from "@emotion/react";
import { Theme } from "@mui/system";
import { useOpenURL } from "hooks/useOpenUrl";
import Filler from "comps/common/Filler";
import { useDialog } from "hooks/useDialog";
import { wait } from "utils/wait";
import { DiscordIcon } from "comps/icons/DiscordIcon";
import useGoogleAnalytics from "hooks/useGoogleAnalytics";
import getInitials from "utils/getInitials/getInitals";
import { toJSONPostRequest, xfetchLocal, appReload } from "utils/xfetch";
import sendEmail from "utils/sendEmail";
import { AuthStatus, authStore } from "comps/auth/AuthStore";
import { useSyncExternalStore } from "react";

const ProfileScreen = () => {
  const { appUser, userAuth } = useContext(AppUserContext);

  const authStatus = useSyncExternalStore<AuthStatus | undefined>(
    authStore.subscribe,
    authStore.get
  );

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const browser = useOpenURL();
  const theme = useContext(ThemeContext) as Theme;
  const ga = useGoogleAnalytics();

  const LINKS = [
    {
      id: "glasses",
      label: t("profile.glasses"),
      href: "https://xrai.glass/ar-glasses",
      icon: <ShoppingCartRounded sx={{ opacity: 0.6 }} />,
    },
    {
      id: "support",
      label: t("profile.support"),
      href: "https://xrai.glass/contact",
      icon: <SupportRounded sx={{ opacity: 0.6 }} />,
    },
    {
      id: "knowledge_base",
      label: t("profile.knowledge_base"),
      href: "https://xrai.glass/knowledge",
      icon: <PsychologyRounded sx={{ opacity: 0.6 }} />,
    },
    {
      id: "faq",
      label: t("profile.faq"),
      href: "https://xrai.glass/faq",
      icon: <QuizRounded sx={{ opacity: 0.6 }} />,
    },
  ];

  const SOCIAL = [
    {
      id: "twitter",
      label: "Twitter",
      href: "https://twitter.com/xraiglass",
      icon: <Twitter sx={{ opacity: 0.6 }} />,
    },
    {
      id: "instagram",
      label: "Instagram",
      href: "https://www.instagram.com/xraiglass/",
      icon: <Instagram sx={{ opacity: 0.6 }} />,
    },
    {
      id: "discord",
      label: "Discord",
      href: "https://discord.gg/7HjyDJ3JAz",
      icon: <DiscordIcon sx={{ opacity: 0.6 }} />,
    },
    {
      id: "linkedin",
      label: "LinkedIn",
      href: "https://www.linkedin.com/company/xraiglass/",
      icon: <LinkedIn sx={{ opacity: 0.6 }} />,
    },
  ];

  const Confirmation = useDialog();

  const fullSignout = async () => {
    setLoading(true);
    userAuth.signOut();
  };

  const signOut = () => {
    Confirmation.showDialog({
      title: t("Sign out"),
      message: t("login.sign_out_question"),
      buttons: [
        {
          label: t("Cancel"),
        },
        {
          label: t("Ok"),
          action: async (evt) => {
            await fullSignout();
          },
        },
      ],
    });
  };

  const confirmAccountCancel = () => {
    Confirmation.showDialog({
      title: t("CancelAccount.ask"),
      message: t("CancelAccount.warning"),
      buttons: [
        {
          label: t("CancelAccount.nevermind"),
        },
        {
          label: t("CancelAccount.confirm"),
          action: async (evt) => {
            await wait(400);
            handleAccountCancel();
          },
        },
      ],
    });
  };

  // Double Confirm of the Account Deletion
  const handleAccountCancel = () => {
    Confirmation.showDialog({
      title: t("CancelAccount.are_you_sure"),
      buttons: [
        {
          label: t("CancelAccount.nevermind"),
        },
        {
          label: t("CancelAccount.confirm"),
          action: async (evt) => {
            await wait(400);
            callForgetMe();
          },
        },
      ],
    });
  };

  const callForgetMe = async () => {
    try {
      setLoading(true);
      const payload = await toJSONPostRequest({});
      const call = await xfetchLocal("/forgetme", payload);
      if (call.status !== 200) {
        throw new Error(
          `Unable to complete account deletion - error code: ${call.status}`
        );
      }
      setLoading(false);
      Confirmation.showDialog({
        title: t("CancelAccount.deleted"),
        closable: false,
        buttons: [
          {
            label: t("Done"),
            action() {
              fullSignout();
            },
          },
        ],
      });
      // Lets force it after 1 second
      await wait(1000);
      fullSignout();
    } catch (e) {
      setLoading(false);
      Confirmation.showDialog({
        title: t("CancelAccount.error"),
        message: e.message,
        buttons: [
          {
            label: t("profile.support"),
            action() {
              sendEmail(
                process.env.REACT_APP_SUPPORT_EMAIL,
                "Unable to cancel account",
                `I need help canceling my account, but got this error: ${e.message}`
              );
            },
          },
          {
            label: t("Close"),
          },
        ],
      });
    }
  };

  const openLink = async (url: string) => {
    await wait(300);
    ga.event(`profile open-link ${url}`);
    browser.open(url);
  };

  const FadedPaper = styled(Box)({
    backgroundColor: `transparant !important`,
    borderRadius: "8px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
    overflow: "hidden",
  });

  useEffect(() => {
    if (authStatus?.tokens?.accessToken?.jwtToken) {
      setLoading(false);
    } else {
      appReload();
    }
  }, [authStatus]);

  return (
    <DefaultLayout
      bgColor={theme.palette.neutral?.main ?? theme.palette.background.default}
      pageTitle="Profile"
      backLabel={t("Back")}
      backNavigate={-1}
      menuItems={<></>}
      headerComponent={
        <Box sx={{ px: 3, py: 2 }}>
          <Typography variant="h1" component="h1" gutterBottom>
            {t("Profile")}
          </Typography>
        </Box>
      }
    >
      {loading && (
        <Backdrop style={{ zIndex: 6000 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Container
        maxWidth="sm"
        sx={{
          px: 3,
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowY: "scroll",
        }}
      >
        <div>
          <FadedPaper sx={{ mb: 3, p: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                sx={{
                  width: "80px",
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  height: "80px",
                  marginBottom: 1,
                }}
              >
                {!!appUser && !!appUser.ProfilePicture && (
                  <img
                    onError={(e) => {
                      e.currentTarget.parentElement.classList.add(
                        "image-error"
                      );
                    }}
                    alt={getInitials(
                      `${appUser?.FirstName || ""} ${appUser?.LastName || ""}`
                    )}
                    style={{ width: "80px", height: "80px" }}
                    src={appUser?.ProfilePicture}
                  />
                )}
                {!appUser?.ProfilePicture && (
                  <span>
                    {getInitials(
                      `${appUser?.FirstName || ""} ${appUser?.LastName || ""}`
                    )}
                  </span>
                )}
              </Avatar>

              <div>
                <Typography
                  variant="h2"
                  sx={{ fontSize: "1.3rem", fontWeight: "700" }}
                  component="div"
                >
                  {`${appUser?.FirstName || ""} ${appUser?.LastName || ""}`}
                </Typography>

                <Typography variant="subtitle1" component="div">
                  {`${appUser?.EmailAddress || ""}`}
                </Typography>

                <Button
                  size="small"
                  variant="contained"
                  disabled={loading}
                  onClick={() => signOut()}
                >
                  {loading ? "..." : t("Sign out")}
                </Button>
              </div>
            </Stack>
          </FadedPaper>

          <FadedPaper sx={{ marginBottom: 3, padding: 0 }}>
            <List sx={{ padding: 0 }}>
              {LINKS.map((link, index) => {
                return (
                  <div key={index}>
                    <ListItemButton
                      sx={{ py: "12px" }}
                      onClick={() => {
                        openLink(link.href);
                      }}
                    >
                      <Stack
                        alignItems="center"
                        spacing={2}
                        sx={{ width: "100%" }}
                        direction="row"
                      >
                        {link.icon}
                        <Typography
                          sx={{
                            fontWeight: 500,
                            width: "100%",
                            lineHeight: "125%",
                          }}
                        >
                          {link.label}
                        </Typography>
                        <ChevronRight style={{ opacity: 0.3 }} />
                      </Stack>
                    </ListItemButton>
                    {index < SOCIAL.length - 1 && <Divider />}
                  </div>
                );
              })}
            </List>
          </FadedPaper>

          <FadedPaper sx={{ marginBottom: 3, padding: 0 }}>
            <List sx={{ padding: 0 }}>
              <ListItemButton
                sx={{ py: "12px" }}
                onClick={() => {
                  browser.open("https://give.ucdavis.edu/VCSA/125342");
                }}
              >
                <div style={{ marginRight: "16px" }}>❤️</div>
                <Stack
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%" }}
                  direction="row"
                >
                  <Typography
                    sx={{ fontWeight: 500, width: "100%", lineHeight: "125%" }}
                  >
                    In Memory of <strong>Karim Abou Najm</strong>
                  </Typography>
                  <ChevronRight style={{ opacity: 0.3 }} />
                </Stack>
              </ListItemButton>
            </List>
          </FadedPaper>

          <Typography
            variant="body2"
            sx={{ ml: "16px", mb: "4px", textTransform: "uppercase" }}
          >
            XRAI {t("Social")}
          </Typography>

          <FadedPaper sx={{ marginBottom: 3, padding: 0 }}>
            <List sx={{ padding: 0 }}>
              {SOCIAL.map((link, index) => {
                return (
                  <div key={index}>
                    <ListItemButton
                      sx={{ py: "12px" }}
                      key={index}
                      onClick={() => {
                        openLink(link.href);
                      }}
                    >
                      <Stack
                        alignItems="center"
                        spacing={2}
                        sx={{ width: "100%" }}
                        direction="row"
                      >
                        {link.icon}
                        <Typography
                          sx={{
                            fontWeight: 500,
                            width: "100%",
                            lineHeight: "125%",
                          }}
                        >
                          {link.label}
                        </Typography>
                        <ChevronRight style={{ opacity: 0.3 }} />
                      </Stack>
                    </ListItemButton>
                    {index < SOCIAL.length - 1 && <Divider />}
                  </div>
                );
              })}
            </List>
          </FadedPaper>

          <FadedPaper sx={{ marginBottom: 3, padding: 0 }}>
            <List sx={{ padding: 0 }}>
              <ListItemButton sx={{ py: 2 }} onClick={confirmAccountCancel}>
                <Stack
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%" }}
                  direction="row"
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: theme.palette.error.main,
                      width: "100%",
                      lineHeight: "125%",
                    }}
                  >
                    {t("CancelAccount.cancel_account")}
                  </Typography>
                  <ChevronRight style={{ opacity: 0.3 }} />
                </Stack>
              </ListItemButton>
            </List>
          </FadedPaper>
        </div>
      </Container>

      <Container sx={{ display: "flex", flexDirection: "column" }}>
        <Filler />

        <Stack sx={{ p: 3, textAlign: "center" }}>
          <Typography
            variant="body2"
            component="div"
            style={{ marginBottom: 3 }}
            onClick={() => browser.open("https://give.ucdavis.edu/VCSA/125342")}
          ></Typography>

          <Typography variant="body2">
            &copy; Copyright 2022 - {new Date().getFullYear()}. All Rights
            Reserved.
            <br />
            XRAI Ltd &amp; XRAI Inc
          </Typography>
        </Stack>

        {Confirmation.Dialog}
      </Container>
    </DefaultLayout>
  );
};

export default ProfileScreen;
