/**
 * Group an Array
 * This will take an array, and the key to group on. It will then
 * will return a map of keys, with an array of the items.
 */
export function groupArray<T extends Record<string, unknown>>(arr: T[], key: keyof T): Record<string, T[]> {
  const obj: Record<string, T[]> = {};
  for (const item of arr) {
    const group = item[key] as string;
    if (!obj[group]) {
      obj[group] = [];
    }
    obj[group].push(item);
  }
  return obj;
}