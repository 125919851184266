import React from "react";
import { useTranslation } from "react-i18next";

interface ThtmlProps {
  tag?: string;
}

export const Thtml = ({tag}:ThtmlProps) => {
  const { t } = useTranslation();
  return (
    <>
      <span dangerouslySetInnerHTML={{__html:t(tag)}}/>
    </>
  );
};
