import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingContainer } from "themes/theme";
import {  Typography } from "@mui/material";
import DefaultLayout from "comps/layout/DefaultLayout";

const Privacy = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);

  let html: string = `
      <div>
      <span><h3><span style="">LAST UPDATED: November 2022</span></h3>
<p><span style="font-size: 18px; ">XRAI Glass is a companion software application (“<strong>XRAI Glass</strong>”), which pairs with Xreal Air Augmented Reality Glasses manufactured by Xreal Technology Limited and distributed by EE Limited (the “<strong>Device</strong>”), designed for use by hearing impaired individuals to transcribe and translate audio recordings into plain text and overlay captioned text boxes on to users’ live visual interactions with other individuals.</span></p>
<p><span style="font-size: 18px; ">For users subscribing to XRAI Glass’ premium tier (or a trial of the premium tier), Audio Content will be shared with third-party cloud services providers to deliver transcription, translation and personal assistant functionalities. Otherwise, all Audio Content is processed locally on a user’s mobile device for the period in which XRAI Glass is open (the “<strong>Session</strong>”). Once a Session has ended, all Audio Content is automatically deleted from a user’s mobile device. </span></p>
<p><span style="font-size: 18px; ">XRAI Limited, a company registered in England and Wales under company number 14164476, having its registered office at 71-75, Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ (the “<strong>Company</strong>,” “<strong>we</strong>,” or “<strong>us</strong>”) wants you to be familiar with how we collect, use and disclose Personal Information. </span></p>
<p><span style="font-size: 18px; ">“<strong>Personal Information</strong>” is information that identifies you as an individual or relates to an identifiable individual. This Privacy Notice describes our processing practices of <u>Personal Information</u>&nbsp;that we collect and use in connection with:</span></p>
<ul>
<li style="margin-top: 6pt; margin-right: 0cm; margin-bottom: 12pt;"><span style="font-size: 18px; "> websites operated by us from which you are accessing this Privacy Notice (the “<strong>Websites</strong>”); </span></li>
<li style="margin-top: 6pt; margin-right: 0cm; margin-bottom: 12pt;"><span style="font-size: 18px; "> software applications made available by us for use on or through computers and mobile devices (the “<strong>Apps</strong>”); </span></li>
<li style="margin-top: 6pt; margin-right: 0cm; margin-bottom: 12pt;"><span style="font-size: 18px; "> our social media pages from which you are accessing this Privacy Notice (collectively, our “<strong>Social Media Pages</strong>”); and </span></li>
<li style="margin-top: 6pt; margin-right: 0cm; margin-bottom: 12pt;"><span style="font-size: 18px; "> e-mail messages that we send to you or other communications with you. </span></li>
</ul>
<p><span style="font-size: 18px; ">Collectively, we refer to the Websites, Apps, Social Media Pages, and emails as the “<strong>Platforms</strong>”.</span></p>
<p style="margin-top: 3pt; margin-right: 0cm; margin-bottom: 3pt; padding-left: 1cm;"><span style="font-size: 18px; ">[1] &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Xreal Technology Limited is a company registered in the People’s Republic of China, having its principal office at 15/F, A2 Avic Plaza, Haidian District, Beijing, China, 100098.</span></p>
<p style="margin-top: 3pt; margin-right: 0cm; margin-bottom: 3pt; padding-left: 1cm;"><span style="font-size: 18px; ">[1] &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; EE Limited is a company registered in England and Wales under company number 02382161, having its registered office at Trident Place, Mosquito Way, Hatfield, Hertfordshire, United Kingdom, AL10 9BW.</span></p>
<p><span style="">.</span></p>
<h3><span style=""><strong>COLLECTION AND PROCESSING OF PERSONAL INFORMATION</strong></span></h3>
<p><span style="font-size: 18px; "><strong>How we collect Personal Information</strong></span></p>
<p style="font-size: 18px;"><span style="font-size: 18px; ">We collect <u>Personal Information</u><em>&nbsp;</em>in a variety of ways, including through our Platforms and from other sources, as set out in the grids below.</span></p>
<p style="text-align: justify; margin-top: 6pt; margin-right: 0cm; margin-bottom: 12pt; padding-left: 0cm; font-size: 18px;"><span style="font-size: 18px; ">We need to collect certain Personal Information in order to provide the requested Platforms to you. If you do not provide the information requested, we may not be able to provide the Platforms. We will note which Personal Information is required to provide the Platforms at the time of its collection.</span></p>
<p style="text-align: justify; margin-top: 6pt; margin-right: 0cm; margin-bottom: 12pt; padding-left: 0cm; font-size: 18px;"><span style="font-size: 18px; ">If you disclose any Personal Information relating to other people to us, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Notice.</span></p>
<p style="text-align: justify; margin-top: 6pt; margin-right: 0cm; margin-bottom: 12pt; padding-left: 0cm; font-size: 18px;"><span style="font-size: 18px; "><strong>Processing of Personal Information </strong></span></p>
<p style="text-align: justify; margin-top: 6pt; margin-right: 0cm; margin-bottom: 12pt; padding-left: 0cm; font-size: 18px;"><span style="font-size: 18px; ">We use your Personal Information for legitimate business purposes, as described in the overview below.</span></p>
<p><span style=""><strong>Making our&nbsp;</strong><strong>Platforms</strong>&nbsp;<strong>available to users</strong></span></p>
<table width="1200" style="border-collapse: collapse; table-layout: fixed; margin-left: auto; margin-right: auto; border: 1px solid #99acc2; height: 2151.25px; width: 1211px;" height="4804">
<thead>
<tr style="height: 163px;">
<td style="width: 177px; height: 163px;">
<p><span style=""><strong>Purpose</strong></span></p>
</td>
<td style="width: 379px; height: 163px;">
<p><span style=""><strong>Examples of Processing Activities</strong></span></p>
</td>
<td style="width: 282px; height: 163px;">
<p><span style=""><strong><u>Personal Information Categories</u></strong>&nbsp;</span></p>
</td>
<td style="width: 198px; height: 163px;">
<p><span style=""><strong>Legal Basis</strong></span></p>
</td>
<td style="width: 175px; height: 163px;">
<p><span style=""><strong>Third Party Sources</strong></span></p>
</td>
</tr>
</thead>
<tbody>
<tr style="height: 264px;">
<td style="width: 177px; height: 264px;">
<p><span style="">Providing the functionality of the Platforms</span></p>
</td>
<td>
<p><span style="">Provide the functionality of the Platforms, such as arranging access to a user’s online account; facilitating a user’s purchases; verifying a user’s information; and processing a user’s subscription.</span></p>
</td>
<td>
<p><span style="">Name &amp; Contact Details; Account Information; Preferences; Relationship History; Transaction Information; Device Information; and Social Media Information.</span></p>
</td>
<td>
<p><span style="">Performance of the contract, including the End User License Agreement that we enter into with users to provide the Platforms.</span></p>
</td>
<td style="width: 175px; height: 264px;">
<p><span style="">Single-sign-on service providers.</span></p>
</td>
</tr>
<tr style="height: 541px;">
<td style="width: 177px; height: 541px;">
<p><span style="">User-care service</span></p>
</td>
<td>
<p><span style="">Administering user-care services to facilitate and address inquiries, requests, comments and complaints about any of our Platforms (such as by phone, email, or on social media), for example, to send users documents or information users request, or to assist users in using the Platforms.</span></p>
</td>
<td>
<p><span style="">Name &amp; Contact Details; Account Information; Relationship History; Transaction Information; Device Information; and Preferences.</span></p>
</td>
<td>
<p><span style="">Performance of the End User License Agreement that we enter into with users to provide the Platforms. </span></p>
<p><span style="">Legitimate interests, such as responding to inquiries or complaints.</span></p>
<p><span style="">Legal obligations*, such as when users submit a request to access their Personal Information.</span></p>
</td>
<td style="width: 175px; height: 541px;">
<p><span style="">N/A</span></p>
</td>
</tr>
<tr style="height: 717.062px;">
<td style="width: 177px; height: 717px;">
<p><span style="">Communicating important changes / Service messages</span></p>
</td>
<td>
<p><span style="">Send to you important information regarding our relationship with you, our Platforms, any changes to our terms, conditions, policies and procedures, and/or other administrative information. </span></p>
</td>
<td>
<p><span style="">Name &amp; Contact Details; Account Information; Preferences; Relationship History; Transaction Information; Device Information; and Social Media Information.</span></p>
</td>
<td width="102">
<p><span style="">Legitimate interests, such as to ensure our Platforms are used in accordance with our terms, conditions, and policies. </span></p>
<p><span style="">Performance of contract, where provided in our End User License Agreement.</span></p>
<p><span style="">Legal obligations*, such as to inform users of material changes to our End User License Agreement to comply with applicable consumer and/or data protection laws.</span></p>
</td>
<td style="width: 175px; height: 717px;">
<p><span style="">N/A</span></p>
</td>
</tr>
<tr style="height: 465.188px;">
<td style="width: 177px; height: 465px;">
<p><span style="">Operations and general business</span></p>
</td>
<td>
<p><span style="">Administering online Platforms (including troubleshooting and diagnostic testing, conducting performance analyses of our systems and Platforms, testing new system features to evaluate their impact, system and log maintenance, technical support, system debugging, and the hosting of data); employee training and managing work activities and personnel generally; and facilitating mergers, acquisitions and other reorganizations and restructurings of our business (including prospective transactions).** </span></p>
</td>
<td>
<p><span style="">Personal Information as relevant for the specific business operation.</span></p>
</td>
<td>
<p><span style="">Legitimate interests, such as responding to user complaints and concerns.</span></p>
<p><span style="">Legal obligations*, for example, relating to financial transactions, such as the obligation to maintain books and records.</span></p>
</td>
<td style="border: 1pt solid windowtext; width: 175px; padding: 4px;" width="90">
<p><span style="">Third party organizations, when they share Personal Information with us to, for example, facilitate mergers, acquisitions and other reorganization and restructurings of our business. </span></p>
</td>
</tr>
</tbody>
</table>
<p><span style=""><strong>Marketing and user engagement</strong></span></p>
<table width="1200" style="border-collapse: collapse; table-layout: fixed; margin-left: auto; margin-right: auto; border: 1px solid #99acc2; height: 818px; width: 1197px;" height="1492">
<thead>
<tr style="height: 137.778px;">
<td style="width: 172px; height: 138px;">
<p><span style=""><strong>Purpose</strong></span></p>
</td>
<td style="width: 342px; height: 138px;">
<p><span style=""><strong>Examples of Processing Activities</strong></span></p>
</td>
<td style="width: 261px; height: 138px;">
<p><span style=""><strong><u>Personal Information Categories</u></strong><strong>&nbsp;</strong></span></p>
</td>
<td style="width: 310px; height: 138px;">
<p><span style=""><strong>Legal Basis</strong></span></p>
</td>
<td style="width: 112px; height: 138px;">
<p><span style=""><strong>Third Party Sources</strong></span></p>
</td>
</tr>
</thead>
<tbody>
<tr style="height: 440px;">
<td style="width: 172px; height: 440px;">
<p><span style="">Marketing</span></p>
</td>
<td style="width: 342px; height: 440px;">
<p><span style="">Send you promotional information about our Platforms, newsletters, promotions, offers and other news about our Company.</span></p>
</td>
<td style="width: 261px; height: 440px;">
<p><span style="">Name &amp; Contact Details; Account Information; Relationship History; Transaction Information; Preferences; Marketing Data; End-User Content; Device Information; and Social Media Information.</span></p>
</td>
<td style="width: 310px; height: 440px;">
<p><span style="">Legitimate interests, such as to promote our Platforms.</span></p>
<p><span style="">Consent, for example, where we would like to send you direct SMS and email marketing communications, but do not have an existing relationship with you, we will ask for and rely on your prior opt-in consent.</span></p>
</td>
<td style="width: 112px; height: 440px;">
<p><span style="">Third party organizations, for example, the Royal National Institute for Deaf People or other charities.</span></p>
</td>
</tr>
<tr style="height: 239px;">
<td style="width: 172px; height: 239px;">
<p><span style="">Relationship building and engagement</span></p>
</td>
<td style="width: 342px; height: 239px;">
<p><span style="">Facilitate and respond to any reviews, social sharing and posts on our Platforms.</span></p>
</td>
<td style="width: 261px; height: 239px;">
<p><span style="">Name &amp; Contact Details; Account Information; Marketing Data; Social Media Information; and End-User Content.</span></p>
</td>
<td style="width: 310px; height: 239px;">
<p><span style="">Legitimate interests, such as engaging with individuals who post on our Social Media Pages.</span></p>
</td>
<td style="width: 112px; height: 239px;">
<p><span style="">N/A</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style=""><strong>Personalization and improving our Platforms</strong></span></p>
<table width="1200" style="border-collapse: collapse; table-layout: fixed; margin: 25px auto; border: 1px solid #99acc2; height: 1407.64px; width: 1197px;" height="3489">
<thead>
<tr style="height: 142px;">
<td style="width: 172px; height: 142px; padding: 20px;">
<p><span style=""><strong>Purpose</strong></span></p>
</td>
<td style="width: 343px; height: 142px; padding: 20px;">
<p><span style=""><strong>Examples of Processing Activities</strong></span></p>
</td>
<td style="width: 261px; height: 142px; padding: 20px;">
<p><span style=""><strong><u>Personal Information Categories</u></strong></a></span></p>
</td>
<td style="width: 304px; height: 142px; padding: 20px;">
<p><span style=""><strong>Legal Basis</strong></span></p>
</td>
<td style="width: 117px; height: 142px; padding: 20px;">
<p><span style=""><strong>Third Party Sources</strong></span></p>
</td>
</tr>
</thead>
<tbody>
<tr style="height: 423px;">
<td style="width: 172px; height: 423px; padding: 20px;">
<p><span style="">Personalizing our Platforms</span></p>
</td>
<td style="height: 423px; width: 343px;">
<p><span style="">Personalize our interactions with you and provide you with information and/or offers tailored to your interests, such as targeted advertising tailored to your interests, on our online Platforms; deliver content via our Platforms that we believe will be relevant and interesting to you.</span></p>
<p><span style="">To carry out these purposes, we may use automated means, including profiling.</span></p>
</td>
<td style="height: 423px; width: 261px;">
<p><span style="">Name &amp; Contact Details; Account Information; Marketing Data; Social Media Information; Relationship History; Transaction Information; Device Information; and Preferences.</span></p>
</td>
<td style="height: 423px; width: 304px;">
<p><span style="">Consent, for example, where we would like to send you offers tailored to your specific interests, purchase history and/or behavior, and such tailoring of offers would result in extensive profiling.</span></p>
<span style="">Legitimate interests, such as providing tailored Platforms based on past usage and/or preferences, and such tailoring would be based on basic and privacy-non-intrusive segmentation.</span></td>
<td style="width: 117px; height: 423px; padding: 20px;">
<p><span style=" font-size: 18px;">N/A</span></p>
</td>
</tr>
<tr style="height: 616.472px;">
<td style="width: 172px; padding: 20px; height: 616px;">
<p><span style="font-size: 18px; ">Improving and developing new Platforms</span></p>
</td>
<td style="height: 616px; width: 343px;">
<p><span style="">Conduct data analysis, for example, monitoring and analyzing usage of Platforms and using data analytics to improve the efficiency of our Platforms; develop new Platforms; consider ways for enhancing, improving, repairing, maintaining or modifying our current Platforms; identify usage trends, for example, understanding which parts of our Platforms are of most interest to users; determine the effectiveness of our promotional campaigns, so that we can adapt our campaigns to the needs and interests of our users; and operate and expand our business activities, for example, understanding which parts of our Platforms are of most interest to our users so we can focus our energies on meeting our users’ interests.</span></p>
</td>
<td style="height: 616px; width: 261px;">
<p><span style="">Name &amp; Contact Details; Account Information; Relationship History; Transaction Information; Preferences; End-User Content; Device Information; and Social Media Information.</span></p>
</td>
<td style="width: 304px; padding: 20px; height: 616px;">
<p><span style="">Legitimate interests, such as developing new Platforms.</span></p>
<p><span style="">Consent, such as when we use cookies and similar technologies and the data collected by means of such technologies qualify as Personal Information.</span></p>
</td>
<td style="width: 117px; padding: 20px; height: 616px;">
<p><span style=" font-size: 18px;">N/A</span></p>
</td>
</tr>
<tr style="height: 225px;">
<td style="width: 172px; height: 225px; padding: 20px;">
<p><span style="font-size: 18px; ">Aggregating and/or anonymizing Personal Information</span></p>
</td>
<td style="width: 343px; height: 225px; padding: 20px;">
<p><span style="font-size: 18px; ">Aggregate and/or anonymize Personal Information so that it will no longer be considered Personal Information.</span></p>
</td>
<td style="border: 1pt solid windowtext; width: 261px; padding: 4px; height: 225px;">
<p style="margin-top: 3pt; margin-right: 0cm; margin-bottom: 3pt; padding-left: 0cm;"><span style="font-size: 18px; ">Personal Information as relevant for the specific business purpose.</span></p>
</td>
<td style="border: 1pt solid windowtext; width: 304px; padding: 4px; height: 225px;">
<p style="margin-top: 3pt; margin-right: 0cm; margin-bottom: 3pt; padding-left: 0cm;"><span style="font-size: 18px; ">Legitimate interests, such as to generate other data for our use, which we may use and disclose for any purpose, as it no longer identifies you or any other individual.</span></p>
</td>
<td style="width: 117px; height: 225px; padding: 20px;"><span style="font-size: 18px; ">N/A</span></td>
</tr>
</tbody>
</table>
<p><span style=""><strong>Security and legal reasons</strong></span></p>
<table style="border-collapse: collapse; table-layout: fixed; margin-left: auto; margin-right: auto; border: 1px solid #99acc2; height: 994px; width: 1197px;" width="1200" height="2146">
<thead>
<tr style="height: 163px;">
<td style="width: 172px; height: 163px;">
<p><span style=""><strong>Purpose</strong></span></p>
</td>
<td style="width: 311px; height: 163px;">
<p><span style=""><strong>Examples of Processing Activities</strong></span></p>
</td>
<td style="width: 265px; height: 163px;">
<p><span style=""><strong><u>Personal Information Categories</u></strong><strong>&nbsp;</strong></span></p>
</td>
<td style="width: 253px; height: 163px;">
<p><span style=""><strong>Legal Basis</strong></span></p>
</td>
<td style="width: 196px; height: 163px;">
<p><span style=""><strong>Third Party Sources</strong></span></p>
</td>
</tr>
</thead>
<tbody>
<tr style="height: 264px;">
<td style="width: 172px; height: 264px;">
<p><span style="">Fraud prevention and security</span></p>
</td>
<td style="width: 311px;" width="194">
<p><span style="">Conduct audits, verify that our internal processes function as intended and are compliant with legal, regulatory or contractual requirements; monitor for and prevent fraud; and security purposes, including system security.</span></p>
</td>
<td style="width: 265px;">
<p><span style="">Name &amp; Contact Details; Account Information; Device Information; Relationship History; and Transaction Information.</span></p>
</td>
<td style="width: 253px;">
<p><span style="">Legal obligations*, such as to detect and prevent cyberattacks.</span></p>
<p><span style="">Legitimate interests, such as identifying and/or preventing fraudulent transactions.</span></p>
</td>
<td style="width: 196px; height: 264px;">
<p><span style="">Single-sign-on service providers.</span></p>
</td>
</tr>
<tr style="height: 565.938px;">
<td style="width: 172px; height: 566px;">
<p><span style="">Legal and compliance</span></p>
</td>
<td style="width: 311px; height: 566px;">
<p><span style="">Fulfil our legal and compliance-related obligations [hyperlink to ‘Other Disclosures’], including complying with applicable laws; complying with legal processes; responding to requests from public and government authorities; meeting national security or law enforcement requirements.</span></p>
<p><span style="">Enforcing our terms and conditions; protecting our operations; protecting the rights, privacy, or property of the Company; and allowing us to pursue available legal remedies, defend claims and limit the damages that the Company may sustain.</span></p>
</td>
<td style="width: 265px;" width="142">
<p><span style="">Personal Information as relevant for the specific legal action, regulatory investigation, and/or legal processes in question, which may include:</span></p>
<p><span style="">Name &amp; Contact Details; Account Information; End-User Content; Preferences; Marketing Data; Relationship History; Transaction Information; Social Media Information; and Device Information.</span></p>
</td>
<td style="width: 253px;" width="113">
<p><span style="">Legal obligations*, such as complying with legal processes.</span></p>
<p><span style="">Legitimate interests, such as enforcing terms and conditions to protect trademarks and bringing or defending legal claims.</span></p>
</td>
<td style="border: 1pt solid windowtext; width: 196px; padding: 4px;" width="85">
<p><span style="">Public and/or government and/or regulatory authorities, including courts, tribunals, regulators and government authorities.</span></p>
<p><span style="">Third persons (legal or natural), as relevant for the specific legal action and/or processes in question (such as lawyers, auditors, insurers, advisory firms etc.).</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style="">*&nbsp; &nbsp; &nbsp;For more information on our legal obligations, please see the section ‘<strong><em>Other Disclosures</em>’</strong>&nbsp;below.</span></p>
<p><span style="">**&nbsp; &nbsp;For more information on the disclosure of Personal Information in connection with a sale or business transaction, please see ‘<strong><em>Other Disclosures</em></strong>’ below.</span></p>
<p style="font-weight: bold;"><span style="">Information we process on behalf of others</span></p>
<p><span style="">To the extent necessary to provide our services, we process Session Information and/or Audio Content on your behalf that is captured by you when using our Platforms and/or the Device.</span></p>
<p><span style="">Privacy, data protection and surveillance laws in your jurisdiction may apply to your use of our Platforms and/or the Device (including relating to the recording or sharing of Session Information and/or Audio Content). Capturing, recording or sharing Session Information and/or Audio Content that involves other people may affect their privacy rights. You are solely responsible for ensuring that you comply with any privacy, data protection, surveillance, or other applicable laws when you use our Platforms and/or the Device. For example, you may need to provide notice, obtain consents and/or process any rights requests from third parties with respect to your use of the Platforms and/or the Device, such as other individuals who interact with you while you are using our Platforms and/or the Device. </span></p>
<a id="personal" data-hs-anchor="true"></a>
<h3><span style=""><strong>Personal Information</strong></span></h3>
<p><span style="">&nbsp;We collect the following categories of Personal Information:</span></p>
<p><span style=""><u><br></u><u></u>A) <span style="text-decoration: underline;">Personal Information that we receive from you:</span></span></p>
<table style="border-collapse: collapse; table-layout: fixed; margin-left: auto; margin-right: auto; border: 1px solid #99acc2; height: 811.5px;" width="1200" height="1082">
<tbody>
<tr style="height: 87.375px;">
<td style="width: 322.766px; height: 87px; padding: 20px;">
<p><span style=""><strong>Name &amp; Contact Details</strong></span></p>
</td>
<td style="width: 876.234px; height: 87px; padding: 20px;">
<p><span style="">Such as first and last name, telephone number, and email address.</span></p>
</td>
</tr>
<tr style="height: 87.375px;">
<td style="width: 322.766px; height: 87px; padding: 20px;">
<p><span style=""><strong>Account Information</strong></span></p>
</td>
<td style="width: 876.234px; height: 87px; padding: 20px;">
<p><span style="">Such as your chosen username, and other information you share in your account.</span></p>
</td>
</tr>
<tr style="height: 87.375px;">
<td style="width: 322.766px; height: 87px; padding: 20px;">
<p><span style=""><strong>End-User Content</strong></span></p>
</td>
<td style="width: 876.234px; height: 87px; padding: 20px;">
<p><span style="">Such as reviews about Platforms, posts on our Social Media Pages, blogs, and comment sections.</span></p>
</td>
</tr>
<tr style="height: 113px;">
<td style="width: 322.766px; height: 113px; padding: 20px;">
<p><span style=""><strong>Preferences</strong></span></p>
</td>
<td style="width: 876.234px; height: 113px; padding: 20px;">
<p><span style="">Such as language, interests, and other user feedback/preferences that you might express during your use of our Platforms.</span></p>
</td>
</tr>
<tr style="height: 113px;">
<td style="width: 322.766px; height: 113px; padding: 20px;">
<p><span style=""><strong>Marketing Data</strong></span></p>
</td>
<td style="width: 876.234px; height: 113px; padding: 20px;">
<p><span style="">Such as your choices regarding our newsletters, surveys, and other marketing/advertising displayed or provided to you, and preferred methods of such promotional communication.</span></p>
</td>
</tr>
<tr style="height: 113px;">
<td style="width: 322.766px; height: 113px; padding: 20px;">
<p><span style=""><strong>Relationship History</strong></span></p>
</td>
<td style="width: 876.234px; height: 113px; padding: 20px;">
<p><span style="">Such as details of your communications with us, and details of your claims, complaints and queries in general.</span></p>
</td>
</tr>
<tr style="height: 87.375px;">
<td style="width: 322.766px; height: 87px; padding: 20px;">
<p><span style=""><strong>Transaction Information</strong></span></p>
</td>
<td style="width: 876.234px; height: 87px; padding: 20px;">
<p><span style="">Such as details of Platforms you have purchased from us.</span></p>
</td>
</tr>
<tr style="height: 122px;">
<td style="width: 322.766px; height: 122px; padding: 20px;">
<p><span style=""><strong>End-User Contacts</strong></span></p>
</td>
<td style="width: 876.234px; height: 122px; padding: 20px;">
<p><span style="">Such as first and last name, telephone number, and email address of contact records of individuals</span></p>
</td>
</tr>
<tr>
<td style="width: 322.766px; padding: 20px;">
<p><span style=""><strong>Session Information</strong></span></p>
</td>
<td style="width: 876.234px; padding: 20px;">
<p><span style="">Such as the output of transcriptions.</span></p>
</td>
</tr>
<tr>
<td style="width: 322.766px; padding: 4px;">
<p><span style=""><span style=""><strong>&nbsp; &nbsp; Audio Content</strong></span></span></p>
</td>
<td style="width: 876.234px; padding: 4px;">
<p><span style="">&nbsp; &nbsp;Such as audio recordings that are captured by users when using our Platforms and/or the Device.</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style="">&nbsp; B) <span style="text-decoration: underline;"><span style="font-weight: normal;">Personal Information that we collect through your use of our Platforms or from other sources:</span></span></span></p>
<table style="border-collapse: collapse; table-layout: fixed; margin-left: auto; margin-right: auto; border: 1px solid #99acc2; height: 455.167px;" width="1200" height="441">
<tbody>
<tr style="height: 113px;">
<td style="width: 329.264px; height: 113px;">
<p><span style=""><strong>Social Media Information</strong></span></p>
</td>
<td style="width: 869.736px; height: 113px;">
<p><span style="">Such as profile pictures, social media account ID, and other social media profile information, including lists of friends/followers on social media.</span></p>
</td>
</tr>
<tr style="height: 163px;">
<td style="width: 329.264px; height: 163px;">
<p><span style=""><strong>Device Information</strong></span></p>
</td>
<td style="width: 869.736px; height: 163px;">
<p><span style="">Such as information about your devices and your use of our Platforms, including how you interact with the Platforms and information about the device itself, such as [the hardware model, operating system, IP addresses and browser type]. This includes data obtained through cookies and similar technologies, as described in our cookies and similar technologies policy.</span></p>
</td>
</tr>
<tr style="height: 59.3889px;">
<td style="width: 329.264px; padding: 4px; height: 59px;">
<p><span style=""><strong>&nbsp; Session Information</strong></span></p>
</td>
<td style="width: 869.736px; padding: 4px; height: 59px;">
<p><span style="">&nbsp; Such as the output of transcriptions.</span></p>
</td>
</tr>
<tr style="height: 59.3889px;">
<td style="width: 329.264px; padding: 4px; height: 59px;">
<p><span style=""><strong>&nbsp;&nbsp;</strong><span style="font-weight: bold;">Audio Content</span></span></p>
</td>
<td style="width: 869.736px; padding: 4px; height: 59px;">
<p><span style="">&nbsp; Such as audio recordings that are captured by users when using our Platforms and/or the Device.</span></p>
</td>
</tr>
<tr style="height: 59.3889px;">
<td style="width: 329.264px; padding: 4px; height: 59px;" colspan="2">
<p><span style=""><strong>&nbsp;</strong></span></p>
<p style="font-weight: bold;"><span style="">Disclosure of Personal Information</span></p>
<p><span style="">We disclose Personal Information for the purposes described in the table below:</span></p>
</td>
</tr>
</tbody>
</table>
<table style="border-collapse: collapse; table-layout: fixed; margin-left: auto; margin-right: auto; border: 1px solid #99acc2; height: 2413.67px;" width="1200" height="2546">
<tbody>
<tr style="height: 87.3889px;">
<td style="width: 391.958px; height: 87px;">
<p><span style=""><strong>Recipients</strong></span></p>
</td>
<td style="width: 807.042px; height: 87px;">
<p><span style=""><strong>Purpose</strong></span></p>
</td>
</tr>
<tr style="height: 188.167px;">
<td style="width: 391.958px; height: 188px;">
<p><span style="">Website hosting service providers</span></p>
</td>
<td style="width: 807.042px; height: 188px;">
<p><span style="">- Improving and developing new Platforms</span></p>
<p><span style="">- Operations and general business</span></p>
<p><span style="">- Providing the functionality of the Platforms</span></p>
</td>
</tr>
<tr style="height: 238.556px;">
<td style="width: 391.958px; height: 239px;">
<p><span style="">Information technology and related infrastructure service providers</span></p>
</td>
<td style="width: 807.042px; height: 239px;">
<p><span style="">- Fraud prevention and security</span></p>
<p><span style="">- Improving and developing new Platforms</span></p>
<p><span style="">- Operations and general business</span></p>
<p><span style="">- Providing the functionality of the Platforms</span></p>
</td>
</tr>
<tr style="height: 390px;">
<td style="width: 391.958px; height: 390px;">
<p><span style="">Email delivery service providers</span></p>
</td>
<td style="width: 807.042px; height: 390px;">
<p><span style="">- Communicating important changes</span></p>
<p><span style="">- User-care service</span></p>
<p><span style="">- Improving and developing new Platforms</span></p>
<p><span style="">- Marketing</span></p>
<p><span style="">- Personalizing our Platforms</span></p>
<p><span style="">- Providing the functionality of the Platforms</span></p>
<p><span style="">- Relationship building and engagement</span></p>
</td>
</tr>
<tr style="height: 239px;">
<td style="width: 391.958px; height: 239px;">
<p><span style="">Advertising networks</span></p>
</td>
<td style="width: 807.042px; height: 239px;">
<p><span style="">- Improving and developing new Platforms</span></p>
<p><span style="">- Marketing</span></p>
<p><span style="">- Personalizing our Platforms</span></p>
<p><span style="">- Relationship building and engagement</span></p>
</td>
</tr>
<tr style="height: 390px;">
<td style="width: 391.958px; height: 390px;">
<p><span style="">User-care service or related benefits (including special promotions) service providers</span></p>
</td>
<td style="width: 807.042px; height: 390px;">
<p><span style="">- Communicating important changes</span></p>
<p><span style="">- User-care service</span></p>
<p><span style="">- Improving and developing new Platforms</span></p>
<p><span style="">- Marketing</span></p>
<p><span style="">- Providing the functionality of the Platforms</span></p>
<p><span style="">- Personalizing our Platforms</span></p>
<p><span style="">- Relationship building and engagement</span></p>
</td>
</tr>
<tr style="height: 440.111px;">
<td style="width: 391.958px; height: 440px;">
<p><span style="">Analytics providers for our Platforms</span></p>
</td>
<td style="width: 807.042px; height: 440px;">
<p><span style="">- Aggregating and/or anonymizing Personal Information</span></p>
<p><span style="">- User-care service</span></p>
<p><span style="">- Fraud prevention and security</span></p>
<p><span style="">- Improving and developing new Platforms</span></p>
<p><span style="">- Marketing</span></p>
<p><span style="">- Operations and general business</span></p>
<p><span style="">- Personalizing our Platforms</span></p>
<p><span style="">- Relationship building and engagement</span></p>
</td>
</tr>
<tr style="height: 138px;">
<td style="width: 391.958px; height: 138px;">
<p><span style="">Law enforcement, public, regulatory and government authorities, courts or tribunals</span></p>
</td>
<td style="width: 807.042px; height: 138px;">
<p><span style="">- Fraud prevention and security</span></p>
<p><span style="">- Legal and Compliance</span></p>
</td>
</tr>
<tr style="height: 138px;">
<td style="width: 391.958px; height: 138px;">
<p><span style="">Auditing service providers</span></p>
</td>
<td style="width: 807.042px; height: 138px;">
<p><span style="">- Fraud prevention and security</span></p>
<p><span style="">- Legal and Compliance</span></p>
</td>
</tr>
<tr style="height: 163px;">
<td style="width: 391.958px; height: 163px;">
<p><span style="">Other services, including, without limitation, our Social Media Pages</span></p>
</td>
<td style="width: 807.042px; height: 163px;">
<p><span style="">- Individual user public interactions and communications, such as message boards, chat, profile pages, blogs and other services to which you choose to post information</span></p>
<p><span style="">- Social sharing activities</span></p>
</td>
</tr>
</tbody>
</table>
<p style="font-weight: bold;"><span style="">Connecting to the Platforms via single-sign-on service providers</span></p>
<p><span style="">When you sign up and log in to the Platforms through a third party application, such as your Google account, you authorize us to collect certain Personal Information for account authentication purposes. Upon sign up, Google will ask your permission to share certain information from your Google account with us. This includes [your name and email address]. This information is collected by Google and is provided to us under the terms of Google’s privacy policy. You can regulate the information that we receive from Google using your Google activity controls.</span></p>
<p style="font-weight: bold;"><span style="">Other Disclosures</span></p>
<p><span style="">We also disclose your Personal Information as necessary or appropriate, in particular when we have a legal obligation or legitimate interest to do so, as set out in further detail below. We may disclose your Personal Information to:</span></p>
<ul>
<li><span style=""><strong>Comply with applicable law and regulations</strong>.</span>
<ul>
<li><span style="">This may include laws outside your country of residence, which could give rise to a legal obligation requiring us to process your Personal Information, including when (i) we receive a court order to disclose information for the purposes of court proceedings, (ii) we are required to comply with requests and orders from law enforcement to provide information in relation to a criminal investigation, or to take steps to report information we believe is important to law enforcement where so required or advisable under applicable local laws, or (iii) we receive requests from competent authorities, such as data protection or consumer protection authorities.</span></li>
</ul>
</li>
<li><span style=""><strong> For dispute resolution purposes, and to protect our rights, privacy, safety or property, you or others.</strong></span></li>
<li><span style=""><strong> In connection with a sale or business transaction.</strong></span>
<ul>
<li><span style=""> We have a legitimate interest in disclosing or transferring your Personal Information to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any insolvency, bankruptcy or similar proceedings). You will be notified of any such business transaction and of possible changes to the processing of your Personal Information in accordance with applicable law and the ‘<strong>Updates To This Privacy Notice</strong>’ section. </span></li>
</ul>
</li>
</ul>
<h3 style="font-weight: bold;"><span style="">COOKIES AND SIMILAR TECHNOLOGIES</span></h3>
<p><span style="">We may collect personal information through the use of cookies and similar technologies. Please see our cookies and similar technologies policy for more information. </span></p>
<h3 style="font-weight: bold;"><span style="">SECURITY</span></h3>
<p><span style="">We seek to use reasonable organizational, technical and administrative measures to protect Personal Information within our organization. Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the “<span style="font-weight: bold;">Contacting Us</span>” section below.</span></p>
<h3 style="font-weight: bold;"><span style="">CHOICES AND INDIVIDUALS' RIGHTS</span></h3>
<p style="font-weight: bold;"><span style="">Your choices regarding our use and disclosure of your Personal Information</span></p>
<p><span style="">We give you choices regarding our use and disclosure of your Personal Information for marketing purposes. </span></p>
<p><span style="">You may opt out from receiving marketing-related communications from us. If you no longer want to receive marketing related communications from us on a going-forward basis, you may opt out by (i) adjusting your account Settings [hyperlink to XRAI Glass account settings] and adjusting your marketing preferences, (ii) following the unsubscribe opt-out links on any marketing communications sent to you, and/or (iii) contacting us at any time at privacy@xrai.glass.</span></p>
<p><span style="">We will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt out of receiving marketing from us, we may still send you important administrative messages, from which you cannot opt out.</span></p>
<p style="font-weight: bold;"><span style="">How you can exercise your rights </span></p>
<p><span style="">If you would like to request to access, correct, update, suppress, restrict, or delete Personal Information, object to or opt out of the processing of Personal Information, withdraw your consent (which will not affect the lawfulness of processing prior to the withdrawal), or if you would like to request to receive a copy of your Personal Information for purposes of transmitting it to another company (to the extent these rights are provided to you by applicable law), you may contact us in accordance with the “Contacting Us” section below. We will respond to your request consistent with applicable law. </span></p>
<p><span style="">In your request, please make clear what Personal Information you would like to have changed or whether you would like to have your Personal Information suppressed from our database. For your protection, we may only implement requests with respect to the Personal Information associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request. We will try to comply with your request as soon as reasonably practicable.</span></p>
<p><span style="">Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting a change or deletion (e.g., when you make a purchase, you may not be able to change or delete the Personal Information provided until after the completion of such purchase). </span></p>
<p><span style="">You may lodge a complaint with the UK’s Information Commissioner’s Office through this page.</span></p>
<h3 style="font-weight: bold;"><span style="">RETENTION PERIOD</span></h3>
<p><span style="">We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Notice unless a longer retention period is required or permitted by law, for example, for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. </span></p>
<p><span style="">The criteria used to determine our retention periods include (i) the length of time we have an ongoing relationship with you and provide the Platforms to you (for example, for as long as you have an account with us or keep using the Platforms); (ii) whether there is a legal obligation to which we are subject (for example, certain laws require us to keep records of your transactions for a certain period of time before we can delete them); or (iii) whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitations, litigation or regulatory investigations).</span></p>
<p><span style="">Where a legal obligation arises or retention is advisable in light of our legal position, in some circumstances, we will retain certain Personal Information, even after your account has been deleted and/or we no longer provide the Platforms to you; for example:</span></p>
<ul>
<li><span style=""><strong> To cooperate with law enforcement or public, regulatory and government authorities:</strong> If we receive a preservation order or search warrant related to your Platforms account, we will preserve Personal Information subject to such order or warrant after you delete your Platforms account.</span></li>
<li><span style=""><strong> To comply with legal provisions on tax and accounting</strong>: We may retain your Personal Information, such as Relationship History, and/or Transaction Information, for up to 7 years after you delete your Platforms account, as required by tax law and to comply with bookkeeping requirements. </span></li>
<li><span style=""><strong> To pursue or defend a legal action:</strong> We may retain relevant Personal Information in the event of a legal claim or complaint, including regulatory investigations or legal proceedings about a claim related to your Personal Information, or if we reasonably believe there is a prospect of litigation (whether in respect of our relationship with you or otherwise) after the dispute has been settled or decided by a court or tribunal from which there is no further right of appeal.</span></li>
</ul>
<h3 style="font-weight: bold;"><span style="">THIRD-PARTY SERVICES</span></h3>
<p><span style="">This Privacy Notice does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third party operating any website or service to which the Platforms link. The inclusion of a link on the Platforms does not imply endorsement of the linked site or service by us.</span></p>
<p><span style="">The Platforms may provide functionality allowing you to make payments to the Company using third-party payment services with which you have created your own account. When you use such a service to make a payment to us, your Personal Information will be collected by such third party and not by us and will be subject to the third party’s privacy notice rather than this Privacy Notice. We have no control over and are not responsible for this third party’s collection, use, and disclosure of your Personal Information.</span></p>
<p><span style="">In addition, we are not responsible for the information collection, use, disclosure, or security policies or practices of other organizations, such as Google, or any other app developer, app provider, social media platform provider, operating system provider, wireless service provider, or device manufacturer, including with respect to any Personal Information you disclose to other organizations through or in connection with the Apps or our Social Media Pages.</span></p>
<h3><span style="">USE OF THE PLATFORMS BY MINORS</span></h3>
<p><span style="">The Platforms are not directed to individuals under the age of thirteen (13), and we do not knowingly collect Personal Information from individuals under 13.</span></p>
<h3><span style="">JURISDICTION AND CROSS-BORDER TRANSFER</span></h3>
<p><span style="">Your Personal Information may be stored and processed in countries where we have facilities or in which we engage service providers [hyperlink to list of key services providers], and by using the Platforms, you understand that your Personal Information will be transferred to countries outside of your country of residence, including the United States and Germany, which may have data protection rules that are different from those of your country.</span></p>
<p><span style="">Where this will involve transferring your Personal Information outside the UK, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:</span></p>
<p style="padding-left: 40px;"><span style=""><span style="font-weight: bold;">Adequacy Decisions:</span> Some non-UK countries are recognized by the Secretary of State as providing an adequate level of data protection according to UK standards (the full list of these countries is available here).</span></p>
<p style="padding-left: 40px;"><span style=""><span style="font-weight: bold;">Standard Contractual Clauses:</span> If we transfer Personal Information from the UK to countries which are not considered adequate under the UK GDPR, we have put in place the UK’s international data transfer agreement for international data transfers (the “<span style="font-weight: bold;">UK IDTA</span>”) to protect your Personal Information. You may obtain a copy of the UK IDTA by contacting us in accordance with the “<span style="font-weight: bold;">Contacting Us</span>” section below or by following this link. “<span style="font-weight: bold;">UK GDPR</span>” has the meaning given to it in section 3(10) (as supplemented by section 205(4)) of the Data Protection Act 2018.</span></p>
<h3><span style="">SENSITIVE INFORMATION</span></h3>
<p><span style="">Unless we request it, we ask that you not send us, and you not disclose, any sensitive Personal Information (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background, or trade union membership) on or through the Platforms or otherwise to us.</span></p>
<h3><span style="">UPDATES TO THIS PRIVACY NOTICE</span></h3>
<p><span style="">The “Last Updated” legend at the top of this Privacy Notice indicates when this Privacy Notice was last revised. Any changes will become effective when we post the revised Privacy Notice on the Platforms. If we make a material change to this Privacy Notice, we may notify you of this change, and depending on the nature of the change, we may seek your consent to the change. </span></p>
<h3><span style="">CONTACTING US</span></h3>
<p><span style="">XRAI Limited, a company registered in England and Wales under company number 14164476, located at 71-75, Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ, is responsible for collection, use, and disclosure of your Personal Information under this Privacy Notice.</span></p>
<p><span style="">If you have any questions about this Privacy Notice, please contact us at privacy@xrai.glass.</span></p>
<p><span style="">Because email communications are not always secure, please do not include credit card or other sensitive information in your emails to us.</span></p>
</div>
`;
  return (
    <DefaultLayout pageTitle="Onboard Privacy" backLabel={t('Back')} menuItems={<></>} backNavigate={-1}>
      <OnboardingContainer>
        <Typography variant="h1" component="h1" gutterBottom>
          XRAI Glass Privacy Policy
        </Typography>
        <Typography variant="body1" className="privacy-terms" component="div">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Typography>
      </OnboardingContainer>
    </DefaultLayout>
  );
};

export default Privacy;
