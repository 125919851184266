import { useEffect, useCallback } from "react";
import { useStoreLocal, useStoreUnity } from "state/store";
import { GuidedTourSteps } from "types/types";

const useGuidedTour = () => {
  const [guidedTour, setGuidedTour] = useStoreLocal<{
    [key: string]: { show: boolean; interacted: boolean };
  }>("guidedTour", {});

  const [historySeqNum] = useStoreUnity<number>("SessionHistorySeqNum", 0);

  const displayStep = (key: string) => {
    return (guidedTour?.[key]?.show && !guidedTour?.[key]?.interacted) ?? false;
  };

  const setGuidedTourInteracted = useCallback(
    (key: string, nextStep: string) => {
      if (nextStep === null) {
        setGuidedTour({
          ...guidedTour,
          [key]: {
            show: false,
            interacted: true,
          },
        });

        return;
      }

      setGuidedTour({
        ...guidedTour,
        [key]: {
          show: false,
          interacted: true,
        },
        [nextStep]: {
          show: true,
          interacted: false,
        },
      });
    },
    [guidedTour, setGuidedTour]
  );

  useEffect(() => {
    const setGuidedTourShow = (key: string) => {
      setGuidedTour({
        ...guidedTour,
        [key]: {
          show: true,
          interacted: false,
        },
      });
    };

    // Start the guided tour if there are no conversations and the user has not already interacted with the button.
    if (!guidedTour?.[GuidedTourSteps.startButton]?.interacted) {
      if (historySeqNum === 1) {
        setGuidedTourShow(GuidedTourSteps.startButton);
      }
    }
  }, [guidedTour, setGuidedTour, historySeqNum]);

  return {
    guidedTour,
    displayStep,
    setGuidedTourInteracted,
  };
};

export default useGuidedTour;
