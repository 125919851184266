export type HeyXraiResponses = "asked" | "answered" | "error" | undefined;
let status:HeyXraiResponses = undefined;
let listeners:Array<any> = [];

export const heyXraiStore = {
  set(_status:HeyXraiResponses) {
    status = _status;
    emitChange();
  },
  get() {
    return status;
  },
  subscribe(listener:any) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  }
}

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}
