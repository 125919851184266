import React from "react";
import { Icon } from "@mui/material";
import {
  BatteryChargingFull,
  BatteryCharging90,
  BatteryCharging80,
  BatteryCharging60,
  BatteryCharging50,
  BatteryCharging30,
  BatteryCharging20,
  BatteryFull,
  Battery90,
  Battery80,
  Battery60,
  Battery50,
  Battery30,
  Battery20,
} from "@mui/icons-material";

type Props = {
  state?: string;
  level?: number;
}

function BatteryIcon(props: Props) {
  return !props.state || !props.level ? (
    <Icon />
  ) : props.state === "Charging" ? (
    props.level > 0.95 ? (
      <BatteryChargingFull />
    ) : props.level > 0.85 ? (
      <BatteryCharging90 />
    ) : props.level > 0.75 ? (
      <BatteryCharging80 />
    ) : props.level > 0.55 ? (
      <BatteryCharging60 />
    ) : props.level > 0.45 ? (
      <BatteryCharging50 />
    ) : props.level > 0.25 ? (
      <BatteryCharging30 />
    ) : (
      <BatteryCharging20 />
    )
  ) : props.level > 0.95 ? (
    <BatteryFull />
  ) : props.level > 0.85 ? (
    <Battery90 />
  ) : props.level > 0.75 ? (
    <Battery80 />
  ) : props.level > 0.55 ? (
    <Battery60 />
  ) : props.level > 0.45 ? (
    <Battery50 />
  ) : props.level > 0.25 ? (
    <Battery30 />
  ) : (
    <Battery20 />
  );
}

export default BatteryIcon;
