import { PaletteMode, createTheme, Palette } from "@mui/material";

const neutralPalette = (mode: PaletteMode, screenCast: boolean): Palette =>
  createTheme({
    palette: {
      name: "xrai-neutral-palette",
      screenCast,
      mode,
      ...(mode === "light" && !screenCast
        ? {
            panel: {
              main: "#888888",
            },
            primary: {
              contrastText: "#121212",
              dark: "rgb(95, 95, 95)",
              light: "rgb(159, 159, 159)",
              main: "#888888",
            },
            secondary: {
              contrastText: "#F5F5F5",
              dark: "rgb(95, 95, 95)",
              light: "rgb(159, 159, 159)",
              main: "#888888",
            },
            background: {
              default: "#e0e0e0",
              paper: "#e0e0e0",
            },
            error: {
              contrastText: "#F5F5F5",
              dark: "#c62828",
              light: "#ef5350",
              main: "#d32f2f",
            },
            warning: {
              contrastText: "#F5F5F5",
              dark: "#e65100",
              light: "#ff9800",
              main: "#ed6c02",
            },
            info: {
              contrastText: "#F5F5F5",
              dark: "#01579b",
              light: "#03a9f4",
              main: "#0288d1",
            },
            success: {
              contrastText: "#F5F5F5",
              dark: "#1b5e20",
              light: "#4caf50",
              main: "#2e7d32",
            },
            tonalOffset: 0.2,
            contrastThreshold: 3,
            common: {
              black: "#000000",
              white: "#F5F5F5",
            },
            grey: {
              50: "#fafafa",
              100: "#f5f5f5",
              200: "#eeeeee",
              300: "#e0e0e0",
              400: "#bdbdbd",
              500: "#9e9e9e",
              600: "#757575",
              700: "#616161",
              800: "#424242",
              900: "#212121",
              A100: "#f5f5f5",
              A200: "#eeeeee",
              A400: "#bdbdbd",
              A700: "#616161",
            },
            text: {
              disabled: "rgba(0, 0, 0, 0.38)",
              primary: "rgba(0, 0, 0, 0.87)",
              secondary: "rgba(0, 0, 0, 0.6)",
            },
            divider: "rgba(0, 0, 0, 0.12)",
            action: {
              activatedOpacity: 0.12,
              active: "rgba(0, 0, 0, 0.54)",
              disabled: "rgba(0, 0, 0, 0.26)",
              disabledBackground: "rgba(0, 0, 0, 0.12)",
              disabledOpacity: 0.38,
              focus: "rgba(0, 0, 0, 0.25)",
              focusOpacity: 0.25,
              hover: "rgba(0, 0, 0, 0.04)",
              hoverOpacity: 0.04,
              selected: "rgba(0, 0, 0, 0.15)",
              selectedOpacity: 0.15,
            },
          }
        : // DARK MODE PALETTE
          {
            panel: {
              main: screenCast ? "#000000" : "#555555",
            },
            primary: {
              main: "#555555",
              dark: "rgb(59, 59, 59)",
              light: "rgb(119, 119, 119)",
              contrastText: screenCast ? "#F5F5F5" : "#fcf7e6",
            },
            secondary: {
              main: "#555555",
              dark: "rgb(59, 59, 59)",
              light: "rgb(119, 119, 119)",
              contrastText: "#F5F5F5",
            },
            background: {
              default: screenCast ? "#000000" : "#212121",
              paper: screenCast ? "#000000" : "#212121",
            },
            error: {
              contrastText: "rgba(0, 0, 0, 0.87)",
              dark: "#f57c00",
              light: "#ffb74d",
              main: "#ffa726",
            },
            warning: {
              main: "#f57c00",
              dark: "#e65100",
              light: "#ff9800",
              contrastText: "#F5F5F5",
            },
            info: {
              contrastText: "rgba(0, 0, 0, 0.87)",
              dark: "#0288d1",
              light: "#4fc3f7",
              main: "#29b6f6",
            },
            success: {
              main: "#2e7d32",
              dark: "#1b5e20",
              light: "#4caf50",
              contrastText: "#F5F5F5",
            },
            tonalOffset: 0.2,
            contrastThreshold: 3,
            common: {
              black: "#000000",
              white: "#F5F5F5",
            },
            grey: {
              50: "#fafafa",
              100: "#f5f5f5",
              200: "#eeeeee",
              300: "#e0e0e0",
              400: "#bdbdbd",
              500: "#9e9e9e",
              600: "#757575",
              700: "#616161",
              800: "#424242",
              900: "#212121",
              A100: "#f5f5f5",
              A200: "#eeeeee",
              A400: "#bdbdbd",
              A700: "#616161",
            },
            text: {
              disabled: "rgba(255, 255, 255, 0.5)",
              primary: "#fff",
              secondary: "rgba(255, 255, 255, 0.7)",
            },
            divider: "rgba(255, 255, 255, 0.12)",
            action: {
              activatedOpacity: 0.24,
              active: "#fff",
              disabled: "rgba(255, 255, 255, 0.3)",
              disabledBackground: "rgba(255, 255, 255, 0.12)",
              disabledOpacity: 0.38,
              focus: "rgba(255, 255, 255, 0.25)",
              focusOpacity: 0.25,
              hover: "rgba(255, 255, 255, 0.08)",
              hoverOpacity: 0.08,
              selected: "rgba(255, 255, 255, 0.15)",
              selectedOpacity: 0.15,
            },
          }),
    },
  }).palette;

export default neutralPalette;
