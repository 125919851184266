import React from "react";

import SubtitleList from "comps/subtitles/SubtitleList";
import SubtitleScroller from "comps/subtitles/SubtitleScroller";

import { PanelPosition } from "pages/SubtitleScreen";

type Props = {
  panelPosition: PanelPosition;
  panelHeight: number;
  bgColor: string;
  isArMode: boolean;
};

const SubtitleBody: React.FC<Props> = ({
  panelPosition,
  panelHeight,
  bgColor,
  isArMode,
}) => (
  <section
    className="subtitle-holder"
    style={{
      position: "relative",
      flexShrink: 1,
      flexGrow: 1,
      flexDirection: "column",
      paddingTop: "16px",
      display: "flex",
      overflowX: "hidden",
      overflowY: "scroll",
      ...(panelPosition === PanelPosition.top
        ? {
            justifyContent: "start",
          }
        : panelPosition === PanelPosition.bottom
        ? {
            justifyContent: "end",
          }
        : {
            justifyContent: "end",
          }),
    }}
  >
    <SubtitleScroller
      arrowDownBottom="90px"
      style={{
        paddingRight: isArMode ? "50px" : "",
        ...(panelPosition === PanelPosition.top
          ? {
              overflow: "hidden scroll",
              marginTop: "var(--subtitle-margin)",
              minHeight: `${panelHeight}vh`,
              maxHeight: `${panelHeight}vh`,
            }
          : panelPosition === PanelPosition.bottom
          ? {
              overflow: "hidden scroll",
              marginBottom: "var(--subtitle-margin)",
              maxHeight: `${panelHeight}vh`,
              minHeight: `${panelHeight}vh`,
            }
          : {
              overflow: "auto",
            }),
      }}
      backgroundColor={bgColor}
      position={panelPosition}
    >
      <SubtitleList />
    </SubtitleScroller>
  </section>
);

export default SubtitleBody;
