const deepStringify = (obj: any, cache = new WeakMap<any, boolean>()) => {
    if (obj === null || typeof obj !== 'object') {
        return JSON.stringify(obj);
    }

    if (cache.has(obj)) {
        return JSON.stringify('__circular__');
    }

    cache.set(obj, true);

    let objString: string;
    if (Array.isArray(obj)) {
        objString = '[' + obj.map((item: any) => deepStringify(item, cache)).join(',') + ']';
    } else {
        let props = Object.getOwnPropertyNames(obj).map((prop: string) => {
            let value = obj[prop];
            let valueStr: string;

            if (typeof value === 'function') {
                valueStr = '"<Function>"';
            } else if (typeof value === 'symbol') {
                valueStr = '"<Symbol>"';
            } else {
                valueStr = deepStringify(value, cache);
            }

            return JSON.stringify(prop) + ':' + valueStr;
        }).join(',');

        objString = '{' + props + '}';
    }

    cache.delete(obj);
    return objString;
}

const levelTag = (level:number) => {
  return (
    level === -1 ? "ever"   :
    level ===  0 ? "trace"  :
    level ===  1 ? "debug"  :
    level ===  2 ? "info"   :
    level ===  3 ? "warn"   :
    level ===  4 ? "fail"   :
    level ===  5 ? "crit"   : "what"
  );
}

const unityPushLog = (level:number, ...args: any[]) => {
  const argsArray = Object.keys(args).map((k:string)=>{
    return args[parseInt(k)];
  });
  const levtext:string = levelTag(level);
  const payload:any = { level, msg: deepStringify([level,levtext,...argsArray])}
  if(window['xlog']) {
    window['xlog'](level, payload.msg);
  } else {
    console.log("dev-xlog", level, payload.msg);
  }
}

const xconsole = {
  ever(...args: any[]):void {
    unityPushLog(-1, ...args);
  },
  always(...args: any[]):void {
    unityPushLog(-1, ...args);
  },
  trace(...args: any[]):void {
    unityPushLog(0, ...args);
  },
  log(...args: any[]):void {
    unityPushLog(0, ...args);
  },
  debug(...args: any[]):void {
    unityPushLog(1, ...args);
  },
  info(...args: any[]):void {
    unityPushLog(2, ...args);
  },
  warn(...args: any[]):void {
    unityPushLog(3, ...args);
  },
  fail(...args: any[]):void {
    unityPushLog(4, ...args);
  },
  error(...args: any[]):void {
    unityPushLog(4, ...args);
  },
  crit(...args: any[]):void {
    unityPushLog(5, ...args);
  },
  critical(...args: any[]):void {
    unityPushLog(5, ...args);
  },
}

export default xconsole;
