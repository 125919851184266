import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)({
  fontSize: "1rem",
  fontWeight: "600",
  padding: "0.75rem 0.5rem",
  textTransform: 'none',
  borderRadius: "0.2rem",
});

export const XRAIButton = (buttonProps: ButtonProps) => {
  return <StyledButton {...buttonProps}>{buttonProps.children}</StyledButton>;
};

export const XRAIBack = (props: ButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
  <XRAIButton
    variant="text"
    style={{ marginLeft: "0vw",  padding: 0 }}
    startIcon={<ArrowBack color="secondary" />}
    onClick={() => navigate(-1)}
  >
    {t("Back")}
  </XRAIButton>
  );
}
