import React, { useState, useEffect } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getSetting, setSetting } from "utils/settings";
import { useTranslation } from "react-i18next";
import { isEmpty, Setting } from "types/types";
import { Thtml } from "comps/common/Thtml";
import { XraiFieldWrapper } from "comps/XraiFieldWrapper";
interface SettingOptionProps {
  name?: string;
  skipLabel?: boolean;
  skipDescription?: boolean;
  crapHack?: string;
}

const SettingOption = ({
  name,
  skipLabel,
  skipDescription,
  crapHack,
}: SettingOptionProps) => {
  const { t } = useTranslation();
  const [setting, setSettingData] = useState<Setting>();
  const [options, setOptions] = useState<[string, string][]>();
  useEffect(() => {
    getSetting(name).then((s) => {
      if (s) {
        setSettingData(s);
        setOptions(s.Options);
      }
    });
  }, [name]);

  return (
    <>
      {!isEmpty(setting) && options?.length > 0 && (
        <XraiFieldWrapper
          label={skipLabel ? "" : t(`settings.${setting.Name}.title`)}
          description={
            !skipDescription ? (
              <Thtml tag={`settings.${setting.Name}.summary`} />
            ) : (
              ""
            )
          }
          crapHack={crapHack}
        >
          <FormControl fullWidth className="x-form-control">
            <Select
              key={setting.Name}
              displayEmpty
              sx={{
                boxShadow: 2,
              }}
              value={setting.Value}
              onChange={(e) => {
                setSettingData({
                  ...setting,
                  Value: e.target.value,
                });
                let newSetting = setting;
                newSetting.Value = e.target.value;
                setSetting(setting.Name, e.target.value);
              }}
            >
              {options.map((v, index) => {
                if (
                  true &&
                  setting.Name !== "SelectedMicrophone" &&
                  setting.Name !== "SelectedAudioDevice" &&
                  setting.Name !== "SelectedAudioSource" &&
                  setting.Name !== "SelectedAudioOutput" &&
                  setting.Name !== "SelectedVideoSource"
                ) {
                  return (
                    <MenuItem key={index} value={v[0]}>
                      {t(`settings.${setting.Name}.${v[0]}`)}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={index} value={v[0]}>
                      {t(v[1])}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </XraiFieldWrapper>
      )}
    </>
  );
};

export default SettingOption;
