import React from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackButton from "comps/BackButton";
import SettingOption from "comps/settings/SettingOption";
import { Thtml } from "comps/common/Thtml";
import usePageTitle from "hooks/usePageTitle";
import { BackButtonWrapper, OnboardingContainer } from "themes/theme";

const RegionScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  usePageTitle("Onboard Region");
  return (
    <OnboardingContainer>
      <BackButtonWrapper>
        <BackButton
          name={t("region.back")}
          handler={() => navigate("/language")}
        />
      </BackButtonWrapper>

      <div className="x-spacer-sm" />

      <Typography variant="h1" component="h1">
        {t("region.title")}
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden scroll",
          paddingRight: theme.spacing(2),
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography component="p" style={{ margin: `${theme.spacing(2)} 0` }}>
            <Thtml tag="region.summary" />
          </Typography>

          <SettingOption name="AwsTranscribeRegion" />
        </Box>

        <Stack sx={{ my: 2 }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => navigate("/permissions")}
          >
            {t("continue")}
          </Button>
        </Stack>
      </div>
    </OnboardingContainer>
  );
};

export default RegionScreen;
