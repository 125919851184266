import { useEffect, useReducer, useCallback } from "react";
import { getUserCredentials } from "./getUserCredentials";
import useGoogleAnalytics from "hooks/useGoogleAnalytics";
import { xfetchLocal, xfetchOther, paired } from "utils/xfetch";
import { AuthStatus, authStore } from "./AuthStore";
import { authStateZero, authStateReducer } from "./AuthTypes";
import { useSyncExternalStore } from "react";

/**
 * useUnityAuth
 * This hook encapsulates auth into a React hook
 *
 * Usage:
 * const { state, signIn, checkLogin, signOut } = useUnityAuth();
 * checkLogin();
 * signIn({ provider: 'google' })
 * {state.appUser.IdentityId}
 */

const useUnityAuth = () => {

  const authStatus = useSyncExternalStore<AuthStatus|undefined>(
      authStore.subscribe, authStore.get);
  const [state, dispatch] = useReducer(authStateReducer, authStateZero);
  const ga = useGoogleAnalytics();

  const fetchUserData = useCallback(async () => {
    try {
      dispatch({ type: "USER_DATA_INIT" });

      // Get Authenticated User if exists
      if (authStatus?.tokens?.accessToken?.jwtToken) {
        const appUser = await getUserCredentials(authStatus.tokens).catch((e) => {
          throw e;
        });
        // Tell Analytics the user signed in
        if (appUser.DeviceId) ga.set("user_id", appUser.DeviceId);
        dispatch({
          type: "USER_DATA_SUCCESS",
          payload: { appUser, isLoading: false },
        });
      } else {
        dispatch({
          type: "USER_NOT_LOGGED_IN",
          payload: { isLoading: false },
        });
      }
    } catch (e) {
      dispatch({
        type: "USER_DATA_FAILURE",
        payload: {
          isLoading: false,
          errorMessage: `${e}`,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[authStatus]);

  const checkLogin = () => {
    xfetchLocal("/checklogin",{method:"POST"});
  };

  const signIn = (authSettings: any = {}) => {
    xfetchLocal("/unitylogin",{method:"POST"});
  }

  const signOut = async ()=>{
    if(paired()) {
        await xfetchOther("/unitylogoutother",{method:"POST"});
        xfetchLocal(`/unpairdevice`);
    } else {
        await xfetchLocal("/unitylogout",{method:"POST"});
    }
  }

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserData,authStatus?.tokens]);

  return { state, signIn, checkLogin, signOut };
};

export default useUnityAuth;
