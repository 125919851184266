import React, { useContext, useState, } from "react";
import { LangSettingType } from "comps/language/langaugeUtils";
import { DialogTransitionUp } from "utils/DialogTransition";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "@emotion/react";
import { CloseOutlined } from "@mui/icons-material";
import { FavoriteSpokenLanguages } from "./FavoriteSpokenLanguages";
import { FavoriteSubtitleLanguages } from "./FavoriteSubtitleLanguages";
import useLanguageDialog from "comps/language/useLanguageDialog";

interface FavoriteLanguageProps {
  type: LangSettingType;
  onSelect: (t: any) => void;
  onClose: () => void;
  open: boolean;
  id: string;
}

export default function FavoriteLanguageDialog(props: FavoriteLanguageProps) {
  const theme = useContext(ThemeContext) as Theme;
  const { t } = useTranslation();
  const [ready, setReady] = useState(true);
  const isDark = theme.palette.mode === "dark";
  const LanguageDialog = useLanguageDialog();
  const title = props.type === "SourceLanguage" ?
      t("settings.SourceLanguage.title") : t("settings.TargetLanguage.title");

  // Refresh the Views - will force the
  // subtitle and spoken favorite views
  // to refresh after closing the Large Language Modal
  const refresh = async ()=>{
    setReady(false);
    setTimeout(()=>{
      setReady(true);
    },100)
  }

  return <>
  <Dialog
  open={props.open}
  onClose={props.onClose}
  TransitionComponent={DialogTransitionUp}
  aria-labelledby="scroll-dialog-title"
  aria-describedby="scroll-dialog-description"
  PaperProps={{
    style: {
      width: "30em",
      maxWidth: "80vw",
      minHeight: "60vh"
    }
  }}
  >
  {props.open && (
    <>
      <DialogTitle
        style={{
          padding: 0,
          backgroundColor: isDark ? "#111" : "",
        }}
      >
        <Toolbar style={{ padding: "0 12px" }}>
          <IconButton onClick={props.onClose}>
            <CloseOutlined />
          </IconButton>
          <Typography style={{ width: "100%" }}>
            {title}
          </Typography>
        </Toolbar>
        <Divider />
      </DialogTitle>
      <DialogContent style={{ padding: 0, minHeight: "200px" }}>
        {ready && props.type === "SourceLanguage" && <FavoriteSpokenLanguages />}
        {ready && props.type === "TargetLanguage" && <FavoriteSubtitleLanguages />}
        <ListItem
          disablePadding
          onClick={() => {
            LanguageDialog.open({
              type: props.type,
              title: title,
              onClose: function() {
                refresh();
              },
              onSelect: function (lang: string): void {
                // throw new Error("Function not implemented.");
              },
              hideSelection: true,
              hideFlags: true,
            });
          }}
        >
          <ListItemButton>
            <ListItemText>
              {t("language.edit-favorite-languages")}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </DialogContent>
    </>
  )}
</Dialog>
{LanguageDialog.Dialog}
</>
}
