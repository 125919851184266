import React from "react";
import { TFunction } from "i18next";
import { Container, Paper, useTheme } from "@mui/material";
import SubtitleItem from "./SubtitleItem";

interface CaptionPreviewProps {
  t: TFunction<"translation", undefined, "translation">;
}

const CaptionPreview: React.FC<CaptionPreviewProps> = ({ t }) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        p: 1,
        backgroundColor:
          theme.palette.neutral?.main ?? theme.palette.background.default,
      }}
    >
      <Container>
        <SubtitleItem
          key="previewText"
          text={t("previewText")}
          t={t}
          color={theme.palette.primary.main}
        />
      </Container>
    </Paper>
  );
};

export default CaptionPreview;
