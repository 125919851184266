import React, { useContext } from "react";
import { Check } from "@mui/icons-material";
import { ThemeContext } from "@emotion/react";
import { Theme } from "@mui/material";

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  onClick?: () => void;
}
export default function RoundCheckbox(props: CheckboxProps) {
  const theme = useContext(ThemeContext) as Theme;
  return (
    <button
      disabled={props.disabled}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.onClick) props.onClick();
      }}
      style={{
        cursor: props.disabled ? "not-allowed" : "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `solid 1px ${theme.palette.action.focus}`,
        width: "20px",
        height: "20px",
        color: "#FFF",
        backgroundColor: props.checked
          ? props.disabled ? theme.palette.grey.A700 : theme.palette.success.main
          : "transparent",
        borderRadius: "20px",
        marginLeft: theme.spacing(1),
      }}
    >
      {props.checked && <Check style={{ fontSize: "16px" }} />}
    </button>
  );
}
