import React, { useState } from "react";

import CustomTabPanel from "./CustomTabPanel";
// TODO: Gut this once satisfied with new component.
// import LanguageSwitcherVert from "comps/LanguageSwitcherVert";
import InputListItem from "comps/common/InputListItem";
import ArFontSizeSelector from "comps/settings/ArFontSizeSelector";
import VideoPassthroughToggle from "./VideoPassthroughToggle";
import SettingOption from "comps/settings/SettingOption";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import { GlassesIcon } from "comps/icons/GlassesIcon";
import { MicOutlined } from "@mui/icons-material";
import { SubtitleDepthIcon } from "comps/icons/SubtitleDepthIcon";
import {
  Box,
  Divider,
  // TODO: Populate this back in behind subscriptions.
  // MenuItem,
  // Select,
  // useTheme,
  Slider,
  Stack,
  Switch,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import { setSetting } from "utils/settings";
import { toJSONPostRequest, xfetch } from "utils/xfetch";

import { useStoreLocal, useStoreUnity } from "state/store";
import { TFunction } from "i18next";

// TODO: Populate this back in behind subscriptions.
// import MicrosoftLogo from "comps/icons/MicrosoftLogo";
// import VoskLogo from "comps/icons/VoskLogo";
// import DeepgramLogo from "comps/icons/DeepgramLogo";
// import AmazonLogo from "comps/icons/AmazonLogo";

type Props = {
  activeTab: number;
  inAr: boolean;
  noiseCancel: boolean;
  t: TFunction<"translation", undefined, "translation">; // Translation
};

const InputSlider = styled(Slider)({
  marginTop: "12px !important",
});

const SettingsScreen: React.FC<Props> = ({
  activeTab,
  inAr,
  noiseCancel,
  t,
}) => {
  const [arHave3Dof] = useStoreUnity<boolean>("ArHave3Dof", false);
  const [hideAudioOptions] = useStoreUnity<boolean>("HideAudioOptions", false);

  // global persistent state
  const [screenCast, setScreenCast] = useStoreLocal<boolean>("screenCast");

  // TODO: Populate this back in behind subscriptions.
  // const [translationEngineSelection, setTranslationEngineSelection] =
  //   useStoreUnity<string>("translationEngineSelection", "azure");
  // const theme = useTheme();

  // legacy global state
  const [depth, setDepth] = useState(2.0);
  const [enableNoiseCancel, setEnableNoiseCancel] = useState(noiseCancel);

  const theme = useTheme();

  const depthMax = 16;
  const depthMin = 0.5;

  // Change the Depth of the Captions
  const depthChange = (val: number) => {
    if (val !== depth) {
      setDepth(val);
      toJSONPostRequest({ depth: val }).then((payload) => {
        xfetch("/changeDepth", payload);
      });
    }
  };

  return (
    <CustomTabPanel value={activeTab} index={0}>
      <div style={{ paddingTop: theme.spacing(2) }}>
        <Stack direction="column" spacing={2}>
          {/* TODO: GUT THIS ONCE SATISFIED WITH NEW COMPONENT */}
          {/* <LanguageSwitcherVert /> */}

          {/* TODO: Populate this back in behind subscriptions. */}
          {/* <Typography variant="body2">
            {t("language.transcriptionEnginePreference")}
          </Typography>

          <Select
            value={translationEngineSelection ?? "azure"}
            onChange={(e) => {
              setTranslationEngineSelection(e.target.value as string);
            }}
            sx={{
              "& .MuiSelect-select": {
                display: "flex !important",
                alignItems: "center !important",
              },
            }}
          >
            <MenuItem value="azure">
              <MicrosoftLogo style={{ marginRight: theme.spacing(1) }} />
              {t("Microsoft")}
            </MenuItem>

            <MenuItem value="vosk">
              <VoskLogo style={{ marginRight: theme.spacing(1) }} />
              {t("Vosk")}
            </MenuItem>

            <MenuItem value="deepgram">
              <DeepgramLogo style={{ marginRight: theme.spacing(1) }} />
              {t("Deepgram")}
            </MenuItem>

            <MenuItem value="aws">
              <AmazonLogo style={{ marginRight: theme.spacing(1) }} />
              {t("Amazon")}
            </MenuItem>
          </Select>

          <Divider variant="middle" style={{ marginTop: "16px" }} /> */}

          {inAr && (
            <InputListItem
              label={t("ArFontSize")}
              icon={<GlassesIcon />}
              input={
                <ArFontSizeSelector
                  sx={{
                    mt: "8px !important",
                  }}
                />
              }
            />
          )}

          {arHave3Dof && inAr && (
            <>
              <InputListItem
                label={t("SubtitleDistance")}
                icon={<SubtitleDepthIcon />}
                labelRight={
                  <span
                    style={{
                      opacity: 0.5,
                      position: "absolute",
                      right: "0",
                    }}
                  >
                    {depth.toFixed(2)}m
                  </span>
                }
                input={
                  <InputSlider
                    value={depth}
                    min={depthMin}
                    max={depthMax}
                    step={0.5}
                    sx={{
                      mt: "8px !important",
                    }}
                    onChange={(v: any, value: number) => {
                      depthChange(value);
                    }}
                  />
                }
              />
            </>
          )}

          <InputListItem
            label={t("MinimalInterface")}
            icon={
              <>{screenCast ? <VisibilityIcon /> : <VisibilityOffIcon />}</>
            }
            labelRight={
              <Stack direction="row" style={{ marginRight: "-12px" }}>
                <Switch
                  checked={screenCast}
                  onChange={(e) => {
                    setScreenCast(e.target?.checked ? true : false);
                  }}
                />
              </Stack>
            }
          />

          <VideoPassthroughToggle />

          <Divider
            variant="middle"
            style={{ marginBottom: "-8px", marginTop: "8px" }}
          />

          <InputListItem
            label={t("NoiseCancellation")}
            icon={<SettingsVoiceIcon />}
            labelRight={
              <Stack
                alignItems="center"
                spacing={2}
                direction="row"
                style={{ marginRight: "-2px" }}
              >
                <Typography
                  variant="body2"
                  component="span"
                  style={{
                    opacity: 0.4,
                    whiteSpace: "nowrap",
                  }}
                ></Typography>
                <Switch
                  className="-mr-2"
                  style={{ paddingRight: 0 }}
                  checked={enableNoiseCancel}
                  onChange={(e) => {
                    let enabled = e.target.checked;
                    setEnableNoiseCancel(enabled);
                    setSetting("EnableNoiseCancel", enabled ? "True" : "False");
                  }}
                />
              </Stack>
            }
          />

          {!hideAudioOptions && (
            <>
              <InputListItem
                label={t("SelectedAudioSource")}
                icon={<MicOutlined />}
              />

              <Box sx={{ marginTop: "-1rem" }}>
                <SettingOption
                  name="SelectedAudioDevice"
                  skipLabel={true}
                  skipDescription={true}
                  crapHack="0rem"
                />

                <SettingOption
                  name="SelectedAudioSource"
                  skipLabel={true}
                  skipDescription={true}
                />
              </Box>
            </>
          )}
        </Stack>
      </div>
    </CustomTabPanel>
  );
};

export default SettingsScreen;
