import React, { useState, useEffect, useMemo } from "react";
import useGuidedTour from "./useGuidedTour";
import { GuidedTourSteps } from "types/types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Tooltip,
  TooltipProps,
  Typography,
  Zoom,
  styled,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import useSessionManager from "hooks/useSessionManager";
import { useGlowingEffect } from "colors/Utils";

const CustomTooltip = styled(
  ({ className, PopperProps, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      PopperProps={{
        ...PopperProps,
        sx: {
          zIndex: 1000,
        },
      }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "1.5rem !important",
    padding: 10,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
}));

const GuidedTooltip = () => {
  const [open, setOpen] = useState(false);
  const [targetElement, setTargetElement] = useState(undefined);
  const [currentStep, setCurrentStep] = useState<string | undefined>(undefined);

  const { t } = useTranslation();
  const { isActiveSession } = useSessionManager();
  const { guidedTour, displayStep, setGuidedTourInteracted } = useGuidedTour();

  const theme = useTheme();
  const glowCss = useGlowingEffect(displayStep(currentStep));

  // The step attribute is used to determine the order of the guided tour.
  // The name of the object key is simply for reference.
  const stepMap: {
    [key: string]: {
      showTooltip: boolean;
      step: number;
      id?: string;
      verbiage?: string;
    };
  } = useMemo(
    () => ({
      [GuidedTourSteps.startButton]: {
        verbiage: t("GuidedWalkthrough.subtitlesFlow.addButton"),
        id: "ActionButtons--startButton",
        showTooltip: true,
        step: 1,
      },
      // [GuidedTourSteps.alignButton]: {
      //   verbiage: t("GuidedWalkthrough.subtitlesFlow.alignButton"),
      //   id: "ActionButtons--alignButton",
      //   showTooltip: isActiveSession(),
      // },
      [GuidedTourSteps.settingsButton]: {
        verbiage: t("GuidedWalkthrough.subtitlesFlow.settingsButton"),
        id: "SubtitleSettingsDrawer--settingsButton",
        showTooltip: true,
        step: 3,
      },
      [GuidedTourSteps.stopButton]: {
        verbiage: t("GuidedWalkthrough.subtitlesFlow.stopButton"),
        id: "ActionButtons--stopButton",
        showTooltip: isActiveSession(),
        step: 2,
      },
      // TODO: This seems buggy and not much of a value add. Consider removing.
      // [GuidedTourSteps.convoListItem]: {
      //   verbiage: undefined,
      //   id: "ConversationListItem--container",
      //   showTooltip: false,
      //   step: 4,
      // },
      [GuidedTourSteps.editButton]: {
        verbiage: t(
          "GuidedWalkthrough.subtitlesFlow.conversationHistoryEditButton"
        ),
        id: "ButtonMenu--ellipsis",
        showTooltip: true,
        step: 4,
      },
    }),
    [t, isActiveSession]
  );

  useEffect(() => {
    if (guidedTour) {
      const current = Object.keys(guidedTour)?.find(
        (key) => guidedTour[key]?.show
      );

      setCurrentStep(current);
    }
  }, [guidedTour, setCurrentStep]);

  useEffect(() => {
    const getNextKey = () => {
      const keys = Object.keys(stepMap);
      const step = stepMap[currentStep].step;
      const nextStep = keys.find((key) => stepMap[key].step === step + 1);

      if (nextStep) {
        return nextStep;
      }

      return null;
    };

    const applyGlowAndTooltip = async () => {
      if (currentStep && displayStep(currentStep) && stepMap[currentStep]?.id) {
        let element = document.getElementById(stepMap[currentStep].id);

        // If the element is not immediately present on the page, wait for it to appear.
        const waitForElement = async (id: string, timeout = 5000) => {
          const start = Date.now();

          while (!element && Date.now() - start < timeout) {
            await new Promise((resolve) => setTimeout(resolve, 100));
            element = document.getElementById(id);
          }

          return element;
        };

        element = await waitForElement(stepMap[currentStep].id);

        if (element) {
          setTargetElement(element);

          element.style["boxShadow"] = glowCss;

          // Not all steps will need a tooltip.
          if (stepMap[currentStep].showTooltip) {
            setOpen(true);
          }

          const handleClick = async () => {
            if (
              element &&
              window.getComputedStyle(element)?.display !== "none" &&
              window.getComputedStyle(element)?.visibility !== "hidden"
            ) {
              await new Promise((resolve) => setTimeout(resolve, 300));

              setGuidedTourInteracted(currentStep, getNextKey());
              element.style["boxShadow"] = "none";

              // Remove the event listener after it has been invoked
              document.removeEventListener("click", handleClick);
            }
          };

          document.addEventListener("click", handleClick);

          return () => {
            document.removeEventListener("click", handleClick);
          };
        } else {
          setOpen(false);
        }
      } else {
        setTargetElement(undefined);
        setOpen(false);
      }
    };

    applyGlowAndTooltip();
  }, [currentStep, stepMap, glowCss, displayStep, setGuidedTourInteracted]);

  if (!currentStep) {
    return <></>;
  }

  if (!targetElement) {
    return <></>;
  }

  return (
    <CustomTooltip
      TransitionComponent={Zoom}
      arrow
      id="GuidedTooltip"
      title={
        <Typography
          sx={{ fontSize: "1.1rem", color: theme.palette.common.white }}
        >
          {stepMap?.[currentStep]?.verbiage || ""}
        </Typography>
      }
      open={open}
      PopperProps={{
        anchorEl: targetElement,
        placement: "top",
      }}
    >
      <Box />
    </CustomTooltip>
  );
};

export default GuidedTooltip;
