import React, { useEffect, useRef } from "react";
import { FixedSizeList } from "react-window";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { SessionHistoryUtterance, SessionMeta } from "types/types";

import { useStoreLocal } from "state/store";
import useSessionManager from "hooks/useSessionManager";

import { Stack, useTheme } from "@mui/material";
import ConversationListItem from "./ConversationListItem";
import ConversationSkeleton from "./ConversationSkeleton";
import AutoSizer from "react-virtualized-auto-sizer";

type Props = {
  data: SessionHistoryUtterance[];
  conversationTitles: { [key: string]: string };
  selectedConversations: SessionMeta[];
  editMode: boolean;
  mounted: boolean;
  toggleSelected: (session: SessionMeta) => void;
};

const ConversationListInfiniteScroll: React.FC<Props> = ({
  data,
  conversationTitles,
  selectedConversations,
  editMode,
  mounted,
  toggleSelected,
}) => {
  const infiniteScrollRef = useRef<FixedSizeList>(null);

  const [fontSize] = useStoreLocal<number>("fontSize");
  const [selectedConversation, setSelectedConversation] =
    useStoreLocal<SessionMeta>("selectedConversation", null);

  const { t } = useTranslation();
  const { switchSession } = useSessionManager();
  const navigate = useNavigate();
  const theme = useTheme();

  const calculateRowHeight = () => {
    // Base Height + Calculated height for padding based on font size.
    return 80 + fontSize * 8.25;
  };

  const Row = ({ index, style, isScrolling }: any) => {
    const dataset = data[index];

    const {
      TranscriptionId: Id,
      Session,
      LastMessageUserPP,
      LastMessageColor,
      LastMessage,
      LastMessageSpeakerId,
      Users,
    } = dataset;

    const selected = selectedConversations.includes(Session);

    return (
      <div key={index} style={style}>
        {index < data.length - 1 && isScrolling ? (
          <Stack
            spacing={3}
            sx={{
              p: 2,
              height: "100%",
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <div style={{ height: "100%", alignContent: "center" }}>
              <ConversationSkeleton />
            </div>
          </Stack>
        ) : (
          <ConversationListItem
            selected={selected}
            onClick={() => {
              if (editMode) {
                toggleSelected(Session);
              } else {
                switchSession(Session.Id);
                let conversationLink = `/subtitles?sessionId=${Session.Id}`;
                if (Id) conversationLink = `${conversationLink}&Id=${Id}`;
                setSelectedConversation(Session);
                navigate(conversationLink);
              }
            }}
            style={{
              opacity: editMode && !selected ? 0.7 : 1,
              height: "100%",
              paddingBottom: theme.spacing(1),
              paddingTop: theme.spacing(1),
            }}
            ConversationNames={Users?.join(",")}
            LastMessage={LastMessage}
            LastMessageColor={LastMessageColor}
            LastMessageSpeakerId={LastMessageSpeakerId}
            LastUserAvatar={LastMessageUserPP}
            SessionEnds={Session.End ?? Session.Created}
            SessionId={Session.Id}
            t={t}
            conversationTitles={conversationTitles}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (infiniteScrollRef.current) {
      // Use a timeout to ensure the DOM is ready.
      setTimeout(() => {
        const itemIndex = data.findIndex(
          (item) => item.Session.Id === selectedConversation?.Id
        );

        if (itemIndex) infiniteScrollRef.current?.scrollToItem(itemIndex, "center");
      }, 100);
    }
  }, [data, selectedConversation?.Id, mounted]);

  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <FixedSizeList
          useIsScrolling
          height={height}
          itemCount={data.length}
          itemSize={calculateRowHeight()}
          width={width}
          ref={infiniteScrollRef}
        >
          {Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default ConversationListInfiniteScroll;
