import { PaletteMode, createTheme, Palette } from "@mui/material";

const companyPalette = (mode: PaletteMode, screenCast: boolean): Palette =>
  createTheme({
    palette: {
      name: "xrai-company-palette",
      screenCast,
      mode,
      ...(mode === "light" && !screenCast
        ? {
            panel: {
              main: "#a6aee5",
            },
            primary: {
              main: "#a6aee5",
              dark: "rgb(154, 163, 225)",
              light: "rgb(154, 163, 225)",
              contrastText: "#121212",
            },
            secondary: {
              main: "#121212",
              dark: "#121212",
              light: "#f0f0f0",
              contrastText: "#121212",
            },
            background: {
              default: "#f1f2fb",
              paper: "#818cda",
            },
            error: {
              main: "#d32f2f",
              dark: "#c62828",
              light: "#ef5350",
              contrastText: "#FFFFFF",
            },
            warning: {
              main: "#f57c00",
              dark: "#e65100",
              light: "#ff9800",
              contrastText: "#FFFFFF",
            },
            info: {
              main: "#0288d1",
              dark: "#01579b",
              light: "#03a9f4",
              contrastText: "#FFFFFF",
            },
            success: {
              main: "#2e7d32",
              dark: "#1b5e20",
              light: "#4caf50",
              contrastText: "#FFFFFF",
            },
            tonalOffset: 0.2,
            contrastThreshold: 3,
            common: {
              black: "#000000",
              white: "#FFFFFF",
            },
            grey: {
              50: "#fafafa",
              100: "#f5f5f5",
              200: "#eeeeee",
              300: "#e0e0e0",
              400: "#bdbdbd",
              500: "#9e9e9e",
              600: "#757575",
              700: "#616161",
              800: "#424242",
              900: "#212121",
              A100: "#f5f5f5",
              A200: "#eeeeee",
              A400: "#bdbdbd",
              A700: "#616161",
            },
            text: {
              primary: "rgba(0, 0, 0)",
              secondary: "rgba(0, 0, 0, 0.6)",
              disabled: "rgba(0, 0, 0, 0.38)",
            },
            divider: "rgba(0, 0, 0, 0.12)",
            action: {
              activatedOpacity: 0.12,
              active: "#000000",
              disabled: "rgba(0, 0, 0, 0.26)",
              disabledBackground: "rgba(0, 0, 0, 0.12)",
              disabledOpacity: 0.38,
              focus: "rgba(0, 0, 0, 0.25)",
              hover: "rgba(0, 0, 0, 0.04)",
              hoverOpacity: 0.04,
              selected: "rgba(0, 0, 0, 0.08)",
              selectedOpacity: 0.08,
            },
          }
        : // DARK MODE PALETTE
          {
            panel: {
              main: screenCast ? "#000000" : "#191969",
            },
            action: {
              activatedOpacity: 0.24,
              active: "#fff",
              disabled: "rgba(255, 255, 255, 0.3)",
              disabledBackground: "rgba(255, 255, 255, 0.12)",
              disabledOpacity: 0.38,
              focus: "rgba(255, 255, 255, 0.25)",
              focusOpacity: 0.25,
              hover: "rgba(255, 255, 255, 0.08)",
              hoverOpacity: 0.08,
              selected: "rgba(255, 255, 255, 0.16)",
              selectedOpacity: 0.15,
            },
            background: {
              default: screenCast ? "#000000" : "#0f0f34",
              paper: screenCast ? "#000000" : "#2c2c83",
            },
            common: {
              black: "#000",
              white: "#fff",
            },
            contrastThreshold: 3,
            divider: "rgba(255, 255, 255, 0.12)",
            error: {
              main: "#f44336",
              light: "#e57373",
              dark: "#d32f2f",
              contrastText: "#fff",
            },
            grey: {
              50: "#fafafa",
              100: "#f5f5f5",
              200: "#eeeeee",
              300: "#e0e0e0",
              400: "#bdbdbd",
              500: "#9e9e9e",
              600: "#757575",
              700: "#616161",
              800: "#424242",
              900: "#212121",
              A100: "#f5f5f5",
              A200: "#eeeeee",
              A400: "#bdbdbd",
              A700: "#616161",
            },
            info: {
              main: "#29b6f6",
              light: "#4fc3f7",
              dark: "#0288d1",
              contrastText: "rgba(0, 0, 0, 0.87)",
            },
            primary: {
              main: "#191969",
              light: "rgb(71, 71, 255)",
              dark: "rgb(17, 17, 73)",
              contrastText: screenCast ? "#FFFFFF" : "#FCF7E6",
            },
            secondary: {
              main: "#FCF7E6",
              light: "#FF955A",
              dark: "#121212",
              contrastText: screenCast ? "#FFFFFF" : "#FCF7E6",
            },
            success: {
              main: "#66bb6a",
              light: "#81c784",
              dark: "#388e3c",
              contrastText: "rgba(0, 0, 0, 0.87)",
            },
            text: {
              primary: "#fff",
              secondary: "rgba(255, 255, 255, 0.7)",
              disabled: "rgba(255, 255, 255, 0.5)",
            },
            tonalOffset: 0.2,
            warning: {
              main: "#ffa726",
              light: "#ffb74d",
              dark: "#f57c00",
              contrastText: "rgba(0, 0, 0, 0.87)",
            },
          }),
    },
  }).palette;

export default companyPalette;
