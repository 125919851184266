import { ReactElement } from "react";

export function isEmpty(x: any) {
    if (x === null || x === undefined) {
        return true;
    }
    for (let prop in x) {
        return false;
    }
    return true;
}

export function jsonEqual(x: any, y:any) {

    return x === y || JSON.stringify(x) === JSON.stringify(y);

}

// This will determine the glow effect for the guided tour.
export enum GuidedTourSteps {
  startButton = "showConversationAddButton",
//   alignButton = "showConversationDetailsAlignButton",
  settingsButton = "showConversationDetailsSettingsButton",
  stopButton = "showConversationStopButton",
//   convoListItem = "showConversationListItem",
  editButton = "showConversationDetailsEditButton",
}

export interface Transcription {
    Id: string;
    SessionId: string;
    UserId?: string;
    SpeakerId: string;
    Transcript: string;
    Color: string;
    Created: string;
}

export type Channel = {
  name: string;
  codes: string[];
  muted: boolean;
};

export interface EntitlementProvider {
    name: string;
    services: Array<string>;
    regions?: Array<string>;
}
export interface Entitlement {
    name: string;
    providers?: Array<EntitlementProvider>;
    allowed: number;
    meter_type: "mins" | "days" | "months" | "tokens";
    start_date?: string;
    product_name?: string;
    product_sku?: string;
    subscription_id?: string;
    hs_subscription_id?: string;
    subscription_name?: string;
    subscription_period?: string;
    subscription_period_months?: number;
    subscription_elapsed_months?: number;
    consumed?: number;
    allowed_total?: number
}

export interface SubtitleLiveFluid {
    n: number; //sequence number
    t: string; //transcript text
    i: string; //stream key
}

export interface SubtitleLiveFinal {
    n: number; //sequence number
    t: string; //transcript text
    i: string; //speakerId
    l: string; //speakerLabel
    c: string; //color
    g: string; //transcript GUID
}

export interface TranscriptionBreak {
    Id: string;
    SessionId: string;
    UserId: number;
    LastTranscriptId: string;
    Status: number;
    Created: string;
}

export interface SessionMeta {
    Id: string;
    Created: string;
    End: string;
    AudioSettings: string;
}

export interface User {
    Id: string;
    FirstName: string;
    LastName: string;
    EmailAddress: string;
    IsMuted: boolean;
    ProfilePicture: number[];
}

export interface AppUserSetup {
    onboarded: boolean;
    hasPermissions: boolean
}

export interface AppUser {
    DeviceId?: string;
    Platform?: string;
    FirstName: string;
    LastName: string;
    Entitlements: Array<Entitlement>;
    EmailAddress: string;
    ProfilePicture?: string;
    Setup: AppUserSetup
}

export interface SessionHistoryUtterance extends SessionHistory {
    TranscriptionId?: string;
}

export interface SessionHistory {
    Session: SessionMeta;
    Users: string[];
    LastMessage: string;
    LastMessageSpeakerId: string;
    LastMessageColor?: string;
    LastMessageUserPP: string;
}

export interface SessionHistories {
    histories: SessionHistory[];
}

export interface SessionConversationDetail {
    Transcriptions: Transcription[];
    Users: User[];
    Breakpoints?: TranscriptionBreak[];
    TranscriptionId?: string;
}

export interface SingleConversation {
    ConversationNames: string;
    LastMessage: string;
    LastMessageSpeakerId: string;
    LastMessageColor?: string;
    LastUserAvatar: string;
    SessionEnds: string;
    SessionId: string;
}

export interface XMenuItem {
    label: string;
    onClick: ()=>void,
    disabled?: boolean,
    active?: boolean,
    icon: ReactElement<any,any>
}

export interface Setting {
    Id: number;
    Name: string;
    Type: string;
    Value: string;
    Group: string;
    Options: [string,string][];
    Bits: number;
  }

export interface Trinary {
  value:
      "Maybe"
    | "True"
    | "False"
    ;
}

export interface CaptionStatus {
  status:
      "pendingConnect"
    | "pendingStop"
    | "confirmStop"
    | "pendingStart"
    | "confirmStart"
    | "pendingPause"
    | "confirmPause";
}

export type PermissionStatusType = "Default" | "Denied" | "DeniedAndDontAskAgain" | "Granted";

export interface PermissionsStatus {
    audioPermission: PermissionStatusType;
    bluetoothPermission: PermissionStatusType;
    contactsPermission: PermissionStatusType;
}

export interface BackendStatus {
  Status: "End" | "Start" | "Pause";
}

export interface Multicast {
  Type: string;
  IpEp: string;
  Name: string;
  Meta: string;
}
