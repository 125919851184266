import React, { useCallback, useEffect, useState, useContext } from "react";
import { CircularProgress, Stack, Typography, Button, Box, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSyncExternalStore } from "react";
import { useStore, useStoreUnity } from "state/store";
import { AuthStatus, authStore } from "comps/auth/AuthStore";
import { PairState } from "comps/pairing/PairTypes";
import DefaultLayout from "comps/layout/DefaultLayout";
import Logo from "comps/Logo";
import useOrientation from "hooks/useOrientation";
import AppUserContext from "comps/context/AppUserContext";
import { AppUser } from "types/types";
import Filler from "comps/common/Filler";
import { Thtml } from "comps/common/Thtml";
import { xfetchLocal, xfetchOther } from "utils/xfetch";
import get from 'lodash/get';

const LoginScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pendLoading, setPendLoading] = useState(false);
  const [pendLogin, setPendLogin] = useState(false);
  const [showPairing, ] = useStoreUnity<boolean>("EnablePairing",false);
  const [jumpArMode, ] = useStoreUnity<boolean>("JumpArMode",false);
  const authStatus = useSyncExternalStore<AuthStatus|undefined>(
      authStore.subscribe, authStore.get);
  const [pairState,] = useStore<PairState>("Pairing");
  const [, setIsArMode] = useStore<boolean>("IsArMode",false);

  const loading = () => {
    return !authStatus ||
      get(userAuth,"state.isLoading") ||
      get(pairState,"OtherStatus") === "pending" ||
      get(pairState,"LocalStatus") === "pending" ||
      pendLoading;
  }

  const niceText = (code:string):string => {
    return code ? code : t("Generating") ;
  }

  const { userAuth } = useContext(AppUserContext);
  const orientation = useOrientation();

  const navigateNext = useCallback((appUser:AppUser)=>{
    if ( !appUser.Setup.onboarded) {
      navigate("/welcome");
    } else if ( !appUser.Setup.hasPermissions ) {
      navigate("/verifyperms");
    } else {
      if ( jumpArMode ) {
        setIsArMode(true);
      }
      navigate("/subtitles")
    }
  }, [navigate, jumpArMode, setIsArMode])

  const cancelLogin = useCallback(()=>{
    setPendLoading(false);
    setPendLogin(false);
  }, [])

  const clearStatus = useCallback(()=>{
    setPendLoading(false);
    setPendLogin(false);
    authStore.clear();
  }, [])

  useEffect(()=>{
    if(userAuth.state.appUser) {
      navigateNext(userAuth.state.appUser)
    }
  }, [userAuth.state.appUser, navigateNext])

  useEffect(()=>{
    if ( pendLogin && !loading() && !userAuth.state.appUser ) {
      userAuth.signIn({provider: "Azure-AD-B2C"})
      setPendLoading(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pendLogin])

  useEffect(()=>{
    if ( authStatus?.reason === "cancel" ) {
      cancelLogin();
    }
  },[authStatus,cancelLogin])

  useEffect(()=>{
    const shouldAutoLogin = localStorage.getItem("autologin");
    if(shouldAutoLogin) {
      localStorage.removeItem("autologin");
      setPendLogin(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  /*
  useEffect(() => {
    const _iid = setTimeout(() => {
      Auth.currentAuthenticatedUser().then(data => {
         if ( data && !userAuth.state.appUser) {
           setPendLogin(true);
         }
      }).catch(()=>{});
    }, 2000);
    return () => clearTimeout(_iid);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])*/

  // If an error happens clear pendLoading
  useEffect(()=>{
    if(userAuth.state.errorMessage) {
      setPendLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userAuth.state.errorMessage])

  return (
    <DefaultLayout
      pageTitle="Login"
      hideBack={true}
      quitShow={true}
      quitOnClick={()=>{
        xfetchOther('/quit');
        xfetchLocal('/unpair');
      }}
      menuItems={<></>}
      bgColor={theme.palette.primary.main}
      >

      <Filler />

      <Logo
        type={orientation === "landscape" ? 'horizontal' : 'vertical'}
        style={{
          maxWidth:orientation === "landscape" ? '250px' : '170px',
          margin:"0 auto"
        }} />

      <Filler />

      <Stack
        spacing={2}
        justifyContent="stretch"
        alignItems="center"
        sx={{p:'24px', width:"100%", pb:'48px'}}>

        <Box style={{
            padding:"14px",
            opacity:1.0,
            textAlign:"center"
        }}>
            <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText, lineHeight: '120%'}}>
            {
              (() => {
                const localCodeText = showPairing ? niceText(pairState?.LocalCode) : null;
                return localCodeText ? localCodeText : <span>&nbsp;</span>;
              })()
            }
            </Typography>
        </Box>

        <Button size="large"
            startIcon={
              !loading() &&
              <Logo type="logomark" style={{display:"flex", width:"24px"}} />
            }
            sx={{
              width: "100%",
              maxWidth:"24em",
              height: '70px',
              textTransform: "none !important",
              backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
              ":disabled": { opacity: 0.6 }
            }}
            disabled={loading()}
            onClick={() => {
              setPendLogin(true);
            }}
          >
            { loading() ?
              <CircularProgress size={"2rem"} thickness={6} sx={{ color: theme.palette.primary.contrastText }} /> :
              t("Sign in")
            }
          </Button>

          { loading() &&
          <Box style={{
            padding:"12px",
            opacity:0.6,
            textAlign:"center"
          }}>
            <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText, lineHeight: '120%'}}>
              { authStatus ?
                 <Link style={{ color:  'white' }} onClick={() => cancelLogin()} to={'/login'}><Thtml tag="login.login-cancel"/></Link>
               : <Link style={{ color:  'white' }} onClick={() => clearStatus()} to={'/login'}><Thtml tag="login.clear-status"/></Link>
              }
            </Typography>
          </Box>
          }

          { !loading() &&
          <Box style={{
            padding:"12px",
            opacity:0.6,
            textAlign:"center"
          }}>
            <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText, lineHeight: '120%'}}>
              <Link style={{ color:  'white' }} to={'/terms'}><Thtml tag="welcome.terms"/></Link>
              <span> {t("and")} </span>
              <Link style={{ color:  'white' }} to={'/privacy'}><Thtml tag="welcome.privacy"/></Link>
            </Typography>
          </Box>
          }

          {userAuth && userAuth.state.errorMessage &&
            <Typography variant="body1" sx={{pt:'24px', textAlign:"center", leading:"115%", color: theme.palette.primary.contrastText}}>
              ⛔️ {userAuth.state.errorMessage}
            </Typography>
          }
      </Stack>

    </DefaultLayout>
  )
}

export default LoginScreen;
