import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useActivePopup from "hooks/useActivePopup";

interface XraiModalProps {
  title: string;
  content: string | React.ReactNode;
  open: boolean;
  closeHandler?: () => void;
  confirmHandler?: () => void;
}

const XraiModal = ({
  title,
  content,
  open,
  closeHandler,
  confirmHandler,
}: XraiModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  useActivePopup(open);

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      PaperProps={{
        sx: {
          backgroundColor:
            theme.palette.neutral?.main ?? theme.palette.background.default,
        },
      }}
    >
      <DialogTitle sx={{ fontSize: "1.5rem" }}>{title}</DialogTitle>
      <DialogContent sx={{ paddingBottom: "0" }}>
        {typeof content === "string" ? (
          <DialogContentText>{content}</DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
        {closeHandler != null && (
          <Button variant="contained" onClick={closeHandler}>
            <Typography variant="body2">{t("Cancel")}</Typography>
          </Button>
        )}

        {confirmHandler != null && (
          <Button variant="contained" onClick={confirmHandler}>
            <Typography variant="body2">{t("Continue")}</Typography>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default XraiModal;
