import { toJSONPostRequest, xfetch } from "utils/xfetch";

export interface FavoriteLanguagePayload {
  key: string;
  langcode: string;
  selected: boolean;
  multiSupport?: boolean;
  vendor?: "azure" | "aws" | "deepgram" | "vosk" | "xrai" | undefined;
  disabled?: boolean;
}

export const getLanguageList = async (
  { group, sublist }: { group: string; sublist: string }
): Promise<
  FavoriteLanguagePayload[]
> => {
  if (globalThis.isXraiGo) return Promise.resolve([]);

  const res = await xfetch(`/language/get?group=${group}&subList=${sublist}`);
  const data = (await res.json()) as FavoriteLanguagePayload[];
  return data;
};

export const getFavoriteSpokenLanguages = async (): Promise<
  FavoriteLanguagePayload[]
> => {
  const res = await xfetch("/language/get?group=spoken&subList=favorite");
  const data = (await res.json()) as FavoriteLanguagePayload[];
  return data;
};

export const getFavoriteSubtitleLanguages = async (): Promise<
  FavoriteLanguagePayload[]
> => {
  const res = await xfetch("/language/get?group=subtitle&subList=favorite");
  const data = (await res.json()) as FavoriteLanguagePayload[];
  return data;
};

export const togSpokenLangs = async (
  engine: FavoriteLanguagePayload,
) => {
  const payload = await toJSONPostRequest(null);
  return await xfetch(
    `/language/toggle?code=${engine.key}&group=spoken`,
     payload
  );
};

export const setFavoriteSpokenLangs = async (
  langs: FavoriteLanguagePayload[],
) => {
  const payload = await toJSONPostRequest({
    selected: langs.map((l)=>l.key),
  });

  return await xfetch(
    "/language/select?group=spoken",
    payload
  );
};


export const setFavoriteSubtitleLang = async (
  lang: FavoriteLanguagePayload
) => {
  const payload = await toJSONPostRequest({
    selected: [ lang.key ],
  });
  return await xfetch(
    "/language/select?group=subtitle",
    payload
  );
};
