import React, { useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { ChevronRight, ExpandMore } from "@mui/icons-material";

import { useStore } from "state/store";
import { useTranslation } from "react-i18next";
import useLanguages from "./useLanguages";

import LanguageListItemContainer from "./LanguageListItemContainer";

type Props = {
  languageToggleValue: "from" | "to";
  searchTerm?: string;
};

const LanguageList: React.FC<Props> = ({ languageToggleValue, searchTerm }) => {
  const [favoritedCollapsed, setFavoritedCollapsed] = useState(false);
  const [favoritesSelected, setFavoritesSelected] = useState(true);
  const [allSelected, setAllSelected] = useState(false);

  const [iconSize] = useStore<number>("rootIconSize");

  const theme = useTheme();
  const { t } = useTranslation();
  const {
    loading,
    groupedLanguages,
    favoriteLanguages,
    type,
    downloadStatus,
    selectedLanguages,
    isLangDownloaded,
    toggleFavorite,
    setFavoriteSubtitleLang,
    toggleFavoriteSpokenLangs,
    selectLanguage,
    getLangListFromSearch,
  } = useLanguages(languageToggleValue);

  const languagesFromSearch = getLangListFromSearch(searchTerm);
  const favoritesFromSearch = languagesFromSearch.filter((lang) => {
    const groupedLangs = groupedLanguages[lang];

    if (groupedLangs.length === 1) {
      return favoriteLanguages.find((f) => f.key === groupedLangs[0].key);
    } else {
      return groupedLangs.some((gl) => {
        return favoriteLanguages.find((f) => f.key === gl.key);
      });
    }
  });

  const xraiVendorsFromFavorites = favoriteLanguages
    .filter((f) => f.vendor === "xrai" && f.langcode !== "_xb") // _xb is Auto-reverse
    .map((f) => f.langcode);

  const favorites = [
    ...new Set([...xraiVendorsFromFavorites, ...favoritesFromSearch]),
  ];

  const handleFavoritesExpansion = () => {
    setFavoritesSelected(!favoritesSelected);
    setAllSelected(false);
  };

  const handleAllSelection = () => {
    // Collapse Favorite Languages if All Languages are expanded.
    // Reopen Favorite Languages if it was automatically collapsed when All Languages is closed.
    setAllSelected(!allSelected);

    if (!allSelected && favoritesSelected && !favoritedCollapsed) {
      setFavoritesSelected(false);
      setFavoritedCollapsed(true);
    }

    if (allSelected && favoritedCollapsed) {
      setFavoritesSelected(true);
      setFavoritedCollapsed(false);
    }
  };

  return (
    <Stack sx={{ display: "flex", mb: 1, height: "100%" }}>
      {/* FAVORITE LANGUAGES LIST */}
      <div
        style={{
          overflow: "hidden",
        }}
      >
        <Accordion
          defaultExpanded
          expanded={favoritesSelected}
          onChange={handleFavoritesExpansion}
          sx={{
            backgroundColor: theme.palette.neutral?.main,
            backgroundImage: theme.palette.image?.neutral ?? "none",
            backgroundSize: "cover", // Adjusts image to cover the whole container
            backgroundRepeat: "no-repeat", // Prevents image from repeating
            backgroundPosition: "center", // Centers the image
            "&.Mui-expanded": {
              height: "100%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme.palette.neutral?.main,
              backgroundImage: theme.palette.image?.neutral ?? "none",
              backgroundSize: "cover", // Adjusts image to cover the whole container
              backgroundRepeat: "no-repeat", // Prevents image from repeating
              backgroundPosition: "center", // Centers the image
              "& .MuiCollapse-root": {
                overflow: "hidden scroll !important",
              },
            },
          }}
        >
          <AccordionSummary
            expandIcon={favoritesSelected ? <ExpandMore /> : <ChevronRight />}
            sx={{
              pl: 0,
              mt: 0,
              minHeight: "unset",
              overflow: "hidden scroll",
              "& .MuiAccordionSummary-content": { m: "0px !important" },
              "&.Mui-expanded": {
                minHeight: "1rem",
                py: 2,
              },
            }}
          >
            <Typography variant="h6">{t("Language.favorites")}</Typography>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 0,
            }}
          >
            {loading && (
              <CircularProgress
                color="inherit"
                sx={{
                  alignSelf: "center",
                  height: iconSize,
                  width: iconSize,
                  my: 2,
                }}
              />
            )}

            {((!loading && favorites.length === 0) ||
              languagesFromSearch?.length === 0) && (
              <Typography variant="body2" sx={{ textAlign: "center", my: 2 }}>
                {t("no-langs-available")}
              </Typography>
            )}

            {!loading &&
              favorites.map((langKey, index) => (
                <LanguageListItemContainer
                  key={`${langKey}-${index}-favorites`}
                  langKey={langKey}
                  index={index}
                  groupedLanguages={groupedLanguages}
                  favoriteLanguages={favoriteLanguages}
                  type={type}
                  downloadStatus={downloadStatus}
                  setFavoriteSubtitleLang={setFavoriteSubtitleLang}
                  toggleFavoriteSpokenLangs={toggleFavoriteSpokenLangs}
                  toggleFavorite={toggleFavorite}
                  selectLanguage={selectLanguage}
                  selectedLanguages={selectedLanguages}
                  isLangDownloaded={isLangDownloaded}
                />
              ))}
          </AccordionDetails>
        </Accordion>
      </div>

      {/* ALL LANGUAGES */}
      <div
        style={{
          overflow: "hidden",
          marginBottom: theme.spacing(1),
          maxHeight: "100%",
        }}
      >
        <Accordion
          expanded={allSelected}
          onChange={handleAllSelection}
          sx={{
            backgroundColor: theme.palette.neutral?.main,
            backgroundImage: theme.palette.image?.neutral ?? "none",
            backgroundSize: "cover", // Adjusts image to cover the whole container
            backgroundRepeat: "no-repeat", // Prevents image from repeating
            backgroundPosition: "center", // Centers the image
            "&.Mui-expanded": {
              height: "100%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme.palette.neutral?.main,
              backgroundImage: theme.palette.image?.neutral ?? "none",
              backgroundSize: "cover", // Adjusts image to cover the whole container
              backgroundRepeat: "no-repeat", // Prevents image from repeating
              backgroundPosition: "center", // Centers the image
              "& .MuiCollapse-root": {
                overflow: "hidden scroll !important",
              },
            },
          }}
        >
          <AccordionSummary
            expandIcon={allSelected ? <ExpandMore /> : <ChevronRight />}
            sx={{
              pl: 0,
              overflow: "hidden scroll",
              "& .MuiAccordionSummary-content": { m: "0px !important" },
            }}
          >
            <Typography variant="h6">{t("Language.all")}</Typography>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 0,
            }}
          >
            {loading && (
              <CircularProgress
                color="inherit"
                sx={{
                  alignSelf: "center",
                  height: iconSize,
                  width: iconSize,
                  my: 2,
                }}
              />
            )}

            {((!loading && Object.keys(groupedLanguages).length === 0) ||
              languagesFromSearch?.length === 0) && (
              <Typography variant="body2" sx={{ textAlign: "center", my: 2 }}>
                {t("no-langs-available")}
              </Typography>
            )}

            {!loading &&
              languagesFromSearch.map((langKey, index) => (
                <LanguageListItemContainer
                  key={`${langKey}-${index}-all`}
                  langKey={langKey}
                  index={index}
                  groupedLanguages={groupedLanguages}
                  favoriteLanguages={favoriteLanguages}
                  type={type}
                  downloadStatus={downloadStatus}
                  setFavoriteSubtitleLang={setFavoriteSubtitleLang}
                  toggleFavoriteSpokenLangs={toggleFavoriteSpokenLangs}
                  toggleFavorite={toggleFavorite}
                  selectLanguage={selectLanguage}
                  selectedLanguages={selectedLanguages}
                  isLangDownloaded={isLangDownloaded}
                />
              ))}
          </AccordionDetails>
        </Accordion>
      </div>
    </Stack>
  );
};

export default LanguageList;
