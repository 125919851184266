import { Entitlement } from "types/types";
import { ProductSku, parseSku } from "./parseSku";



export default class Entitlements {
  entitlements: Entitlement[];

  constructor(entitlements: Entitlement[]) {
    this.entitlements = entitlements;
  }

  /**
   * Get an Entitlment by Id
   */
  public getById = (key: string): Entitlement | undefined => {
    return this.entitlements.find((e) => e.name === key);
  };

  /**
   * Check if they can use a specific entitlement
   */
  public canUse = (key: string): boolean => {
    const exists: Entitlement | undefined = this.getById(key);
    if (exists) {
      if(exists.allowed === -1) {
        return true;
      } else if (typeof exists.consumed === "undefined") {
        return true;
      } else {
        return exists.consumed < exists.allowed;
      }
    }
    return false;
  };

  /**
   * Get all subscriptions (product_skus)
   */
  public subscriptions = (): Array<string> => {
    let items: string[] = this.entitlements
      .map((e) => {
        return e.product_sku;
      })
      .filter((d) => d);
    return [...new Set(items)];
  };
  
  public hasEntitlement = (key:string):boolean=>{
    return this.entitlements.find(e=>e.name === key) ? true : false
  }

  public getPlans = (): ProductSku[] => {
    return this.subscriptions()
      .filter((d) => d)
      .map((s) => {
        return parseSku(s);
      });
  };

  public hasPlan = (planId: string): boolean => {
    let plans = this.getPlans();
    if(!plans.length && planId === "glass_essentials") {
      return true;
    }
    return plans.find((s) => s.plan === planId) ? true : false;
  };

  /**
   * Check if user has a specific subscription
   * if no subscriptions are found default the lookup
   * to glass_essentials
   */
  public hasSubscription = (key: string): boolean => {
    const subs = this.subscriptions();
    if (subs.length === 0) {
      return key === "glass_essentials";
    }
    return subs.includes(key);
  };

  /**
   * Get the Usage for any entitlement
   */
  public usage = (key: string): { remaining: number; meter: string, allowed: number } => {
    const entitlement = this.getById(key);
    if(entitlement.allowed === -1) {
      return {
        meter: entitlement.meter_type,
        remaining: -1,
        allowed: entitlement.allowed
      }
    } else if (entitlement.consumed) {
      return {
        meter: entitlement.meter_type,
        remaining: entitlement.allowed - entitlement.consumed,
        allowed: entitlement.allowed
      };
    } else {
      return {
        meter: entitlement.meter_type,
        remaining: entitlement.allowed,
        allowed: entitlement.allowed
      };
    }
  };
}
