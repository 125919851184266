import { useSyncExternalStore } from "react";
import { xfetch } from "utils/xfetch";
import { DownloadStatus, downloadStore } from "../languageDrawer/DownloaderStore";

export default function useLanguageDownloader() {

  const downloadStatus = useSyncExternalStore<DownloadStatus | undefined>(downloadStore.subscribe, downloadStore.get);

  const startDownload = (key: string) => {
    // TODO: Implement Downloading
    return xfetch(`/startdownload?hashname=${key}`);
  };

  const cancelDownload = (key: string) => {
    // TODO: Implement Cancel Downloading
    return xfetch(`/canceldownload?hashname=${key}`);
  };

  const cancelAllDownloads = () => {
    // TODO: Implement Cancel Downloading
    return xfetch(`/cancelalldownloads`);
  };

  return {
    downloadStatus,
    startDownload,
    cancelDownload,
    cancelAllDownloads,
  };
}
