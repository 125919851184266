import React from "react";
import { Lock, LockOpen } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import useOrientation from "hooks/useOrientation";
import { xfetch } from "utils/xfetch";
import xconsole from "utils/xconsole";
import { useStore } from "state/store";

export default function HorizontalLock() {
  const orientation = useOrientation();
  const [isLocked, setIsLocked] = useState(false);
  const [iconSize,] = useStore<number>("rootIconSize");
  const toggleLock = async () => {
    if (!isLocked) {
      try {
        await xfetch("/locklandscape",{method:"POST"});
        setIsLocked(true);
      } catch(e) {
        xconsole.error("fail lock landscape",e)
      }
    } else {
      try {
        await xfetch("/unlocklandscape",{method:"POST"});
        setIsLocked(false);
      } catch(e) {
        xconsole.error("fail unlock landscape",e)
      }
    }
  };
  if (orientation === "landscape" || isLocked) {
    return (
      <IconButton
        onClick={() => {
          toggleLock();
        }}
        aria-label="Toggle landscape"
        color="white"
      >
        {!isLocked ?
          <LockOpen
            sx={{ width: iconSize, height: iconSize }}
          /> :
          <Lock
            sx={{ width: iconSize, height: iconSize }}
          />
        }
      </IconButton>
    );
  } else {
    return <></>;
  }
}
