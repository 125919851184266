import React  from "react";
import { IconButton, SxProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MicExternalOn } from "@mui/icons-material";
import { wait } from "utils/wait";
import { useStore } from "state/store"

interface DantesMenuItemProps {
  handler?: () => void;
  iconSx?: undefined | SxProps
  color?: string
}

const DantesMenuItem = (
  { handler, iconSx, color }: DantesMenuItemProps,
) => {
  const navigate = useNavigate();
  const [height,] = useStore<number>("rootIconSize");
  return (
    <IconButton style={{color:color}}
      onClick={ async () => {
        await wait(200);
        if(handler) {
          handler();
        } else {
          navigate("/dante");
        }
      }}
    >

      <MicExternalOn
        sx={{ ...{color, width: height, height: height}, ...iconSx }} />
    </IconButton>
  );
};

export default DantesMenuItem;
