import React from "react";
import { Stack } from "@mui/system";
import { Skeleton } from "@mui/material";

export default function ConversationSkeleton() {
  return (
    <Stack  direction="row" spacing={2}>
      <Skeleton variant="circular" style={{flexShrink:0, width:"46px", height:"46px"}} />
      <Stack style={{width:"100%"}} spacing={1} direction="column">
        <Skeleton variant="text" style={{width:"120px", height:"18px"}} />
        <Skeleton variant="text" style={{width:"100%", height:"24px", maxWidth:"400px"}} />
      </Stack>
    </Stack>
  )
}