import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { CloseOutlined, ShoppingBasket } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { toJSONPostRequest, xfetchLocal } from "utils/xfetch";
import EmptyContent from "comps/common/EmptyContent";
import { useDialog } from "hooks/useDialog";
import { useOpenURL } from "hooks/useOpenUrl";
import SubscriptionAccordion from "./SubscriptionAccordion";
import xconsole from "utils/xconsole";
import AppUserContext from "comps/context/AppUserContext";
import Entitlements from "comps/entitlements/entitlements";
import { parseSku } from "comps/entitlements/parseSku";
import { Subscription } from "./subscriptionUtils";

// const mock: Subscription[] = [
//   {
//     Id: "glass_premium_annual",
//     Name: "Premium (Annual)",
//     Price: "$9.99",
//   },
//   {
//     Id: "glass_premium_monthly",
//     Name: "Premium (Monthly)",
//     Price: "$9.99",
//   },
//   {
//     Id: "glass_ultimate_monthly",
//     Name: "Ultimate (Monthly)",
//     Price: "$49.99",
//   },
//   {
//     Id: "glass_ultimate_6monthly",
//     Name: "Ultimate (6 Months)",
//     Price: "$159.99",
//   },
// ];

interface Props {
  open: boolean;
  onClose: () => void;
}
export default function SubscriptionDialog(props: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { appUser } = useContext(AppUserContext);
  const [isOpen, setIsOpen] = useState(props.open);
  const [expanded, setExpanded] = useState("glass_essentials");
  const [planFeatures, setPlanFeatures] = useState<any>([]);
  const [available, setAvailable] = useState<Subscription[]>([]);
  const URL = useOpenURL();
  const getFeatures = (activeId: string) => {
    const features = planFeatures.find(
      (s: any) => s.id === parseSku(activeId).plan
    );
    if (features) {
      return features.includes;
    }
    return [];
  };

  const entitlements = new Entitlements(appUser?.Entitlements || []);
  const ConfirmationDialog = useDialog();

  const launch_billing_func = async (id: string) => {
    const req = {
      Product: id,
    };
    const payload = await toJSONPostRequest(req);
    await xfetchLocal("/launchbillingflow", payload);
    handleClose();
  };

  const openManage = () => {
    URL.open("https://play.google.com/store/account/subscriptions");
  };

  const handleClose = () => {
    setIsOpen(false);
    props.onClose();
  };

  const loadSubscriptions = async () => {
    const call = await xfetchLocal("/loadsubscriptions", { method: "POST" });
    const subs: Subscription[] = await call.json();
    return subs;
  };

  // const loadMockSubscriptions = async () => {
  //   return mock;
  // };

  const injectEssentials = (
    subs: Array<Subscription>,
    hasTrial: boolean
  ): Subscription[] => {
    if (hasTrial) {
      return subs;
    } else {
      return [
        ...[
          {
            Id: "glass_essentials",
            Name: "Essentials (Monthly)",
            Price: "0",
          },
        ],
        ...subs,
      ];
    }
  };

  useEffect(() => {
    if (props.open !== isOpen) {
      setIsOpen(props.open);
    }
    if (props.open) {
      // Get Subscriptions
      loadSubscriptions()
        .then((subs) => {
          xconsole.log({ subs });
          setAvailable(subs);
        })
        .catch((e) => {
          xconsole.error("loadSubscriptions", e);
        });

      // Get Subscription Features
      xfetchLocal("/config/subscriptions.json")
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data && data.plans) {
            setPlanFeatures(data.plans);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <>
      <Dialog
        className="subtitle-settings-drawer"
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        <Toolbar
          style={{
            paddingRight: "3px",
            paddingLeft: "0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Container style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => handleClose()}>
              <CloseOutlined />
            </IconButton>
            <Typography style={{ width: "60%" }} component="div">
              {t("subscriptions.title", "Subscriptions")}
            </Typography>
          </Container>
        </Toolbar>
        <DialogContent
          style={{
            padding: "8px",
            backgroundColor: "rgba(155,155,155,0.2)",
          }}
        >
          {injectEssentials(available, entitlements.hasPlan("trial")).length ===
            0 &&
            !entitlements.hasPlan("trial") && (
              <EmptyContent
                title={t(
                  "subscriptions.none_found",
                  "No Available Subscriptions"
                )}
                header={<ShoppingBasket style={{ marginTop: "-60px" }} />}
              />
            )}
          {entitlements.hasPlan("trial") && (
            <SubscriptionAccordion
              key={"glass_premium_monthly_trial"}
              active={true}
              features={getFeatures("glass_trial")}
              onExpanded={() => {
                setExpanded("glass_premium_monthly_trial");
              }}
              onUnsubscribe={(id) => {
                openManage();
              }}
              subscription={{
                Id: "glass_premium_monthly_trial",
                Name: "Trial",
                Price: "0",
              }}
              onSubscribe={(id) => {}}
              expanded={expanded === "glass_premium_monthly_trial"}
            />
          )}
          {injectEssentials(available, entitlements.hasPlan("trial"))
            .sort((a, b) => {
              return a.Name > b.Name ? 1 : -1;
            })
            .map((sub) => {
              const features = getFeatures(sub.Id);
              const isActive =
                (sub.Id === "glass_essentials" &&
                  entitlements.subscriptions.length === 0) ||
                entitlements.hasSubscription(sub.Id);
              return (
                <SubscriptionAccordion
                  key={sub.Id}
                  features={features}
                  active={isActive}
                  subscription={sub}
                  onExpanded={() => {
                    setExpanded(sub.Id);
                  }}
                  onUnsubscribe={(id) => {
                    openManage();
                  }}
                  onSubscribe={(id) => {
                    if (sub.Id === "glass_essentials") {
                      alert(
                        "Essentials needs to cancel previous subscriptions"
                      );
                    } else {
                      launch_billing_func(id);
                    }
                  }}
                  expanded={expanded === sub.Id}
                />
              );
            })}

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ minWidth: "140px", marginTop: "1em" }}
              variant="contained"
              onClick={() => {
                openManage();
                handleClose();
              }}
            >
              {t("Manage")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {ConfirmationDialog.Dialog}
    </>
  );
}
