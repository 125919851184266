import React, { useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SettingOption from "comps/settings/SettingOption";
import { OnboardingContainer } from "themes/theme";
import { useTheme } from "@mui/material/styles";
import { Thtml } from "comps/common/Thtml";
import Logo from "comps/Logo";
import AppUserContext from "comps/context/AppUserContext";
import { useStoreLocal } from "state/store";
import usePageTitle from "hooks/usePageTitle";

const WelcomeScreen = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [checkedTOS, setCheckedTOS] = useStoreLocal("tosSigned", false);
  const { appUser } = useContext(AppUserContext);

  const handleChangeTOS = (event: any) => {
    setCheckedTOS(event.target.checked);
  };

  usePageTitle("Onboard Welcome");

  return (
    <OnboardingContainer>
      <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
        <Logo
          style={{ width: "100px", marginTop: theme.spacing(2) }}
          type="logomark"
          color={theme.palette.primary.main}
        />
      </Stack>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden scroll",
          paddingRight: theme.spacing(2),
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography component="h1" variant="h1" sx={{ mb: 2 }}>
            {t("welcome.title")}

            <span
              style={{
                display: "inline-block",
                color: theme.palette.primary.light,
                marginLeft: theme.spacing(1),
              }}
            >
              {appUser?.FirstName || t("Friend")}!
            </span>
          </Typography>

          <Typography component="h2" variant="h2">
            {t("welcome.subTitle")}
          </Typography>

          <Typography variant="body1" component="p" sx={{ my: 2, mx: 0 }}>
            {t("welcome.summary")}
          </Typography>

          <Stack direction="column">
            <SettingOption name="SelectedAudioDevice" />
            <SettingOption name="SelectedAudioSource" />
          </Stack>
        </Box>

        <Stack spacing={2} sx={{ my: 2 }}>
          <Stack alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checkedTOS}
                  onChange={handleChangeTOS}
                />
              }
              label={
                <Typography variant="body2" sx={{ lineHeight: "120%" }}>
                  <Thtml tag="welcome.agree" />
                  <span> </span>
                  <Link to={"/terms"}>
                    <Thtml tag="welcome.terms" />
                  </Link>
                  <span> {t("and")} </span>
                  <Link to={"/privacy"}>
                    <Thtml tag="welcome.privacy" />
                  </Link>
                </Typography>
              }
            />
          </Stack>

          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={!checkedTOS}
            onClick={() => navigate("/language")}
          >
            {t("continue")}
          </Button>
        </Stack>
      </div>
    </OnboardingContainer>
  );
};

export default WelcomeScreen;
