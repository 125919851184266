import React, { useEffect, useState, useCallback } from "react";

import { Box } from "@mui/system";
import {
  Visibility,
  LaptopMac,
  PhoneIphone,
  CloseOutlined,
} from "@mui/icons-material";
import {
  Divider,
  Stack,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Toolbar,
  ListItemButton,
  useTheme,
} from "@mui/material";

import xconsole from "utils/xconsole";
import { xfetchLocal, toJSONPostRequest } from "utils/xfetch";

import { useStore } from "state/store";
import { useStoreUnity } from "state/store";

import { useTranslation } from "react-i18next";

import { PairState } from "./PairTypes";
import { MeshDeviceInfo } from "comps/pairing/PairTypes";
import LongPressListItemButton from "comps/LongPressListItemButton";

import BatteryIcon from "./BatteryIcon";
import ConnectInfo from "./ConnectInfo";
import XraiErrorModal from "comps/XraiErrorModal";

// Uncomment for testing purposes.
// import { MockDeviceList } from "mocks/PairingMocks";

type Props = {
  open: boolean;
  onClose: () => void;
};

export function PairingDialog(props: Props) {
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [longPressKey, setLongPressKey] = useState<string>("");
  const [activeKey, setActiveKey] = useState<string>("");
  const [wifiName, setWifiName] = useState<string>("");
  const [wifiPass, setWifiPass] = useState<string>("");
  const [pairCode, setPairCode] = useState<string>("");
  const [localDevices, setLocalDevices] = useState<MeshDeviceInfo[]>([]);
  const [knownDevices, setKnownDevices] = useState<MeshDeviceInfo[]>([]);
  const [otherDevices, setOtherDevices] = useState<MeshDeviceInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadInfo, setLoadInfo] = useState<MeshDeviceInfo>(null);
  const [scanning, setScanning] = useStoreUnity<boolean>(
    "BluetoothScanning",
    false
  );

  const { t } = useTranslation();
  const theme = useTheme();

  const [pairState] = useStore<PairState>("Pairing");

  const [meshDevices] = useStoreUnity<MeshDeviceInfo[]>("MeshDevices");

  // Uncomment for testing purposes.
  // const deviceList = useCallback(() => MockDeviceList, []);

  const deviceList = useCallback((): MeshDeviceInfo[] => {
    if (!meshDevices) {
      return loading && loadInfo ? [loadInfo] : [];
    }

    if (!(loading && loadInfo)) {
      return meshDevices;
    }

    const found = meshDevices.some((device) => device.Id === loadInfo.Id);

    return found ? meshDevices : [...meshDevices, loadInfo];
  }, [loading, loadInfo, meshDevices]);

  const toggleKey = (key: string) => {
    if (longPressKey) return;

    if (activeKey !== key) {
      setActiveKey(key);
    } else {
      setActiveKey("");
    }
  };

  const toggleLongPressKey = (key: string) => {
    if (activeKey) return;

    if (longPressKey !== key) {
      setLongPressKey(key);
    } else {
      setLongPressKey("");
    }
  };

  const connect = async (info: MeshDeviceInfo) => {
    const routeKey = info?.Id || null;
    const tempCode = info?.Code || pairCode || null;
    setLoading(true);
    setLoadInfo(info);

    const payload = {
      routeKey: routeKey,
      pairCode: tempCode,
      wifiName: wifiName,
      wifiPass: wifiPass,
    };

    const cryload = await toJSONPostRequest(payload);

    const res = await xfetchLocal(`/pairdevice`, cryload);

    if (res.status !== 200 && res.status !== 204) {
      // Display Error Message
      xconsole.error(`Pairing error: ${res.statusText}`);
      setShowErrorModal(true);
      setLoading(false);
      setLoadInfo(null);
    } else {
      // We are connected - close the dialog
      // we will be asynchronously reloaded
      // props.onClose();
    }
  };

  const forget = async (info: MeshDeviceInfo) => {
    const routeKey = info?.Id || null;
    if (loadInfo?.Id === info?.Id) {
      setLoadInfo(null);
    }

    const payload = {
      routeKey: routeKey,
    };

    const cryload = await toJSONPostRequest(payload);
    const res = await xfetchLocal(`/forgetdevice`, cryload);

    if (res.status !== 200 && res.status !== 204) {
      xconsole.error(`Forget error: ${res.statusText}`);
    } else {
      // We are connected - close the dialog
      // we will be asynchronously reloaded
      // props.onClose();
    }
  };

  const unpair = async () => {
    setLoading(true);
    setLoadInfo(null);
    // TODO: Change the following to actor model
    const res = await xfetchLocal(`/unpairdevice`);
    // Check if call was successful
    if (res.status !== 200 && res.status !== 204) {
      // We are not paired - close the dialog
      props.onClose();
      setLoading(false);
      setLoadInfo(null);
    } else {
      // We are connected - close the dialog
      // we will be asynchronously reloaded
      // props.onClose();
    }
  };

  useEffect(() => {
    const devices = deviceList();
    const idsSeen = new Set<string>();
    const newLocalDevices: MeshDeviceInfo[] = [];
    const newKnownDevices: MeshDeviceInfo[] = [];
    const newOtherDevices: MeshDeviceInfo[] = [];

    for (const device of devices) {
      if (device.Route === "local" && !idsSeen.has(device.Id)) {
        newLocalDevices.push(device);
        idsSeen.add(device.Id);
      }
    }

    for (const device of devices) {
      if (device.Route === "known" && !idsSeen.has(device.Id)) {
        newKnownDevices.push(device);
        idsSeen.add(device.Id);
      }
    }

    for (const device of devices) {
      if (device.Route === "found" && !idsSeen.has(device.Id)) {
        newOtherDevices.push(device);
        idsSeen.add(device.Id);
      }
    }

    setLocalDevices(newLocalDevices);
    setKnownDevices(newKnownDevices);
    setOtherDevices(newOtherDevices);
  }, [loading, loadInfo, deviceList, meshDevices]);

  useEffect(() => {
    if (
      pairState?.OtherCode &&
      pairState.OtherCode === pairCode &&
      pairState.OtherStatus === "failure"
    ) {
      xconsole.debug("Pairing fail status");
      setShowErrorModal(true);
      setLoading(false);
      setLoadInfo(null);
    }
  }, [t, pairCode, pairState]);

  useEffect(() => {
    if (props.open) {
      xconsole.log(`pairing startscanning`);
      setScanning(true);
    } else {
      xconsole.log(`pairing stopscanning`);
      setScanning(false);
      setWifiName("");
      setWifiPass("");
      setPairCode("");
      setActiveKey("");
    }
  }, [props.open, setScanning]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={props.open}
      PaperProps={{ sx: { backgroundColor: theme.palette.background.default } }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {showErrorModal && (
          <XraiErrorModal
            open={showErrorModal}
            closeHandler={() => setShowErrorModal(!showErrorModal)}
            message={t("pairing.error")}
          />
        )}

        <DialogTitle>
          <Toolbar style={{ padding: "0 0" }}>
            <IconButton onClick={props.onClose}>
              <CloseOutlined />
            </IconButton>

            <Typography style={{ width: "100%" }}>
              {t("pairing.pair-with-device")}
            </Typography>
          </Toolbar>

          <Divider />
        </DialogTitle>

        <DialogContent
          sx={{
            px: "24px",
            pb: "12px",
          }}
        >
          {scanning && (
            <ListItem key="scan">
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{t("pairing.scanningnearby")}</Typography>

                <CircularProgress size="1.5rem" sx={{ ml: 2 }} color="info" />
              </div>
            </ListItem>
          )}

          <Stack direction="column">
            {/* NEARBY DEVICES */}
            <List>
              <ListItem key="head">
                <ListItemText>{t("pairing.nearbydevicelist")}</ListItemText>
              </ListItem>

              {otherDevices.flatMap((info: MeshDeviceInfo, index: number) => {
                let key = `n${index}`;

                const items = [
                  <ListItemButton key={key} onClick={() => toggleKey(key)}>
                    <ListItemIcon>
                      {info.Type === "phone" ? (
                        <PhoneIphone />
                      ) : info.Type === "glass" ? (
                        <Visibility />
                      ) : (
                        <LaptopMac />
                      )}
                    </ListItemIcon>

                    <ListItemText
                      primary={info.Name}
                      secondary={t(info.State)}
                    />

                    <BatteryIcon
                      state={info.PowerState}
                      level={info.PowerLevel}
                    />
                  </ListItemButton>,
                ];

                if (activeKey === key) {
                  items.push(
                    <ListItem key={`a${key}`}>
                      <ConnectInfo
                        pair
                        pairCode={pairCode}
                        setPairCode={setPairCode}
                        wifi
                        wifiName={wifiName}
                        setWifiName={setWifiName}
                        wifiPass={wifiPass}
                        setWifiPass={setWifiPass}
                        connect={connect}
                        loading={loading}
                        setLoading={setLoading}
                        state={info.State}
                        info={info}
                        onCancel={() => setActiveKey("")}
                      />
                    </ListItem>
                  );
                }

                return items;
              })}

              <ListItemButton key="other" onClick={() => toggleKey("other")}>
                <ListItemIcon>
                  <LaptopMac />
                </ListItemIcon>

                <ListItemText
                  primary={t("pairing.devicenotshown")}
                  secondary={t("unknown")}
                />

                <BatteryIcon />
              </ListItemButton>

              {activeKey === "other" && (
                <ListItem key={`a${"other"}`}>
                  <ConnectInfo
                    pair
                    pairCode={pairCode}
                    setPairCode={setPairCode}
                    connect={connect}
                    loading={loading}
                    setLoading={setLoading}
                    info={null}
                    onCancel={() => setActiveKey("")}
                  />
                </ListItem>
              )}
            </List>

            {/* KNOWN DEVICES */}
            <List>
              <ListItem key="head">
                <ListItemText>{t("pairing.knowndevicelist")}</ListItemText>
              </ListItem>

              {knownDevices.map((info: MeshDeviceInfo, index: number) => {
                const key = `k${index}`;

                const items = [
                  <LongPressListItemButton
                    key={key}
                    onLongPress={() => toggleLongPressKey(key)}
                    onClick={() => toggleKey(key)}
                  >
                    <ListItemIcon>
                      {info.Type === "phone" ? (
                        <PhoneIphone />
                      ) : info.Type === "glass" ? (
                        <Visibility />
                      ) : (
                        <LaptopMac />
                      )}
                    </ListItemIcon>

                    <ListItemText
                      primary={info.Name}
                      secondary={t(info.State)}
                    />

                    <BatteryIcon
                      state={info.PowerState}
                      level={info.PowerLevel}
                    />
                  </LongPressListItemButton>,
                ];

                if (longPressKey === key) {
                  items.push(
                    <ListItem key={`lp${key}`}>
                      <ConnectInfo
                        wifi
                        wifiName={wifiName}
                        setWifiName={setWifiName}
                        wifiPass={wifiPass}
                        setWifiPass={setWifiPass}
                        connect={connect}
                        loading={loading}
                        setLoading={setLoading}
                        state={info.State}
                        info={info}
                        onCancel={() => setLongPressKey("")}
                      />
                    </ListItem>
                  );
                }

                if (activeKey === key) {
                  items.push(
                    <ListItem key={`a${key}`}>
                      <ConnectInfo
                        forget={forget}
                        connect={connect}
                        loading={loading}
                        setLoading={setLoading}
                        info={info}
                        onCancel={() => setActiveKey("")}
                      />
                    </ListItem>
                  );
                }

                return items;
              })}

              {knownDevices.length === 0 && (
                <ListItem key="none">
                  <Typography variant="body2">
                    {t("pairing.noknowndevices")}
                  </Typography>
                </ListItem>
              )}
            </List>

            {/* THIS DEVICE */}
            <List>
              <ListItem key="head">
                <ListItemText>{t("pairing.thisdevicelist")}</ListItemText>
              </ListItem>

              {localDevices.flatMap((info: MeshDeviceInfo, index: number) => {
                let key = `t${index}`;

                const items = [
                  <ListItemButton key={key} onClick={unpair}>
                    <ListItemIcon>
                      {info.Type === "phone" ? (
                        <PhoneIphone />
                      ) : info.Type === "glass" ? (
                        <Visibility />
                      ) : (
                        <LaptopMac />
                      )}
                    </ListItemIcon>

                    <ListItemText
                      primary={info.Name}
                      secondary={t(info.State)}
                    />

                    <BatteryIcon
                      state={info.PowerState}
                      level={info.PowerLevel}
                    />
                  </ListItemButton>,
                ];

                return items;
              })}
            </List>
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
