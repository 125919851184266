import React, { useCallback, useEffect, useRef } from "react";

interface Props {
  onScroll?: (evt: any) => void;
  onScrollable?: (amount: number) => void;
  blockScroll?: boolean;
  children?: any;
  className?: string;
  style?: any;
}

export default function ScrollMonitor(props: Props) {
  const scrollEle = useRef<HTMLDivElement | null>(null);
  const wrapperEle = useRef<HTMLDivElement | null>(null);

  const { onScrollable } = props;

  const checkScrollStatus = useCallback(() => {
    if (scrollEle.current && wrapperEle.current) {
      onScrollable(
        wrapperEle.current.clientHeight - scrollEle.current.clientHeight
      );
    }
  }, [onScrollable, wrapperEle, scrollEle]);

  useEffect(() => {
    checkScrollStatus();
  }, [props.children, checkScrollStatus]);

  useEffect(() => {
    const element = scrollEle.current;
    const dispatchScroll = (evt: Event) => {
      if (!props.blockScroll) {
        const scrollAmount = Math.abs(
          element.scrollHeight - element.scrollTop - element.clientHeight
        );
        if (props.onScroll) props.onScroll(scrollAmount);
      }
    };
    element?.addEventListener("scroll", dispatchScroll);
    return () => {
      element?.removeEventListener("scroll", dispatchScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section
      style={props.style}
      className={props.className || ""}
      ref={scrollEle}
    >
      <div ref={wrapperEle} className="wrapper" style={{ height: "100%" }}>
        {props.children}
      </div>
    </section>
  );
}
