import React from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackButton from "comps/BackButton";
import {
  OnboardingContainer,
  BackButtonWrapper,
} from "themes/theme";
import { Thtml } from "comps/common/Thtml";
import usePageTitle from "hooks/usePageTitle";

import LanguageDropdown from "comps/language/LanguageDropdown";

const LanguageScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  usePageTitle("Onboard Language");

  return (
    <OnboardingContainer>
      <BackButtonWrapper>
        <BackButton
          name={t("language.back")}
          handler={() => navigate("/welcome")}
        />
      </BackButtonWrapper>

      <div className="x-spacer-sm" />

      <Typography variant="h1">{t("language.title")}</Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden scroll",
          paddingRight: theme.spacing(2),
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography variant="body1" component="p" sx={{ my: 2, mx: 0 }}>
            <Thtml tag="language.summary" />
          </Typography>

          <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
            <LanguageDropdown
              type="SourceLanguage"
              displayType="dropdown"
              config="wizard"
            />
            <LanguageDropdown
              type="TargetLanguage"
              displayType="dropdown"
              config="wizard"
            />
            {/* <SettingOption name="LocaleLanguage" /> */}
          </Stack>
        </Box>

        <Stack sx={{ my: 2 }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => navigate("/region")}
          >
            {t("continue")}
          </Button>
        </Stack>
      </div>
    </OnboardingContainer>
  );
};

export default LanguageScreen;
