import React from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  label: string;
  placeholder: string;
  value: string;
  required: boolean;
  setValue: (value: string) => void;
  format?: (value: string) => string;
};

const FormattedTextField: React.FC<Props> = ({
  label,
  placeholder,
  value,
  required,
  setValue,
  format,
}) => {
  const { t } = useTranslation();

  const handleInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const rv = evt.target.value;
    const fv = format ? format(rv) : rv;
    setValue(fv);
  };

  return (
    <TextField
      required={required}
      variant="outlined"
      autoCapitalize="false"
      autoComplete="false"
      fullWidth={true}
      value={value}
      onChange={handleInput}
      sx={{
        marginTop: "2ex",
        "& input": { textAlign: "center", height: "100%" },
        "& .MuiFormLabel-asterisk": { color: "#FF0000" },
      }}
      type="text"
      label={t(label)}
      placeholder={t(placeholder)}
    />
  );
};

export default FormattedTextField;
