import React, { useEffect, useCallback, forwardRef, ChangeEvent } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Radio, { RadioProps } from "@mui/material/Radio";
import { Theme, Palette } from "@mui/material/styles";
import {
  FormGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

import BasicThemesSwatch from "colors/BasicThemesSwatch";
import HueSwatch from "colors/HueSwatch";
import MoreColorsSwatch from "colors/MoreColorsSwatch";
import { hues } from "colors/Palettes";
import { ColorHueKey } from "colors/Types";

import xconsole from "utils/xconsole";
import { useTranslation } from "react-i18next";
import { useStore, useStoreLocal } from "state/store";

export const TooltipRadio = forwardRef<HTMLButtonElement, RadioProps>(
  (props, ref) => {
    const {
      "aria-labelledby": ariaLabelledBy,
      "aria-label": ariaLabel,
      inputProps,
      ...other
    } = props;

    return (
      <Radio
        ref={ref}
        {...other}
        inputProps={{
          ...inputProps,
          "aria-labelledby": ariaLabelledBy,
          "aria-label": ariaLabel,
        }}
      />
    );
  }
);

TooltipRadio.propTypes = {
  "aria-label": PropTypes.string,
  "aria-labelledby": PropTypes.string,
  inputProps: PropTypes.object,
};

const ColorTool: React.FC = () => {
  const [theme] = useStore<Theme>("appTheme");
  const [darkMode] = useStore<boolean>("darkMode");

  const [screenCast] = useStoreLocal<boolean>("screenCast");
  const [appThemeMode, setAppThemeMode] = useStoreLocal("appThemeMode");
  const [appPalette, setAppPalette] = useStoreLocal<Palette>("palette");
  const [hueRing, setHueRing] = useStoreLocal<ColorHueKey[]>("hueRing", []);
  const [showMoreColors, setShowMoreColors] = useStoreLocal(
    "showMoreColors",
    false
  );

  const handleAppThemeMode = (event: ChangeEvent<HTMLInputElement>) => {
    setAppThemeMode(event.target.value);
  };

  const { t } = useTranslation();

  const updateColorState = useCallback(
    (palette: Palette) => {
      const hue = palette.name as ColorHueKey;
      if (hues.includes(hue)) {
        setHueRing((oldRing) => {
          if (oldRing.includes(hue)) return oldRing;
          let newRing = oldRing.slice(0, 2);
          newRing.unshift(hue);
          return newRing;
        });
      }
      setAppPalette(palette);
    },
    [setAppPalette, setHueRing]
  );

  const updateColorStateEvent = useCallback(
    (palette: Palette) => (event: ChangeEvent<HTMLInputElement>) => {
      updateColorState(palette);
    },
    [updateColorState]
  );

  useEffect(() => {
    localStorage.setItem("showMoreColors", JSON.stringify(showMoreColors));
  }, [showMoreColors]);

  const colorPicker = () => {
    return (
      <div
        style={{
          height: "100%",
          paddingTop: theme.spacing(2),
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography gutterBottom variant="body2" component="div">
            {t("lightdarkmode", "Light and Dark Mode")}
          </Typography>

          <FormGroup>
            <FormControlLabel
              sx={{ marginRight: "0px" }}
              label={null}
              control={
                <FormControl>
                  <Select
                    sx={{
                      boxShadow: 2,
                    }}
                    labelId="theme-mode-label"
                    id="theme-mode-select"
                    value={appThemeMode}
                    onChange={handleAppThemeMode}
                    color="secondary"
                  >
                    <MenuItem value={"system"}>System</MenuItem>
                    <MenuItem value={"light"}>Light</MenuItem>
                    <MenuItem value={"dark"}>Dark</MenuItem>
                  </Select>
                </FormControl>
              }
            />
          </FormGroup>
        </Stack>

        <Stack direction="column" spacing={2}>
          <Grid container style={{ padding: "0px !important" }}>
            <Grid item xs={19}>
              <Box>
                <Typography variant="body2">{t("colors.favorites")}</Typography>

                <BasicThemesSwatch
                  updateColorStateEvent={updateColorStateEvent}
                  t={t}
                  appPalette={appPalette}
                  theme={theme}
                  darkMode={darkMode}
                  screenCast={screenCast}
                />

                <HueSwatch
                  updateColorStateEvent={updateColorStateEvent}
                  t={t}
                  appPalette={appPalette}
                  theme={theme}
                  hueRing={hueRing}
                  darkMode={darkMode}
                  screenCast={screenCast}
                />

                {showMoreColors && (
                  <MoreColorsSwatch
                    updateColorStateEvent={updateColorStateEvent}
                    t={t}
                    appPalette={appPalette}
                    theme={theme}
                    darkMode={darkMode}
                    screenCast={screenCast}
                  />
                )}

                <Checkbox
                  color="secondary"
                  checked={showMoreColors}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setShowMoreColors(event.target.checked);
                  }}
                  icon={<KeyboardArrowDownIcon />}
                  checkedIcon={<KeyboardArrowUpIcon />}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </div>
    );
  };

  const safelyRenderColorPicker = () => {
    // safely render error filled code
    try {
      return colorPicker();
    } catch (e) {
      xconsole.debug("colorPicker exception fail", e);
      return <div />;
    }
  };

  return <>{safelyRenderColorPicker()}</>;
};

export default ColorTool;
