import React, { ReactElement, useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { XMenuItem } from "types/types";

interface ButtonMenuProps {
  label?: string | ReactElement<any, any>;
  icon?: ReactElement<any, any>;
  menu: Array<XMenuItem>;
  title?: string;
  glow?: boolean;
  showHelperText?: boolean;
  helperText?: string;
}

const ButtonMenu = (props: ButtonMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!!props.icon && !props.label && (
        <IconButton
          title={props.title}
          id="ButtonMenu--ellipsis"
          onClick={handleClick}
        >
          {props.icon}
        </IconButton>
      )}

      {!!props.icon && props.label && (
        <Button
          title={props.title}
          onClick={handleClick}
          startIcon={props.icon}
        >
          {props.label}
        </Button>
      )}

      {!props.icon && props.label && (
        <Button title={props.title} onClick={handleClick}>
          {props.label}
        </Button>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        sx={{
          ".MuiMenu-paper": {
            py: "2px",
            borderRadius: "12px",
          },
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.menu.map((menuItem, index) => {
          return (
            <div key={index}>
              <MenuItem
                sx={{ minWidth: "180px" }}
                onClick={() => {
                  handleClose();
                  menuItem.onClick();
                }}
                disabled={menuItem.disabled}
              >
                {!!menuItem.icon && (
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                )}

                <ListItemText>{menuItem.label}</ListItemText>
              </MenuItem>

              {index < props.menu.length - 1 && <Divider />}
            </div>
          );
        })}
      </Menu>
    </>
  );
};

export default ButtonMenu;
