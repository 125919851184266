import React from "react";

type Props = {
  size?: number;
  style?: React.CSSProperties;
}

export default function MicrosoftLogo(props: Props) {
  const size = `${props.size || 24}px`;
  return (
    <svg
      style={props?.style}
      width={size}
      height={size}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M60.8302 60.8205H0V0H60.8302V60.8205Z" fill="#F1511B" />
      <path d="M128 60.8205H67.1648V0H127.995V60.8205H128Z" fill="#80CC28" />
      <path d="M60.8302 128H0V67.1796H60.8302V128Z" fill="#00ADEF" />
      <path d="M128 128H67.1648V67.1796H127.995V128H128Z" fill="#FBBC09" />
    </svg>
  );
}
