import { useEffect, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { SessionConversationDetail, Transcription } from "types/types";

import { xfetch } from "utils/xfetch";
import xconsole from "utils/xconsole";

const ConversationDetailScreen = () => {
  // Get Session Id from URL Param
  const [searchParams] = useSearchParams();
  const sessionId: string = searchParams.get("sessionId");

  // Hooks
  const [transcriptions, setTranscriptions] = useState([] as Transcription[]);
  const [loading, setLoading] = useState(true);

  const historyDataLoad = useCallback(
    async (sid: string) => {
      const data = { SessionId: sid };
      const reqSettings: RequestInit = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const res = await xfetch("/viewsession", reqSettings);

      try {
        const { Transcriptions } =
          await res.json() as SessionConversationDetail;

        Transcriptions.sort((a, b) => {
          const date1 = new Date(a.Created);
          const date2 = new Date(b.Created);

          if (date1 === date2) {
            return 0;
          } else if (date1 < date2) {
            return -1;
          } else {
            return 1;
          }
        });

        setTranscriptions(Transcriptions);
      } catch (error) {
        xconsole.error("fail viewsession",error);
      } finally {
        setLoading(false);
      }
    },
    [setTranscriptions, setLoading]
  );

  useEffect(() => {
    if (sessionId) {
      historyDataLoad(sessionId);
    }
  }, [sessionId, historyDataLoad]);

  return { transcriptions, loading };
};

export default ConversationDetailScreen;
