import React from "react";
import { ArrowBack } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { XRAIButton } from "./XRAIButton";

interface BackButtonProps {
  name?: string;
  handler?: () => void;
  color?: undefined | "black" | "white";
}

const BackButton = ({ name, handler }: BackButtonProps) => {
  return (
    <>
      <Box>
        <XRAIButton
          color="inherit"
          variant="text"
          startIcon={<ArrowBack />}
          onClick={handler}
          sx={{
            backgroundColor: "transparent",
            fontSize: "21px",
            fontWeight: "400",
            marginLeft: "-5px",
          }}
        >
          <Typography variant="button">{name}</Typography>
        </XRAIButton>
      </Box>
    </>
  );
};

export default BackButton;
