import React, { ChangeEvent } from "react";

import { TooltipRadio } from "colors/ColorTool";
import { getColorPalette, hues } from "./Palettes";

import CheckIcon from "@mui/icons-material/Check";
import { Box, Divider, Palette, Theme, Tooltip, Typography } from "@mui/material";

import { TFunction } from "i18next";

type Props = {
  updateColorStateEvent: (
    palette: Palette
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  t: TFunction<"translation", undefined, "translation">;
  appPalette: Palette;
  theme: Theme;
  darkMode: boolean;
  screenCast: boolean;
};

const MoreColorsSwatch: React.FC<Props> = ({
  updateColorStateEvent,
  t,
  appPalette,
  theme,
  darkMode,
  screenCast,
}) => (
  <>
    <Divider sx={{ marginTop: "12px", marginBottom: "12px" }} />

    <Typography variant="body2">{t("colors.additional")}</Typography>

    {hues.map((hue) => {
      let palette = getColorPalette(hue, darkMode, screenCast);
      let backgroundColor = palette.primary.main;

      return (
        <Tooltip key={palette.name} title={t(palette.name)} placement="right">
          <TooltipRadio
            sx={{ m: 0.25, p: 0 }}
            color="default"
            checked={appPalette.name === palette.name}
            onChange={updateColorStateEvent(palette)}
            value={palette.name}
            name={t(palette.name)}
            icon={
              <Box
                sx={{ width: 48, height: 48, boxShadow: 2 }}
                style={{ backgroundColor, border: "1px solid" }}
              />
            }
            checkedIcon={
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  border: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                style={{ backgroundColor }}
              >
                <CheckIcon
                  style={{
                    color: theme.palette.getContrastText(backgroundColor),
                    fontSize: 30,
                  }}
                />
              </Box>
            }
          />
        </Tooltip>
      );
    })}
  </>
);

export default MoreColorsSwatch;
