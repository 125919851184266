import React, { useEffect, ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import { Stack } from "@mui/system";
import { AppBar, Button, IconButton, Slide } from "@mui/material";
import { ArrowBack, HighlightOff } from "@mui/icons-material";

import usePageTitle from "hooks/usePageTitle";

import ProfileMenuItem from "comps/headerItems/ProfileMenuItem";
import SafeArea from "comps/common/SafeArea";

import { useStore } from "state/store";
import { wait } from "utils/wait";
import { useMinimize } from "hooks/useMinimize";
import { useTheme } from "@mui/material/styles";

interface DefaultLayoutProps {
  children?: any;
  className?: string;
  headerComponent?: ReactElement<any, any>;
  hideBack?: boolean;
  hideBackIcon?: boolean;
  quitShow?: boolean;
  quitText?: string;
  quitOnClick?: () => void;
  pageTitle?: string;
  backLabel?: string;
  backOnClick?: () => void;
  backNavigate?: any;
  hideMenuItems?: boolean;
  menuItems?: ReactElement<any, any>;
  style?: React.CSSProperties;
  barColor?: string;
  menuBorder?: boolean;
  bgColor?: string;
  fgColor?: string;
}

const DefaultLayout = (props: DefaultLayoutProps = {}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { isIdle, minimize } = useMinimize();
  const [iconSize] = useStore<number>("rootIconSize");

  usePageTitle(props.pageTitle || "XRAI Glass");

  useEffect(() => {
    const root = document.getElementById("root");
    const oldBgColor = root.style.backgroundColor;
    const newBgColor = props.bgColor || theme.palette.background.default;
    root.style.backgroundColor = newBgColor;
    return () => {
      document.getElementById("root")!.style.backgroundColor = oldBgColor;
    };
  }, [theme, props.bgColor]);

  return (
    <Stack
      className={props.className}
      direction="column"
      sx={{
        ...{ height: "100%" },
        ...{ minHeight: "100%" },
        ...{
          backgroundColor: props.barColor || "transparent",
          backgroundImage: theme.palette.image?.main ?? "none",
          backgroundSize: "100vw 100vh", // Adjusts image to cover the whole container
          backgroundRepeat: "no-repeat", // Prevents image from repeating
          backgroundPosition: "center", // Centers the image
        },
      }}
      style={{ ...props.style }}
    >
      {isIdle && <SafeArea position="top" />}

      {!globalThis.isXraiGo && (
        <Slide direction="down" in={!minimize} mountOnEnter unmountOnExit>
          <AppBar
            color="inherit"
            sx={{
              zIndex: 100,
              boxShadow: 0,
              backgroundColor: props.barColor || "transparent",
              backgroundImage: "none",
            }}
            position="sticky"
          >
            <SafeArea position="top" />
            <Stack
              alignItems="center"
              justifyContent="space-between"
              id="menu-stack"
              spacing={1}
              direction="row"
              sx={{
                px: "16px",
                pt: "10px",
                pb: "0",
                width: "100%",
                borderBottom: props.menuBorder
                  ? "0.5px solid rgba(255, 255, 255, 0.12);"
                  : "none",
              }}
            >
              {/* QuitButton */}
              {!!props.quitShow && (
                <Button
                  variant="text"
                  startIcon={
                    <HighlightOff
                      sx={{
                        width: iconSize,
                        height: iconSize,
                      }}
                      opacity="0.6"
                    />
                  }
                  onClick={async () => {
                    if (props.quitOnClick) {
                      await wait(300);
                      props.quitOnClick();
                    }
                  }}
                  sx={{
                    backgroundColor: "transparent",
                    color: theme.palette.text.primary,
                  }}
                >
                  {props.quitText}
                </Button>
              )}

              {/* BackButton */}
              {!props.hideBack && (
                <IconButton
                  onClick={async () => {
                    await wait(300);
                    if (props.backOnClick) props.backOnClick();
                    if (props.backNavigate)
                      navigate(props.backNavigate || "/home");
                  }}
                >
                  {props.hideBackIcon ? null : (
                    <ArrowBack sx={{ width: iconSize, height: iconSize }} />
                  )}
                </IconButton>
              )}

              <div className="x-filler" />

              {/* Provided menuItems or default */}
              {props.menuItems}
              {!props.menuItems && <ProfileMenuItem />}
            </Stack>
            {props.headerComponent}
          </AppBar>
        </Slide>
      )}
      {props.children}
    </Stack>
  );
};

export default DefaultLayout;
