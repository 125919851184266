import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import xconsole from "./xconsole";
import { xfetchLocal } from './xfetch';
import { encode as arrayBufferToBase64 } from 'base64-arraybuffer';

export async function getEncryptionKey()  {
    try {
      const res = await xfetchLocal("/getkey");
      const data = await res?.json();
      const key = data?.key;

      if (key === "bypass") {
        xconsole.debug("skip getEncryptionKey");
      } else if (key) {
        globalThis.aesKey = data.key as string;
      } else if (!key) {
        globalThis.aesKey = null;
      }
    } catch (e) {
      xconsole.error("fail getEncryptionKey",e);
    }
  };

export async function importKey(base64Key: string): Promise<CryptoJS.lib.WordArray> {
    return CryptoJS.enc.Base64.parse(base64Key);
}

export async function encrypt(body: string): Promise<[string, string]> {
    const key = await importKey(globalThis.aesKey);
    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    const encrypted = AES.encrypt(CryptoJS.enc.Utf8.parse(body), key, { iv: iv });
    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
    const cipherBase64 = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

    return [ivBase64, cipherBase64];
}

function wordArrayToByteArray(wordArray: CryptoJS.lib.WordArray): Uint8Array {
    const words = wordArray.words;
    const sigBytes = wordArray.sigBytes;
    const byteArray = new Uint8Array(sigBytes);

    for (let i = 0; i < sigBytes; i++) {
        const byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
        byteArray[i] = byte;
    }

    return byteArray;
}

export async function decrypt(cipher: ArrayBuffer | string, ivBase64: string): Promise<Uint8Array> {
    // AES key is only `null` in XRAI Go, otherwise undefined.
    if (globalThis.aesKey === null) {
        return new Uint8Array();
    }

    try {
        let cipherBase64: string;
        if (typeof cipher === 'string') {
            cipherBase64 = cipher;
        } else {
            cipherBase64 = arrayBufferToBase64(cipher);
        }

        const key = await importKey(globalThis.aesKey);
        const decrypted = AES.decrypt(
            CryptoJS.lib.CipherParams.create({
                ciphertext: CryptoJS.enc.Base64.parse(cipherBase64)
            }),
            key,
            { iv: CryptoJS.enc.Base64.parse(ivBase64) }
        );

        return wordArrayToByteArray(decrypted);

    } catch (e) {
        xconsole.error("fail decrypt",e);
        return new Uint8Array();
    }
}

export async function decryptEvent(data: string): Promise<string> {
    if ( data.length < 24 ) {
        return data;
    }

    const iv = data.slice(0, 24);
    const cipher = data.slice(24);
    const decryptedBytes = await decrypt(cipher, iv);

    return new TextDecoder("utf-8").decode(decryptedBytes);
}
