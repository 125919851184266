import React from "react";
import { Stack, tooltipClasses } from "@mui/material";
import { CloudOffOutlined, CloudOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LanguageNode } from "./types";
import ClickableTooltip from "comps/ClickableTooltip";

type Props = {
  lang: LanguageNode;
};

export default function LanguageIcons(props: Props) {
  const { t } = useTranslation();

  const formatVendorLabel = (label: string): string => {
    let finalLabel: string = label;

    switch (label) {
      case "aws":
        finalLabel = "Amazon";
        break;
      case "azure":
        finalLabel = "Microsoft";
        break;
      case "deepgram":
        finalLabel = "Deepgram";
        break;
      case "vosk":
        finalLabel = "Vosk";
        break;
    }

    return finalLabel;
  };

  const tooltipInfo = props.lang.cloud
    ? `${formatVendorLabel(props.lang.vendor)}: ${t("Language.cloud")}`
    : `${formatVendorLabel(props.lang.vendor)}: ${t("Language.no_cloud")}`;

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ mr: 1 }}>
      <ClickableTooltip
        id={props.lang.key}
        title={tooltipInfo}
        placement="left"
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            width: "200px",
          },
        }}
      >
        {props.lang.cloud ? <CloudOutlined /> : <CloudOffOutlined />}
      </ClickableTooltip>
    </Stack>
  );
}
