import React from "react";

type Props ={
  color?: "black" | "white";
  size?: number;
  style?: React.CSSProperties;
}

export default function AmazonLogo(props: Props) {
  const color = props.color || "black";
  const size = `${props.size || 24}px`;
  return (
    <svg
      style={props?.style}
      width={size}
      height={size}
      viewBox="0 0 149 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120.053 115.537C68.1408 140.243 35.9233 119.572 15.2998 107.017C14.0237 106.226 11.8546 107.202 13.7366 109.364C20.6073 117.695 43.124 137.775 72.5149 137.775C101.926 137.775 119.423 121.726 121.612 118.927C123.786 116.151 122.251 114.62 120.053 115.537ZM134.633 107.485C133.239 105.67 126.156 105.332 121.698 105.879C117.234 106.411 110.533 109.139 111.116 110.778C111.415 111.391 112.025 111.116 115.092 110.84C118.167 110.533 126.782 109.446 128.577 111.793C130.381 114.156 125.829 125.412 124.998 127.227C124.195 129.043 125.305 129.511 126.814 128.302C128.301 127.093 130.995 123.963 132.802 119.534C134.597 115.08 135.692 108.868 134.633 107.485Z"
        fill="#FF9900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.1478 65.1246C85.1478 71.6071 85.3116 77.0131 82.0351 82.77C79.3905 87.4509 75.2014 90.3293 70.5209 90.3293C64.1317 90.3293 60.4107 85.4614 60.4107 78.277C60.4107 64.0946 73.1182 61.5205 85.1478 61.5205V65.1246ZM101.927 105.682C100.827 106.664 99.236 106.735 97.9958 106.079C92.4728 101.493 91.4897 99.3631 88.4475 94.9866C79.3204 104.301 72.8612 107.086 61.0195 107.086C47.0245 107.086 36.1186 98.45 36.1186 81.1554C36.1186 67.6523 43.4441 58.4547 53.8577 53.9617C62.8915 49.9827 75.5057 49.2807 85.1478 48.1812V46.0279C85.1478 42.0726 85.4517 37.3922 83.1351 33.9756C81.0988 30.9097 77.214 29.6459 73.7969 29.6459C67.4551 29.6459 61.7915 32.8987 60.4107 39.6387C60.1295 41.1368 59.03 42.6113 57.5323 42.6814L41.3841 40.9499C40.027 40.645 38.5294 39.5454 38.9038 37.4622C42.6248 17.8979 60.2933 12 76.1135 12C84.211 12 94.7889 14.1533 101.178 20.285C109.276 27.8438 108.503 37.9304 108.503 48.9063V74.8367C108.503 82.6299 111.733 86.0465 114.775 90.2593C115.851 91.7569 116.086 93.5595 114.728 94.6822C111.335 97.5138 105.297 102.78 101.974 105.729L101.927 105.682Z"
        fill={color}
      />
      <path
        d="M120.053 115.537C68.1408 140.243 35.9233 119.572 15.2998 107.017C14.0237 106.226 11.8546 107.202 13.7366 109.364C20.6073 117.695 43.124 137.775 72.5149 137.775C101.926 137.775 119.423 121.726 121.612 118.927C123.786 116.151 122.251 114.62 120.053 115.537ZM134.633 107.485C133.239 105.67 126.156 105.332 121.698 105.879C117.234 106.411 110.533 109.139 111.116 110.778C111.415 111.391 112.025 111.116 115.092 110.84C118.167 110.533 126.782 109.446 128.577 111.793C130.381 114.156 125.829 125.412 124.998 127.227C124.195 129.043 125.305 129.511 126.814 128.302C128.301 127.093 130.995 123.963 132.802 119.534C134.597 115.08 135.692 108.868 134.633 107.485Z"
        fill="#FF9900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.1478 65.1246C85.1478 71.6071 85.3116 77.0131 82.0351 82.77C79.3905 87.4509 75.2014 90.3293 70.5209 90.3293C64.1317 90.3293 60.4107 85.4614 60.4107 78.277C60.4107 64.0946 73.1182 61.5205 85.1478 61.5205V65.1246ZM101.927 105.682C100.827 106.664 99.236 106.735 97.9958 106.079C92.4728 101.493 91.4897 99.3631 88.4475 94.9866C79.3204 104.301 72.8612 107.086 61.0195 107.086C47.0245 107.086 36.1186 98.45 36.1186 81.1554C36.1186 67.6523 43.4441 58.4547 53.8577 53.9617C62.8915 49.9827 75.5057 49.2807 85.1478 48.1812V46.0279C85.1478 42.0726 85.4517 37.3922 83.1351 33.9756C81.0988 30.9097 77.214 29.6459 73.7969 29.6459C67.4551 29.6459 61.7915 32.8987 60.4107 39.6387C60.1295 41.1368 59.03 42.6113 57.5323 42.6814L41.3841 40.9499C40.027 40.645 38.5294 39.5454 38.9038 37.4622C42.6248 17.8979 60.2933 12 76.1135 12C84.211 12 94.7889 14.1533 101.178 20.285C109.276 27.8438 108.503 37.9304 108.503 48.9063V74.8367C108.503 82.6299 111.733 86.0465 114.775 90.2593C115.851 91.7569 116.086 93.5595 114.728 94.6822C111.335 97.5138 105.297 102.78 101.974 105.729L101.927 105.682Z"
        fill={color}
      />
    </svg>
  );
}
