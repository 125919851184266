import { useCallback } from "react";
import { useStoreUnity } from "state/store";
import { jsonEqual } from "types/types";

const sessionIdZero = "00000000-0000-0000-0000-000000000000";

const useSessionManager = () => {
  const [targetSessionId, setTargetSessionId] = useStoreUnity<null | string>(
    "TargetSessionId",
    null
  );

  const [activeSessionId] = useStoreUnity<null | string>(
    "ActiveSessionId",
    null
  );

  const [activeSegmentId] = useStoreUnity<null | string>(
    "ActiveSegmentId",
    null
  );

  const isActiveSession = useCallback((): boolean => {
    return activeSessionId && activeSessionId !== sessionIdZero;
  }, [activeSessionId]);

  const switchSession = useCallback(
    (sessionId: string) => {
      if (!jsonEqual(targetSessionId, sessionId)) {
        setTargetSessionId(sessionId);
      }
    },
    [targetSessionId, setTargetSessionId]
  );

  const resetSession = useCallback(() => {
    setTargetSessionId(sessionIdZero);
  }, [setTargetSessionId]);

  return {
    targetSessionId,
    activeSessionId,
    activeSegmentId,
    isActiveSession,
    switchSession,
    resetSession,
  };
};

export default useSessionManager;
