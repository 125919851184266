import React, { useCallback, useEffect, useState, useRef } from "react";

import { CloseOutlined, TuneOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  styled,
  Paper,
  Typography,
} from "@mui/material";

import { useThemeUpdater } from "themes/ThemeLoader";
import ColorTool from "colors/ColorTool";

import { useTranslation } from "react-i18next";
import { getConfiguration } from "comps/settings/getConfigurationSetting";
import { toJSONPostRequest, xfetchLocal } from "utils/xfetch";

import { useStore, useStoreLocal, useStoreUnity } from "state/store";
import { getOrMakeStore } from "state/store";

import CustomTabPanel from "./CustomTabPanel";
import SettingsScreen from "./SettingsScreen";
import FontsScreen from "./FontsScreen";
import SpacingScreen from "./SpacingScreen";
import SettingsNavigation from "./SettingsNavigation";
import StreamScreen from "./StreamScreen";
import CaptionPreview from "comps/subtitles/CaptionPreview";
import useActivePopup from "hooks/useActivePopup";
import XraiGoSettings from "./XraiGoSettings"

export const PaperSettings = styled(Paper)({
  backgroundColor: "transparent",
  marginTop: "5px",
  paddingBottom: "12px",
  elevation: 3,
  width: "100%",
  height: "100%",
});

type Props = {
  inAr: boolean;
};

interface VideoStatus {
  camera: string;
  enabled: boolean;
  cameras: string[];
}

const SubtitleSettingsDrawer: React.FC<Props> = ({ inAr }) => {
  // global persistent state
  const [textAlign] = useStoreLocal<string>("textAlign");
  const [fontSize] = useStoreLocal<number>("fontSize");
  const [letterSpacing] = useStoreLocal<number>("letterSpacing");
  const [lineSpacing] = useStoreLocal<number>("lineSpacing");
  const [lineWidth] = useStoreLocal<number>("lineWidth");
  const [textTransform] = useStoreLocal<string>("textTransform");
  const [fontWeight] = useStoreLocal<number>("fontWeight");
  const [wordSpacing] = useStoreLocal<number>("wordSpacing");
  const [margin] = useStoreLocal<number>("subtitleMargin");

  // global ephermal state
  const [, setCameraPass] = useStore<boolean>("cameraPass", false);
  const [isDrawerOpen, setIsDrawerOpen] = useStore<boolean>(
    "isDrawerOpen",
    false
  );

  // legacy global state
  const [enableNoiseCancel, setEnableNoiseCancel] = useState(false);
  const [enableServer] = useStoreUnity<boolean>("EnableServer", false);

  // other state
  const { t } = useTranslation();
  const theme = useTheme();
  const { updateTheme } = useThemeUpdater();
  useActivePopup(isDrawerOpen);

  const containerRef = useRef(null);
  const [bgHeight, setBgHeight] = useState("100%");

  // Dynamically control overflow to extend color to the bottom of the drawer.
  if (containerRef.current) {
    if (containerRef.current.scrollHeight + "px" !== bgHeight) {
      setBgHeight(containerRef.current.scrollHeight + "px");
    }
  }

  const [activeTab, setActiveTab] = useState(0);
  const [iconSize] = useStore<number>("rootIconSize");

  const getVideoStatus = useCallback(async () => {
    if (globalThis.isXraiGo) return;

    const payload = await toJSONPostRequest(null);
    const call = await xfetchLocal("/videostatus", payload);
    const status: VideoStatus = await call.json();
    const enabled = status.enabled ? true : false;

    setCameraPass(enabled);
  }, [setCameraPass]);

  const initView = useCallback(() => {
    getConfiguration().then((res) => {
      if (globalThis.isXraiGo) return;

      let noiseCancel = res.get("EnableNoiseCancel");
      if (noiseCancel) {
        setEnableNoiseCancel(noiseCancel.Value === "True");
      }
    });

    getVideoStatus();
    // warning: infinite loop on getVideoStatus as it depends on props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("subtitleMargin", margin.toString());
    document.documentElement.style.setProperty(
      "--subtitle-margin",
      `${margin * 2}%`
    );
  }, [margin]);

  useEffect(() => {
    if (isDrawerOpen) initView();
  }, [isDrawerOpen, initView]);

  useEffect(() => {
    document.documentElement.style.setProperty("--text-align", textAlign);
  }, [textAlign]);

  useEffect(() => {
    getOrMakeStore<number>("rootFontScale").set(fontSize);
  }, [fontSize]);

  useEffect(() => {
    updateTheme({
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              textTransform: textTransform,
            },
          },
        },
      },
    });
    // warning: updateTheme infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textTransform]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--word-spacing",
      `${wordSpacing}rem`
    );

    document.documentElement.style.setProperty(
      "--font-weight",
      fontWeight.toString()
    );
  }, [wordSpacing, fontWeight]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--letter-spacing",
      `${letterSpacing}rem`
    );
  }, [letterSpacing]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--line-spacing",
      lineSpacing.toString()
    );
  }, [lineSpacing]);

  useEffect(() => {
    document.documentElement.style.setProperty("--line-width", `${lineWidth}%`);
  }, [lineWidth]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--text-transform",
      textTransform
    );
  }, [textTransform]);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const id = "subtitle-setting-popover";
  const showCaptionPreview =
    activeTab === 1 || activeTab === 2 || activeTab === 3;

  return (
    <div className="settings-popover">
      <IconButton
        id="SubtitleSettingsDrawer--settingsButton"
        color="white"
        aria-describedby={isDrawerOpen ? id : ""}
        onClick={openDrawer}
      >
        <TuneOutlined
          style={{
            width: iconSize,
            height: iconSize,
          }}
        />
      </IconButton>

      <Drawer
        id={id}
        className="subtitle-settings-drawer"
        anchor="bottom"
        open={isDrawerOpen}
        onClose={closeDrawer}
        PaperProps={{
          sx: {
            height: "72%",
            overflow: "hidden",
            backgroundColor:
              theme.palette.panel?.main ?? theme.palette.background.paper,
            backgroundImage: theme.palette.image?.panel ?? "none",
            backgroundSize: "cover", // Adjusts image to cover the whole container
            backgroundRepeat: "no-repeat", // Prevents image from repeating
            backgroundPosition: "center", // Centers the image
          },
        }}
      >
        <Stack
          direction="column"
          sx={{
            flex: 1,
            p: 0.5,
            height: "100%",
          }}
        >
          {/* Navigation */}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              flex: "0 0 auto",
            }}
          >
            <SettingsNavigation
              activeTab={activeTab}
              handleChangeTab={handleChangeTab}
              theme={theme}
              t={t}
            />
          </Box>

          {/* Body Content */}
          <Paper
            sx={{
              flex: 1,
              overflow: "hidden auto",
              mt: 1,
              // backgroundColor: theme.palette.primary.light,
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
            }}
          >
            {showCaptionPreview && (
              <div style={{ padding: theme.spacing(2), paddingBottom: 0 }}>
                <Typography
                  variant="body2"
                  sx={{
                    p: 1,
                  }}
                >
                  {t("Preview")}
                </Typography>

                <CaptionPreview t={t} />
              </div>
            )}

            {!globalThis.isXraiGo && (
              <SettingsScreen
                activeTab={activeTab}
                inAr={inAr}
                noiseCancel={enableNoiseCancel}
                t={t}
              />
            )}

            {globalThis.isXraiGo && (
              <XraiGoSettings activeTab={activeTab} index={0} />
            )}

            <CustomTabPanel
              value={activeTab}
              index={1}
            >
              <ColorTool />
            </CustomTabPanel>

            <FontsScreen
              activeTab={activeTab}
              t={t}
              index={2}
            />

            <SpacingScreen
              activeTab={activeTab}
              t={t}
              index={3}
            />

            {!globalThis.isXraiGo && enableServer && (
              <StreamScreen
                activeTab={activeTab}
                index={4}
              />
            )}
          </Paper>

          {/* Footer */}
          <Box
            sx={{
              mt: 1,
              display: "flex",
              justifyContent: "right",
              flex: "0 0 auto",
            }}
          >
            <Button
              endIcon={<CloseOutlined />}
              onClick={() => closeDrawer()}
              color="inherit"
              variant="text"
              sx={{ backgroundColor: "transparent" }}
            >
              {t("Close")}
            </Button>
          </Box>
        </Stack>
      </Drawer>
    </div>
  );
};

export default SubtitleSettingsDrawer;
