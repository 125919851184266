import { SubtitleLiveFinal, SubtitleLiveFluid } from "types/types";

let fluidSubtitle:SubtitleLiveFluid | undefined = undefined;
let finalSubtitles:SubtitleLiveFinal[] = [];
let seqN: number | undefined = undefined;
let listeners:Array<any> = [];

export const lastFluidSeqN = (): number => {
  return fluidSubtitle ? fluidSubtitle.n : 0 ;
}

export const lastFinalSeqN = (): number => {
  const end = finalSubtitles.length;
  return end > 0 ? finalSubtitles[end-1].n : 0 ;
}

export const showFluid = (): boolean => {
  return fluidSubtitle?.t && (lastFluidSeqN() > lastFinalSeqN());
}

export const fluidSubtitleStore = {
  set(_fluidSubtitle:SubtitleLiveFluid) {
    fluidSubtitle = _fluidSubtitle;
    emitChange();
  },
  get() {
    return fluidSubtitle;
  },
  subscribe(listener:any) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  }
}

export const finalSubtitlesStore = {
  push(_finalSubtitle:SubtitleLiveFinal) {
    finalSubtitles.push(_finalSubtitle);
    emitChange();
  },
  clear() {
    finalSubtitles = [];
    emitChange();
  },
  get() {
    return finalSubtitles;
  },
  subscribe(listener:any) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  }
}

export const sequenceNumberStore = {
  set(_num: number | undefined) {
    seqN = _num;
    emitChange();
  },
  get() {
    return seqN
  },
  subscribe(listener:any) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  }
}

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}
