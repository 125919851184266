import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Button,
  Divider,
  Input,
  List,
  Paper,
  Slide,
  styled,
  Theme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { ThemeContext } from "@emotion/react";
import { useTranslation } from "react-i18next";
import ChatBubbleTwoToneIcon from "@mui/icons-material/ChatBubbleTwoTone";
import {
  SessionHistory,
  SessionHistoryUtterance,
  SessionMeta,
} from "types/types";
import { useXRaiSnackbar } from "hooks/useXraiSnackbar";
import { useDeleteConversation } from "comps/conversations/useDeleteConversation";
import EmptyContent from "comps/common/EmptyContent";
import { xfetch } from "utils/xfetch";
import vibrate from "utils/vibrate";
import ConversationSkeleton from "./ConversationSkeleton";
import xconsole from "utils/xconsole";
import { useStore, useStoreLocal, useStoreUnity } from "state/store";
import ConversationListInfiniteScroll from "./ConversationListInfiniteScroll";
import XraiModal from "comps/XraiModal";
import { Audiotrack, FolderZip, TextSnippet } from "@mui/icons-material";
import useGoogleAnalytics from "hooks/useGoogleAnalytics";
import { useBottomSheet } from "hooks/useBottomSheet";

void xconsole;

interface ConversationListProps {
  keyword: string;
  editMode: boolean;
  searchResults?: undefined | SessionHistory[];
  conversationTitles: { [key: string]: string };
  onEditModeEnd: () => void;
  onConversationsLoaded: (items: SessionHistory[]) => void;
  onSelectionChange: (items: SessionMeta[]) => void;
  startCaptionHandler: (e?: any, action?: "pause" | "play") => Promise<void>;
}

export const fetchHistory = async (): Promise<SessionHistory[]> => {
  const res = await xfetch("/sessionhistory");
  if (!res) {
    return [];
  }
  const data = (await res.json()) as SessionHistory[];
  data.sort((a, b) => {
    const date1 = new Date(a.Session.Created);
    const date2 = new Date(b.Session.Created);
    if (date1 === date2) {
      return 0;
    } else if (date1 > date2) {
      return -1;
    } else {
      return 1;
    }
  });
  return data;
};

let historySeqNumDone: number = 0;

const ConversationList = (props: ConversationListProps) => {
  // TODO: Implement recording.
  const [hasRecording] = useState(false);
  const [renameModalIsOpen, setRenameModalIsOpen] = useState(false);
  const [renameValue, setRenameValue] = useState("");
  const [squashHeight, setSquashHeight] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [searchResults, setSearchResults] = useState<
    undefined | SessionHistoryUtterance[]
  >(undefined);
  const [selectedConversations, setSelectedConversations] = useState<
    SessionMeta[]
  >([]);

  const [loading, setLoading] = useStore<boolean>(
    "SessionHistoryLoading",
    true
  );
  const [conversations, setConversations] = useStore<SessionHistoryUtterance[]>(
    "SessionHistory",
    []
  );

  const [conversationTitles, setConversationTitles] = useStoreLocal<{
    [key: string]: string;
  }>("conversationTitles", {});

  const [historySeqNum] = useStoreUnity<number>("SessionHistorySeqNum", 0);

  const { t } = useTranslation();
  const theme = useContext(ThemeContext) as Theme;
  const ga = useGoogleAnalytics();

  const DeletePrompt = useDeleteConversation();
  const AlertSnack = useXRaiSnackbar({
    position: "top",
  });

  const getReqSettings = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const ShareDrawer = useBottomSheet({
    title: t("conversations.share"),
    menuItems: [
      {
        icon: <TextSnippet />,
        label: t("conversations.share_transcript"),
        onClick: async () => {
          xfetch(
            "/sharetranscript?sessionid=" + selectedConversations?.[0]?.Id,
            getReqSettings
          );

          ga.event("share-transcript");
        },
      },
      {
        icon: <Audiotrack />,
        label: t("conversations.share_audio"),
        disabled: !hasRecording,
        onClick: async () => {
          xfetch(
            "/sharerecording?sessionid=" + selectedConversations?.[0]?.Id,
            getReqSettings
          );

          ga.event("share-audio");
        },
      },
      {
        icon: <FolderZip />,
        label: t("conversations.share_transcript_audio"),
        disabled: !hasRecording,
        onClick: async () => {
          xfetch(
            "/sharepackage?sessionid=" + selectedConversations?.[0]?.Id,
            getReqSettings
          );

          ga.event("share-audio-transcript");
        },
      },
    ],
  });

  const conversationDataLoad = useCallback(async () => {
    if (!historySeqNum) return;
    if (historySeqNum !== historySeqNumDone) {
      historySeqNumDone = historySeqNum;
      const data = await fetchHistory();

      if (!data || data.length === 0) {
        setSquashHeight(true);
      } else {
        setSquashHeight(false);
      }

      // Uncomment for testing purposes
      // const dummyData: SessionHistory[] = Array.from({ length: 100 }, () => [
      //   ...data,
      // ]).flat();

      setConversations(
        data?.sort((a: SessionHistoryUtterance, b: SessionHistoryUtterance) => {
          return new Date(a.Session.End) > new Date(b.Session.End) ? -1 : 1;
        })
      );

      props.onConversationsLoaded(data);
      //setFilteredConversations(data);
      setLoading(false);
    }
  }, [setLoading, historySeqNum, setConversations, props]);

  /** Toggles the selected status of a ConversationListItem */
  const toggleSelected = (sessionHistory: SessionMeta) => {
    let updated = [...selectedConversations];
    if (selectedConversations.includes(sessionHistory)) {
      updated = updated.filter((sh) => sh !== sessionHistory);
    } else {
      updated.push(sessionHistory);
    }
    setSelectedConversations(updated);
    vibrate(10);
    props.onSelectionChange(updated);
  };

  const handleRename = async () => {
    setRenameValue(conversationTitles[selectedConversations[0].Id] || "");
    setRenameModalIsOpen(true);
  };

  const handleShare = () => {
    ShareDrawer.openDrawer();
  };

  const handleDelete = async () => {
    let deleted = await DeletePrompt.deleteConversations(
      selectedConversations.map((s) => s.Id)
    );
    AlertSnack.success(
      `${deleted.length} ${t("conversations.title")} ${t("Deleted")}`
    );
    setSelectedConversations([]);
    props.onEditModeEnd();
    conversationDataLoad();
  };

  const clearEditMode = () => {
    setSelectedConversations([]);
    setEditMode(false);
    props.onEditModeEnd();
  };

  const EditModeButton = styled(Button)({
    minHeight: "40px",
    padding: `0 ${theme.spacing(2)}`,
    margin: theme.spacing(0.5),
  });

  useEffect(() => {
    if (props.searchResults) {
      setSearchResults(props.searchResults);
    } else {
      setSearchResults(undefined);
    }
  }, [props.searchResults]);

  useEffect(() => {
    setEditMode(props.editMode);
  }, [props.editMode]);

  useEffect(() => {
    conversationDataLoad();
  }, [historySeqNum, conversationDataLoad]);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, [mounted]);

  return (
    <>
      <div className="x-filler" style={{ height: "100%" }}>
        {searchResults?.length === 0 && (
          <Stack>
            <EmptyContent
              header={
                <ChatBubbleTwoToneIcon
                  sx={{
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                    width: "120px",
                    height: "120px",
                  }}
                />
              }
              title={t("conversations.no_conversations_found")}
              message={t("conversations.no_conversations_message")}
            />
          </Stack>
        )}

        {!loading && !searchResults && conversations?.length === 0 && (
          <Slide in={true} direction="up">
            <Stack>
              <EmptyContent
                header={
                  <ChatBubbleTwoToneIcon
                    sx={{
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.light
                          : theme.palette.primary.main,
                      width: "120px",
                      height: "120px",
                    }}
                  />
                }
                title={t("conversations.start")}
                message={
                  <div style={{ width: "100%", marginBottom: "8px" }}>
                    {t("conversations.no_conversations_message")}
                  </div>
                }
              />
            </Stack>
          </Slide>
        )}

        <List sx={{ width: "100%", height: squashHeight ? "0" : "100%" }}>
          {loading && (
            <Stack
              spacing={3}
              style={{
                padding: "24px 16px",
              }}
            >
              {new Array(10).fill(0).map((c, index) => {
                return (
                  <div key={index} style={{}}>
                    <ConversationSkeleton />
                    <div style={{ height: "20px" }} />
                    <Divider
                      variant="fullWidth"
                      sx={{ mb: "-6px", opacity: 0.4 }}
                      component="li"
                    />
                  </div>
                );
              })}
            </Stack>
          )}

          {(searchResults || conversations) && (
            <ConversationListInfiniteScroll
              data={searchResults || conversations}
              conversationTitles={props.conversationTitles}
              selectedConversations={selectedConversations}
              editMode={props.editMode}
              toggleSelected={toggleSelected}
              mounted={mounted}
            />
          )}
        </List>
      </div>

      {DeletePrompt.Dialog}
      {AlertSnack.Snackbar}
      {ShareDrawer.drawer}

      {renameModalIsOpen && (
        <XraiModal
          title={t("conversations.rename_conversation")}
          content={
            <Input
              autoFocus
              value={renameValue}
              onChange={(e) => setRenameValue(e.target.value)}
              type="text"
              placeholder={`${t("untitled")}`}
              style={{
                width: "100%",
                padding: "0.5rem",
                borderRadius: "0.25rem",
              }}
            />
          }
          closeHandler={() => setRenameModalIsOpen(false)}
          confirmHandler={async () => {
            setConversationTitles((prev) => ({
              ...prev,
              [selectedConversations?.[0]?.Id]: renameValue,
            }));

            setRenameModalIsOpen(false);

            AlertSnack.success(t("conversations.rename_success"));
          }}
          open={renameModalIsOpen}
        />
      )}

      {editMode && (
        <Paper
          elevation={0}
          sx={{ py: 1, position: "sticky", bottom: "0", borderRadius: 0 }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <div style={{ padding: `0 ${theme.spacing(0.5)}`}}>
              <EditModeButton
                onClick={() => clearEditMode()}
                variant="contained"
              >
                {t("Cancel")}
              </EditModeButton>

              <EditModeButton
                onClick={handleRename}
                disabled={selectedConversations?.length !== 1}
                variant="contained"
              >
                {t("Rename")}
              </EditModeButton>

              <EditModeButton
                onClick={handleShare}
                disabled={selectedConversations?.length !== 1}
                variant="contained"
              >
                {t("Share")}
              </EditModeButton>

              <EditModeButton
                onClick={handleDelete}
                disabled={!selectedConversations.length}
                variant="contained"
                color="error"
              >
                <span>{t("Delete")}</span>
                {!!selectedConversations.length && (
                  <span>
                    <span style={{ paddingLeft: "6px" }}>
                      {selectedConversations.length}
                    </span>
                  </span>
                )}
              </EditModeButton>
            </div>
          </Stack>
        </Paper>
      )}
    </>
  );
};

export default ConversationList;
