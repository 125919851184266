import React from "react";
import {
  ClickAwayListener,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useStoreLocal } from "state/store";

type Props = {
  id: string;
  title: string;
  children: React.ReactElement;
  placement?: TooltipProps["placement"];
  sx?: TooltipProps["sx"];
};

const CustomTooltip = styled(
  ({ className, PopperProps, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      PopperProps={{
        ...PopperProps,
        sx: {
          zIndex: 99999,
        },
      }}
    />
  )
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "1rem",
    padding: 10,
    borderRadius: 10,
  },
}));

const ClickableTooltip: React.FC<Props> = ({
  id,
  title,
  children,
  placement,
  sx,
}) => {
  const [openId, setOpenId] = useStoreLocal<string | null>(
    "ClickableTooltipOpenId",
    null
  );

  const [isOpen, setIsOpen] = React.useState(false);

  const handleTooltipOpen = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isOpen) {
      setIsOpen(true);
      setOpenId(id);
    } else {
      setIsOpen(false);
      setOpenId(null);
    }
  };

  const handleTooltipClose = () => {
    if (isOpen) {
      setOpenId(null);
    }
  };

  React.useEffect(() => {
    if (openId === id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openId, id, setIsOpen]);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <CustomTooltip
          placement={placement ?? "bottom"}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={isOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={title}
          onClick={(e) => handleTooltipOpen(e, id)}
          sx={sx}
        >
          {children}
        </CustomTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ClickableTooltip;
