import React, { useEffect, CSSProperties } from "react";
import { styled } from '@mui/material/styles';
import {  Box, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Logo from "comps/Logo";

export const BackButtonWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

export const ContinueGroup = styled(Box)({
  marginTop: "auto",
  justifyContent: "center",
  paddingBottom: "24px",
});

export const LogoPosition = styled(Logo)({
  position: "absolute",
  left: "50vw",
  top: "0vh",
  transform: "translate(-50%, 50%)",
  textTransform: 'none',
});

interface OnboardingProps {
  children?: any;
}

export const OnboardingContainer: React.FC<OnboardingProps> = (
  props: OnboardingProps = {}
) => {

  const theme = useTheme();

  const style: CSSProperties = {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    padding: "24px",
  };

  useEffect(() => {
    const root = document.getElementById("root");
    const oldBgColor = root ? root.style.backgroundColor : "";
    const newBgColor = theme.palette.background.default;
    if (root) root.style.backgroundColor = newBgColor;
    return () => {
      if (root) root.style.backgroundColor = oldBgColor;
    };
  }, [theme]);

  return (
    <Container style={style} maxWidth="sm">
      {props.children}
    </Container>
  );
};
