import React from "react";

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useStore, useStoreLocal } from "state/store";

import Flag from "react-world-flags";
import { ReactComponent as KlingonFlag } from "assets/Klingon-Empire-Flag.svg";
import LanguageListItem from "./LanguageListItem";
import { LangCodeToCountry } from "./LangCodeToCountry";
import { DownloadStatus } from "./DownloaderStore";
import {
  FavoriteLanguagePayload,
  LangSettingType,
  LanguageGroup,
  LanguageNode,
} from "./types";

import FavoriteToggle from "comps/FavoriteToggle";
import RoundCheckbox from "comps/RoundCheckbox";
import { ContentCopy } from "@mui/icons-material";

type Props = {
  langKey: string;
  index: number;
  groupedLanguages: LanguageGroup;
  favoriteLanguages: FavoriteLanguagePayload[];
  type: LangSettingType;
  downloadStatus: DownloadStatus;
  selectedLanguages: string[];
  setFavoriteSubtitleLang: (lang: FavoriteLanguagePayload) => Promise<void>;
  toggleFavoriteSpokenLangs: (lang: FavoriteLanguagePayload) => Promise<void>;
  toggleFavorite: (id: string) => Promise<FavoriteLanguagePayload[]>;
  selectLanguage: (key: string) => void;
  isLangDownloaded: (langNode: LanguageNode) => boolean;
};

const LanguageListItemContainer: React.FC<Props> = ({
  langKey,
  index,
  groupedLanguages,
  favoriteLanguages,
  type,
  downloadStatus,
  selectedLanguages,
  setFavoriteSubtitleLang,
  toggleFavoriteSpokenLangs,
  toggleFavorite,
  selectLanguage,
  isLangDownloaded,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const xraiNoTranslationLanguageNode: LanguageNode = {
    key: "xrai._xa-SP",
    cloud: true,
    entitlement: "",
    langcode: "",
    local_down: 0,
    local_size: 0,
    speaker_id: false,
    vendor: "xrai",
  };

  const isXraiVendor = favoriteLanguages
    .filter((f) => f.vendor === "xrai")
    .map((f) => f.langcode)
    .includes(langKey);

  const groupedLangs = groupedLanguages?.[langKey] ?? [
    xraiNoTranslationLanguageNode,
  ];

  const countryCode = LangCodeToCountry[groupedLangs?.[0]?.langcode];

  const selected = selectedLanguages
    ? selectedLanguages.find((sl) => {
        return groupedLangs.find((gl) => gl.key === sl);
      })
    : "";

  const favorited = favoriteLanguages
    ? favoriteLanguages.find((f) => groupedLangs.find((gl) => gl.key === f.key))
    : undefined;

  //TODO: this needs to be useStoreUnity and I'm not sure the point of this?
  const [translationEngineSelection] = useStoreLocal<string>(
    "translationEngineSelection",
    "azure"
  );
  const [expanded, setExpanded] = useStore<string | undefined>(
    `lang-expanded`,
    undefined
  );

  const langNode = () => {
    if (isXraiVendor) {
      return xraiNoTranslationLanguageNode;
    } else if (groupedLangs?.length === 1) {
      return groupedLangs[0];
    } else {
      const engineFound = groupedLangs.find(
        (gl) => gl.vendor === translationEngineSelection
      );

      if (engineFound) {
        return engineFound;
      } else {
        const microsoftEngine = groupedLangs.find(
          (gl) => gl.vendor === "azure"
        ); // Microsoft is the default engine.

        return microsoftEngine ?? groupedLangs[0];
      }
    }
  };

  const disableLang = () => {
    const node = langNode();
    return selectedLanguages?.length >= 2 && node.multilingo === false;
  };

  const handleLanguageFavoriting = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    toggleFavorite(favorited?.key ?? langNode().key);
  };

  const handleLanguageSelection = () => {
    if (!favorited) {
      toggleFavorite(langNode().key).then((resp) => {
        if (type === "TargetLanguage") {
          setFavoriteSubtitleLang(resp.find((f) => f.key === langNode().key));
        } else {
          toggleFavoriteSpokenLangs(resp.find((f) => f.key === langNode().key));
        }

        selectLanguage(langNode().key);
      });
    } else {
      if (type === "TargetLanguage") {
        setFavoriteSubtitleLang(
          favoriteLanguages.find((f) => f.key === langNode().key)
        );
      } else {
        toggleFavoriteSpokenLangs(
          favoriteLanguages.find((f) => f.key === langNode().key)
        );
      }

      selectLanguage(langNode().key);
    }
  };

  return (
    <Stack
      id={langKey}
      key={`${langKey}-${index}`}
      style={
        expanded === langKey
          ? {
              boxShadow: theme.shadows[2],
              borderTop:
                theme.palette.mode === "dark"
                  ? `1px solid ${theme.palette.grey[800]}`
                  : `1px solid ${theme.palette.grey[300]}`,
              borderBottom:
                theme.palette.mode === "dark"
                  ? `1px solid ${theme.palette.grey[800]}`
                  : `1px solid ${theme.palette.grey[300]}`,
            }
          : {}
      }
    >
      <ListItemButton
        disabled={disableLang()}
        dense
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (langKey === "_xa") return;

          if (expanded === langKey) {
            setExpanded(undefined);
          } else {
            setExpanded(langKey);
          }
        }}
        alignItems="center"
        sx={{ pl: expanded === langKey ? 0 : 0, pr: 2 }}
      >
        <ListItemIcon sx={{ minWidth: "28px", mr: 1 }}>
          {/* Country Flag */}
          {countryCode !== "" && (
            <Flag code={countryCode} height={20} width={20} />
          )}

          {/* Klingon */}
          {(langNode().langcode === "tlh-Latn" ||
            langNode().langcode === "tlh-Piqd") &&
            type === "TargetLanguage" && <KlingonFlag />}

          {/* No Translation */}
          {langNode().key === xraiNoTranslationLanguageNode.key && (
            <ContentCopy />
          )}
        </ListItemIcon>

        <ListItemText>
          <Typography
            style={{
              marginLeft: "0px",
              fontWeight: expanded === langKey ? "bold" : "normal",
            }}
          >
            {t(`settings.${type}.${langKey}`)}
          </Typography>
        </ListItemText>

        {langNode().key !== xraiNoTranslationLanguageNode.key && (
          <FavoriteToggle
            style={{
              opacity: !!favorited ? 1.0 : 0.6,
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
            }}
            checked={!!favorited}
            onClick={handleLanguageFavoriting}
          />
        )}

        <RoundCheckbox checked={!!selected} onClick={handleLanguageSelection} />
      </ListItemButton>

      {expanded === langKey &&
        groupedLangs.map((lang) => {
          return (
            <div key={`sub-${lang.key}`}>
              <LanguageListItem
                hideFlags
                hideFavorite
                selectedLanguages={selectedLanguages}
                isLangDownloaded={isLangDownloaded}
                type={type}
                langCode={langKey}
                downloadStatus={downloadStatus}
                showVendor
                showIcons
                label={`${t(`Engines.${lang.vendor}.brand`)}`}
                description={`${t(`Engines.${lang.vendor}.description`)}`}
                favorited={
                  favoriteLanguages.find((f) => f.key === lang.key)
                    ? true
                    : false
                }
                selected={
                  favoriteLanguages.find((f) => f.key === lang.key) &&
                  selectedLanguages?.includes(lang.key)
                }
                onDownloadCancel={(key) => {
                  // TODO: Implement download cancel.
                }}
                onDownloadStart={(key) => {
                  // TODO: Implement download start.
                }}
                lang={lang}
                onToggleFavorite={(e) => {
                  toggleFavorite(e);
                }}
                onSelect={async () => {
                  let resp: FavoriteLanguagePayload[] = undefined;
                  if (!favoriteLanguages?.find((f) => f.key === lang?.key)) {
                    resp = await toggleFavorite(
                      isXraiVendor
                        ? xraiNoTranslationLanguageNode.key
                        : lang?.key
                    );
                  }

                  if (
                    favoriteLanguages?.find((f) => f.key === lang?.key) &&
                    !selectedLanguages?.includes(lang?.key)
                  ) {
                    await toggleFavorite(
                      isXraiVendor
                        ? xraiNoTranslationLanguageNode.key
                        : lang?.key
                    );
                  }

                  if (type === "TargetLanguage") {
                    setFavoriteSubtitleLang(
                      (resp ?? favoriteLanguages).find(
                        (f) => f.key === lang.key
                      )
                    );
                  } else {
                    toggleFavoriteSpokenLangs(
                      (resp ?? favoriteLanguages).find(
                        (f) => f.key === lang.key
                      )
                    );
                  }
                }}
              />
            </div>
          );
        })}
    </Stack>
  );
};

export default LanguageListItemContainer;
