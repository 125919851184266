import { CloseOutlined, Download } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";

interface Props {
  resource?: string;
  size?: number;
  onCancel?: ()=>void;
  onStart?: ()=> void;
  percent: number;
}

export default function DownloadButton(props: Props) {
  const [progress, setProgress] = useState<number>(-1);
  const size = props.size || 24;

  useEffect(()=>{
    if(props.percent > 1 && progress !== props.percent) {
      setProgress(props.percent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.percent])

  const handleDownload = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    if(props.onStart) props.onStart();
  };

  const handleCancel = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    setProgress(-1);
    if(props.onCancel) props.onCancel();
    // TODO: Handle Real Cancel
  };

  return (
    <>
      {progress === -1 && (
        <IconButton onClick={handleDownload}>
          <Download style={{ fontSize: `${size}px` }} />
        </IconButton>
      )}
      {progress > -1 && (
        <IconButton
          onClick={handleCancel}
          style={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            color="secondary"
            variant="determinate"
            size={size}
            value={progress}
          />
          <CloseOutlined
            style={{ fontSize: `${size * 0.6}px`, position: "absolute" }}
          />
        </IconButton>
      )}
    </>
  );
}