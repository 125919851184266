import React, { useState } from "react";
import { PairingDialog } from "./PairingDialog";
import { useStore } from "state/store";

export default function usePairingDialog() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [, setActivePopup] = useStore<boolean>("activePopup");

  const open = () => {
    setIsOpen(true);
    setActivePopup(true);
  };

  return {
    open,
    isOpen: open,
    Dialog: (
      <PairingDialog
        onClose={() => {
          setIsOpen(false);
          setActivePopup(false);
        }}
        open={isOpen}
      />
    ),
  };
}
