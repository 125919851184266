import React, { useEffect, useRef, useState } from "react";
interface Props {
  position: "top" | "bottom" | "left" | "right";
  default?: number;
  style?: any;
  onSize?: (px: number) => void;
}
export default function SafeArea(props: Props) {
  const [size, setSize] = useState(0);
  const def = props.default || 0;
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const _size = ["top","bottom"].indexOf(props.position) > -1 ? divRef.current?.offsetHeight || def : divRef.current?.offsetWidth || def;
    if (size !== _size) {
      setSize(_size);
      if(props.onSize) props.onSize(_size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  return (
    <div
      ref={divRef}
      style={{
        ...props.style,
        ...{
          height: `var(--sa-${props.position}, ${def}px)`,
        }
      }}
      className={`safe-area safe-area-${props.position}`}
    >
    </div>
  );
}
