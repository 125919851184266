import { PaidOutlined, PersonOutlined, SecurityUpdate, SettingsOutlined } from "@mui/icons-material";
import { Avatar, Badge, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Theme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {  useNavigate } from "react-router-dom";
import AppUserContext from "comps/context/AppUserContext";
//import PersonIcon from "@mui/icons-material/Person";
import { ReactComponent as PersonIcon } from "assets/MenuAccount.svg";
import { ThemeContext } from "@emotion/react";
import { useSoftwareUpdateContext } from "comps/softwareUpdate/SoftwareUpdateContext";
import getInitials from "utils/getInitials/getInitals";
import useSubscriptionDialog from "comps/subscriptions/useSubscriptionDialog";
import Entitlements from "comps/entitlements/entitlements";
import EntitlementUsageMeter from "comps/entitlements/EntitlementUsageMeter";
import {useStore} from "state/store"

const ProfileMenuItem = ()=>{

  const [iconSize,] = useStore<number>("rootIconSize");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { appUser } = useContext(AppUserContext);
  const [displayName, setDisplayName] = useState<string | undefined>(undefined)
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const [profilePhoto, setProfilePhoto] = useState<string | undefined>(undefined);

  const theme = useContext(ThemeContext) as Theme;
  const SoftwareUpdater = useSoftwareUpdateContext();
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const SubscriptionDrawer = useSubscriptionDialog();
  const entitlements = new Entitlements(appUser?.Entitlements || []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    if(appUser && appUser.FirstName) {
      setDisplayName(getInitials(`${appUser.FirstName || ''} ${appUser.LastName || ''}`))
    } else {
      setDisplayName(undefined)
    }
    setProfilePhoto(appUser?.ProfilePicture);
  },[appUser])

  return (
    <>
      <IconButton
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Badge color="error" variant={SoftwareUpdater.isUpdateAvailable ? "dot" : undefined }> 
          {!profilePhoto ? (
            <Avatar sx={{ 
              color: theme.palette.primary.contrastText,
              width:iconSize, 
              height:iconSize, 
              background:theme.palette.primary.light 
              }}>
              { displayName || 
                <PersonIcon /> 
              }
            </Avatar>
          ) : (
            <Avatar sx={{ 
              color: theme.palette.primary.contrastText,
              width:iconSize, 
              height:iconSize, 
              background:theme.palette.primary.contrastText }
              }>
              <img
                onError={()=>{
                  setProfilePhoto(undefined);
                }}
                alt={ displayName || "Profile Photo" }
                src={profilePhoto}
                style={{width:iconSize, height:iconSize}}
                referrerPolicy="no-referrer"/>
            </Avatar>
          )}
          </Badge>
      </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={()=>{
            navigate('/profile')
          }}>
            <ListItemIcon>
              <PersonOutlined />
            </ListItemIcon>
            <ListItemText>{t("navigation.profile")}</ListItemText>
          </MenuItem>

          {appUser?.Platform !== "iOS" && <MenuItem onClick={()=>{
              SubscriptionDrawer.open();
              handleClose();
            }}>
              <ListItemIcon>
                <PaidOutlined />
              </ListItemIcon>
              <ListItemText>{t("navigation.subscriptions","Subscriptions")}</ListItemText>
            </MenuItem>
          }

          <EntitlementUsageMeter label={t("advanced-cloud")} id="advanced_cloud" entitlements={entitlements} />
          <EntitlementUsageMeter label={t("standard-cloud")} id="standard_cloud" entitlements={entitlements} />

          {SoftwareUpdater.isUpdateAvailable && <Divider />}
          {SoftwareUpdater.isUpdateAvailable &&
            <MenuItem onClick={()=>{
              SoftwareUpdater.open();
            }}>
              <ListItemIcon>
                <SecurityUpdate />
              </ListItemIcon>
              <ListItemText>{t("updater.update_available")}</ListItemText>
            </MenuItem>
          }
          <Divider />
          <MenuItem sx={{minWidth:'180px'}} onClick={()=>{
            navigate('/settings');
          }}>
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>
            <ListItemText>{t("navigation.settings")}</ListItemText>
          </MenuItem>
        </Menu>
        {SubscriptionDrawer.Drawer}
    </>
  )
}

export default ProfileMenuItem;
