/**
 * Get Initials will return the initials for a given string, number or nullable
 * If null, it will return an empty string
 * if a single word it will return a single character
 * if more than one word it will return the first char of the first and last word
 */
export default function getInitials(fullName: string | number | null | undefined): string {
  if (!fullName) {
    return "";
  }
  const words = `${fullName}`.trim().split(" ");
  return ((words.shift()?.[0] || '') + (words.pop()?.[0] || '')).toUpperCase();
}