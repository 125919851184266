import React from "react";

import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import { Disk } from "comps/Icons";
import { DeleteForeverRounded, Edit, Pause } from "@mui/icons-material";

import AudioPlayer from "comps/AudioPlayer";
import ButtonMenu from "comps/ButtonMenu";
import ProfileMenuItem from "comps/headerItems/ProfileMenuItem";
import DefaultLayout from "comps/layout/DefaultLayout";
// import ConversationSearchBar from "comps/conversations/ConversationSearchBar";
import { PairingButton } from "comps/pairing/PairingButton";
import { Box, Slide, Typography } from "@mui/material";
import DanteMenuItem from "comps/headerItems/DanteMenuItem";

import {
  CaptionStatus,
  SessionHistory,
  SessionMeta,
  XMenuItem,
} from "types/types";
import { TFunction } from "i18next";
import StreamsScreen from "pages/StreamsScreen";
import { useStoreUnity } from "state/store";

type Props = {
  children: React.ReactNode;
  captionStatus: CaptionStatus;
  iconSize: number;
  bgColor: string;
  isChatHistory: boolean;
  isArMode: boolean;
  playRecording: boolean;
  sessionId: string;
  ga: any; // Google Analytics
  deletePrompt: any; // UseDialogResponse
  conversationsCount: number;
  editMode: boolean;
  selectedSessions: SessionMeta[];
  displayConversationInfo: boolean;
  setRenameModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleNavigateBack: () => void;
  setPlayRecording: (value: boolean) => void;
  navigate: (url: string | number) => void;
  xhostLocal: () => string;
  t: TFunction<"translation", undefined, "translation">; // Translation
  shareDrawer: {
    drawer: React.ReactNode;
    openDrawer: () => void;
    closeDrawer: () => void;
  };
  setConversationDeleted: (value: boolean) => void;
  setEditMode: (value: boolean) => void;
  setSearchResults: React.Dispatch<React.SetStateAction<SessionHistory[]>>;
  startSession: () => void;
  isActiveSession: () => boolean;
};

const SubtitleContainer: React.FC<Props> = ({
  children,
  captionStatus,
  iconSize,
  bgColor,
  isChatHistory,
  isArMode,
  playRecording,
  sessionId,
  shareDrawer,
  ga,
  deletePrompt,
  conversationsCount,
  editMode,
  selectedSessions,
  displayConversationInfo,
  setRenameModalIsOpen,
  handleNavigateBack,
  setPlayRecording,
  navigate,
  xhostLocal,
  t,
  setConversationDeleted,
  setEditMode,
  setSearchResults,
  startSession,
  isActiveSession,
}) => {
  const [enableDante] = useStoreUnity<boolean>("EnableDante", false);

  const optionsMenu: Array<XMenuItem> = [
    // TODO: Fix recording functionality prior to uncommenting.
    // {
    //   icon: <PlayCircleIcon />,
    //   label: t("conversations.play_audio"),
    //   disabled: !hasRecording,
    //   onClick: async () => {
    //     if (!playRecording) {
    //       ga.event("conversation-play");
    //     }
    //     setPlayRecording(!playRecording);
    //   },
    // },
    {
      icon: <Edit />,
      label: t("conversations.rename_conversation"),
      onClick: () => setRenameModalIsOpen(true),
    },
    {
      icon: <ShareIcon />,
      label: t("conversations.share"),
      onClick: async () => {
        shareDrawer.openDrawer();
      },
    },
    {
      icon: <DeleteForeverRounded color="error" />,
      label: t("conversations.deletePrompt"),
      onClick: async () => {
        await deletePrompt.deleteConversations([sessionId]).then(() => {
          setConversationDeleted(true);
          ga.event("conversation-delete");
          navigate(-1);
        });
      },
    },
  ];

  return (
    <DefaultLayout
      bgColor={bgColor}
      className="subtitle-screen"
      menuItems={
        globalThis.isXraiGo ? null : (
          <>
            {captionStatus.status === "confirmStart" && (
              <div style={{ marginRight: "8px" }}>
                <Disk className="animate-pulse" />
              </div>
            )}

            {captionStatus.status === "confirmPause" && (
              <div style={{ marginRight: "0px" }}>
                <Pause
                  sx={{
                    marginTop: (1 / iconSize) * 15,
                    height: iconSize * 1.2 + "px !important",
                    width: iconSize * 1.2 + "px !important",
                  }}
                  className="animate-pulse"
                />
              </div>
            )}

            {!isChatHistory && <StreamsScreen />}

            <PairingButton />

            {enableDante && <DanteMenuItem />}

            {!isChatHistory && !isActiveSession() && (
              <ButtonMenu
                icon={
                  <MoreVertIcon style={{ width: "28px", height: "28px" }} />
                }
                menu={[
                  {
                    label: `${t("Manage")} ${t("conversations.title")}`,
                    onClick() {
                      setEditMode(true);
                    },
                    disabled: conversationsCount === 0,
                    icon: <DeleteSweepOutlinedIcon />,
                  },
                ]}
              />
            )}

            {isChatHistory && (
              <ButtonMenu
                title={t("conversations.options")}
                icon={
                  <MoreVertIcon style={{ width: "28px", height: "28px" }} />
                }
                menu={optionsMenu}
              />
            )}

            <ProfileMenuItem />
          </>
        )
      }
      pageTitle={`Subtitles ${isArMode ? "AR" : "Basic"}`}
      backLabel={t("Home")}
      backOnClick={handleNavigateBack}
      headerComponent={
        playRecording && isChatHistory ? (
          <Slide direction="down" in={playRecording}>
            <div>
              <AudioPlayer
                onClose={() => setPlayRecording(false)}
                autoPlay={true}
                src={`${xhostLocal()}/recording?sessionid=${sessionId}`}
              />
            </div>
          </Slide>
        ) : !isChatHistory && displayConversationInfo ? (
          <Slide direction="down" in={displayConversationInfo}>
            <Box
              sx={{
                px: "16px",
                pb: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{ textAlign: "center", marginTop: "16px" }}
              >
                {!editMode && t("conversations.title")}
                {editMode &&
                  !selectedSessions.length &&
                  t("conversations.select")}
                {editMode && selectedSessions.length > 0 && (
                  <>
                    <span>{selectedSessions.length} </span>
                    {t("conversations.selected")}
                  </>
                )}
              </Typography>

              {/* {!editMode && (
                <ConversationSearchBar
                  onClear={() => {
                    setSearchResults(undefined);
                  }}
                  onResults={setSearchResults}
                />
              )} */}
            </Box>
          </Slide>
        ) : null
      }
    >
      {children}
    </DefaultLayout>
  );
};

export default SubtitleContainer;
