import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  ChevronRight,
  CloseOutlined,
  ExpandMore,
  Search,
} from "@mui/icons-material";
import { useStore } from "state/store";
import { ThemeContext } from "@emotion/react";
import { DialogTransitionUp } from "utils/DialogTransition";
import { wait } from "utils/wait";
import { groupArray } from "utils/groupArray/groupArray";
import LanguageListItem from "../languageDrawer/LanguageListItem";
import { DownloadStatus } from "../languageDrawer/DownloaderStore";
import {
  LangSettingType,
  LanguageGroup,
  getLanguages,
  isLangDownloaded,
  setActiveLanguage,
} from "./langaugeUtils";
import FavoriteToggle from "comps/FavoriteToggle";
import { getState } from "utils/settings";
import SearchBar from "comps/SearchBar";
import { safeSearchTerm } from "utils/safeSearchTerm";
import { toJSONPostRequest, xfetch } from "utils/xfetch";
import {
  FavoriteLanguagePayload,
  getLanguageList,
} from "comps/favoriteLanguages/favoriteLanguage-utils";

interface Props {
  id: string;
  open: boolean;
  title?: string;
  type: LangSettingType;
  downloadStatus: DownloadStatus;
  onClose: (v: any) => void;
  onSelect: (lang: string) => void;
  onDownloadStart?: (key: string) => void;
  onDownloadCancel?: (key: string) => void;
  hideSelection?: boolean;
  hideFlags?: boolean;
}

export default function LanguageDialog(props: Props) {
  const group = props.type === "SourceLanguage" ? "spoken" : "subtitle";
  const theme = useContext(ThemeContext) as Theme;
  const { t } = useTranslation();
  const [searchMode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [expanded, setExpanded] = useStore<string | undefined>(
    `lang-expanded`,
    undefined
  );
  const [isOpen, setIsOpen] = useState(props.open);
  const [filterFavorites, setFilterFavorites] = useState(false);
  //const [selectedLang, setSelectedLang] = useState<undefined | string>(undefined);
  const [languageGrouped, setLanguageGrouped] = useState<LanguageGroup>({});
  const [favorites, setFavorites] = useState<FavoriteLanguagePayload[]>([]);

  const handleClose = (evt: any) => {
    props.onClose(evt);
    setIsOpen(false);
  };

  const getLangsUnity = useCallback(() => {
    getLanguageList({ group: group, sublist: "favorite" }).then((res) => {
      setFavorites(res);
    });
  }, [setFavorites, group]);

  useEffect(() => {
    getLangsUnity();
  }, [getLangsUnity]);

  const toggleFavorite = async (id: string) => {
    const payload = await toJSONPostRequest(null);
    await xfetch(`/language/favorite?code=${id}&group=${group}`, payload);
    getLangsUnity();
  };

  const selectLanguage = async (lang: string) => {
    console.log(`🔥 Selecting Language: ${lang}`);
    await setActiveLanguage(lang, props.type);
    await wait(300);
    props.onSelect(lang);
  };

  const toggleFavoritesView = () => {
    setFilterFavorites(!filterFavorites);
  };

  /**
   * Get the Langs to Show
   * This is used so we can easily to to our favorites list
   */
  const getLangList = useCallback(
    (term?: string) => {
      return (
        Object.keys(languageGrouped)
          .sort((a, b) => {
            try {
              // Sort by Localized Label
              const aLabel = languageGrouped[a][0].label;
              const bLabel = languageGrouped[b][0].label;
              return aLabel > bLabel ? 1 : -1;
            } catch (e) {
              return 1;
            }
          })
          ?.filter((a) => {
            const langs = languageGrouped[a];
            if (term) {
              let label = langs[0].label;
              return label.toLowerCase().search(term.toLowerCase()) > -1;
            } else if (filterFavorites) {
              let hasFav = false;
              langs.forEach((lang) => {
                if (!hasFav && favorites.find((f) => f.key === lang.key)) {
                  hasFav = true;
                }
              });
              return hasFav;
            } else {
              return true;
            }
          }) ?? []
      );
    },
    [languageGrouped, favorites, filterFavorites]
  );

  const handleToggleSearch = () => {
    if (!searchMode && filterFavorites) {
      setFilterFavorites(false);
    }
    if (searchMode) {
      setSearchTerm(undefined);
    }
    setSearchMode(!searchMode);
  };

  const scrollIntoView = async (langKey: string) => {
    await wait(400);
    let ele: HTMLElement;
    const splitId = langKey.split(".");
    try {
      // Try to expand if this selected lang is part of a group
      const genericLangId = splitId[1]; // get en-US from aws.en-US
      setExpanded(genericLangId); // Expand the Group it belongs to
      ele =
        document.getElementById(genericLangId) ||
        document.getElementById(langKey);
    } catch (e) {
      // It's not part of a group, just select the single lang
      ele = document.getElementById(langKey);
    }
    if (ele) {
      ele.scrollIntoView({
        block: "start",
      });
    }
  };

  const handleSearchTerm = useCallback((term: string) => {
    if (term && term.length) {
      setSearchTerm(safeSearchTerm(term));
    } else {
      setSearchTerm(undefined);
    }
  }, []);

  useEffect(() => {
    setIsOpen(props.open);

    const getSelectedAndScroll = () => {
      getState(props.type).then((activeLang) => {
        // console.log(`🔥 Get Language Selected for ${props.type}: ${activeLang}`);
        // setSelectedLang(activeLang);
        scrollIntoView(activeLang);
      });
    };

    if (props.open) {
      setLoading(true);
      getLanguages(props.type)
        .then(async (langs: any[]) => {
          const langGroup: any = groupArray(langs, "langcode");
          // Loop over langs to get their localized label in our payload
          Object.keys(langGroup).forEach((lgKey: string) => {
            const langsArr: Array<any> = langGroup[lgKey];
            // Loop over lang nodes
            langsArr.forEach((lang, index) => {
              // Set the label
              langGroup[lgKey][index].label = t(
                `settings.${props.type}.${lang.langcode}`
              );
            });
          });
          setLoading(false);
          setLanguageGrouped(langGroup);
          getSelectedAndScroll();
        })
        .catch(() => {
          setLoading(false);
          getSelectedAndScroll();
        });
    } else {
      setSearchMode(false);
      setSearchTerm(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={DialogTransitionUp}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: {
          width: "30em",
          maxWidth: "80vw",
          minHeight: "60vh",
        },
      }}
    >
      {isOpen && (
        <>
          <DialogTitle
            style={{
              padding: 0,
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Toolbar style={{ padding: "0 12px" }}>
              <IconButton onClick={handleClose}>
                <CloseOutlined />
              </IconButton>
              <Typography style={{ width: "100%" }}>
                {props.title || "Select Langauge"}
              </Typography>
              <IconButton
                style={
                  searchMode
                    ? {
                        color: theme.palette.primary.contrastText,
                        backgroundColor: theme.palette.primary.main,
                      }
                    : {}
                }
                onClick={handleToggleSearch}
              >
                <Search />
              </IconButton>
              <FavoriteToggle
                checked={filterFavorites}
                onClick={() => {
                  toggleFavoritesView();
                }}
              />
            </Toolbar>
            {searchMode && (
              <Toolbar style={{ padding: "0 18px", marginTop: "-12px" }}>
                <SearchBar
                  onKeyword={handleSearchTerm}
                  onClear={() => {
                    setSearchMode(false);
                    setSearchTerm(undefined);
                  }}
                />
              </Toolbar>
            )}
            <Divider />
          </DialogTitle>
          <DialogContent style={{ padding: 0, minHeight: "200px" }}>
            {loading && Object.keys(languageGrouped).length === 0 && (
              <Stack
                style={{
                  padding: "20px",
                  textAlign: "center",
                  opacity: 0.5,
                }}
                alignContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Stack>
            )}
            {!loading && Object.keys(languageGrouped).length === 0 && (
              <Stack
                style={{
                  padding: "20px",
                  textAlign: "center",
                  opacity: 0.5,
                }}
              >
                <Typography>{t("no-langs-available")}</Typography>
              </Stack>
            )}

            {getLangList(searchTerm).map((langKey, index) => {
              let groupedLangs = languageGrouped[langKey];

              if (groupedLangs.length === 1) {
                return (
                  <LanguageListItem
                    isLangDownloaded={isLangDownloaded}
                    showVendor={true}
                    downloadStatus={props.downloadStatus}
                    label={t(
                      `settings.${props.type}.${groupedLangs[0].langcode}`
                    )}
                    favorited={
                      favorites.find((f) => f.key === groupedLangs[0].key)
                        ? true
                        : false
                    }
                    onDownloadCancel={(key) => {
                      if (props.onDownloadCancel) props.onDownloadCancel(key);
                    }}
                    onDownloadStart={(key) => {
                      if (props.onDownloadStart) props.onDownloadStart(key);
                    }}
                    //selected={selectedLang === groupedLangs[0].key}
                    key={`${langKey}-${index}`}
                    lang={groupedLangs[0]}
                    onToggleFavorite={(e) => {
                      toggleFavorite(e);
                    }}
                    onSelect={() => {
                      selectLanguage(groupedLangs[0].key);
                    }}
                    hideSelection={props?.hideSelection ?? false}
                    hideFlags={props?.hideFlags ?? false}
                  />
                );
              } else {
                return (
                  <Stack
                    id={langKey}
                    className={`lang-${langKey} lang-group ${
                      expanded === langKey ? "lang-group-expanded" : ""
                    }`}
                    key={`${langKey}-${index}`}
                    style={
                      expanded === langKey
                        ? {
                            boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
                            backgroundColor: theme.palette.background.default,
                          }
                        : {}
                    }
                  >
                    <ListItemButton
                      dense
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (expanded === langKey) {
                          setExpanded(undefined);
                        } else {
                          setExpanded(langKey);
                        }
                      }}
                      alignItems="center"
                    >
                      <ListItemIcon style={{ minWidth: "28px" }}>
                        {expanded === langKey && <ExpandMore />}
                        {expanded !== langKey && <ChevronRight />}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          style={{
                            marginLeft: "0px",
                            fontWeight:
                              expanded === langKey ? "bold" : "normal",
                          }}
                        >
                          {t(`settings.${props.type}.${langKey}`)}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                    {expanded === langKey &&
                      groupedLangs.map((lang) => {
                        return (
                          <div key={`sub-${lang.key}`}>
                            <LanguageListItem
                              isLangDownloaded={isLangDownloaded}
                              downloadStatus={props.downloadStatus}
                              showVendor={true}
                              showIcons={true}
                              label={`${t(`Engines.${lang.vendor}.brand`)}`}
                              description={`${t(
                                `Engines.${lang.vendor}.description`
                              )}`}
                              favorited={
                                favorites.find((f) => f.key === lang.key)
                                  ? true
                                  : false
                              }
                              onDownloadCancel={(key) => {
                                if (props.onDownloadCancel)
                                  props.onDownloadCancel(key);
                              }}
                              onDownloadStart={(key) => {
                                if (props.onDownloadStart)
                                  props.onDownloadStart(key);
                              }}
                              //selected={selectedLang === lang.key}
                              lang={lang}
                              onToggleFavorite={(e) => {
                                toggleFavorite(e);
                              }}
                              onSelect={() => {
                                selectLanguage(lang.key);
                              }}
                              hideSelection={props?.hideSelection ?? false}
                              hideFlags={props?.hideFlags ?? false}
                            />
                          </div>
                        );
                      })}
                  </Stack>
                );
              }
            })}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
