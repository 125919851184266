import { useCallback, useState } from "react";
import { TFunction } from "i18next";

import { BackendStatus, CaptionStatus, SessionMeta } from "types/types";

import xconsole from "utils/xconsole";
import { toJSONPostRequest, xfetch } from "utils/xfetch";
import { deleteConversation } from "comps/conversations/useDeleteConversation";
import { useStoreLocal } from "state/store";

type Arguments = {
  dialog: any;
  isChatHistory: boolean;
  activeSessionId: string;
  activeSegmentId: string;
  t: TFunction<"translation", undefined, "translation">; // Translation
  navigate: (url: string) => void;
  resetSession: () => void;
};

export const useSubtitleScreenHook = ({
  dialog,
  isChatHistory,
  activeSegmentId,
  activeSessionId,
  t,
  navigate,
  resetSession,
}: Arguments) => {
  const [captionStatus, setStatus] = useState<CaptionStatus>({
    status: "pendingConnect",
  });

  const [, setSelectedConversation] = useStoreLocal<SessionMeta>(
    "selectedConversation",
    null
  );

  const pendingStatus = () => {
    return (
      captionStatus.status ===
      ("pendingConnect" || "pendingStart" || "pendingStop" || "pendingPause")
    );
  };

  const getStatus = useCallback(async () => {
    if (globalThis.isXraiGo) {
      setStatus({ status: "confirmStart" });
      return;
    }

    try {
      const res = await xfetch("/status");
      const { Status } = (await res.json()) as BackendStatus;

      if (Status === "End") {
        setStatus({ status: "confirmStop" });
      } else if (Status === "Start") {
        setStatus({ status: "confirmStart" });
      } else if (Status === "Pause") {
        setStatus({ status: "confirmPause" });
      }
    } catch (e) {
      xconsole.log(`getStatus fail ${e.message}`);
    }
  }, [setStatus]);

  // auto start
  const startSession = useCallback(async () => {
    if (
      captionStatus.status === "pendingConnect" ||
      captionStatus.status === "confirmStop"
    ) {
      if (!isChatHistory) {
        resetSession();
      }

      try {
        setStatus({ status: "pendingStart" });
        await xfetch("/startsession");
        getStatus();
      } catch (e) {
        xconsole.log(`startCaptionHandler stop fail ${e.message}`);
      }
    }
  }, [setStatus, getStatus, captionStatus.status, isChatHistory, resetSession]);

  // start button
  const startCaptionHandler = useCallback(
    async (e?: any, action?: "pause" | "play" | undefined) => {
      if (!isChatHistory) {
        resetSession();
      }

      if (action === "play") {
        try {
          setStatus({ status: "pendingStart" });
          const data = { Start: 1 };
          const payload = await toJSONPostRequest(data);
          await xfetch("/pausestart", payload);
          setStatus({ status: "confirmStart" });
        } catch (e) {
          xconsole.log(`startCaptionHandler stop fail ${e.message}`);
        }

        return;
      }

      if (captionStatus.status === "confirmStop") {
        try {
          setStatus({ status: "pendingStart" });
          await xfetch("/startsession");
          setStatus({ status: "confirmStart" });
        } catch (e) {
          xconsole.log(`startCaptionHandler stop fail ${e.message}`);
        }
      } else if (
        captionStatus.status === "confirmStart" ||
        action === "pause"
      ) {
        try {
          setStatus({ status: "pendingPause" });
          const data = { Start: 0 };
          const payload = await toJSONPostRequest(data);
          await xfetch("/pausestart", payload);
          setStatus({ status: "confirmPause" });
        } catch (e) {
          xconsole.log(`startCaptionHandler start fail ${e.message}`);
        }
      } else {
        try {
          setStatus({ status: "pendingStart" });
          const data = { Start: 1 };
          const payload = await toJSONPostRequest(data);
          await xfetch("/pausestart", payload);
          setStatus({ status: "confirmStart" });
        } catch (e) {
          xconsole.log(`startCaptionHandler other fail ${e.message}`);
        }
      }
    },
    [captionStatus.status, setStatus, resetSession, isChatHistory]
  );

  // stop button
  const handleStop = useCallback(
    async (redirect?: string) => {
      if (globalThis.isXraiGo) return;

      const confirmStop = (): Promise<"cancel" | "discard" | "keep"> => {
        return new Promise((resolve) => {
          dialog.showDialog({
            title: t("caption.stopWarn.title"),
            message: t("caption.stopWarn.summary"),
            onClose: () => resolve("cancel"),
            buttons: [
              {
                label: t("Cancel"),
                action() {
                  resolve("cancel");
                },
              },
              {
                label: t("Discard"),
                action() {
                  resolve("discard");
                },
              },
              {
                label: t("Keep"),
                action() {
                  resolve("keep");
                },
              },
            ],
          });
        });
      };

      if (
        !["pendingConnect", "confirmStop", "pendingStart"].includes(
          captionStatus.status
        )
      ) {
        const confirmed = await confirmStop();

        if (confirmed === "keep" || confirmed === "discard") {
          let deleteSessionId: string | undefined = undefined;

          if (confirmed === "discard") {
            deleteSessionId = isChatHistory ? activeSegmentId : activeSessionId;
          }

          if (confirmed === "keep") {
            setSelectedConversation(null);
          }

          setStatus({ status: "pendingStop" });
          await xfetch("/endsession");
          if (deleteSessionId) {
            await deleteConversation(deleteSessionId);
          }
          setStatus({ status: "confirmStop" });
          if (redirect) navigate(redirect);
        } else if (confirmed === "cancel") {
          startCaptionHandler(undefined, "play");
        }
      } else if (redirect) {
        navigate(redirect);
      }
    },
    [
      dialog,
      t,
      navigate,
      isChatHistory,
      activeSessionId,
      activeSegmentId,
      captionStatus.status,
      startCaptionHandler,
      setStatus,
      setSelectedConversation,
    ]
  );

  return {
    captionStatus,
    pendingStatus,
    getStatus,
    startSession,
    startCaptionHandler,
    handleStop,
  };
};
