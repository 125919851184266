import React from "react";

import CustomTabPanel from "./CustomTabPanel";
import AdjustableControl from "comps/common/AdjustableControl";
import { PanelPosition } from "pages/SubtitleScreen";

import LineStyleIcon from "@mui/icons-material/LineStyle";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import WidthWideIcon from "@mui/icons-material/WidthWide";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import MarginIcon from "@mui/icons-material/Margin";
import { Box, Button, List } from "@mui/material";

import { useStoreLocal } from "state/store";
import { TFunction } from "i18next";
import { Height } from "@mui/icons-material";

type Props = {
  activeTab: number;
  t: TFunction<"translation", undefined, "translation">; // Translation
  index: number;
};

const SpacingScreen: React.FC<Props> = ({ activeTab, t, index }) => {
  const [panelPosition] = useStoreLocal<PanelPosition>("panelPosition");
  const [panelHeight, setPanelHeight] = useStoreLocal<number>("panelHeight");
  const [wordSpacing, setWordSpacing] = useStoreLocal<number>("wordSpacing");
  const [lineSpacing, setLineSpacing] = useStoreLocal<number>("lineSpacing");
  const [margin, setMargin] = useStoreLocal<number>("subtitleMargin");
  const [lineWidth, setLineWidth] = useStoreLocal<number>("lineWidth");
  const [letterSpacing, setLetterSpacing] =
    useStoreLocal<number>("letterSpacing");

  const resetSpacingDefaults = () => {
    setWordSpacing(0);
    setLetterSpacing(0.02);
    setLineSpacing(1.5);
    setLineWidth(100);
    setMargin(0);
  };

  return (
    <CustomTabPanel value={activeTab} index={index}>
      <>
        <List dense={true}>
          <AdjustableControl
            label={t("Words")}
            value={wordSpacing}
            min={0}
            max={2}
            step={0.1}
            icon={<LineStyleIcon />}
            onValueChange={setWordSpacing}
          />

          <AdjustableControl
            label={t("Letters")}
            value={letterSpacing}
            min={0}
            max={0.5}
            step={0.02}
            icon={<SortByAlphaIcon />}
            onValueChange={setLetterSpacing}
          />

          <AdjustableControl
            label={t("Lines")}
            value={lineSpacing}
            min={0.5}
            max={2.5}
            step={0.1}
            icon={<FormatLineSpacingIcon />}
            onValueChange={(newValue) => {
              setLineSpacing(Math.max(0, Math.min(newValue, 10)));
            }}
            units=""
          />

          <AdjustableControl
            label={t("Width")}
            value={lineWidth}
            min={25}
            max={100}
            step={5}
            decimalPlaces={0}
            icon={<WidthWideIcon />}
            onValueChange={(newValue) => {
              setLineWidth(newValue);
            }}
            units="%"
          />

          {panelPosition !== PanelPosition.default && (
            <AdjustableControl
              label={t("Height")}
              value={panelHeight}
              min={0}
              step={5}
              decimalPlaces={0}
              max={70}
              units="%"
              onValueChange={(newValue) => {
                setPanelHeight(newValue);
              }}
              //renderSliderCondition={
              //  panelPosition !== PanelPosition.default
              //}
              //this feature breaks panel formatting and this specific
              //button duplicates the button on the subtitles screen
              //and yet doesn't do anything interesting like toggle
              //between different height variables for top/bottom. So
              //I'm commenting this until until at least formatting is
              //fixed and hopefully some useful behavior added.
              //onIconClick={props.onTogglePosition}
              icon={<Height />}
            />
          )}

          <AdjustableControl
            label={t("Margin")}
            value={margin}
            min={0}
            max={35}
            step={1}
            decimalPlaces={0}
            icon={<MarginIcon />}
            onValueChange={setMargin}
            units="%"
          />
        </List>

        <Box>
          <Button
            variant="contained"
            color="error"
            startIcon={<RestartAltIcon />}
            onClick={resetSpacingDefaults}
          >
            {t("Reset")}
          </Button>
        </Box>
      </>
    </CustomTabPanel>
  );
};

export default SpacingScreen;
