import React, { useEffect, useState } from "react";
import { Box, Stack, Slide, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { xfetchLocal, xfetchOther } from "utils/xfetch";
import DefaultLayout from "comps/layout/DefaultLayout";
import Filler from "comps/common/Filler";
import Logo from "comps/Logo";
import useOrientation from "hooks/useOrientation";
import { getState } from "utils/settings";
import { useDialog } from "hooks/useDialog";
import { wait } from "utils/wait";
import { XLargeButton } from "comps/XLargeButton";
import DanteMenuItem from "comps/headerItems/DanteMenuItem";
import ProfileMenuItem from "comps/headerItems/ProfileMenuItem";
import { PairingButton } from "comps/pairing/PairingButton";
import { useTheme } from "@mui/material/styles";
import { useStore, useStoreUnity } from "state/store";
import { PairState } from "comps/pairing/PairTypes";

const HomeScreen = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const orientation = useOrientation();
  const [, setIsArMode] = useStore<boolean>("IsArMode", false);
  const [showArMode, setShowArMode] = useState(false);
  const [showPairing] = useStoreUnity<boolean>("EnablePairing", false);
  const Dialog = useDialog();
  const [pairState] = useStoreUnity<PairState>("Pairing");
  const [hasGlasses] = useStoreUnity<boolean>("HaveGlasses");
  const [enableDante] = useStoreUnity<boolean>("EnableDante", false);

  const niceText = (code: string): string => {
    return code ? code : t("Generating");
  };

  const localCodeText = showPairing ? niceText(pairState?.LocalCode) : null;

  useEffect(() => {
    xfetchOther("/loadmain", { method: "POST" });
    xfetchLocal("/loadmain", { method: "POST" });
  }, []);

  const updateSubscriptionStatus = async () => {
    const reqSettings: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Length": "0",
      },
      body: "",
    };

    xfetchLocal("/updatesubscriptionstatus", reqSettings);
  };

  useEffect(() => {
    updateSubscriptionStatus();
    getState("ShowArMode").then((res) => {
      const _showArMode = res === "True";
      setShowArMode(_showArMode);
    });
  }, []);

  const navigateToBasic = async () => {
    await wait(200);
    navigate("/subtitles");
  };

  const navigateToAR = async () => {
    if (hasGlasses) {
      setIsArMode(true);
      await wait(200);
      navigate("/subtitles");
    } else {
      Dialog.showDialog({
        title: t("GlassesRequired.title"),
        message: t("GlassesRequired.summary"),
        buttons: [
          {
            label: t("Close"),
            action() {
              navigate("/home");
            },
          },
        ],
      });
    }
  };

  return (
    <DefaultLayout
      pageTitle="Home"
      hideBack={true}
      quitShow={true}
      quitOnClick={() => {
        xfetchOther("/quit");
        xfetchLocal("/unpair");
      }}
      style={{
        overflow: "hidden",
      }}
      menuItems={
        <>
          {enableDante && <DanteMenuItem />}
          <PairingButton />
          <ProfileMenuItem />
        </>
      }
    >
      <Filler />
      <Logo
        color={
          theme.palette.name?.includes("contrast")
            ? theme.palette.primary.contrastText
            : theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.dark
        }
        type={orientation === "landscape" ? "horizontal" : "vertical"}
        style={{
          maxWidth: orientation === "landscape" ? "250px" : "170px",
          margin: "0 auto",
        }}
      />

      <Filler />

      <Slide direction="up" in={true}>
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="center"
          sx={{ p: "24px", width: "100%", pb: "48px" }}
        >
          <Box
            style={{
              padding: "14px",
              opacity: 1.0,
              textAlign: "center",
            }}
          >
            <Typography variant="body1" sx={{ lineHeight: "120%" }}>
              {localCodeText ? localCodeText : <span>&nbsp;</span>}
            </Typography>
          </Box>

          {showArMode && (
            <XLargeButton
              sx={{
                opacity: hasGlasses ? 1 : 0.7,
                backgroundColor: hasGlasses
                  ? theme.palette.warning.main
                  : "transparent",
                color: hasGlasses
                  ? theme.palette.warning.contrastText
                  : theme.palette.text.primary,
                textTransform: "none !important",
              }}
              variant={hasGlasses ? "contained" : "outlined"}
              onClick={navigateToAR}
              label={t("EnhancedSubtitles.title")}
              description={t("EnhancedSubtitles.summary")}
            />
          )}

          <XLargeButton
            variant="contained"
            onClick={navigateToBasic}
            sx={{
              textTransform: "none !important",
            }}
            label={t("BasicSubtitles.title")}
            description={t("BasicSubtitles.summary")}
          />
        </Stack>
      </Slide>

      {Dialog.Dialog}
    </DefaultLayout>
  );
};

export default HomeScreen;
