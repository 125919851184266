import React, { useEffect } from "react";
import ConversationList from "comps/conversations/ConversationList";
import { useStoreLocal } from "state/store";
import { useXRaiSnackbar } from "hooks/useXraiSnackbar";
import { useMinimize } from "hooks/useMinimize";
import { SessionHistory, SessionMeta } from "types/types";
import { TFunction } from "i18next";

type Props = {
  editMode: boolean;
  searchResults: SessionHistory[] | undefined;
  conversationTitles: { [key: string]: string };
  setEditMode: (value: boolean) => void;
  setConversationsCount: React.Dispatch<React.SetStateAction<number>>;
  setSelectedSessions: React.Dispatch<React.SetStateAction<SessionMeta[]>>;
  t: TFunction<"translation", undefined, "translation">; // Translation
  startCaptionHandler: (e?: any, action?: "pause" | "play") => Promise<void>;
};

const ConversationScreen: React.FC<Props> = ({
  editMode,
  searchResults,
  conversationTitles,
  setEditMode,
  setConversationsCount,
  setSelectedSessions,
  t,
  startCaptionHandler,
}) => {
  const [conversationDelete, setConversationDeleted] = useStoreLocal(
    "convoDeleted",
    false
  );

  const { minimize } = useMinimize();

  const AlertSnackbar = useXRaiSnackbar({ position: "top" });

  const clearEditMode = () => {
    setSelectedSessions([]);
    setEditMode(false);
  };

  useEffect(() => {
    // If deleted localStorage mark
    // show snackbar then clear params
    if (conversationDelete) {
      AlertSnackbar.success(t("conversations.deleted"));
      setConversationDeleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return minimize ? <div/> : (
    <>
      <ConversationList
        startCaptionHandler={startCaptionHandler}
        onConversationsLoaded={(sessionHistory: SessionHistory[]) => {
          setConversationsCount(sessionHistory.length);
        }}
        searchResults={searchResults}
        onEditModeEnd={() => {
          clearEditMode();
        }}
        editMode={editMode}
        onSelectionChange={(items) => {
          setSelectedSessions(items);
        }}
        keyword={undefined}
        conversationTitles={conversationTitles}
      />

      {AlertSnackbar.Snackbar}
    </>
  );
};

export default ConversationScreen;
