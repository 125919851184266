import useUnityAuth from "comps/auth/useUnityAuth";
import useEventParsing from "./useEventParsing";
import xconsole from "utils/xconsole";
import { encrypt } from "utils/encryption";
import {
  xfetch,
  xhostLocal,
  xhostOther,
  xfetchLocal,
  xfetchOther,
} from "utils/xfetch";

type Props = {
  setRootFontSize: (size: number) => void;
  getRootFontSize: () => number;
  Dialog: any;
};

const useAppEvents = ({ setRootFontSize, getRootFontSize, Dialog }: Props) => {
  const userAuth = useUnityAuth();

  const {
    isNetworkConnected,
    handleMessage,
    eventParseNetwork,
    eventParseHeyXrai,
    eventParseAuth,
    eventParseState,
    eventParseDownloader,
    eventParseSetting,
    eventParsePurchase,
    eventParseLogin,
    eventParseCapState,
    eventParsePermissionsStatus,
    eventParseMulticast,
    eventParseRelocate,
    eventParseSubtitleFluid,
    eventParseSubtitleFinal,
  } = useEventParsing({ getRootFontSize, setRootFontSize, Dialog });

  const updateState = () => {
    userAuth.checkLogin();
    xfetch("/pumpstate", { method: "POST" });
  };

  const createAppEventLocal = () => {
    if (globalThis.eventLocal == null) {
      xconsole.log("App createAppEventLocal try");
      const eskey = "app.local." + globalThis.appRunNonce;
      const path = `/sse?verb=make&key=${eskey}`;

      encrypt(path)
        .then((value) => {
          const [iv, cipher] = value;
          const fullpath =
            xhostLocal() +
            path +
            `&iv=${encodeURIComponent(iv)}` +
            `&challenge=${encodeURIComponent(cipher)}`;
          var es = new EventSource(fullpath);
          es.onopen = () => {
            xconsole.debug("eventLocal open");
            updateState();
          };
          es.onerror = () => {
            xconsole.debug("eventLocal error");
          };
          es.addEventListener("Relocate", eventParseRelocate);
          globalThis.eventLocal = es;
        })
        .catch((e) => {
          xconsole.error("Create eventLocal fail", e);
        });
    }
  };

  const createAppEventOther = () => {
    if (globalThis.eventOther == null) {
      xconsole.log("App createAppEventOther try");
      const eskey = "app.other." + globalThis.appRunNonce;
      const path = `/sse?verb=make&key=${eskey}`;
      encrypt(path)
        .then((value) => {
          const [iv, cipher] = value;
          const fullpath =
            xhostOther() +
            path +
            `&iv=${encodeURIComponent(iv)}` +
            `&challenge=${encodeURIComponent(cipher)}`;
          var es = new EventSource(fullpath);
          es.onopen = () => {
            xconsole.debug("eventOther open");
            updateState();
          };
          es.onerror = () => {
            xconsole.debug("eventOther error");
          };
          es.addEventListener("CapState", eventParseCapState);
          es.addEventListener("PermissionsStatus", eventParsePermissionsStatus);
          es.addEventListener("Purchase", eventParsePurchase);
          es.addEventListener("Setting", eventParseSetting);
          es.addEventListener("SFLD", eventParseSubtitleFluid);
          es.addEventListener("SFNL", eventParseSubtitleFinal);
          es.addEventListener("Network", eventParseNetwork);
          es.addEventListener("HeyXrai", eventParseHeyXrai);
          es.addEventListener("Login", eventParseLogin);
          es.addEventListener("Auth", eventParseAuth);
          es.addEventListener("State", eventParseState);
          es.addEventListener("AssetManager", eventParseDownloader);
          es.addEventListener("Multicast", eventParseMulticast);
          globalThis.eventOther = es;
        })
        .catch((e) => {
          xconsole.error("Create eventOther fail", e);
        });
    }
  };

  const createAppEventSource = () => {
    xconsole.log("App createAppEventSource beg");
    createAppEventLocal();
    createAppEventOther();
  };

  const deleteAppEventLocal = () => {
    if (globalThis.eventLocal != null) {
      try {
        xconsole.log("App deleteAppEventLocal try");
        let es = globalThis.eventLocal;
        const eskey = "app." + globalThis.appRunNonce;
        xfetchLocal(`/sse?verb=drop&key=${eskey}`);
        es.removeEventListener("Relocate", eventParseRelocate);
        es.close();
        globalThis.eventLocal = null;
      } catch (e) {
        xconsole.error("Create eventOther fail", e);
      }
    }
  };

  const deleteAppEventOther = () => {
    if (globalThis.eventOther != null) {
      try {
        xconsole.log("App deleteAppEventOther try");
        let es = globalThis.eventOther;
        const eskey = "app." + globalThis.appRunNonce;
        xfetchOther(`/sse?verb=drop&key=${eskey}`);
        es.removeEventListener("CapState", eventParseCapState);
        es.removeEventListener(
          "PermissionsStatus",
          eventParsePermissionsStatus
        );
        es.removeEventListener("Purchase", eventParsePurchase);
        es.removeEventListener("Setting", eventParseSetting);
        es.removeEventListener("SFLD", eventParseSubtitleFluid);
        es.removeEventListener("SFNL", eventParseSubtitleFinal);
        es.removeEventListener("Network", eventParseNetwork);
        es.removeEventListener("HeyXrai", eventParseHeyXrai);
        es.removeEventListener("Login", eventParseLogin);
        es.removeEventListener("Auth", eventParseAuth);
        es.removeEventListener("State", eventParseState);
        es.removeEventListener("AssetManager", eventParseDownloader);
        es.removeEventListener("Multicast", eventParseMulticast);
        es.close();
        globalThis.eventOther = null;
      } catch (e) {
        xconsole.error("Create eventOther fail", e);
      }
    }
  };

  const deleteAppEventSource = () => {
    xconsole.log("App deleteAppEventSource end");
    deleteAppEventLocal();
    deleteAppEventOther();
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window["createAppEventSource"] = createAppEventSource;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window["deleteAppEventSource"] = deleteAppEventSource;

  return {
    isNetworkConnected,
    handleMessage,
    createAppEventSource,
    deleteAppEventSource,
  };
};

export default useAppEvents;
