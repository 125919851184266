import { Box, Button, Container, Stack, styled, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toJSONPostRequest, xfetchLocal } from "utils/xfetch";
import DefaultLayout from "comps/layout/DefaultLayout";
import dollarFormat from "utils/formatDollar";
import xconsole from "utils/xconsole";


const ButtonGroup = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBottom: "20vh",
});



const SubscriptionsScreen = () => {
  const { t } = useTranslation();

  const [subscriptions, setSubscriptions] = useState(null);

  useEffect(() => {
    xfetchLocal("/loadsubscriptions", { method: "POST" })
      .then((response) => response.json())
      .then((subscriptions) => {
        xconsole.log({ subscriptions });
        setSubscriptions(subscriptions);
      })
      .catch((e) => {
        xconsole.error("loadsubscriptions",e);
      });
  }, []);

  const launch_billing_func = async (id: string) => {
    const req = {
      Product: id,
    };
    const payload = await toJSONPostRequest(req);
    xfetchLocal("/launchbillingflow", payload);
  };

  return (
    <DefaultLayout
      pageTitle="Subscription"
      backLabel={t("Back")}
      backNavigate={-1}
      headerComponent={
        <div style={{ padding: "16px" }}>
          <Typography variant="h1" component="div" gutterBottom>
            {t("Subscriptions")}
          </Typography>
          <Typography variant="h2" component="div" gutterBottom>
            {t("letschooseplan")}
          </Typography>
        </div>
      }
    >
      <Container maxWidth="sm" style={{ padding: "0 16px" }}>
        <ButtonGroup>
          <Stack spacing={2}>
            {subscriptions &&
              subscriptions.map(
                ({
                  Id,
                  Name,
                  Price,
                }: {
                  Id: string;
                  Name: string;
                  Price: number;
                }) => (
                  <Button
                    key={Id}
                    variant="contained"
                    sx={{ width: "90vw" }}
                    onClick={() => {
                      launch_billing_func(Id);
                    }}
                  >{t(Name)}  {dollarFormat(Price)}</Button>
                )
              )}
          </Stack>
        </ButtonGroup>
      </Container>
    </DefaultLayout>
  );
};

export default SubscriptionsScreen;
