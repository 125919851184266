import React, { ReactElement, useState } from "react";

import {
  Box,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  styled,
  Drawer,
  Typography,
} from "@mui/material";
import { wait } from "utils/wait";

interface MenuItemProps {
  label: string;
  disabled?: boolean;
  icon?: ReactElement<any, any>;
  onClick: () => void;
}

interface Props {
  title?: string;
  menuItems: MenuItemProps[];
  header?:ReactElement<any,any>
}

const CompressedDrawer = styled(Drawer)({
  ".MuiPaper-root": {
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    paddingBottom: "24px",
    paddingTop: "16px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "0",
    minHeight: "256px",
  },
});

export const useBottomSheet = (props: Props) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const menuClick = async (item: MenuItemProps) => {
    if (item.onClick) item.onClick();
    await wait(300);
    setOpen(false);
  };

  return {
    drawer: (
      <CompressedDrawer
        anchor="bottom"
        open={open}
        onClose={() => toggleDrawer(false)}
      >
        {props.header}
        {props.title &&
          (
            <Box sx={{ px: "24px", pb: "18px", pt: "6px" }}>
              <Typography
                variant="h2"
                color="action"
                sx={{
                  fontWeight: "700",
                  fontSize: "1rem",
                }}
                component="h2"
              >
                {props.title}
              </Typography>
            </Box>
          )}
          {(!!props.header || !!props.title) && <Divider />}
        <List>
          {props.menuItems.map((menuItem, index) => (
            <MenuItem
              sx={{ paddingLeft: "24px",  }}
              disabled={menuItem.disabled}
              key={index}
              onClick={() => menuClick(menuItem)}
            >
              {!!menuItem.icon && (
                <ListItemIcon>
                  {menuItem.icon}
                </ListItemIcon>
              )}
              <ListItemText sx={{fontWeight: '400', py:'5px'}} primary={menuItem.label} />
            </MenuItem>
          ))}
        </List>
      </CompressedDrawer>
    ),
    openDrawer: () => toggleDrawer(true),
    closeDrawer: () => toggleDrawer(false),
  };
};
