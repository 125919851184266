import { toJSONPostRequest, xfetchLocal } from "utils/xfetch";

export const useOpenURL = () => {
  return {
    open(url:string) {
      toJSONPostRequest({ url: url }).then(
        (payload) => {
          xfetchLocal('/openurl', payload);
        }
      )
    }
  };
};
