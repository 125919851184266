import { AppUser, AppUserSetup } from "types/types";
import { getState } from "utils/settings";

export const getUserCredentials = async (userSession: any) => {
  const idPayload = userSession.idToken.payload;

  const getUserSetup = async (): Promise<AppUserSetup> => {
    const onboarded: string = await getState("HasOnboarded");
    const haveperms: string = await getState("HaveAllPerms");
    return {
      onboarded: onboarded === "True",
      hasPermissions: haveperms === "True",
    };
  };

  const deviceId:string = await getState("DeviceId").catch(e=>{return ""});
  const firstName:string = idPayload.given_name || "";
  const lastName:string = idPayload.family_name || "";
  const emailAddress:string = idPayload.email || "";
  const platform:string = await getState("Platform").catch(e=>{return ""});

  const entitlementPolicies = JSON.parse(userSession.idToken?.payload?.xrai_policy || "{}");
  const newEntitlements = entitlementPolicies?.entitlement || [];

  const picture:any = idPayload.picture;
  const profilePicture = picture ? picture : '/no-avatar.png';

  const appUser: AppUser = {
    DeviceId: deviceId,
    Platform: platform,
    FirstName:  firstName,
    Entitlements: newEntitlements,
    LastName: lastName,
    EmailAddress: emailAddress,
    ProfilePicture: profilePicture,
    Setup: {
      onboarded: false, // set later on
      hasPermissions: false, // set later on
    },
  };

  if (appUser) {
    let userSetup = await getUserSetup();
    appUser.Setup = userSetup;
  }

  return appUser;
}
