export const LangCodeToCountry: { [key: string]: string } = {
  // "bcp-47"
  "af-ZA": "ZA",
  "am-ET": "ET",
  "ar-AE": "AE",
  "ar-BH": "BH",
  "ar-DZ": "DZ",
  "ar-EG": "EG",
  "ar-IL": "IL",
  "ar-IQ": "IQ",
  "ar-JO": "JO",
  "ar-KW": "KW",
  "ar-LB": "LB",
  "ar-LY": "LY",
  "ar-MA": "MA",
  "ar-OM": "OM",
  "ar-PS": "PS",
  "ar-QA": "QA",
  "ar-SA": "SA",
  "ar-SY": "SY",
  "ar-TN": "TN",
  "ar-YE": "YE",
  "az-AZ": "AZ",
  "bg-BG": "BG",
  "bn-IN": "IN",
  "bs-BA": "BA",
  "ca-ES": "ES",
  "cs-CZ": "CZ",
  "cy-GB": "GB-WLS",
  "da-DK": "DK",
  "de-DE": "DE",
  "de-AT": "AT",
  "de-CH": "CH",
  "el-GR": "GR",
  "en-US": "US",
  "en-AU": "AU",
  "en-CA": "CA",
  "en-GB": "GB",
  "en-GH": "GH",
  "en-HK": "HK",
  "en-IE": "IE",
  "en-IN": "IN",
  "en-KE": "KE",
  "en-NG": "NG",
  "en-NZ": "NZ",
  "en-PH": "PH",
  "en-SG": "SG",
  "en-TZ": "TZ",
  "en-ZA": "ZA",
  "es-ES": "ES",
  "es-AR": "AR",
  "es-BO": "BO",
  "es-CL": "CL",
  "es-CO": "CO",
  "es-CR": "CR",
  "es-CU": "CU",
  "es-DO": "DO",
  "es-EC": "EC",
  "es-GQ": "GQ",
  "es-GT": "GT",
  "es-HN": "HN",
  "es-MX": "MX",
  "es-NI": "NI",
  "es-PA": "PA",
  "es-PE": "PE",
  "es-PR": "PR",
  "es-PY": "PY",
  "es-SV": "SV",
  "es-US": "US",
  "es-UY": "UY",
  "es-VE": "VE",
  "et-EE": "EE",
  "eu-ES": "ES",
  "fa-IR": "IR",
  "fa-AF": "AF",
  "fi-FI": "FI",
  "fil-PH": "PH",
  "fr-FR": "FR",
  "fr-BE": "BE",
  "fr-CA": "CA",
  "fr-CH": "CH",
  "ga-IE": "IE",
  "gl-ES": "ES",
  "gu-IN": "IN",
  "he-IL": "IL",
  "hi-IN": "IN",
  "hr-HR": "HR",
  "hu-HU": "HU",
  "hy-AM": "AM",
  "id-ID": "ID",
  "is-IS": "IS",
  "it-IT": "IT",
  "it-CH": "CH",
  "iu-Latn": "CA",
  "iu-Cans": "CA",
  "ja-JP": "JP",
  "jv-ID": "ID",
  "ka-GE": "GE",
  "kk-KZ": "KZ",
  "km-KH": "KH",
  "kn-IN": "IN",
  "ko-KR": "KR",
  "lo-LA": "LA",
  "lt-LT": "LT",
  "lv-LV": "LV",
  "mk-MK": "MK",
  "ml-IN": "IN",
  "mn-Cyrl": "MN",
  "mn-MN": "MN",
  "mn-Mong": "MN",
  "mr-IN": "IN",
  "ms-MY": "MY",
  "mt-MT": "MT",
  "my-MM": "MM",
  "nb-NO": "NO",
  "ne-NP": "NP",
  "nl-NL": "NL",
  "nl-BE": "BE",
  "pa-IN": "IN",
  "pl-PL": "PL",
  "ps-AF": "AF",
  "pt-PT": "PT",
  "pt-BR": "BR",
  "ro-RO": "RO",
  "ru-RU": "RU",
  "si-LK": "LK",
  "sk-SK": "SK",
  "sl-SI": "SI",
  "so-SO": "SO",
  "sq-AL": "AL",
  "sr-Latn": "RS",
  "sr-Cyrl": "RS",
  "sr-RS": "RS",
  "sv-SE": "SE",
  "sw-KE": "KE",
  "sw-TZ": "TZ",
  "ta-IN": "IN",
  "te-IN": "IN",
  "th-TH": "TH",
  "tlh-Latn": "Qo'noS",
  "tlh-Piqd": "Qo'noS",
  "tr-TR": "TR",
  "uk-UA": "UA",
  "ur-IN": "IN",
  "uz-UZ": "UZ",
  "vi-VN": "VN",
  "wuu-CN": "CN",
  "yue-CN": "CN",
  "zh-CN": "CN",
  "zh-CN-shandong": "CN",
  "zh-CN-sichuan": "CN",
  "zh-HK": "HK",
  "zh-Hans": "CN",
  "zh-Hant": "TW",
  "zh-TW": "TW",
  "zu-ZA": "ZA",
  // "iso-639-1"
  af: "ZA",
  am: "ET",
  ar: "AE",
  as: "IN",
  az: "AZ",
  ba: "RU",
  bg: "BG",
  bho: "IN",
  bn: "IN",
  bo: "CN",
  brx: "IN",
  bs: "BA",
  ca: "ES",
  cs: "CZ",
  cy: "GB-WLS",
  da: "DK",
  de: "DE",
  doi: "IN",
  dsb: "DE",
  dv: "MV",
  el: "GR",
  en: "US",
  es: "ES",
  et: "EE",
  eu: "ES",
  fa: "IR",
  fi: "FI",
  fil: "PH",
  fj: "FJ",
  fo: "FO",
  fr: "FR",
  ga: "IE",
  gl: "ES",
  gom: "IN",
  gu: "IN",
  ha: "NG",
  he: "IL",
  hi: "IN",
  hr: "HR",
  hsb: "DE",
  ht: "HT",
  hu: "HU",
  hy: "AM",
  id: "ID",
  ig: "NG",
  ikt: "CA",
  is: "IS",
  it: "IT",
  iu: "CA",
  ja: "JP",
  jv: "ID",
  ka: "GE",
  kk: "KZ",
  km: "KH",
  kmr: "IQ",
  kn: "IN",
  ko: "KR",
  ks: "IN",
  ku: "IQ",
  ky: "KG",
  ln: "CD",
  lo: "LA",
  lt: "LT",
  lug: "UG",
  lv: "LV",
  lzh: "CN",
  mai: "IN",
  mg: "MG",
  mi: "NZ",
  mk: "MK",
  ml: "IN",
  mn: "MN",
  mr: "IN",
  ms: "MY",
  mt: "MT",
  mww: "VN",
  my: "MM",
  nb: "NO",
  ne: "NP",
  nl: "NL",
  no: "NO",
  nso: "ZA",
  nya: "MW",
  or: "IN",
  otq: "MX",
  pa: "IN",
  pl: "PL",
  prs: "AF",
  ps: "AF",
  pt: "PT",
  ro: "RO",
  ru: "RU",
  run: "BI",
  rw: "RW",
  sd: "PK",
  si: "LK",
  sk: "SK",
  sl: "SI",
  sm: "WS",
  sn: "ZW",
  so: "SO",
  sq: "AL",
  sr: "RS",
  st: "ZA",
  sv: "SE",
  sw: "KE",
  ta: "IN",
  te: "IN",
  th: "TH",
  ti: "ER",
  tk: "TM",
  tl: "PH",
  tn: "ZA",
  to: "TO",
  tr: "TR",
  tt: "RU",
  ty: "PF",
  ug: "CN",
  uk: "UA",
  ur: "IN",
  uz: "UZ",
  vi: "VN",
  wuu: "CN",
  xh: "ZA",
  yo: "NG",
  yua: "MX",
  yue: "CN",
  zh: "CN",
  zu: "ZA",
};
