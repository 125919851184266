import React, { useState, useMemo, useCallback, ChangeEvent } from "react";

import CheckIcon from "@mui/icons-material/Check";
import { Box, Palette, Theme, Tooltip } from "@mui/material";

import { TooltipRadio } from "./ColorTool";
import {
  getClassicPalette,
  getCompanyPalette,
  getContrastPalette,
  getNeutralPalette,
  getRedbullPalette,
} from "./Palettes";

import xconsole from "utils/xconsole";
import { TFunction } from "i18next";

type Props = {
  updateColorStateEvent: (
    palette: Palette
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  t: TFunction<"translation", undefined, "translation">;
  appPalette: Palette;
  theme: Theme;
  darkMode: boolean;
  screenCast: boolean;
};

const BasicThemesSwatch: React.FC<Props> = ({
  updateColorStateEvent,
  t,
  appPalette,
  theme,
  darkMode,
  screenCast,
}) => {
  // these are our baked in full themes
  const themes = useMemo(() => {
    const palettes = [
      getClassicPalette,
      getCompanyPalette,
      getNeutralPalette,
      getContrastPalette
    ];

    if (globalThis.isRedBull) {
      palettes.push(getRedbullPalette);
    }

    return palettes;
  }, []);
  
  const getFixedPalette = useCallback(
    (index: number) => {
      if (index >= 0 && index < themes.length) {
        return themes[index];
      } else {
        xconsole.error(`getFixedPalette fail index ${index}`);
        return getCompanyPalette;
      }
    },
    [themes]
  );

  const [basicThemes] = useState(
    Array.from({ length: themes.length }, (_, i) => i)
  );

  return (
    <>
      {basicThemes.map((themeId) => {
        const palette = getFixedPalette(themeId)(darkMode, screenCast);
        const backgroundColor = palette.primary.main;

        return (
          <Tooltip key={palette.name} title={t(palette.name)}>
            <TooltipRadio
              sx={{ m: 0.25, p: 0 }}
              color="default"
              checked={appPalette.name === palette.name}
              onChange={updateColorStateEvent(palette)}
              value={palette.name}
              name={t(palette.name)}
              icon={
                <>
                  <Box
                    sx={{ width: 48, height: 48, boxShadow: 2 }}
                    style={{ backgroundColor, border: "1px solid" }}
                  />
                </>
              }
              checkedIcon={
                <>
                  <Box
                    sx={{
                      width: 48,
                      height: 48,
                      border: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    style={{ backgroundColor }}
                  >
                    <CheckIcon
                      style={{
                        color: theme.palette.getContrastText(backgroundColor),
                        fontSize: 30,
                      }}
                    />
                  </Box>
                </>
              }
            />
          </Tooltip>
        );
      })}
    </>
  );
};

export default BasicThemesSwatch;
