import React from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackButton from "comps/BackButton";
import { setState } from "utils/settings";
import { Thtml } from "comps/common/Thtml";
import usePageTitle from "hooks/usePageTitle";
import { BackButtonWrapper, OnboardingContainer } from "themes/theme";

const OverviewScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  usePageTitle("Onboard Overview");

  return (
    <OnboardingContainer>
      <BackButtonWrapper>
        <BackButton
          name={t("overview.back")}
          handler={() => navigate("/permissions")}
        />
      </BackButtonWrapper>

      <div className="x-spacer-sm" />

      <Typography variant="h1" component="h1" gutterBottom>
        {t("overview.title")}
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden scroll",
          paddingRight: theme.spacing(2),
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography variant="h2" component="h2" sx={{ fontSize: "1.5rem" }}>
            <Thtml tag="overview.subTitle" />
            <span>&nbsp;XRAI Glass!</span>
          </Typography>

          <Typography
            variant="body1"
            component="div"
            //todo remove style
            sx={{ my: 2, mx: 0 }}
          >
            <Thtml tag="overview.summary" />
          </Typography>
        </Box>

        <Stack spacing={2} sx={{ my: 2 }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              setState("HasOnboarded", true).then(() => {
                navigate("/home");
              });
            }}
          >
            <Thtml tag="overview.getstarted" />
          </Button>
        </Stack>
      </div>
    </OnboardingContainer>
  );
};

export default OverviewScreen;
