import { ReportProblem } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import { t } from "i18next"
import React from "react"
import { appReload } from "utils/xfetch";

interface Props {
  error: any,
  resetErrorBoundary: any
}
export default function ReactErrorDisplay(props:Props) {

  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{
        color:"#FFF",
        borderRadius:"12px",
        position:"absolute",
        backgroundColor:"#000",
        top:"12px",
        bottom:"12px",
        padding:"20px",
        fontFamily:"Roboto, sans-serif",
        left:"12px",
        right:"12px",
        boxShadow: "0px 10px 20px rgba(0,0,0,0.4)"
      }}>
      <ReportProblem color="warning" style={{fontSize:"62px"}} />
      <div>
        <Typography
          style={{
            textAlign:"center",
            fontSize:"24px",
            marginBottom:"8px",
            fontWeight:"bold"}}
          variant="h3">
          {t(`Error occurred`)}
        </Typography>
        <Typography variant="body1" style={{textAlign:"center", lineHeight:"110%"}}>
          {props.error.message}
        </Typography>
      </div>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Button onClick={()=>{
          props.resetErrorBoundary();
        }} color="primary" variant="text">
          {t("Retry")}
        </Button>
        <Button onClick={()=>{
          appReload();
        }} color="error" variant="text">
          {t('Restart')}
        </Button>
      </Stack>
      <div style={{
        maxWidth:"100%",
        color:"#888",
        padding:"16px",
        overflowX: "auto",
        borderRadius:"16px"
      }}>
        <Typography variant="body1">
          <pre style={{fontSize:"11px"}}>
            {props.error.stack}
          </pre>
        </Typography>
      </div>
    </Stack>
  )
}
