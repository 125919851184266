import React, { useContext, useState } from "react";

import {
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";

import { Theme } from "@mui/system";
import { ThemeContext } from "@emotion/react";

import { DownloadStatus } from "./DownloaderStore";
import { useStore } from "state/store";
import { FavoriteLanguagePayload, LanguageNode } from "./types";

import MicrosoftLogo from "comps/icons/MicrosoftLogo";
import AmazonLogo from "comps/icons/AmazonLogo";
import DeepgramLogo from "comps/icons/DeepgramLogo";
import LanguageIcons from "./LanguageIcons";
import VoskLogo from "comps/icons/VoskLogo";
import NoTransLogo from "@mui/icons-material/ContentCopy";
import AutoRevLogo from "@mui/icons-material/SwapHoriz";

import Flag from "react-world-flags";
import { LangCodeToCountry } from "./LangCodeToCountry";
import FavoriteToggle from "comps/FavoriteToggle";
import DownloadButton from "comps/DownloadButton";
import RoundCheckbox from "comps/RoundCheckbox";

type Props = {
  lang: LanguageNode;
  favorited: boolean;
  label: string;
  downloadStatus: DownloadStatus;
  onSelect: (resp?: FavoriteLanguagePayload[]) => void;
  onToggleFavorite: (key: string) => Promise<FavoriteLanguagePayload[]> | void;
  isLangDownloaded: (lang: LanguageNode) => boolean;
  type?: string;
  selected?: boolean;
  description?: string;
  showVendor?: boolean;
  showIcons?: boolean;
  labelStyle?: any;
  langCode?: string;
  hideFlags?: boolean;
  hideSelection?: boolean;
  hideFavorite?: boolean;
  selectedLanguages?: string[];
  onDownloadStart?: (key: string) => void;
  onDownloadCancel?: (key: string) => void;
};

export default function LanguageListItem(props: Props) {
  const downloaded = props.isLangDownloaded(props.lang);
  const [downloadProgress, setDownloadProgress] = useState(-1);
  const theme = useContext(ThemeContext) as Theme;
  const darkColor = theme.palette.mode === "dark" ? "white" : "black";
  const [iconSize] = useStore<number>("rootIconSize");
  const logoSize = 20;

  const countryCode = LangCodeToCountry[props.lang.langcode];
  const isTargetLanguage = props.type === "TargetLanguage";

  const nodeSelected =
    props.selectedLanguages?.length >= 2
      ? props.lang.vendor === "azure"
        ? props.selectedLanguages?.some(
            (lang) => lang.split(".")[1] === props.lang.langcode
          )
        : props.selected
      : props.selected;

  const disableEngine =
    props.selectedLanguages?.length >= 2 && props.lang.multilingo === false
    ;

  const showAzure = () => {
    if (isTargetLanguage) {
      return props.lang.vendor === "azure";
    }

    if (
      props.lang.vendor === "azure" &&
      (countryCode === "" || props?.hideFlags)
    ) {
      return true;
    }

    return false;
  };

  const showVosk = () => {
    if (isTargetLanguage) {
      return props.lang.vendor === "vosk";
    }

    if (
      props.lang.vendor === "vosk" &&
      (countryCode === "" || props?.hideFlags)
    ) {
      return true;
    }

    return false;
  };

  const showDeepgram = () => {
    if (isTargetLanguage) {
      return props.lang.vendor === "deepgram";
    }

    if (
      props.lang.vendor === "deepgram" &&
      (countryCode === "" || props?.hideFlags)
    ) {
      return true;
    }

    return false;
  };

  const showAws = () => {
    if (isTargetLanguage) {
      return props.lang.vendor === "aws";
    }

    if (
      props.lang.vendor === "aws" &&
      (countryCode === "" || props?.hideFlags)
    ) {
      return true;
    }

    return false;
  };

  return (
    <ListItemButton
      disabled={disableEngine}
      dense
      key={props.lang.key}
      onClick={(evt) => {
        if (downloaded) {
          evt.preventDefault();
          evt.stopPropagation();
        }
      }}
      id={props.lang.key}
      sx={{
        px: props?.hideSelection ? theme.spacing(1) : 0,
      }}
    >
      {(props.showVendor || nodeSelected) && (
        <ListItemIcon sx={{ minWidth: "28px", mr: 1 }}>
          {props.lang.key === "xrai._xa-SP" && <NoTransLogo />}
          {props.lang.key === "xrai._xb-RV" && <AutoRevLogo />}

          {showAzure() && <MicrosoftLogo size={logoSize} />}

          {showVosk() && <VoskLogo size={logoSize} />}

          {showDeepgram() && <DeepgramLogo size={logoSize} />}

          {showAws() && <AmazonLogo size={logoSize} color={darkColor} />}

          {countryCode !== "" && !isTargetLanguage && !props.hideFlags && (
            <Flag code={countryCode} height={logoSize} width={logoSize} />
          )}
        </ListItemIcon>
      )}

      <ListItemText>
        <Typography
          sx={{
            paddingInlineEnd: `calc(${iconSize * 2}px + ${theme.spacing(2)})`, // 2 Icons + padding
          }}
        >
          {props.label}
        </Typography>
      </ListItemText>

      <ListItemSecondaryAction
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {props.showIcons && <LanguageIcons lang={props.lang} />}

        {!downloaded && (
          <>
            <DownloadButton
              percent={downloadProgress}
              onStart={() => {
                // Todo: Implement Download
                if (props.onDownloadStart) {
                  props.onDownloadStart(props.lang.key);
                }

                setDownloadProgress(60); // mocking it up
              }}
              onCancel={() => {
                // Todo: Implement Download
                if (props.onDownloadCancel) {
                  props.onDownloadCancel(props.lang.key);
                }

                setDownloadProgress(-1);
              }}
            />
          </>
        )}

        {downloaded && (
          <>
            {!props.hideFavorite && props.lang.key !== "xrai._xa-SP" && (
              <FavoriteToggle
                style={{
                  opacity: props.favorited ? 1.0 : 0.6,
                  marginLeft: theme.spacing(1),
                }}
                checked={props.favorited}
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  props.onToggleFavorite(props.lang.key);
                }}
              />
            )}

            {!props.hideSelection && (
              <RoundCheckbox
                checked={nodeSelected}
                disabled={disableEngine}
                onClick={async () => {
                  await props.onSelect();

                  if (
                    props.hideFavorite &&
                    props.lang.key !== "xrai._xa-SP" &&
                    props.favorited
                  ) {
                    props.onToggleFavorite(props.lang.key);
                  }
                }}
              />
            )}
          </>
        )}
      </ListItemSecondaryAction>
    </ListItemButton>
  );
}
