export interface ProductSku {
  sku: string;
  isTrial: boolean;
  term: string;
  plan: string;
}

export function parseSku(sku: string): ProductSku {
  if (sku === "glass_premium_monthly_trial") {
    return {
      sku: "glass_trial",
      isTrial: true,
      term: "month",
      plan: "trial",
    };
  }
  const raw = sku.replace("glass_", "");
  const split = raw.split("_");
  const plan = split[0];
  const term = raw.replace(`_${plan}`, "").split("_")[1];

  return {
    sku: sku,
    plan,
    isTrial: sku.search("trial") > -1,
    term: term,
  };
}