import React from "react";

type Props = {
  size?: number;
  style?: React.CSSProperties;
}

export default function DeepgramLogo(props: Props) {
  const size = `${props.size || 24}px`;
  return (
    <svg
      style={props?.style}
      width={size}
      height={size}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H120C124.418 0 128 3.58172 128 8V120C128 124.418 124.418 128 120 128H8C3.58172 128 0 124.418 0 120V8Z"
        fill="#38EDAC"
      />
      <path
        d="M104.995 65.1816C105.169 53.4511 100.739 42.373 92.5025 33.992C84.2655 25.611 73.2783 21 61.5673 21H26.4343C26.1882 21 26 21.1885 26 21.435V67.3564C26 67.603 26.1882 67.7914 26.4343 67.7914H43.733C43.9791 67.7914 44.1673 67.603 44.1673 67.3564V39.1975H61.5673C68.4434 39.1975 74.8852 41.9235 79.6914 46.8824C84.4973 51.8415 87.0305 58.3664 86.8278 65.2686C86.4225 78.7971 74.8998 89.8169 61.1476 89.8169H44.0515C43.6606 89.8169 43.2987 89.9765 43.0237 90.2519L26.6225 106.768C26.1737 107.231 26.4922 108 27.1291 108H60.8292C84.8157 108 104.633 88.7873 104.995 65.1669V65.1816Z"
        fill="black"
      />
    </svg>
  );
}
