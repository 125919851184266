import { useEffect } from "react";
import { useStore } from "state/store";

const useActivePopup = (open: boolean) => {
  const [, setActivePopup] = useStore<boolean>("activePopup");

  useEffect(() => {
    if (open) {
      setActivePopup(true);
    } else {
      setActivePopup(false);
    }

    return () => {
      setActivePopup(false);
    };
  }, [open, setActivePopup]);
};

export default useActivePopup;
