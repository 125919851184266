import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackButton from "comps/BackButton";
import PermissionSettings from "comps/PermissionSettings";
import usePageTitle from "hooks/usePageTitle";
import { BackButtonWrapper, OnboardingContainer } from "themes/theme";

const PermissionsScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  usePageTitle("Onboard Permissions");

  return (
    <OnboardingContainer>
      <BackButtonWrapper>
        <BackButton
          name={t("permissions.back")}
          handler={() => navigate("/region")}
        />
      </BackButtonWrapper>

      <PermissionSettings continue="/overview"></PermissionSettings>
    </OnboardingContainer>
  );
};

export default PermissionsScreen;
