import React, { useCallback } from "react";
import { useEffect, useContext } from "react";
import { ThemeProvider } from "@emotion/react";
import { Theme, Palette, createTheme } from "@mui/material/styles";
import { useGlobalState } from "state";
import {
  getCompanyPalette,
  getContrastPalette,
  getNeutralPalette,
  getColorPalette,
  getClassicPalette,
  getRedbullPalette,
} from "colors/Palettes";
import _ from "lodash";
import { useStore, useStoreLocal } from "state/store";
import xconsole from "utils/xconsole";
import { PanelPosition } from "pages/SubtitleScreen";
import { ColorHueKey } from "colors/Types";
import companyPalette from "colors/palettes/companyPalette";
import redbullPalette from "colors/palettes/redbullPalette";

interface ThemeLoaderProps {
  children?: any;
  // trs - I deliberatley deleted isdark, fontsize etc, please do not add them back in!
  // all style information like dark, fontsize etc should be INSIDE the theme, never outside
  // there should never be any reference to theme properties inside the app i.e. "isDark"
}

export const ThemeUpdaterContext = React.createContext({
  updateTheme: null,
  getTheme: null,
});

// an external hook for child components in the tree to update the theme
export function useThemeUpdater() {
  return useContext(ThemeUpdaterContext);
}

/*
This is how a child component in the visual hierarchy might
use the theme updater hook to update the theme:
```jsx
const MyChildComponent = () => {
    // Get the
     function from context via our hook
    const { updateTheme } = useThemeUpdater();

    // Use it to update the theme when needed
    const handleButtonClick = () => {
        updateTheme({ palette: { mode: 'light' } });
    };

    return (
        <button onClick={handleButtonClick}>
            Change theme
        </button>
    );
};
```
*/

const baseFontSizes = {
  typography: {
    h1: { fontSize: "2.230rem" },
    h2: { fontSize: "1.900rem" },
    h3: { fontSize: "1.618rem" },
    h4: { fontSize: "1.378rem" },
    h5: { fontSize: "1.174rem" },
    h6: { fontSize: "1.000rem" },
    body1: { fontSize: "1.000rem" },
    body2: { fontSize: "0.852rem" },
    subtitle1: { fontSize: "0.852rem" },
    subtitle2: { fontSize: "0.726rem" },
  },
};

function mapAndClamp(
  value: number,
  fromLow: number,
  fromHigh: number,
  toLow: number,
  toHigh: number
) {
  let fromRange = fromHigh - fromLow;
  let toRange = toHigh - toLow;
  let scaleFactor = toRange / fromRange;

  // calculate and clamp value
  let newValue = toLow + (value - fromLow) * scaleFactor;
  newValue = Math.max(toLow, Math.min(toHigh, newValue));

  return newValue;
}

function getRootIconSize(): number {
  let root = document.querySelector<HTMLElement>(":root");
  let appFontSize = parseFloat(root.style.fontSize);
  let iconSize = mapAndClamp(appFontSize, 16, 28, 32, 48);
  return iconSize;
}

export function normalizeTheme(theme: Theme) {
  return theme ? _.merge({}, theme, baseFontSizes) : theme;
}

export function applyRootFontSize(rootFontSize: number, rootFontScale: number) {
  const appFontSize = (rootFontSize || 16) * (rootFontScale || 1);
  const appFontSizePx = Math.min(28, appFontSize) + "px";

  xconsole.log("setting root fontSize: " + appFontSizePx);

  let root = document.querySelector<HTMLElement>(":root");
  root.style.fontSize = appFontSizePx;
}

export function ThemeLoader({ ...props }: ThemeLoaderProps) {
  // global persistent state initialization
  // spacing
  useStoreLocal<number>("wordSpacing", 0);
  useStoreLocal<number>("letterSpacing", 0.02);
  useStoreLocal<number>("lineSpacing", 1.5);
  useStoreLocal<number>("lineWidth", 100);
  useStoreLocal<number>("subtitleMargin", 0);
  // fonts
  useStoreLocal<number>("fontSize", 1);
  useStoreLocal<string>("textAlign", "left");
  useStoreLocal<string>("textTransform", "none");
  useStoreLocal<number>("fontWeight", 400);
  useStoreLocal<number>("panelHeight", 40);
  // other
  useStoreLocal<boolean>("screenCast", false);
  useStoreLocal<string>("appThemeMode", "system");
  useStore<boolean>("darkMode", false);
  useStoreLocal<string>("themeMode", "system");
  useStoreLocal<PanelPosition>("panelPosition", PanelPosition.default);

  const [darkMode] = useStore<boolean>("darkMode");
  const [screenCast] = useStoreLocal<boolean>("screenCast");
  const [rootFontSize] = useGlobalState("rootFontSize");
  const [rootFontScale] = useStore<number>("rootFontScale");
  const [, setMaxFontSize] = useStore<number>("maxFontSize");
  const [, setRootIconSize] = useStore<number>("rootIconSize");

  const getInitialPalette = useCallback((): Palette => {
    if (globalThis.isRedBull) {
      return redbullPalette(darkMode ? "dark" : "light", screenCast);
    }
    return companyPalette(darkMode ? "dark" : "light", screenCast);
  }, [darkMode, screenCast]);

  const [palette, setPalette] = useStoreLocal<Palette>(
    "palette",
    getInitialPalette
  );

  const getInitialTheme = useCallback((): Theme => {
    let theme: Theme = null;
    theme = createTheme({ palette: palette });
    theme = normalizeTheme(theme);
    return theme;
  }, [palette]);

  const [theme, setTheme] = useStore<Theme>("appTheme", getInitialTheme);

  useEffect(() => {
    applyRootFontSize(rootFontSize, rootFontScale);
    let maxFontSize = (rootFontSize || 16) * (rootFontScale || 1);
    setMaxFontSize(maxFontSize);
    setRootIconSize(getRootIconSize());
  }, [rootFontSize, rootFontScale, setMaxFontSize, setRootIconSize]);

  useEffect(() => {
    if (
      palette === undefined ||
      darkMode === undefined ||
      screenCast === undefined
    ) {
      return;
    }

    const name = palette?.name;
    if (!name) return;

    let newPalette: Palette = null;

    switch (name) {
      case "xrai-company-palette":
        newPalette = getCompanyPalette(darkMode, screenCast);
        break;
      case "xrai-contrast-palette":
        newPalette = getContrastPalette(darkMode, screenCast);
        break;
      case "xrai-neutral-palette":
        newPalette = getNeutralPalette(darkMode, screenCast);
        break;
      case "xrai-classic-palette":
        newPalette = getClassicPalette(darkMode, screenCast);
        break;
      case "xrai-redbull-palette":
        newPalette = getRedbullPalette(darkMode, screenCast);
        break;
      default:
        let hue = palette.name as ColorHueKey;
        newPalette = getColorPalette(hue, darkMode, screenCast);
        break;
    }

    if (newPalette) {
      setPalette(newPalette);
    }
    // disable for palette.name as it will be handled by it's useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPalette, darkMode, screenCast]);

  // These are styles which need reflective access to the theme which will always be applied at the end of everything
  // With each theme update, which means you should place things in here, which are unlikely to be overridden downstream
  function reflexiveThemeValues(theme: Theme) {
    const textColor = theme.palette.text.primary;
    const primaryColorLight = theme.palette.primary.light;

    return {
      palette: {
        background: {},
      },
      components: {
        MuiSelect: {
          styleOverrides: {
            root: {
              color: textColor,
              backgroundColor: primaryColorLight,
            },
            icon: {
              color: textColor,
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&.Mui-selected": {
                color: textColor,
                backgroundColor: theme.palette.action.selected,
              },
              "&.Mui-selected:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            contained: {
              color: textColor,
              backgroundColor: primaryColorLight,
              "&:disabled": {
                color: theme.palette.action.disabled,
                backgroundColor: theme.palette.action.disabledBackground,
                cursor: "not-allowed",
                pointerEvents: "auto",
              },
            },
          },
        },
      },
    };
  }

  // Update the theme state to merge the newValues with the existing theme
  // new values can be sparse, i.e. only the values that need to be updated
  // 1) useState setter can accept a function as an argument instead of a
  // direct value where the first argument to this function will be the
  // current state value merge old theme options with new ones and create a
  // theme from it 2) the first {} on _.merge ensures the original theme is
  // not mutated in place, only a new object is created and mutated 3) Note
  // when multiple source objects are passed to `_.merge`, the rightmost
  // value for each property wins in the event of a conflict. We need to
  // manually, propagate, font family on typography. All of the variance we
  // will just stick with the very first ones when the theme was created.

  const updateTheme = useCallback(
    (newValues: any) => {
      setTheme((oldTheme) => {
        const mergedThemeOptions = _.merge({}, oldTheme, newValues);
        //propagateTypography(mergedThemeOptions);
        let newTheme = createTheme(mergedThemeOptions);
        newTheme = _.merge({}, newTheme, reflexiveThemeValues(newTheme));
        newTheme = normalizeTheme(newTheme);
        return newTheme;
      });
    },
    [setTheme]
  );

  function pruneTheme(theme: Theme): any {
    delete theme.components;
    delete theme.shadows;
    delete theme.typography;
    return theme;
  }

  const updatePalette = useCallback(
    (palette: Palette) => {
      setTheme((oldTheme) => {
        let newTheme = null;

        delete oldTheme.palette.panel;
        delete oldTheme.palette.neutral;
        delete oldTheme.palette.image;

        newTheme = createTheme({ palette: palette });
        newTheme = pruneTheme(newTheme);
        newTheme = _.merge({}, oldTheme, newTheme);
        newTheme = _.merge({}, newTheme, reflexiveThemeValues(newTheme));
        newTheme = normalizeTheme(newTheme);
        return newTheme;
      });
    },
    [setTheme]
  );

  const getTheme = () => {
    return theme;
  };

  useEffect(() => {
    updatePalette(palette);
  }, [palette, updatePalette]);

  return (
    <ThemeUpdaterContext.Provider value={{ updateTheme, getTheme }}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeUpdaterContext.Provider>
  );

  /*
  function propagateTypography(mergedThemeOptions: any) {
    if (mergedThemeOptions.typography) {
      Object.keys(mergedThemeOptions.typography).forEach((key) => {
        if (mergedThemeOptions.typography[key] instanceof Object) {
          if (mergedThemeOptions.typography.fontFamily) {
            if (mergedThemeOptions.typography[key].hasOwnProperty("fontFamily")) {
              mergedThemeOptions.typography[key].fontFamily = mergedThemeOptions.typography.fontFamily;
            }
          }
        }
      });
    }
  }
  */
}
