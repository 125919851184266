import { Icon, IconProps } from "@mui/material";
import React from "react";

export function SubtitleDepthIcon(props: IconProps) {
  return (
    <Icon sx={props.sx}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 20H22M5 4H19M3 16.01L3.01 15.999M21.01 16.01L21 15.999M4 12.01L4.01 11.999M20.01 12.01L20 11.999M5 8.01L5.01 7.999M19.01 8.01L19 7.999M12 7V17M12 7L10.5 8.5M12 7L13.5 8.5M12 17L9 14M12 17L15 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </Icon>
  );
}
