import React, { useState, useEffect, useCallback, useContext } from "react";

import {
  Typography,
  Stack,
  Container,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { Setting } from "types/types";
import { toJSONPostRequest, xfetch, xfetchLocal } from "utils/xfetch";
import DefaultLayout from "comps/layout/DefaultLayout";

import { useNavigate } from "react-router-dom";
import xconsole from "utils/xconsole";
import { useStringToIndex } from "utils/stringToIndex";
import SettingsGroup from "comps/settings/SettingsGroup";
import { ArrowRight } from "@mui/icons-material";
import { getState, setSetting } from "utils/settings";
import { useStoreUnity } from "state/store";
import { useDialog } from "hooks/useDialog";
import Logo from "comps/Logo";
import AppUserContext from "comps/context/AppUserContext";

const isFlat = (path: string) => {
  return !path.includes("/") || path === "/";
};

const getNextPart = (path: string) => {
  const stripSep = path.replace(/^[./]+/, "");
  return stripSep.split(/[./]/)[0];
};

const getPagePrefix = () => {
  const stripBase = window.location.pathname.replace(/^[/]settings/, "");
  const chompSeps = stripBase.replace(/^[./]*|[./]*$/g, "");
  return chompSeps;
};

const stripPrefix = (path: string, prefix: string) => {
  if (path.startsWith(prefix)) {
    return path.substring(prefix.length);
  }

  return path;
};

const matchPrefix = (path: string, prefix: string) => {
  if (!prefix) return true;

  if (path.includes(".") && path.includes("/")) {
    return path.startsWith(prefix + ".");
  }

  const prefixSlash = prefix + "/";
  const canonical = path;

  return canonical.startsWith(prefixSlash) || false;
};

const SettingsPage = () => {
  const [appBuild, setAppBuild] = useState<string>("0000-00-00.0");
  const [devModeClicks, setDevModeClicks] = useState(0);

  const [devMode, setDevMode] = useStoreUnity("DevMode", false);

  const { t } = useTranslation();
  const { appUser } = useContext(AppUserContext);
  const [settings, setSettings] = useState<Setting[]>([]);
  const navigate = useNavigate();
  const Dialog = useDialog();
  const theme = useTheme();

  const prefix = getPagePrefix();
  const groupToIndex = useStringToIndex();
  const allGroups = new Set<string>();
  const nestedGroups = new Set<string>();
  const groupToSettings = new Map<string, Setting[]>();

  const getStatus = async () => {
    try {
      const res = await getState("XraiBuild");
      setAppBuild(res);
    } catch (e) {
      xconsole.error("SettingsScreen getStatus", e);
    }
  };

  const forgetknowns = async () => {
    const payload = {
      routeKey: "*",
    };

    const cryload = await toJSONPostRequest(payload);

    const res = await xfetchLocal(`/forgetdevice`, cryload);

    if (res.status !== 200 && res.status !== 204) {
      xconsole.error(`settings forgetknowns fail (${res.statusText})`);
    }
  };

  const devModeClick = useCallback(() => {
    if (devModeClicks === 7) {
      let message = "";
      if (devMode) {
        setDevMode(false);
        message = t("Disabled");
      } else {
        setDevMode(true);
        message = t("Enabled");
      }

      setDevModeClicks(0);

      Dialog.showDialog({
        closable: false,
        title: t("settings.DevSettings.title"),
        message: message,
        buttons: [
          {
            label: t("Ok"),
          },
        ],
      });
    } else {
      setDevModeClicks(devModeClicks + 1);
    }
  }, [devModeClicks, t, setDevMode, devMode, Dialog]);

  const getSetting = useCallback(
    (name: string) => {
      return settings.find((s) => s.Name === name);
    },
    [settings]
  );

  const updateSetting = async (name: string, value: string) => {
    const setting = getSetting(name);
    setting.Value = value;
    setSettings([...settings]);
    await setSetting(name, value);
    getSettings();
  };

  groupToIndex("");
  groupToIndex("ar");

  for (const s of settings) {
    const fullGroup = s.Group;

    if (!matchPrefix(fullGroup, prefix)) {
      continue;
    }

    const nestedPath = stripPrefix(fullGroup, prefix);
    const thisGroup = getNextPart(nestedPath);
    const index = groupToIndex(thisGroup);
    void index;

    allGroups.add(thisGroup);

    if (isFlat(nestedPath)) {
      if (groupToSettings.has(thisGroup)) {
        const settings = groupToSettings.get(thisGroup);
        settings.push(s);
        groupToSettings.set(thisGroup, settings);
      } else {
        groupToSettings.set(thisGroup, [s]);
      }
    } else {
      nestedGroups.add(thisGroup);
    }
  }

  const sortedGroups = Array.from(allGroups.values()).sort(
    (a, b) => groupToIndex(a) - groupToIndex(b)
  );

  const getSettings = useCallback(async () => {
    xfetch("/configuration")
      .then((res) => res.json())
      .then(async (data) => {
        setSettings(data);
      })
      .catch((e) => {
        xconsole.error("SettingsPage getSettings", e);
      });
  }, [setSettings]);

  useEffect(() => {
    getStatus();
    getSettings();
  }, [getSettings]);

  return (
    <DefaultLayout
      bgColor={theme.palette.neutral?.main ?? theme.palette.background.default}
      menuBorder
      backLabel={t("back")}
      pageTitle="Settings"
      backNavigate={-1}
      menuItems={
        <>
          <Typography variant="h3" sx={{ width: "100%", textAlign: "center" }}>
            {prefix ? t(`settings.headers.${prefix}`) : t("settings.title")}
          </Typography>

          <Button
            onClick={() => {
              navigate("/vitals");
            }}
            variant="contained"
          >
            <Typography variant="body2">{t("vitals.vitals")}</Typography>
          </Button>
        </>
      }
    >
      <Container
        maxWidth="sm"
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          flex: 1,
        }}
      >
        <Stack direction="column">
          {!prefix && (
            <>
              <section
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "baseline",
                }}
              >
                <Typography variant="body2">{t("Version")}</Typography>

                <Button
                  variant="text"
                  onClick={() => devModeClick()}
                  sx={{
                    mb: 1,
                    p: 0,
                    color: theme.palette.text.primary,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ opacity: 0.5 }}
                  >
                    {`v:${appBuild}`}
                  </Typography>
                </Button>

                {devMode && (
                  <>
                    <Button
                      onClick={forgetknowns}
                      variant="contained"
                      sx={{ mb: 3 }}
                    >
                      <Typography variant="body2">
                        {t("settings.forgetknowns")}
                      </Typography>
                    </Button>
                  </>
                )}
              </section>
              <Divider />
            </>
          )}

          {appUser?.Platform !== "iOS" && prefix === "ar" && (
            <section>
              <>
                <List sx={{ mb: 1 }}>
                  <Typography variant="body2">
                    {t("Glasses Support")}
                  </Typography>

                  <ListItem style={{ padding: 0 }}>
                    <ListItemText>
                      <Typography variant="body2" component="div">
                        {t("APK_Install_Message")}
                      </Typography>

                      <Button
                        onClick={() => navigate("/installers")}
                        variant="contained"
                        sx={{ mt: 1 }}
                      >
                        <Typography variant="body2">
                          {t("Browse Glasses")} →
                        </Typography>
                      </Button>
                    </ListItemText>
                  </ListItem>
                </List>

                <Divider />
              </>
            </section>
          )}

          {sortedGroups.map((group) => (
            <React.Fragment key={group}>
              {groupToSettings.has(group) &&
                groupToSettings.get(group).map((s) => (
                  <React.Fragment key={s.Name}>
                    {group !== "developer" ||
                    (group === "developer" && devMode) ? (
                      <>
                        <SettingsGroup
                          setting={s}
                          updateSetting={(setting, value) =>
                            updateSetting(setting.Name, `${value}`)
                          }
                        />

                        <Divider />
                      </>
                    ) : null}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}

          {sortedGroups.map((group) => (
            <React.Fragment key={group}>
              {nestedGroups.has(group) &&
                group &&
                ((group === "developer" && devMode) ||
                  group !== "developer") && (
                  <React.Fragment key={group}>
                    <Button
                      onClick={() => {
                        navigate(
                          `/settings/${prefix ? prefix + "/" : ""}${group}`
                        );
                      }}
                      variant="text"
                      color="secondary"
                      sx={{
                        my: 1,
                        justifyContent: "left",
                        pl: 0,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: "bold",
                            color: theme.palette.primary.contrastText,
                          }}
                        >
                          {t(`settings.navigationButtons.${group}`)}
                        </Typography>

                        <ArrowRight
                          fontSize="large"
                          sx={{ color: theme.palette.primary.contrastText }}
                        />
                      </div>
                    </Button>

                    <Divider />
                  </React.Fragment>
                )}
            </React.Fragment>
          ))}
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: "25vh", mt: 2 }}
        >
          <Logo type="logomark" style={{ opacity: 0.5, width: "100px" }}></Logo>
        </Stack>

        {/* Hook Rendered Elements */}
        {Dialog.Dialog}
      </Container>
    </DefaultLayout>
  );
};

export default SettingsPage;
