import { getState, setSetting } from "utils/settings";
import xconsole from "utils/xconsole";
import { xfetch } from "utils/xfetch";

export type LangSettingType = "SourceLanguage" | "TargetLanguage"; // LocalizationLanguage

export type LanguageNode = {
  cloud: boolean;
  entitlement: string;
  key: string;
  langcode: string;
  label?: string;
  local_down: number;
  local_size: number;
  speaker_id: boolean;
  vendor: string;
}

export type LanguageGroup = { [key: string]: LanguageNode[] };

/**
 * Get Languages From unity
 * @param type (translation | transcription)
 * @returns Payload of languages with the .data as an array of LanguageNodes
 */
export async function getLanguages(
  type: LangSettingType
): Promise<LanguageNode[]> {
  let langs = [];
  try {
    const group = type === "SourceLanguage" ? "spoken" : "subtitle";
    const call = await xfetch(`/language/get?group=${group}&subList=all`);
    const res = await call.json();
    langs = res ?? [];
  } catch(e) {
    xconsole.error("fail getLanguages",e);
  }
  return langs;
}

/**
 * Set Active Language
 * Provide the type (SourceLanguage for example) and the language key
 */
export async function setActiveLanguage(key:string, type: LangSettingType):Promise<Boolean> {
  try {
    await setSetting(type, key);
    return true;
  } catch(e) {
    xconsole.error(`fail setActiveLanguage Error ${type} ${key}`,e);
    return false;
  }
}

/**
 * Get Active Language
 * Provide the type (transcription | translation)
 * TODO:// needs to be wired up to Unity
 */
export async function getActiveLanguage(type: LangSettingType):Promise<string> {
  let lang = await getState(type);
  return lang;
}

export function isLangDownloaded(langNode: LanguageNode) {
  return langNode.cloud ? true : langNode.local_down === langNode.local_size
}
