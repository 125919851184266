import xconsole from "utils/xconsole";

declare var window: any;

/**
 * Use Google Analytics Hook
 *
 * const ga = useGoogleAnalytics();
 *
 * How to track specific events
 *
 * ga.event("subtitles","start")
 * ga.event("subtitles","stop")
 *
 * How to set custom dimensions
 *
 * ga.set('UserId', '1234567')
 *
 */

function useGoogleAnalytics() {
  return {
    event(name:string, params?:any) {
      if(window['gtag']) {
        window.gtag("event", name, params);
      }
      xconsole.log(`event: ${name} ${params ? `data: ${JSON.stringify(params)}` : ""}`);
    },
    set(name:string, params:any) {
      if(window["gtag"]) {
        let obj:{[key:string]:any} = {};
        obj[name] = params;
        window.gtag("set", obj);
      }
    }
  }
}

export default useGoogleAnalytics;
