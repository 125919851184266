import { toJSONPostRequest, xfetchLocal } from "utils/xfetch";

export default function sendEmail(to:string, subject:string, body:string) {
  const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
  toJSONPostRequest({ url: mailtoLink }).then(
    (payload) => {
      xfetchLocal(
        '/openurl',
        payload
      )
    }
  );
}
