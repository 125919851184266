import {
  CloseOutlined,
  Pause,
  PlayArrow,
} from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";

interface PlayerProps {
  src: string;
  autoPlay?: boolean;
  onFinish?: () => void;
  onClose?: () => void;
  onPause?: () => void;
  onUpdate?: () => void;
}

const smallIcons = {
  height: "32px",
  width: "32px",
};

const xSmallIcons = {
  height: "28px",
  width: "28px",
};


function formatTime(_seconds: number = 0) {
  let seconds = isNaN(_seconds) ? 0 : _seconds;
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);
  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${
    s.toString().padStart(2, "0")
  }`;
}

function percentage(num: number, max: number) {
  const percent = (num / max) * 100;
  return isNaN(percent) ? 0 : percent;
}

export default function AudioPlayer(props: PlayerProps) {
  const [duration, setDuration] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [playing, setPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const audioRef = useRef<HTMLAudioElement>();

  function togglePlay() {
    if (playing) audioRef.current.pause();
    if (!playing) audioRef.current.play();
  }

  function close() {
    audioRef.current.pause();
    if (props.onFinish) props.onFinish();
    if (props.onClose) props.onClose();
  }


  useEffect(() => {
    if(audioRef.current) {
      const audio = audioRef.current;
      const started = () => setPlaying(true);
      const paused = () => setPlaying(false);
      const ended = () => setPlaying(false);
      const loaded = async () => {
        setDuration(audio.duration);
        setLoading(false);
      };
      const timeupdate = () => setCurrentTime(audio.currentTime);
      
      audio.addEventListener("play", started);
      audio.addEventListener("loadeddata", loaded);
      audio.addEventListener("pause", paused);
      audio.addEventListener("ended", ended);
      audio.addEventListener("timeupdate", timeupdate);

      return () => {
        audio.removeEventListener("play", started);
        audio.removeEventListener("paused", paused);
        audio.removeEventListener("loadeddata", loaded);
        audio.removeEventListener("ended", ended);
        audio.removeEventListener("timeupdate", timeupdate);
      };
    }
    // 
    
  }, [audioRef]);



  return (
    <Paper
      elevation={2}
      sx={{ borderRadius: 0, py:"16px", background: "transparent", position: "relative" }}
    >
      <Stack spacing={2} direction="row" alignItems="center" sx={{px:"8px", width:"100%"}}>
        {loading && (
          <IconButton>
            <CircularProgress sx={{ width:'28px', height:'28px' }} />
          </IconButton>
        )}
        {!loading && playing && (
          <IconButton onClick={() => togglePlay()}>
            <Pause sx={smallIcons} />
          </IconButton>
        )}
        {!loading && !playing && (
          <IconButton onClick={() => togglePlay()}>
            <PlayArrow sx={smallIcons} />
          </IconButton>
        )}
        <Stack sx={{width: "100%"}} spacing={1}>
          <LinearProgress
            variant="determinate"
            color="primary"
            sx={{ width: "100%" }}
            value={percentage(currentTime, duration)}
          />
          <Typography component="div" variant="body2" sx={{flex: 1, width:"100%", flexDirection: "row", justifyContent:"space-between"}}>
            <Stack sx={{width:"100%"}} direction="row" justifyContent="space-between">
              <div>{formatTime(currentTime)}</div>
              <div>
                {!!duration && formatTime(duration)}
                {!duration && formatTime(0)}
              </div>
            </Stack>
          </Typography>
        </Stack>
        
        <IconButton sx={{  }} onClick={() => close()}>
          <CloseOutlined sx={xSmallIcons}></CloseOutlined>
        </IconButton>
      </Stack>

      <audio ref={audioRef}>
        <source src={props.src} type="audio/wav" />
      </audio>

      
    </Paper>
  );
}
