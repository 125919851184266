import { toJSONPostRequest, xfetch } from "utils/xfetch";

type GPTCommands = "ask" | "setModel" | "getModel" | "status" | "usage" | "startChat" | "stopChat";
interface Props {
  prompt?: string;
  command?: GPTCommands;
}
interface GPTResponse {
  command?: GPTCommands;
  response: string;
  prompt?: string;
}
export default async function queryChatGPT(props: Props): Promise<GPTResponse> {
  const payload = await toJSONPostRequest({
    prompt: props.prompt,
    command: "ask"
  });
  const response = await xfetch("/gpt", payload);
  if (response.status === 401) {
    throw new Error("Invalid API Key");
  } else
  if (response.status === 500) {
    throw new Error("Unavailable");
  } else {
    const data = await response.json();
    return {
      command: props.command,
      response: data.response,
      prompt: props.prompt
    };
  }
}
