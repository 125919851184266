import React from "react";
import { useStore, useStoreUnity } from "state/store";
import { Stack } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { Box, colors, Fade, styled, Typography } from "@mui/material";
import { TFunction } from "i18next";
import {
  darkenColor,
  getContrastColorFromBase,
  xraiIndexToColor,
} from "colors/Utils";
import { TRADITIONAL_PALETTES, hues, speakerColors } from "colors/Palettes";

interface Props {
  text: string; //transcript text
  number?: number; //sequence number
  speakerId?: string; //speakerId
  speakerLabel?: string; //speakerLabel
  color?: string; //color
  date?: string;
  fluid?: boolean;
  nextLabel?: string;
  t: TFunction<"translation", undefined, "translation">;
}

const CompactSpeakerBar = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "0px",
  top: "0px",
  bottom: "0",
  width: "5px",
  borderRadius: "10px",
  opacity: "100%",
  backgroundColor: theme.palette.secondary.main,
}));

const CompactSpeakerBarFlash = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "0px",
  top: "0px",
  bottom: "0",
  width: "5px",
  borderRadius: "10px",
  opacity: "100%",
  animation: "flash 1s infinite",
  backgroundColor: theme.palette.primary.main,
}));

//@ts-ignore
const segmenter = new Intl.Segmenter(undefined, { granularity: "word" });
const splitText = (text: string): Array<{ word: string; last: boolean }> => {
  const segments: Array<any> = Array.from(segmenter.segment(text));
  return segments.map((seg, index) => {
    return {
      word: seg.segment,
      last: index === segments.length - 1,
    };
  });
};

// hacky code to detect light and adapt the subtitle item to make it more visible in light bg
function getLuminance(hexColor: string) {
  const r = parseInt(hexColor.substring(1, 3), 16) / 255;
  const g = parseInt(hexColor.substring(3, 5), 16) / 255;
  const b = parseInt(hexColor.substring(5, 7), 16) / 255;

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

let indexMap: Map<string, number> = new Map();
let nextIndex: number = 0;

const getStringIndex = (key: string): number => {
  if (!key) return 0;
  if (!indexMap.has(key)) {
    indexMap.set(key, nextIndex++);
    return nextIndex;
  }
  return indexMap.get(key);
};

//reserve empty and XRAI colors as 0 and 1
getStringIndex("");
getStringIndex("XRAI");

export default function SubtitleItem(props: Props) {
  const theme = useTheme();
  const isLightBackground =
    getLuminance(theme.palette.primary.contrastText) < 0.7;
  const [maxFontSize] = useStore<number>("maxFontSize");
  const [subtitleBackgroundColor] = useStoreUnity<number>(
    "SubtitleBackColor",
    -1
  );

  const getSpeakerColor = (color: string, speakerLabel: string) => {
    if (speakerLabel === "XRAI") {
      return theme.palette.mode === "dark"
        ? theme.palette.primary.light
        : theme.palette.primary.dark;
    }

    const isTradationalPalette = TRADITIONAL_PALETTES.includes(
      theme.palette.name
    );

    const isRedBullPalette = theme.palette.name === "xrai-redbull-palette";

    if (color && isTradationalPalette) {
      return getContrastColorFromBase(
        theme.palette.neutral?.main ?? theme.palette.background.default,
        color
      );
    } else {
      const speaker = speakerLabel?.replace("Speaker", "").trim() ?? "";

      let colorIndex = getStringIndex(speaker);

      if (isNaN(colorIndex)) {
        colorIndex = 0;
      }

      // Used for custom palettes
      let colorTheme = hues[hues.indexOf(theme.palette.name as any)];
      if (theme.palette.name === "xrai-neutral-palette") {
        colorTheme = "grey";
      }

      const getThemedColors = () => Object.entries(colors?.[colorTheme] ?? {});

      const themedColors = (i: number) =>
        (theme.palette.mode === "dark"
          ? getThemedColors()
          : getThemedColors()?.reverse()
        )?.find((key, index) => {
          if (index === i - 1 && colorTheme) {
            return (colors as any)[colorTheme][key[0]];
          }

          return false;
        })?.[1];

      // This is used in the event we run out of colors for the number of speakers.
      const hueColor = hues[colorIndex];
      const lightColors = colors[hueColor]?.[300];
      const darkColors = colors[hueColor]?.[700];

      let speakerColor;
      if (theme.palette.mode === "light") {
        if (speakerColors[colorIndex] && isTradationalPalette) {
          speakerColor = darkenColor(speakerColors[colorIndex], 0.2);
        } else {
          speakerColor =
            getContrastColorFromBase(
              theme.palette.neutral?.main ?? theme.palette.background.default,
              isRedBullPalette
                ? theme.palette.primary.main
                : themedColors(colorIndex)
            ) ?? darkColors;
        }
      } else {
        if (speakerColors[colorIndex] && isTradationalPalette) {
          speakerColor = speakerColors[colorIndex];
        } else {
          speakerColor =
            getContrastColorFromBase(
              theme.palette.neutral?.main ?? theme.palette.background.default,
              isRedBullPalette
                ? theme.palette.primary.main
                : themedColors(colorIndex)
            ) ?? lightColors;
        }
      }

      return speakerColor;
    }
  };

  const color = getSpeakerColor(props.color, props.speakerLabel);
  const backgroundColor = xraiIndexToColor(subtitleBackgroundColor);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        position: "relative",
        paddingRight: "12px",
        backgroundColor: backgroundColor,
      }}
    >
      {!props.fluid && (
        <div
          className="subtitle-item"
          style={{
            display: "flex",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Stack>
            <CompactSpeakerBar
              style={{
                backgroundColor: color ?? theme.palette.panel?.contrastText,
              }}
            />

            <Typography
              component="div"
              className="subtitle-text"
              sx={{
                fontSize: maxFontSize,
              }}
            >
              {props.text ? props.text : <span>&nbsp;</span>}
            </Typography>

            {props.nextLabel !== props.speakerLabel &&
              !(props.speakerLabel === "XRAI") && (
                <div>
                  <Typography
                    className="speaker-label"
                    component="h1"
                    sx={{
                      color: color,
                      fontWeight: isLightBackground
                        ? "bold !important"
                        : "normal",
                    }}
                  >
                    {props.speakerLabel.includes("Speaker")
                      ? props.speakerLabel
                      : `${props.t("Speaker")} ${props.speakerLabel}`}
                  </Typography>
                </div>
              )}
          </Stack>
        </div>
      )}

      {props.fluid && props.text !== "" && (
        <div
          style={{
            position: "relative",
            display: "flex",
          }}
        >
          <CompactSpeakerBarFlash
            sx={{
              backgroundColor: color ?? theme.palette.panel?.contrastText,
            }}
          />

          <Typography
            component="div"
            className="subtitle-text"
            sx={{ fontSize: maxFontSize }}
          >
            {splitText(props.text).map((payload, index) => {
              if (payload.last) {
                return (
                  <Fade key={index} in={true} timeout={1000}>
                    <span>
                      <span>{payload.word}</span>
                    </span>
                  </Fade>
                );
              } else {
                return <span key={index}>{payload.word}</span>;
              }
            })}
            <span>&nbsp;</span>
          </Typography>
        </div>
      )}
    </Stack>
  );
}
