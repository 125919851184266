// AudioChannelPage.tsx
import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Container,
  TextField,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Input,
  useTheme,
} from "@mui/material";
import { xfetchLocal } from "utils/xfetch";
import DefaultLayout from "comps/layout/DefaultLayout";
import { useTranslation } from "react-i18next";
import { MicOff, MicRounded } from "@mui/icons-material";
import ChannelProfileSelector from "comps/Dante/ChannelProfileSelector";
import { Channel } from "types/types";
import { isEqual } from "lodash";
import useChannelProfile from "comps/Dante/useChannelProfile";
import { useDialog } from "hooks/useDialog";
import { useXRaiSnackbar } from "hooks/useXraiSnackbar";
import xconsole from "utils/xconsole";

const mockChannels: Channel[] = [
  {
    name: "Channel 1",
    codes: ["en", "es", "fr"],
    muted: false,
  },
  {
    name: "Channel 2",
    codes: ["en", "es"],
    muted: false,
  },
  {
    name: "Channel 3",
    codes: ["en", "fr"],
    muted: false,
  },
  {
    name: "Channel 4",
    codes: ["en"],
    muted: false,
  },
  {
    name: "Channel 5",
    codes: ["en", "es", "fr"],
    muted: false,
  },
  {
    name: "Channel 6",
    codes: ["en", "es", "fr", "de", "it", "pt", "ja", "ko"],
    muted: false,
  },
  {
    name: "Channel 7",
    codes: ["en", "es", "fr", "de", "it", "pt", "ja", "ko"],
    muted: false,
  },
  {
    name: "Channel 8",
    codes: ["en", "es", "fr", "de", "it", "pt", "ja", "ko"],
    muted: false,
  },
];
void mockChannels;

const mockLangs = [
  "en",
  "es",
  "fr",
  "de",
  "it",
  "pt",
  // "ja",
  // "ko",
  // "en",
  // "es",
  // "fr",
  // "de",
  // "it",
  // "pt",
  // "ja",
  // "ko",
];

const goodProfile = (profile:Channel[]) => {
  return profile && Array.isArray(profile) && profile.length > 0;
};
void goodProfile;

const AudioChannelPage = () => {
  const Dialog = useDialog();
  const theme = useTheme();
  const { t } = useTranslation();

  const [langs, setLangs] = useState<string[]>([]);

  const profileKeyRef = useRef<string>("");

  const {
    keys: profileKeys,
    select: selectProfile,
    upsert: upsertProfile,
    remove: deleteProfile,
    submit: submitProfile,
    active: activeProfile,
  } = useChannelProfile();

  const AlertSnack = useXRaiSnackbar({
    position: "top",
    color: "error",
  });

  const [loadedProfileKey, setLoadedProfileKey] = useState<string>("");
  const [loadedProfile, setLoadedProfile] = useState<Channel[]>([]);
  const [editedProfile, setEditedProfile] = useState<Channel[]>([]);

  const [activeDirty, setActiveDirty] = useState<boolean>(false);
  const [editedDirty, setEditedDirty] = useState<boolean>(false);

  const selectProfileWrap = useCallback((key: string) => {
    const profile = selectProfile(key);
    setLoadedProfileKey(key);
    setLoadedProfile(profile);
    setEditedProfile(profile);
  },[selectProfile,setLoadedProfileKey,setLoadedProfile,setEditedProfile]);

  const handleLanguageChange = (index: number, newCodes: string[]) => {
    setEditedProfile((prev) =>
      prev.map((channel, i) =>
        i === index ? { ...channel, codes: newCodes } : channel
      )
    );
  };

  const handleNameChange = (index: number, newName: string) => {
    setEditedProfile((prev) =>
      prev.map((channel, i) =>
        i === index ? { ...channel, name: newName } : channel
      )
    );
  };

  const handleChannelMute = (index: number, muted: boolean) => {
    setEditedProfile((prev) =>
      prev.map((channel, i) => (i === index ? { ...channel, muted } : channel))
    );

    //this is an expensive call and for Money 2020 we are actually treating "mute"
    //as a long term disable between sessions. Revist after. HACK TODO
    //handleSubmit();
  };

  const handleUndo = () => {
    setEditedProfile(loadedProfile);
  };

  const handleSubmit = useCallback(async () => {
    submitProfile(editedProfile);
    selectProfileWrap("Active");
  },[submitProfile, editedProfile, selectProfileWrap]);

  const handleProfileSaveDialog = (): Promise<"cancel" | "save"> => {
    return new Promise(async (resolve) => {
      Dialog.showDialog({
        title: t("settings.DanteInterface.saveProfile"),
        message: t("settings.DanteInterface.saveProfileMessage"),
        body: (
          <Input
            defaultValue={loadedProfileKey}
            autoFocus
            placeholder={t("settings.DanteInterface.profileName")}
            sx={{ width: "calc(100% - 48px)", margin: "0 24px 12px" }}
            onChange={(e) => {
              profileKeyRef.current = e.target.value;
            }}
          />
        ),
        buttons: [
          {
            label: t("Cancel"),
            action() {
              resolve("cancel");
            },
          },
          {
            label: t("Save"),
            action() {
              resolve("save");
            },
          },
        ],
      });
    });
  };

  const handleProfileDeleteDialog = (): Promise<"cancel" | "delete"> => {
    return new Promise(async (resolve) => {
      Dialog.showDialog({
        title: t("settings.DanteInterface.deleteProfile"),
        message: t("settings.DanteInterface.deleteProfileMessage"),
        buttons: [
          {
            label: t("Cancel"),
            action() {
              resolve("cancel");
            },
          },
          {
            label: t("Delete"),
            action() {
              resolve("delete");
            },
          },
        ],
      });
    });
  };

  const handleProfileSave = async () => {
    const result = await handleProfileSaveDialog();

    if (result === "save") {
      if (
        profileKeyRef.current?.toLocaleLowerCase() === "active" ||
        profileKeyRef.current === t("Active")
      ) {
        AlertSnack.error(t("settings.DanteInterface.profileNameError"));
        return;
      }

      // This is the result of saving the profile without altering the name.
      if (profileKeyRef.current === "" && loadedProfileKey !== "") {
        profileKeyRef.current = loadedProfileKey;
      }

      upsertProfile(profileKeyRef.current, editedProfile);
      selectProfileWrap(profileKeyRef.current);
      setLoadedProfile(editedProfile);
      setEditedProfile(editedProfile);
    }

    profileKeyRef.current = "";
    Dialog.closeDialog();
  };

  const handleProfileDelete = async () => {
    const result = await handleProfileDeleteDialog();
    if (result === "delete") {
      deleteProfile(loadedProfileKey);
      Dialog.closeDialog();
    } else {
      Dialog.closeDialog();
    }
  };

  useEffect(() => {

    selectProfileWrap("Active");

    const fetchChannels = async () => {

      const langRes = await xfetchLocal("/getdantelangs");
      const langJsn = await langRes.json();

      if (Array.isArray(langJsn)) {
        setLangs(mockLangs);
      } else {
        xconsole.error("/getdantelangs fail", langJsn);
      }
    };

    fetchChannels();

    return () => {
      setLangs([]);
    };
  }, [selectProfileWrap]);

  useEffect(() => {
  //setEditedProfile((editedProfile) => {
  //  return goodProfile(editedProfile) ? editedProfile :
  //         goodProfile(activeProfile) ? activeProfile : editedProfile;
  //});
    setActiveDirty(!isEqual(activeProfile, editedProfile));
    setEditedDirty(!isEqual(editedProfile, loadedProfile));
  }, [activeProfile, loadedProfile, editedProfile]);

  useEffect(() => {
    setEditedProfile(activeProfile);
  }, [activeProfile, setEditedProfile]);

  return (
    <DefaultLayout pageTitle="audio-channel-setup" backNavigate={-1}>
      <section>
        <Typography
          variant="h4"
          sx={{ mt: 2, mb: 1, display: "flex", justifyContent: "center" }}
        >
          {t("audio-channel-setup")}
        </Typography>
      </section>

      <Container>
        <Divider sx={{ mb: 2 }} />
      </Container>

      <Container sx={{ overflow: "hidden", flex: 1 }}>
        <section style={{ display: "flex", height: "100%" }}>
          <Container sx={{ flex: 1, overflowY: "scroll", width: "60%" }}>
            {Array.isArray(editedProfile) &&
              editedProfile.map((channel, index) => (
                <div key={`Channel ${index}`}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        width: "16px",
                        textAlign: "center",
                        marginRight: "4px",
                        color: channel.muted ? theme.palette.error.main : "",
                      }}
                    >
                      {t(`${index + 1}`)}
                    </Typography>

                    <TextField
                      size="small"
                      InputProps={{
                        sx: { fontSize: "0.75rem" },
                      }}
                      sx={{
                        ml: 0,
                        borderRadius: "4px",
                        border: channel.muted
                          ? `1px solid ${theme.palette.error.main}`
                          : "",
                      }}
                      variant="outlined"
                      value={channel.name}
                      onChange={(e) => handleNameChange(index, e.target.value)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginBottom: "8px",
                    }}
                  >
                    <div
                      style={{
                        width: "16px",
                        marginRight: "4px",
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      {channel.muted ? (
                        <MicOff
                          sx={{
                            marginRight: "4px",
                            cursor: "pointer",
                            height: "16px",
                            width: "16px",
                            color: `${theme.palette.error.main} !important`,
                          }}
                          onClick={() =>
                            handleChannelMute(index, !channel.muted)
                          }
                        />
                      ) : (
                        <MicRounded
                          sx={{
                            marginRight: "4px",
                            cursor: "pointer",
                            height: "16px",
                            width: "16px",
                          }}
                          onClick={() =>
                            handleChannelMute(index, !channel.muted)
                          }
                        />
                      )}
                    </div>

                    <ToggleButtonGroup
                      color="primary"
                      exclusive={false}
                      value={channel.codes}
                      onChange={(_, newLanguages) =>
                        handleLanguageChange(index, newLanguages)
                      }
                      sx={{ ml: 0, width: "50%" }}
                    >
                      {langs.map((lang) => (
                        <ToggleButton
                          key={lang}
                          value={lang}
                          size="small"
                          sx={{ fontSize: "0.75rem" }}
                        >
                          {lang}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </div>
                </div>
              ))}

            <Button
              size="small"
              disabled={!editedDirty}
              variant="contained"
              color="error"
              sx={{
                ml: "19px",
                mb: 8,
                mr: 1,
                fontSize: "0.75rem",
                color: theme.palette.common.white,
              }}
              onClick={handleUndo}
            >
              {t("Undo")}
            </Button>

            <Button
              size="small"
              disabled={!activeDirty}
              variant="contained"
              color="primary"
              sx={{
                mb: 8,
                fontSize: "0.75rem",
                color: theme.palette.common.white,
              }}
              onClick={handleSubmit}
            >
              {t("Submit")}
            </Button>
          </Container>

          <Container sx={{ width: "40%" }}>
            <ChannelProfileSelector
              current={loadedProfileKey}
              options={profileKeys}
              selectProfile={selectProfileWrap}
            />

            <section style={{ display: "flex" }}>
              <Button
                disabled={!loadedProfileKey}
                size="small"
                variant="contained"
                color="error"
                sx={{
                  mb: 8,
                  mr: 1,
                  fontSize: "0.75rem",
                  color: theme.palette.common.white,
                }}
                onClick={handleProfileDelete}
              >
                {t("settings.DanteInterface.deleteProfile")}
              </Button>

              <Button
                size="small"
                variant="contained"
                color="primary"
                sx={{
                  mb: 8,
                  fontSize: "0.75rem",
                  color: theme.palette.common.white,
                }}
                onClick={handleProfileSave}
              >
                {t("settings.DanteInterface.saveProfile")}
              </Button>
            </section>
          </Container>
        </section>
      </Container>

      {/* Hook-rendered comps */}
      {Dialog.Dialog}
      {AlertSnack.Snackbar}
    </DefaultLayout>
  );
};

export default AudioChannelPage;
