import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";

import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import InfoIcon from "@mui/icons-material/Info";
import {
  Cancel,
  Check,
  Close,
  CloseOutlined,
  Translate,
} from "@mui/icons-material";

import { useStore, useStoreUnity } from "state/store";
import { useTranslation } from "react-i18next";
import LanguageList from "./LanguageList";
import { FavoriteLanguagePayload } from "./types";

import ClickableTooltip from "comps/ClickableTooltip";
import useActivePopup from "hooks/useActivePopup";
import useOrientation from "hooks/useOrientation";

type Props = {};

const LanguageDrawer: React.FC<Props> = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const [targetLanguage] = useStoreUnity<string>("TargetLanguageInfo");
  const isAutoReverse = targetLanguage === "_xb";

  const [iconSize] = useStore<number>("rootIconSize");
  const [isOpen, setOpen] = useStore<boolean>("languageDrawerOpen", false);
  const [languageToggleValue, setLanguageToggleValue] = useStore<"from" | "to">(
    "languageToggleValue",
    "from"
  );
  const [selectedLanguages] = useStore<string[]>("selectedLanguages", []);
  const [, setTriggerSubtitleLanguageChange] =
    useStore<FavoriteLanguagePayload>("triggerSubtitleLanguageChange", null);
  const [expanded, setExpanded] = useStore<string | undefined>(
    `lang-expanded`,
    undefined
  );

  const orientation = useOrientation();
  const theme = useTheme();
  const { t } = useTranslation();
  useActivePopup(isOpen);

  const id = isOpen ? "LanguageDrawer--popover" : undefined;
  const maxSelectedFromLanguages = 10;
  const maxSelectedToLanguages = 1;

  const handleLanguageToggleValue = (
    event: React.MouseEvent<HTMLElement>,
    toggleValue: "from" | "to"
  ) => {
    if (toggleValue !== null) {
      setLanguageToggleValue(toggleValue);
      setLoading(true);
    }
  };

  const toggleAutoReverse = () => {
    const AutoReversePayload: FavoriteLanguagePayload = {
      disabled: false,
      key: "xrai._xb-RV",
      langcode: "_xb",
      multiSupport: false,
      selected: true,
      vendor: "xrai",
    };

    const NoTranslationPayload: FavoriteLanguagePayload = {
      disabled: false,
      key: "xrai._xa-SP",
      langcode: "_xa",
      multiSupport: false,
      selected: true,
      vendor: "xrai",
    };

    setTriggerSubtitleLanguageChange(
      isAutoReverse ? NoTranslationPayload : AutoReversePayload
    );
  };

  React.useEffect(() => {
    // Remount component to trigger loading.
    if (loading) {
      setLoading(false);
    }
  }, [loading]);

  return (
    <div>
      <IconButton
        id="LanguageDrawer--iconButton"
        onClick={() => setOpen(true)}
        aria-describedby={id}
      >
        <Translate sx={{ height: iconSize, width: iconSize }} />
      </IconButton>

      <Drawer
        id={id}
        className="language-drawer"
        anchor="bottom"
        open={isOpen}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            width: "100%",
            borderRadius: "12px 12px 0 0",
            height: "85%",
            maxWidth: orientation === "landscape" ? "700px" : "500px",
            margin: "0 auto",
            backgroundColor:
              theme.palette.panel?.main ?? theme.palette.background.paper,
            backgroundImage: theme.palette.image?.panel ?? "none",
            backgroundSize: "cover", // Adjusts image to cover the whole container
            backgroundRepeat: "no-repeat", // Prevents image from repeating
            backgroundPosition: "center", // Centers the image
          },
        }}
      >
        <Stack
          direction="column"
          sx={{
            height: "100%",
            width: "100%",
            px: 0.5,
            py: 0.5,
            overflow: "hidden",
          }}
        >
          <Paper
            sx={{
              height: "100%",
              width: "100%",
              borderRadius: "12px 12px 0 0",
              pt: 2,
              pb: 1,
              display: "flex",
              flexDirection: orientation === "landscape" ? "row" : "column",
              overflow: "hidden",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              backgroundColor: theme.palette.neutral?.main,
              backgroundImage: theme.palette.image?.neutral ?? "none",
              backgroundSize: "cover", // Adjusts image to cover the whole container
              backgroundRepeat: "no-repeat", // Prevents image from repeating
              backgroundPosition: "center", // Centers the image
            }}
          >
            <Stack
              sx={{
                borderRight: orientation === "landscape" ? "1px solid" : "none",
                overflow: orientation === "landscape" ? "auto" : "unset",
              }}
            >
              {/* AUTO-REVERSE ICON, LABEL, TOGGLE */}
              <Container
                sx={{
                  flex: "0 0 auto",
                  pr: orientation === "landscape" ? "0px !important" : "",
                }}
              >
                <FormControl fullWidth>
                  <FormGroup>
                    <FormControlLabel
                      sx={{
                        m: 0,
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                      control={
                        <Switch
                          checked={isAutoReverse}
                          onChange={toggleAutoReverse}
                        />
                      }
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <SwapHorizIcon
                            sx={{ height: iconSize, width: iconSize }}
                          />

                          <Typography
                            variant="h6"
                            sx={{
                              mx: 1,
                            }}
                          >
                            {t("autoReverse")}
                          </Typography>

                          <ClickableTooltip
                            id="AutoReverseTooltip"
                            title={t("autoReverseTooltip")}
                          >
                            <InfoIcon
                              sx={{
                                height: iconSize / 1.5,
                                width: iconSize / 1.5,
                                display: "flex",
                              }}
                            />
                          </ClickableTooltip>
                        </div>
                      }
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
              </Container>

              {/* FROM / TO INFO */}
              {!isAutoReverse && (
                <Container
                  sx={{
                    flex: "0 0 auto",
                    display: "flex",
                    mt: 2,
                    alignItems: "center",
                    justifyContent: "space-between",
                    pr: orientation === "landscape" ? "0px !important" : "",
                  }}
                >
                  <Typography variant="h6">
                    {languageToggleValue === "from"
                      ? t("language.select_langs")
                      : t("language.translateDirections")}
                  </Typography>

                  <div
                    style={{
                      justifyContent: "end",
                      display: "flex",
                      alignItems: "center",
                      paddingRight: theme.spacing(1),
                    }}
                  >
                    <Typography variant="h6">
                      {`${selectedLanguages?.length}/${
                        languageToggleValue === "from"
                          ? maxSelectedFromLanguages
                          : maxSelectedToLanguages
                      }`}
                    </Typography>

                    {selectedLanguages?.length > 0 &&
                    selectedLanguages?.length <=
                      (languageToggleValue === "from"
                        ? maxSelectedFromLanguages
                        : maxSelectedToLanguages) ? (
                      <Check sx={{ ml: 1, fill: theme.palette.success.main }} />
                    ) : (
                      <Cancel sx={{ ml: 1, fill: theme.palette.error.main }} />
                    )}
                  </div>
                </Container>
              )}

              {/* FROM / TO TOGGLE SWITCH */}
              {!isAutoReverse && (
                <Container sx={{ my: 2, flex: "0 0 auto" }}>
                  <ToggleButtonGroup
                    value={languageToggleValue}
                    exclusive
                    onChange={handleLanguageToggleValue}
                    aria-label="text alignment"
                    sx={{
                      borderRadius: "50px",
                      width: "100%",
                    }}
                  >
                    <ToggleButton
                      value="from"
                      aria-label="from"
                      sx={{
                        width: "50%",
                        borderRadius: "50px",
                      }}
                    >
                      {t("From")}
                    </ToggleButton>

                    <ToggleButton
                      value="to"
                      aria-label="to"
                      sx={{
                        width: "50%",
                        borderRadius: "50px",
                      }}
                    >
                      {t("To")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Container>
              )}

              {/* AUTO-REVERSE INFO */}
              {isAutoReverse && (
                <Container
                  sx={{
                    flex: "0 0 auto",
                    display: "flex",
                    my: 2,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h6">
                    {t("language.autoReverseDirections")}
                  </Typography>

                  <div
                    style={{
                      justifyContent: "end",
                      display: "flex",
                      alignItems: "center",
                      paddingRight: theme.spacing(1),
                    }}
                  >
                    <Typography variant="h6">
                      {`${selectedLanguages.length}/2`}
                    </Typography>

                    {selectedLanguages.length === 2 ? (
                      <Check sx={{ ml: 1, fill: theme.palette.success.main }} />
                    ) : (
                      <Cancel sx={{ ml: 1, fill: theme.palette.error.main }} />
                    )}
                  </div>
                </Container>
              )}

              {/* SEARCH BOX */}
              <Container sx={{ flex: "0 0 auto", mb: 2 }}>
                <TextField
                  id="LanguageDrawer--search"
                  placeholder={t("Search")}
                  value={searchTerm}
                  onChange={(e) => {
                    if (expanded) {
                      // Collapse any open engine selection dialogs.
                      setExpanded(undefined);
                    }

                    setSearchTerm(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <Close
                        sx={{
                          m: 1,
                          height: iconSize,
                          width: iconSize,
                          fill:
                            searchTerm === ""
                              ? theme.palette.text.disabled
                              : "",
                        }}
                        onClick={() => setSearchTerm("")}
                      />
                    ),
                    sx: {
                      px: 1,
                      borderRadius: "50px",
                    },
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Container>
            </Stack>

            {/* PRIMARY BODY CONTENT */}
            <Container sx={{ flex: "1 1 auto", overflow: "hidden" }}>
              {!loading && (
                <LanguageList
                  languageToggleValue={
                    isAutoReverse ? "from" : languageToggleValue
                  }
                  searchTerm={searchTerm}
                />
              )}

              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: theme.spacing(2),
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              )}
            </Container>
          </Paper>

          {/* BACK BUTTON */}
          <Box
            sx={{
              my: 1,
              flex: 0,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              id="LanguageDrawer--backButton"
              endIcon={<CloseOutlined />}
              onClick={() => setOpen(false)}
              sx={{ backgroundColor: "transparent" }}
              variant="text"
              color="inherit"
            >
              {t("Close")}
            </Button>
          </Box>
        </Stack>
      </Drawer>
    </div>
  );
};

export default LanguageDrawer;
