import React, { useCallback, useEffect, useState } from "react";
import CustomTabPanel from "./CustomTabPanel";
import { PaperSettings } from "./SubtitleSettingsDrawer";
import { List } from "@mui/material";
import { Setting } from "types/types";
import { xfetch } from "utils/xfetch";
import xconsole from "utils/xconsole";
import SettingsGroup from "comps/settings/SettingsGroup";
import { setSetting } from "utils/settings";

// TODO: This is exactly the kind of thing for which group path is intended.
// List of settings to include is this panel. But, let's wait until we overhaul
// our backend Settings as it will provided more advanced functionality.
const ShowSettings = [
  "StreamMode",
  "StreamName",
  "StreamId",
  "StreamPublishCloud",
  "StreamPublishLocal",
];

type Props = {
  activeTab: number;
  index: number;
};

const StreamScreen: React.FC<Props> = ({ activeTab, index }) => {
  const [settings, setSettings] = useState<Map<string, Setting>>(
    new Map<string, Setting>()
  );

  const updateSetting = async (key: string, value: string) => {
    const setting = getSetting(key);
    setting.Value = value;

    setSettings(settings);
    await setSetting(key, value);
    getSettings();
  };

  const makeSettingsMap = useCallback(
    (settings: Setting[]): void => {
      const map = new Map<string, Setting>();

      settings.forEach((setting) => {
        if (ShowSettings.includes(setting.Name)) {
          map.set(setting.Name, setting);
        }
      });

      setSettings(map);
    },
    [setSettings]
  );

  const getSetting = useCallback(
    (key: string): Setting => {
      return settings.get(key) ?? null;
    },
    [settings]
  );

  const getSettings = useCallback(async () => {
    xfetch("/configuration")
      .then((res) => res.json())
      .then(async (data) => {
        makeSettingsMap(data);
      })
      .catch((e) => {
        xconsole.error("StreamTab getSettings", e);
      });
  }, [makeSettingsMap]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <CustomTabPanel value={activeTab} index={index}>
      <PaperSettings sx={{ p: 2 }}>
        <List dense={true}>
          {Array.from(settings?.values())?.map((setting) => (
            <SettingsGroup
              key={setting.Name}
              setting={setting}
              updateSetting={(setting, value) => {
                updateSetting(setting.Name, `${value}`);
              }}
            />
          ))}
        </List>
      </PaperSettings>
    </CustomTabPanel>
  );
};

export default StreamScreen;
