import { useCallback, useEffect, useState } from "react";
import { useStoreLocal, useStoreUnity } from "state/store";
import { Channel } from "types/types";
import { isEqual } from "lodash";

type ObjectMap<K extends string | number | symbol, V> = {
  [key in K]?: V
};

const useChannelProfile = () => {

  const [rowmap, setRowmap ] = useStoreLocal<ObjectMap<string, Channel[]>>(
    "audioProfiles",
    {}
  );
  
  const [keys, setKeys] = useState<string[]>([]);
  const [vals, setVals] = useState<Channel[][]>([]);

  const [target, setTarget] = useStoreUnity<Channel[]>("TargetAudioProfileJson",[]);
  const [active, setActive] = useStoreUnity<Channel[]>("ActiveAudioProfileJson",[]);

  const select = useCallback(
    (key: string) => {
      if (!rowmap || typeof rowmap !== 'object') return [];
      if (key === "Active" ) return active;
      return rowmap[key] ?? [];
    },
    [rowmap, active]
  );

  const remove = useCallback(
    (key: string) => {
      const rowmap_ = { ...rowmap };
      delete rowmap_[key];
      setRowmap(rowmap_);
    },
    [rowmap, setRowmap]
  );

  const upsert = useCallback(
    (key: string, val: Channel[]) => {
      const rowmap_ = { ...rowmap };
      rowmap_[key] = val;
      setRowmap(rowmap_);
    },
    [rowmap, setRowmap]
  );
  
  useEffect(() => {
    if (!rowmap || typeof rowmap !== 'object') return;
    setKeys(["Active",...Object.keys(rowmap)]);
    setVals([active,...Object.values(rowmap)]);
  }, [rowmap, active])
  
  const submit = useCallback(
    async (profile: Channel[]) => {
      if (isEqual(target, profile)) return;
      setTarget(profile);
      // TODO: will do Unity side later
      // const data = await toJSONPostRequest(profile);
      // await xfetchLocal("/postdante", data);
      setActive(profile);
  }, [target, setTarget, setActive]);

  return {
    keys,
    vals,
    select,
    upsert,
    remove,
    submit,
    target,
    active,
  };
};

export default useChannelProfile;
